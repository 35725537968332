import { Button, DialogActions, DialogContent, DialogTitle, makeStyles, IconButton, Dialog } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { IHasId } from "../Models/IHasId";
import { IDataStore } from "../Stores/IDataStore";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    dialogHeader: {
        '& .MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    cancelButton: {
        '& .MuiButton-label': {
            color: '#9094A9'
        }
    }
});

interface DialogProps<T extends IHasId> {
    open: boolean,
    title: string,
    mode: FormMode,
    row: T,
    store: IDataStore<T>,
    onClose: () => void,
    onValidation?: () => Promise<boolean>,
    onSubmit: (result: boolean) => void,
    dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export enum FormMode {
    Add,
    Edit
}

export function CommonFormDialog<T extends IHasId>(props: React.PropsWithChildren<DialogProps<T>>) {
    const { open, onClose } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const handleClose = (value: any) => {
        if (value != "") {
            return;
        }
        onClose();
    };

    const handleSubmit = (entity: T) => {
        const validation = props.onValidation || (() => {
            return new Promise<Boolean>((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 500)
            });
        });
        validation().then(x => {
            if (x) {
                handleCreateOrUpdate(entity);
            }
        })
    }

    const handleCreateOrUpdate = (entity: T) => {
        if (props.mode === FormMode.Add) {
            props.store.add(entity).then(x => {
                props.onSubmit(x);
            });
        } else {
            props.store.update(entity).then(x => {
                props.onSubmit(x);
            });
        }
    }

    return (<Dialog open={open} onClose={() => handleClose("not")} aria-labelledby="form-dialog-title" fullWidth maxWidth={props.dialogWidth || "sm"}>
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            <span>{(props.mode === FormMode.Add ? t('create') : t('edit')) + ' ' + props.title}</span>
            <IconButton onClick={() => handleClose("")}>
                <ClearIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, marginTop: 24 }}>
            <Button variant="outlined" disableElevation className={classes.cancelButton} onClick={() => handleClose("")}>
                {t('cancel')}
            </Button>
            <Button color="primary" variant="contained" disableElevation onClick={() => handleSubmit(props.row)} >
                {t('submit')}
            </Button>
        </DialogActions>
    </Dialog>);
}