import { TrendBizReport, TrendQueryCriteria } from "../Models/Reports";
import { TrendBizReportsService } from "../Services/TrendBizReportsService";
import { BaseGridDataStore } from "./BaseGridDataStore";
import { QueryCriteria } from "../Models/QueryCriteria";
import { extendObservable } from "mobx";
import _ from "lodash";

export class TrendBizReportsStore extends BaseGridDataStore<TrendBizReport>{
    private _service: TrendBizReportsService;

    constructor() {
        let service = new TrendBizReportsService();
        super(service);
        this._service = service;
        extendObservable(this, {
            getTrendReportsSummary: this.getTrendReportsSummary,
            getCompletionReports: this.getCompletionReports,
            getHealthCheckReports: this.getHealthCheckReports,
            getCorrectiveReports: this.getCorrectiveReports
        });
    }
    genCriteria(selectedDate: string) {
        const criteria: QueryCriteria = { skip: this.pageIndex * this.pageSize, take: this.pageSize };
        criteria.filters = [];
        const trendCriteria = criteria as TrendQueryCriteria;
        const filterOrgCompanyId = _.head(
          this.preFilters.filter((f) => f.field === "filterOrgCompanyId")
        );
        filterOrgCompanyId && (trendCriteria.orgCompanyId = filterOrgCompanyId.value);
        trendCriteria.selectedDate = selectedDate;
        return trendCriteria;
    }

    getTrendReportsSummary(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getTrendReportsSummaryAsync(criteria);
    }

    getCompletionReports(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getCompletionReportsAsync(criteria);
    }

    getHealthCheckReports(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getHealthCheckReportsAsync(criteria);
    }

    getCorrectiveReports(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getCorrectiveReportsAsync(criteria);
    }
}