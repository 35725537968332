import axios from "axios";
import { Customer } from "../Models/Customer";
import { BaseService } from "./BaseService";

export class CustomerService extends BaseService<Customer> {
    constructor() {
        super('companies');
    }
    
    activateCustomerAsync =  async(CustomerId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/activate/${CustomerId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    deactivateCustomerAsync =  async(CustomerId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/deactivate/${CustomerId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    resendPasswordAsync =  async(CustomerId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/resendPassword/${CustomerId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    getTimezoneListAsync =  async(): Promise<any[]> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/timezones`
        const response = await axios.get<any[]>(url);
        return response.data;
    }

    getCustomerInfoAsync =  async(): Promise<Customer> => {
        const url = `${process.env.REACT_APP_API_URL}/companies`
        const response = await axios.get<Customer>(url);
        return response.data;
    }
}