import { useEffect, useRef, useState } from "react";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import { TaskStatusReport } from "../../Models/Reports";
import CommonGridPage from "../../Components/CommonGridPage";
import { Guid } from "guid-typescript";
import useStores from "../../Stores/useStores";
import TaskStatusCell from "../Reports/Comments/TaskStatusCell";
import ReportHeader, {
  ReportHeaderState,
  ReportHeaderProps,
} from "../Reports/Comments/ReportHeader";
import { Parser } from "json2csv";
import { excelDownload, funCSVDownload } from "../../Utils/utils";
import moment from "moment";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import ReactEcharts from "echarts-for-react";
import { Paper, Grid, Box, Tabs, Tab, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface PageState {
  gridId: string;
}

const initState: PageState = {
  gridId: Guid.create().toString(),
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const taskStatusBizReport = observer(() => {
  const [state] = useState<PageState>(initState);
  const { taskStatusBizReportsStore, appStore } = useStores();
  const [dailyChartOption, setDailyChartOption] = useState<any>({});
  const [weeklyChartOption, setWeeklyChartOption] = useState<any>({});
  const [monthlyChartOption, setMonthlyChartOption] = useState<any>({});
  const componentRef = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    const preFilters = [
      {
        field: "filterOrgCompanyId",
        value: orgCompanyId,
      },
      {
        field: "filterDate",
        value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ];
    taskStatusBizReportsStore.setPreFilter([...preFilters]);
    loadChart();
  }, []);

  const handleDownload = (filters: ReportHeaderState) => {
    taskStatusBizReportsStore.excel([]).then((resp: any) => {
      const { data, count } = resp;
      excelDownload(true, data, t('companies_task_status_report'));
    });
  };

  const genCsvData = (data: {
    completedMandatory: number;
    completedOptional: number;
    totalMandatory: number;
    totalOptional: number;
  }): string => {
    const {
      completedMandatory,
      completedOptional,
      totalMandatory,
      totalOptional,
    } = data;
    const totalTask = totalOptional + totalMandatory;
    const totalComplete = completedMandatory + completedOptional;

    return (
      `${completedMandatory}/${totalMandatory} ${t("mandatory_completed")}\n` +
      `${completedOptional}/${totalOptional} ${t("optional_completed")}\n` +
      `${totalComplete}/${totalTask} ${t("total_completed")}`
    );
  };

  const handlePrint = (filters: ReportHeaderState) => { };

  const handleFilterChange = (filters: ReportHeaderState) => {
    const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    taskStatusBizReportsStore.setPreFilter([
      {
        field: "filterOrgCompanyId",
        value: orgCompanyId,
      },
      {
        field: "filterDate",
        value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      },
    ]);
    taskStatusBizReportsStore.query();
    loadChart();
  };

  const reportHeaderProps: ReportHeaderProps = {
    title: t("companies_task_status_report"),
    description: t("generate_companies_task_status_report"),
    showFromDate: true,
    showToDate: false,
    showDepartment: false,
    showEmployee: false,
    showWeekPicker: false,
    showDownload: true,
    showPrint: false,
    handleChange: handleFilterChange,
    handleDownload: handleDownload,
    handlePrint: handlePrint,
  };

  const columns: GridColDef[] = [
    {
      field: "role",
      headerName: t("company"),
      flex: 1,
      sortable: true,
    },
    {
      field: "daily",
      headerName: t("daily_task"),
      flex: 1,
      sortable: false,
      renderCell: (row: GridCellParams) => {
        const report: TaskStatusReport = row.row as TaskStatusReport;
        return <TaskStatusCell {...report.daily} />;
      },
    },
    {
      field: "weekly",
      headerName: t("weekly_task"),
      flex: 1,
      sortable: false,
      renderCell: (row: GridCellParams) => {
        const report: TaskStatusReport = row.row as TaskStatusReport;
        return <TaskStatusCell {...report.weekly} />;
      },
    },
    {
      field: "monthly",
      headerName: t("monthly_task"),
      flex: 1,
      sortable: false,
      renderCell: (row: GridCellParams) => {
        const report: TaskStatusReport = row.row as TaskStatusReport;
        return <TaskStatusCell {...report.monthly} />;
      },
    },
  ];

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: "rgba(0,0,0,0.3)",
    },
  };

  const genChartOption = (
    companies: Array<string>,
    mandatoryCompleteds: Array<number>,
    mandatoryIncompleteds: Array<number>,
    optionalCompleteds: Array<number>,
    optionalIncompleteds: Array<number>
  ) => {
    return {
      legend: {
        data: [
          t("mandatory_completed"),
          t("mandatory_incompleted"),
          t("optional_completed"),
          t("optional_incompleted"),
        ],
        left: "15%",
      },
      toolbox: {
        feature: {
          magicType: {
            type: ["stack"],
          },
          dataView: {
            title: "Data"
          },
        },
      },
      tooltip: {},
      xAxis: {
        data: companies,
        name: t("companies"),
        axisLine: { onZero: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {},
      grid: {
        bottom: 100,
      },
      series: [
        {
          name: t("mandatory_completed"),
          type: "bar",
          barWidth: 25,
          stack: "one",
          emphasis: emphasisStyle,
          data: mandatoryCompleteds,
        },
        {
          name: t("mandatory_incompleted"),
          type: "bar",
          barWidth: 25,
          stack: "one",
          emphasis: emphasisStyle,
          data: mandatoryIncompleteds,
        },
        {
          name: t("optional_completed"),
          type: "bar",
          barWidth: 25,
          stack: "two",
          emphasis: emphasisStyle,
          data: optionalCompleteds,
        },
        {
          name: t("optional_incompleted"),
          type: "bar",
          barWidth: 25,
          stack: "two",
          emphasis: emphasisStyle,
          data: optionalIncompleteds,
        },
      ],
    };
  };

  const loadChart = () => {
    taskStatusBizReportsStore.queryCSV().then((resp: any) => {
      const { data } = resp;
      const rows = data.filter((d: any) => d.role !== "Total");
      const companies = rows.map((r: any) => r.role);
      ["daily", "weekly", "monthly"].forEach((t) => {
        const mandatoryCompleteds = rows.map(
          (r: any) => (r as any)[t].completedMandatory
        );
        const mandatoryIncompleteds = rows.map(
          (r: any) => (r as any)[t].totalMandatory - (r as any)[t].completedMandatory
        );
        const optionalCompleteds = rows.map(
          (r: any) => (r as any)[t].completedOptional
        );
        const optionalIncompleteds = rows.map(
          (r: any) => (r as any)[t].totalOptional - (r as any)[t].completedOptional
        );
        const option = genChartOption(
          companies,
          mandatoryCompleteds,
          mandatoryIncompleteds,
          optionalCompleteds,
          optionalIncompleteds
        );
        if (t === "daily") {
          setDailyChartOption(option);
          return;
        }
        if (t === "weekly") {
          setWeeklyChartOption(option);
          return;
        }
        if (t === "monthly") {
          setMonthlyChartOption(option);
          return;
        }
      });
    });
  };

  return (
    <>
      <ReportHeader {...reportHeaderProps} />
      <div
        ref={componentRef}
        className="section-to-print"
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Box sx={{ width: "100%" }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                  <Tab value={0} label={t("daily_task")} />
                  <Tab value={1} label={t("weekly_task")} />
                  <Tab value={2} label={t("monthly_task")} />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <ReactEcharts option={dailyChartOption} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ReactEcharts option={weeklyChartOption} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ReactEcharts option={monthlyChartOption} />
              </TabPanel>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <CommonGridPage<TaskStatusReport>
              key={state.gridId}
              columns={columns}
              cancelSearch={true}
              useAction={false}
              store={taskStatusBizReportsStore}
              rowHeight={80}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default taskStatusBizReport;
