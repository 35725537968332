import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { Avatar, Box, Button, Checkbox, createStyles, FormControlLabel, FormHelperText, Grid, InputLabel, Link, makeStyles, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import { Company } from "../../Models/Company";
import { RowStatus } from "../../Models/RowStatus";
import { ValidationError } from "../../Models/ValidationError";
import { isValidEmail } from "../../Utils/StringUtils";
import AddIcon from '@material-ui/icons/Add';
import MuiPhoneNumber from 'material-ui-phone-number';
import { SortDirection } from "../../Models/QueryCriteria";
import { LightTooltip } from "../../Components/ToolTips";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import countryList from "../../Utils/country-list";
import { useTranslation } from 'react-i18next';

interface PageState {
    dialogOpen: boolean,
    dialogId: string,
    editRow: Company
    editMode: FormMode,
    errors: ValidationError[]
}

const initCompany: Company = {
    id: '',
    name: '',
    contactFirstName: '',
    contactLastName: '',
    phone: '',
    emailAddress: '',
    address: '',
    expireDate: new Date() || '',
    isFreeTrial: undefined,
    isHQ: undefined,
    isEnterprise: undefined,
    price: '' || undefined,
    isNew: true,
}

const initState: PageState = {
    dialogOpen: false,
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initCompany,
    errors: []
};

const useStyles = makeStyles((theme) =>
    createStyles({
        expireItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        picker: {
            marginTop: "0px",
            marginBottom: "0px",
            width: "100%"
        },
        autoText: {
            '& .MuiInputBase-input': {
                padding: '17px 14px !important'
            },
        },
        input: {
            display: 'none',
        },
    }),
);

export default function EnterprisePage() {
    const { companyStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const confirm = useConfirm();
    const classes = useStyles();
    const { t } = useTranslation();

    //change status
    const handleChangeActive = (company: Company) => {
        if (company.status === RowStatus.Active) {
            companyStore.deactivate(company.id);
        } else {
            companyStore.activate(company.id);
        }
    }

    useEffect(() => {
        companyStore.models = [];
        companyStore.setIsEnterprise(true);
        companyStore.changePage(0);
    }, []);

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('enterprise_name_is_required')
                });
            }
            if (state.editRow.name.trim().length > 100) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('enterprise_name_max_length')
                });
            }

            if (state.editRow.address.trim().length > 255) {
                valdiationErrors.push({
                    field: 'address',
                    message: t('address_max_length')
                });
            }

            if (state.editRow.contactFirstName.trim().length === 0 || state.editRow.contactLastName.trim().length === 0) {
                valdiationErrors.push({
                    field: 'contactFirstName',
                    message: t('contact_person_required')
                });
                valdiationErrors.push({
                    field: 'contactLastName',
                    message: t('contact_person_required')
                });
            } else if (state.editRow.contactFirstName.trim().length > 100 || state.editRow.contactLastName.trim().length > 100) {
                valdiationErrors.push({
                    field: 'contactFirstName',
                    message: t('contact_person_max_lenth')
                });
                valdiationErrors.push({
                    field: 'contactLastName',
                    message: t('contact_person_max_lenth')
                });
            }
            if (state.editRow.emailAddress.trim().length === 0) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t('email_address_required')
                });
            } else if (!isValidEmail(state.editRow.emailAddress)) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t('email_format_error')
                });
            } else if (state.editRow.emailAddress.trim().length > 50) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t('email_address_max_length')
                });
            }


            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: t('name'), width: 200, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company.name} placement="bottom-start" arrow>
                    <span>{company.name}</span>
                </LightTooltip>;
            })
        },
        {
            field: "logoFilePath", headerName: t("company_logo"), width: 150, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: Company = row.row as Company;
                return <Box display="flex" style={{ alignItems: "center", justifyContent: "center" }}>
                    <Box minWidth={30}>
                        <Avatar alt="Remy Sharp" src={report.logoFilePath} />
                    </Box>
                </Box>
            })
        },
        {
            field: 'contactFirstName', headerName: t('contact'), width: 200,
            renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={`${company.contactFirstName} ${company.contactLastName}`}
                    placement="bottom-start" arrow>
                    <span>{`${company.contactFirstName} ${company.contactLastName}`}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'phone', headerName: t('phone'), width: 200, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company.phone} placement="bottom-start" arrow>
                    <span>{company.phone}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'emailAddress', headerName: t('email_address'), width: 230, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company.emailAddress} placement="bottom-start" arrow>
                    <span>{company.emailAddress}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'country', headerName: t('country'), width: 200, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                var defaultValue = company.country ? countryList.find(x => x.countryCode === company.country) : { countryCode: "", countryName: "" };
                return <LightTooltip title={defaultValue?.countryName ?? ""} placement="bottom-start" arrow>
                    <span>{defaultValue?.countryName ?? ""}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'state', headerName: t('state'), width: 200, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company?.state ?? ""} placement="bottom-start" arrow>
                    <span>{company?.state ?? ""}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'address', headerName: t('address'), width: 200, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company.address} placement="bottom-start" arrow>
                    <span>{company.address}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'expireDate', headerName: t('expire_date'), width: 160, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company.expireDate ? moment(company.expireDate).format("YYYY-MM-DD") : ''} placement="bottom-start" arrow>
                    <span>{company.expireDate ? moment(company.expireDate).format("YYYY-MM-DD") : ''}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'price', headerName: t('price'), width: 100, renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <LightTooltip title={company.price ? company.price : ''} placement="bottom-start" arrow>
                    <span>{company.price ? company.price : ''}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'status', headerName: t('status'), width: 110,
            renderCell: ((row: GridCellParams) => {
                const company: Company = row.row as Company;
                return <Switch
                    checked={company.status === RowStatus.Active}
                    onChange={() => handleChangeActive(company)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            })
        },
        {
            field: 'action', headerName: t('actions'), width: 570, editable: false, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const model: Company = row.row as Company;
                return <Box style={{ display: 'flex', justifyContent: '', width: '100%' }}>
                    <RouterLink style={{ color: "#1cad5e", marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }} to={{ pathname: '/app/companyStructure', state: { companyId: model.id } }} color="primary">{t('view_structure')}</RouterLink>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleResend(model.id);
                            event.stopPropagation();
                        }}>{t('resend_password')}</Link>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => handleDownloadInvoice(model.id, model.name)}
                    >{t("download_invoices")}</Link>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setState({ ...state, dialogOpen: true, editRow: model, editMode: FormMode.Edit });
                            event.stopPropagation();
                        }}
                    >{t('edit')}</Link>
                    <Link style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleDelete(model.id);
                            event.stopPropagation();
                        }}>{t('delete')}</Link>
                </Box>;
            })
        }
    ];

    const handleResend = (id: string) => {
        companyStore.resendPassword(id).then(data => {
            if (data) {
                appStore.infoMsg = t('resend_password_success');
                appStore.showInfo = true;
            } else {
                appStore.error = t('resend_password_fail');
                appStore.showError = true;
            }
        })
    }

    const handleDownloadInvoice = (id: string, name: string) => {
        companyStore.downloadInvoice(id).then((res: any) => {
            const { data } = res
            const blob = new Blob([data])
            let dom = document.createElement('a') as any
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(`${name}.pdf`);
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
        })
    }

    const handleDelete = (id: string) => {
        confirm({
            title: t("warning"),
            description: t('delete_success'),
        }).then(() => {
            companyStore.delete(id);
        })
    }

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
            companyStore.setSort("createdTime", SortDirection.Descending);
            companyStore.changePage(0);

            if (state.editMode === FormMode.Edit && !state.editRow.isEnterprise) {
                window.location.href = '/app/companies';
            }
        }
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initCompany, id: Guid.create().toString(), isHQ: true, isEnterprise: true, isNew: true }
        })
    }

    const handleUploadFile = (event: any) => {
        const file = event.target.files[0];

        const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
        if (!file || allowTypes.indexOf(file.type) < 0) {
            if (!/(docx)$/.test(file.name)) {
                appStore.error = `${t('not_allowed_file_type')}`;
                appStore.showError = true;
                return;
            }
        }
        let maxSize = 1024 * 1000 * 10;
        if (file.size > maxSize) {
            appStore.error = `${t('max_allowed_size_is')}:${maxSize / 1000 / 1024} mb`;
            appStore.showError = true;
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function () {
            const base64data = reader.result;
            setState({
                ...state,
                editRow: { ...state.editRow, logoFileName: file.name, logoFileContent: base64data?.toString() },
                errors: [...state.errors].filter(x => x.field !== "logoFileContent")
            });
        }
    }

    return (<>
        <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16 }}
        >
            {t("enterprise")}
        </Typography>
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <Button
                variant="contained"
                disableElevation
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreate}
            >
                {t("add_enterprise")}
            </Button>
        </Box>
        <CommonGridPage<Company>
            columns={columns}
            store={companyStore}
            delConfirmMsg={t('delete_company_tip')}
            onEdit={(row: Company) => setState({ ...state, dialogOpen: true, editRow: row, editMode: FormMode.Edit })}
        />

        <CommonFormDialog<Company>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={companyStore}
            title={t("enterprise")}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        label={t('enterprise_name')}
                        value={state.editRow.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value.substring(0, 100) },
                            errors: [...state.errors].filter(x => x.field !== "name")
                        })}
                        placeholder={t('enterprise_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        value={state.editRow.contactFirstName}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, contactFirstName: event.target.value.substring(0, 100) },
                            errors: [...state.errors].filter(x => x.field !== "contactFirstName")
                        })}
                        placeholder={t('contact_first_name')}
                        label={t('contact_first_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "contactFirstName") > -1}
                        helperText={state.errors.find(x => x.field === "contactFirstName")?.message || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        value={state.editRow.contactLastName}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, contactLastName: event.target.value.substring(0, 100) },
                            errors: [...state.errors].filter(x => x.field !== "contactLastName")
                        })}
                        placeholder={t('contact_last_name')}
                        label={t('contact_last_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "contactLastName") > -1}
                        helperText={state.errors.find(x => x.field === "contactLastName")?.message || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPhoneNumber
                        fullWidth
                        defaultCountry={'us'}
                        value={state.editRow.phone}
                        onChange={(value) => setState({ ...state, editRow: { ...state.editRow, phone: value.substring(0, 20) } })}
                        placeholder={t('phone')}
                        label={t('phone')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        value={state.editRow.emailAddress}
                        disabled={state.editMode === FormMode.Edit}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, emailAddress: event.target.value.substring(0, 50) },
                            errors: [...state.errors].filter(x => x.field !== "emailAddress")
                        })}
                        placeholder={t('email_address')}
                        label={t('email_address')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "emailAddress") > -1}
                        helperText={state.errors.find(x => x.field === "emailAddress")?.message || ''}
                    />
                </Grid>

                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid xs={5}>
                        <Typography style={{
                            fontSize: "0.8rem !important",
                            color: "rgba(0, 0, 0, 0.54)",
                        }}>{t('country')}</Typography>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            className={classes.autoText}
                            onChange={(event, newValue: any) => {
                                if (newValue) {
                                    setState({
                                        ...state,
                                        editRow: {
                                            ...state.editRow,
                                            country: newValue.countryCode,
                                        },
                                    })
                                } else {
                                    setState({
                                        ...state,
                                        editRow: {
                                            ...state.editRow,
                                            country: "",
                                        },
                                    })
                                }
                            }}
                            getOptionLabel={(option: any) => {
                                return option?.countryName ?? "";
                            }}
                            defaultValue={state.editRow.country ? countryList.find(x => x.countryCode === state.editRow.country) : {}}
                            options={(countryList && countryList.length > 0) ? countryList : []}
                            renderInput={(params) =>
                                <TextField {...params}
                                    style={{ padding: "17px 14px !important" }}
                                    placeholder={t('country')}
                                    value={state.editRow.country}
                                    onChange={(event) => setState({
                                        ...state,
                                        editRow: {
                                            ...state.editRow,
                                            country: event.target.value,
                                        },
                                    })}
                                    InputLabelProps={{ shrink: true }}
                                />}
                        />
                    </ Grid>

                    <Grid xs={5}>
                        <TextField
                            fullWidth
                            value={state.editRow.state}
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, state: event.target.value.substring(0, 50) },
                                errors: [...state.errors].filter(x => x.field !== "state")
                            })}
                            placeholder={t('state')}
                            label={t('state')}
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ shrink: true }}
                            error={state.errors.findIndex(x => x.field === "state") > -1}
                            helperText={state.errors.find(x => x.field === "state")?.message || ''}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={state.editRow.address}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, address: event.target.value.substring(0, 255) },
                            errors: [...state.errors].filter(x => x.field !== "address")
                        })}
                        placeholder={t('address')}
                        label={t('address')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "address") > -1}
                        helperText={state.errors.find(x => x.field === "address")?.message || ''}
                    />
                </Grid>

                <Grid item xs={12} className={classes.expireItem}>
                    <FormControlLabel
                        control={<Checkbox checked={state.editRow.isEnterprise === true}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setState({
                                ...state,
                                editRow: {
                                    ...state.editRow,
                                    isEnterprise: !state.editRow.isEnterprise
                                }
                            })}
                            color="primary" />}
                        label={t('is_enterprise')} />
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker
                        disableToolbar
                        InputLabelProps={{ shrink: true }}
                        autoOk
                        format="MM/dd/yyyy"
                        margin="normal"
                        label={t("expire_date")}
                        className={classes.picker}
                        InputAdornmentProps={{ position: "start" }}
                        value={state.editRow.expireDate}
                        onChange={(value: any) => setState({
                            ...state,
                            editRow: { ...state.editRow, expireDate: value },
                            errors: [...state.errors].filter(x => x.field !== "expireDate")
                        })}
                    />
                </Grid>
                {state.editRow.isEnterprise &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={state.editRow.price}
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, price: event.target.value },
                                errors: [...state.errors].filter(x => x.field !== "price")
                            })}
                            placeholder={t("price")}
                            label={t("price")}
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ shrink: true }}
                            error={state.errors.findIndex(x => x.field === "price") > -1}
                            helperText={state.errors.find(x => x.field === "price")?.message || ''}
                        />
                    </Grid>
                }
                { state.editRow.isNew &&  
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        value={state.editRow.language}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, language: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "language")
                        })}
                        placeholder={t('language')}
                        label={t('language')}
                        InputProps={{ disableUnderline: true }}
                        defaultValue='english'
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "language") > -1}
                        helperText={state.errors.find(x => x.field === "language")?.message || ''}
                    >
                        <MenuItem value='english'>{t('english')}</MenuItem>
                        <MenuItem value='italy'>{t('italian')}</MenuItem>
                        <MenuItem value='spanish'>{t('spanish')}</MenuItem>
                    </TextField>
                </Grid>
                }
                <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end" }}>
                    <Grid>
                        <InputLabel id="lblFile" shrink>{t('company_logo')}</InputLabel>
                        <>
                            <input
                                accept="image/png,image/jpg,image/jpeg,image/gif"
                                className={classes.input}
                                id="contained-button-fi1le"
                                type="file"
                                value={''}
                                onChange={handleUploadFile}
                            />
                            <label htmlFor="contained-button-fi1le">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    startIcon={<AddIcon />}
                                >
                                    {t('browse')}
                                </Button>
                            </label>
                        </>
                        <FormHelperText>{state.errors.find(x => x.field === "logoFileContent")?.message || ''}</FormHelperText>
                    </Grid>
                    <Grid item xs={8} style={{ marginLeft: "15px" }}>
                        <Typography
                            style={{ color: "#374359", marginTop: 2, marginBottom: 2, fontSize: 12, lineHeight: "15px" }}
                        >
                            {t('file_name')}: {state.editRow.logoFileName ? state.editRow.logoFileName : ''}
                        </Typography>
                        <Typography
                            style={{ color: "#9094A9", fontSize: 12, lineHeight: "15px" }}
                        >
                            {t('file_formats')}: *.png,*.jpg,*.jpeg,*.gif
                            <br />
                            {t('max_file_size')}: 10M
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
}