import React, { useEffect, useState } from 'react';
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import { TaskStatusReport } from "../../Models/Reports";
import CommonGridPage from "../../Components/CommonGridPage";
import { Guid } from "guid-typescript";
import useStores from "../../Stores/useStores";
import TaskStatusCell from './Comments/TaskStatusCell';
import ReportHeader, { ReportHeaderState, ReportHeaderProps } from './Comments/ReportHeader';
import { Parser } from "json2csv";
import { excelDownload, funCSVDownload } from "../../Utils/utils"
import moment from "moment";
import { useTranslation } from 'react-i18next';
interface PageState {
    gridId: string,
}

const initState: PageState = {
    gridId: Guid.create().toString(),
};

const taskStatusReport = observer(() => {
    const [state] = useState<PageState>(initState);
    const { taskStatusReportsStore, appStore } = useStores();
    const { t } = useTranslation();

    useEffect(() => {
        const preFilters = [
            {
                field: 'filterDate',
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
            }
        ]
        taskStatusReportsStore.setPreFilter([...preFilters]);
    }, []);

    const handleDownload = (filters: ReportHeaderState) => {
        taskStatusReportsStore.excel([]).then((resp: any) => {
            const { data, count } = resp;
            excelDownload(true, data, t('companies_task_status_report'));
          });
    }

    const genCsvData = (data: { completedMandatory: number, completedOptional: number, totalMandatory: number, totalOptional: number }): string => {
        const { completedMandatory, completedOptional, totalMandatory, totalOptional } = data;
        const totalTask = totalOptional + totalMandatory;
        const totalComplete = completedMandatory + completedOptional;

        return `${completedMandatory}/${totalMandatory} Mandatory Completed\n` +
            `${completedOptional}/${totalOptional} Optional Completed\n` +
            `${totalComplete}/${totalTask} Total Completed`;
    }

    const handlePrint = (filters: ReportHeaderState) => {
    }

    const handleFilterChange = (filters: ReportHeaderState) => {
        taskStatusReportsStore.setPreFilter([
            {
                field: 'filterDeptId',
                value: filters.deptId
            },
            {
                field: 'filterDate',
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss")
            }
        ]);
        taskStatusReportsStore.changePage(0);
    }

    const reportHeaderProps: ReportHeaderProps = {
        title: t('task_status_report'),
        description: t('cenerate_task_status_report'),
        showFromDate: true,
        showToDate: false,
        showDepartment: true,
        showEmployee: false,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    }

    const columns: GridColDef[] = [
        {
            field: "role", headerName: t("employee"), flex: 1, sortable: true,
        },
        {
            field: "daily", headerName: t("daily") + ' ' + t('task'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: TaskStatusReport = row.row as TaskStatusReport;
                return <TaskStatusCell {...report.daily} />
            })
        },
        {
            field: "weekly", headerName: t("weekly") + ' ' + t('task'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: TaskStatusReport = row.row as TaskStatusReport;
                return <TaskStatusCell {...report.weekly} />
            })
        },
        {
            field: "monthly", headerName: t("monthly") + ' ' + t('task'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: TaskStatusReport = row.row as TaskStatusReport;
                return <TaskStatusCell {...report.monthly} />
            })
        },
    ]


    return (
        <>
            <ReportHeader {...reportHeaderProps} />
            <CommonGridPage<TaskStatusReport>
                key={state.gridId}
                columns={columns}
                cancelSearch={true}
                useAction={false}
                store={taskStatusReportsStore}
                rowHeight={80}
            />
        </>
    )
});

export default taskStatusReport;