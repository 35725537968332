import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

let resources = {};
let preload = [];
const modulesFiles = require.context('./locales', true, /\.json$/)
modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    if (value) {
        preload.push(moduleName + '')
        let tra = { translation: value }
        resources[moduleName] = tra;
    }
    return modules
}, {})
i18next.use(XHR).use(LanguageDetector).use(initReactI18next).init({
    resources,
    lng: localStorage.getItem("lang") || "english",
    react: { useSuspense: true },
    fallbackLng: "english", //localStorage.getItem("lang") ||
    preload: preload,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
})

export default i18next