import {BaseService} from "./BaseService";
import {CorrectiveActionInstance} from "../Models/CorrectiveActionInstance";
import axios from "axios";


export class CorrectiveActionInstanceService  extends BaseService<CorrectiveActionInstance> {
    constructor() {
        super('CorrectiveActionsInstance');
    }

    async acknowledgeAsync(id:string) :Promise<boolean>{
        const url = `${process.env.REACT_APP_API_URL}/CorrectiveActionsInstance/UpdateReadCorrectiveActionInstance/${id}`;
        const response = await axios.post<boolean>(url);
        return response.data;
    }
}
