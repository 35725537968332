import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LinkIcon from '@material-ui/icons/Link';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CategoryIcon from '@material-ui/icons/Category';
import { useTranslation } from 'react-i18next';
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import useStores from "../../Stores/useStores";
const useStyles = makeStyles({
    featureList: {
        display: 'flex',
        '& a:not(:last-child)': {
            borderRight: 'solid 1px rgba(0, 0, 0, 0.12)'
        },
        '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: 20,
            color: '#374359 !important'
        }
    },
});
export function Setting() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appStore } = useStores();
    const { isAdvanceAccount } = appStore.userAuth as AuthenticateResponseModel;
    return (<>
        <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#374359", fontSize: 24, marginTop: 12, marginBottom: 10 }}
        >
            {t('setting')}
        </Typography>
        <Typography
            variant="h5"
            style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
        >
            {t('manage_checklist')}
        </Typography>
        <Paper elevation={3} style={{ marginTop: 32 }}>
            <List className={classes.featureList}>
                <ListItem component={Link} to="/app/checklists">
                    <ListItemAvatar>
                        <CategoryIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('category_setting')} secondary={t('configure_category')} />
                </ListItem>
                <ListItem component={Link} to="/app/correctiveactions">
                    <ListItemAvatar>
                        <ListAltIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('corrective_actions')} secondary={t('corrective_actions')} />
                </ListItem>
                <ListItem component={Link} to="/app/daychangetime">
                    <ListItemAvatar>
                        <CalendarTodayIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('daily_refresh_timezone')} secondary={t('setting_daily_refreshtime')} />
                </ListItem>
            </List>
            <List className={classes.featureList}>
                <ListItem component={Link} to="/app/sharelinks">
                    <ListItemAvatar>
                        <LinkIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('sharelinks')} secondary={t('setting_sharelinks')} />
                </ListItem>
                { isAdvanceAccount ?
                <ListItem component={Link} to="/app/sensors">
                    <ListItemAvatar>
                        <TrackChangesIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('sensor_device')} secondary={t('setting_sensor_device')} />
                </ListItem>
                :
                <ListItem/>
                }
                <ListItem />
            </List>
        </Paper>
    </>)
}