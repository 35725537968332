
import { extendObservable } from "mobx";
import { Company } from "../Models/Company";
import { QueryCriteria } from "../Models/QueryCriteria";
import { CompanyService } from "../Services/CompanyService";
import { BaseGridDataStore } from "./BaseGridDataStore";
export class CompanyStore extends BaseGridDataStore<Company>{
    private _companyService: CompanyService;
    wordPressLicenses: any
    isEnterprise: any

    constructor() {
        let companyService = new CompanyService();
        super(companyService);
        this._companyService = companyService;
        extendObservable(this, {
            activate: this.activate,
            deactivate: this.deactivate,
        });
    }

    setWordPressLicenses(wordPressLicenses: any) {
        this.wordPressLicenses = wordPressLicenses;
    }


    setIsEnterprise(isEnterprise: any) {
        this.isEnterprise = isEnterprise;
    }

    override query(): void {
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }

        if (this.isEnterprise !== null && this.isEnterprise !== undefined) {
            criteria.filters.push({
                field: 'IsEnterprise',
                value: this.isEnterprise,
                dataType: 2,
                operator: 0
            });
        }

        this._serivce.queryAsync(criteria).then(x => {
            this.models = x.data;
            this.count = x.count;
            this.pages = ((x.count + this.pageSize - 1) / this.pageSize);
        })
    }

    //activate company
    activate(id: string) {
        this._companyService.activateCompanyAsync(id).then(x => {
            if (x) {
                this.query();
            }
        });
    }

    //deactive company
    deactivate(id: string) {
        this._companyService.deactivateCompanyAsync(id).then(x => {
            if (x) {
                this.query();
            }
        });
    }

    //deactive company
    resendPassword(id: string) {
        return this._companyService.resendPasswordAsync(id);
    }

    getShareWith(shareCode: string) {
        return this._companyService.getShareWithAsync(shareCode);
    }

    downloadInvoice(id: string) {
        return this._companyService.downloadInvoiceAsync(id);
    }


    switchCompany(id: string) {
        return this._companyService.switchCompanyAsync(id);
    }

    dashboardCompany(id: string) {
        return this._companyService.dashboardCompanyAsync(id);
    }

    getTimezoneList() {
        return this._companyService.getTimezoneListAsync();
    }

    getCompanyInfo() {
        return this._companyService.getCompanyInfoAsync();
    }

    getCompanyLicenses(companyId: string) {
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };
        return this._companyService.getCompanyLicensesAsync(companyId, criteria);
    }

    getCompanyStructure(companyId: string) {
        return this._companyService.getCompanyStructureAsync(companyId);
    }

    getCompanyUnuseLicense(companyId: string) {
        return this._companyService.getCompanyUnuseLicenseAsync(companyId);
    }
}