
import { extendObservable } from "mobx";
import { Customer } from "../Models/Customer";
import { CustomerService } from "../Services/CustomerService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CustomerStore extends BaseGridDataStore<Customer>{
    private _CustomerService: CustomerService;

    constructor() {
        let customerService = new CustomerService();
        super(customerService);
        this._CustomerService = customerService;
        extendObservable(this, {
            activate: this.activate,
            deactivate: this.deactivate,
        });
    }

    //activate Customer
    activate(id: string) {
        this._CustomerService.activateCustomerAsync(id).then(x => {
            if (x) {
                this.query();
            }
        });
    }

    //deactive Customer
    deactivate(id: string) {
        this._CustomerService.deactivateCustomerAsync(id).then(x => {
            if (x) {
                this.query();
            }
        });
    }

    //deactive Customer
    resendPassword(id: string) {
        return this._CustomerService.resendPasswordAsync(id);
    }

    getTimezoneList() {
        return this._CustomerService.getTimezoneListAsync();
    }

    getCustomerInfo() {
        return this._CustomerService.getCustomerInfoAsync();
    }
}