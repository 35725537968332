import axios from "axios";
import { Scorecard } from "../Models/Reports";
import { BaseService } from "./BaseService";

export class BizScorecardService extends BaseService<Scorecard> {
    constructor() {
        super('bizScorecard');
    }

    downloadAsync = async(type: string, fromDate: string, toDate: string, companyIds: Array<string>) => {
        const url = `${process.env.REACT_APP_API_URL}/bizscorecard/${type}?from=${fromDate}&to=${toDate}&companyIds=${companyIds.join(",")}`;
        await axios.get(url, {responseType: 'blob'}).then((res: any) => {
            const { data } = res;
            const blob = new Blob([data]);
            let dom = document.createElement('a') as any;
            let url = window.URL.createObjectURL(blob);
            dom.href = url;
            const date = fromDate;
            dom.download = decodeURI(`scorecard.${date}.${type}`);
            (fromDate !== toDate || companyIds.length > 1) &&
                (dom.download = decodeURI(`scorecard.${fromDate}-${toDate}.zip`));
            dom.style.display = 'none';
            document.body.appendChild(dom);
            dom.click();
            dom.parentNode.removeChild(dom);
            window.URL.revokeObjectURL(url);
        })
        ;
    }
}