import { Scorecard } from "../Models/Reports";
import { ScorecardService } from "../Services/ScorecardService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class ScorecardStore extends BaseGridDataStore<Scorecard>{
    private _service: ScorecardService;

    constructor() {
        let service = new ScorecardService();
        super(service);
        this._service = service;
    }

    download(date: string, type: string) {
        return this._service.downloadAsync(date, type);
    }
}