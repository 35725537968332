import { TaskAssignmentBizReport } from "../Models/Reports";
import { TaskAssignmentBizReportService } from "../Services/TaskAssignmentBizReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class TaskAssignmentBizStore extends BaseGridDataStore<TaskAssignmentBizReport>{
    private _service: TaskAssignmentBizReportService;

    constructor() {
        let service = new TaskAssignmentBizReportService();
        super(service);
        this._service = service;
    }
}