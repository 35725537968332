import { TrendReport, TrendQueryCriteria } from "../Models/Reports";
import { TrendReportsService } from "../Services/TrendReportsService";
import { BaseGridDataStore } from "./BaseGridDataStore";
import { QueryCriteria } from "../Models/QueryCriteria";
import { extendObservable } from "mobx";

export class TrendReportsStore extends BaseGridDataStore<TrendReport>{
    private _service: TrendReportsService;

    constructor() {
        let service = new TrendReportsService();
        super(service);
        this._service = service;
        extendObservable(this, {
            getTrendReportsSummary: this.getTrendReportsSummary,
            getCompletionReports: this.getCompletionReports,
            getHealthCheckReports: this.getHealthCheckReports,
            getCorrectiveReports: this.getCorrectiveReports
        });
    }
    genCriteria(selectedDate: string) {
        const criteria: QueryCriteria = { skip: this.pageIndex * this.pageSize, take: this.pageSize };
        criteria.filters = [];
        const trendCriteria = criteria as TrendQueryCriteria;
        trendCriteria.selectedDate = selectedDate;
        return trendCriteria;
    }

    getTrendReportsSummary(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getTrendReportsSummaryAsync(criteria);
    }

    getCompletionReports(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getCompletionReportsAsync(criteria);
    }

    getHealthCheckReports(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getHealthCheckReportsAsync(criteria);
    }

    getCorrectiveReports(selectedDate: string) {
        const criteria = this.genCriteria(selectedDate);
        return this._service.getCorrectiveReportsAsync(criteria);
    }
}