export interface SelectListItem {
    text: string,
    value: string,
    selected?: boolean
}
export const EnumToMap = (enumeration: any, isReverse?: boolean): SelectListItem[] => {
    const items = [];
    for (let key in enumeration) {
        if (!isNaN(Number(key))) continue;
        const val = enumeration[key] as string;
        if (val !== undefined && val !== null) {
            if (!isReverse) {
                items.push({ text: key, value: val });
            } else {
                items.push({ text: val, value: key });
            }
            
        }
    }

    return items;
};