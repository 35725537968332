import { useEffect, useState } from 'react';
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import CommonGridPage from "../../Components/CommonGridPage";
import { Guid } from "guid-typescript";
import useStores from "../../Stores/useStores";
import ReportHeader, { ReportHeaderState, ReportHeaderProps } from './Comments/ReportHeader';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Paper, Typography, Box } from "@material-ui/core";
import moment from 'moment';
import { CorrectiveActionInstance } from "../../Models/CorrectiveActionInstance";
import { FollowUpInstance } from "../../Models/FollowUpInstance";
import Avatar from "@material-ui/core/Avatar";
import { excelDownload, funCSVDownload } from "../../Utils/utils";
import { Parser } from "json2csv";
import { useTranslation } from 'react-i18next';
import { LightTooltip } from '../../Components/ToolTips';

interface PageState {
    gridId: string,
    reportType: string,
}

const initState: PageState = {
    gridId: Guid.create().toString(),
    reportType: 'corrective'
};

interface FollowUpCSVHeader {
    'Checklist': string,
    'Date': string,
    'Task': string,
    'Follow Up Comments': string,
    "Action Taken By": string,
}

const correctiveAndFollowReport = observer(() => {
    const { correctiveActionInstanceStore, followUpInstanceStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { t } = useTranslation();

    useEffect(() => {
        setState({ ...state, reportType: 'corrective' });
        const preFilters = [
            {
                field: 'selectedDate',
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
            }
            ,
            {
                field: 'toDate',
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                field: 'isReportQuery',
                value: "true"
            }
        ]
        correctiveActionInstanceStore.setPreFilter([...preFilters]);
        followUpInstanceStore.setPreFilter([...preFilters]);
        correctiveActionInstanceStore.changePageSize(10);
        followUpInstanceStore.changePageSize(10);
    }, []);
    const handleDownload = (filters: ReportHeaderState) => {
        if (state.reportType === "corrective") {
            // correctiveActionInstanceStore.queryCSV()
            //     .then(data => {
            //         console.log(data);
            //         const fields = [`${t("date")}`, `${t("checklist")}`, `${t("task")}`, `${t("corrective_action")}`, `${t("action_taken_by")}`];
            //         const csvData: any = [];
            //         if (data.count > 0 && data.data && data.data.length) {
            //             data.data.forEach(item => {
            //                 const rowData: any = {}

            //                 rowData[`${t("date")}`] = moment(item.createTime).format('MM/DD/YYYY LT');
            //                 rowData[`${t("checklist")}`] = item.checklistInstanceName;
            //                 rowData[`${t("task")}`] = item.taskInstanceName;
            //                 rowData[`${t("corrective_action")}`] = item.correctActionName;
            //                 rowData[`${t("action_taken_by")}`] = item.employeeName;

            //                 csvData.push(rowData);
            //             });
            //             const json2csvParser = new Parser({ fields });
            //             const csv = json2csvParser.parse(csvData);
            //             funCSVDownload(csv, t("corrective_action_reports") + '.csv');
            //         } else {
            //             appStore.error = t("there_is_no_data_to_download");
            //             appStore.showError = true;
            //         }
            //     })
            correctiveActionInstanceStore.excel(['Corrective Actions & Follow Up Reports'])
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('corrective_action_reports'));
                }
            });
        } else {
            followUpInstanceStore.donwload(['Corrective Actions & Follow Up Reports'])
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('followup_comment_reports'));
                }
            });
            // followUpInstanceStore.queryCSV().then(data => {
            //     const fields = [`${t("date")}`, `${t("checklist")}`, `${t("task")}`, `${t("follow_up_comments")}`, `${t("action_taken_by")}`];
            //     const csvData: FollowUpCSVHeader[] = [];
            //     if (data.count > 0 && data.data && data.data.length) {
            //         data.data.forEach(item => {
            //             const rowData: any = {}

            //             rowData[`${t("date")}`] = moment(item.commentTime).format('DD/MM/YYYY LT');
            //             rowData[`${t("checklist")}`] = item.checklistInstanceName;
            //             rowData[`${t("task")}`] = item.taskInstanceName;
            //             rowData[`${t("follow_up_comments")}`] = item.commentMessage;
            //             rowData[`${t("action_taken_by")}`] = item.commentEmployeeName;

            //             csvData.push(rowData);
            //         });
            //         const json2csvParser = new Parser({ fields });
            //         const csv = json2csvParser.parse(csvData);
            //         funCSVDownload(csv, t("followup_comment_reports") + '.csv');
            //     } else {
            //         appStore.error = t("there_is_no_data_to_download");
            //         appStore.showError = true;
            //     }
            // })
        }
    }
    const handlePrint = (filters: ReportHeaderState) => {
    }

    const handleFilterChange = (filters: ReportHeaderState) => {
        const preFilters = [
            {
                field: 'departmentId',
                value: filters.deptId
            },
            {
                field: 'employeeId',
                value: filters.employId
            },
            {
                field: 'selectedDate',
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss")
            }
            ,
            {
                field: 'toDate',
                value: moment(filters.toDate).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                field: 'isReportQuery',
                value: "true"
            }
        ]
        correctiveActionInstanceStore.setPreFilter([...preFilters]);
        followUpInstanceStore.setPreFilter([...preFilters]);
        if (state.reportType === "corrective") {
            correctiveActionInstanceStore.changePage(0);
        } else {
            followUpInstanceStore.changePage(0);
        }
    }

    const handleTypeChange = (type: string) => {
        setState({ ...state, reportType: type });
        if (type === "corrective") {
            correctiveActionInstanceStore.query();
        } else {
            followUpInstanceStore.query();
        }
    }

    const reportHeaderProps: ReportHeaderProps = {
        title: t("corrective_actions_follow_up_reports"),
        description: t("generate_corrective_actions_follow_up_reports"),
        showFromDate: true,
        showToDate: true,
        showDepartment: true,
        showEmployee: true,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    }

    const correctiveColums: GridColDef[] = [

        {
            field: "checklistInstanceName", headerName: t("checklist"), flex: 1, sortable: false,
        },
        {
            field: "taskInstanceName", headerName: t("task"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <LightTooltip title={report.taskInstanceName} placement="top-start" arrow>
                    <Typography component={"span"} style={{ whiteSpace: 'normal' }}>
                        {report.taskInstanceName}
                    </Typography>
                </LightTooltip>;
            })
        },
        {
            field: "correctActionName", headerName: t("corrective_action"), flex: 1, sortable: false,
        },
        {
            field: "createTime", headerName: t("date"), width: 120, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const report: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <Box>
                    <Typography variant="h6">
                        {moment(report.createTime).format('MM/DD/YYYY')}
                    </Typography>
                    <Typography variant="h6">
                        {moment(report.createTime).format('LT')}
                    </Typography>
                </Box>;
            })
        },
        {
            field: "createBy", headerName: t("action_taken_by"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <Box display="flex">
                    <Box minWidth={30}>
                    </Box>
                    <Box ml={2} mt={1}>
                        <Typography variant="h6" component="h2">
                            {report.createBy}
                        </Typography>
                    </Box>
                </Box>
            })
        },
        {
            field: "employeeName", headerName: t("receiver"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <Box display="flex" style={{ alignItems: "center" }}>
                    <Box minWidth={30}>
                        <Avatar alt="Remy Sharp" src={report.employeePhoto} />
                    </Box>
                    <Box ml={2} mt={1}>
                        <Typography variant="h6" component="h2" style={{ display: "flex", flexDirection: "column" }}>
                            <span>{report.employeeName}</span>
                            <span style={{ fontSize: 14, color: "#1CAD5E" }}> {report.isRead ? " (Acknowledged)" : ""}</span>
                        </Typography>

                    </Box>
                </Box>
            })
        },
    ];

    const followColums: GridColDef[] = [
        {
            field: "checklistInstanceName", headerName: t("checklist"), flex: 1, sortable: false,
        },
        {
            field: "taskInstanceName", headerName: t("task"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: FollowUpInstance = row.row as FollowUpInstance;
                return <LightTooltip title={report.taskInstanceName} placement="top-start" arrow>
                    <Typography component={"span"} style={{ whiteSpace: 'normal' }}>
                        {report.taskInstanceName}
                    </Typography>
                </LightTooltip>;
            })
        },
        {
            field: "followByName", headerName: t("follow_up_by"), flex: 1, sortable: false,
        },
        {
            field: "createdTime", headerName: t("date"), width: 120, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const report: FollowUpInstance = row.row as FollowUpInstance;
                return <Box>
                    <Typography variant="h6">
                        {moment(report.createdTime).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography variant="h6">
                        {moment(report.createdTime).format('LT')}
                    </Typography>
                </Box>;
            })
        },
        {
            field: "commentMessage", headerName: t("follow_up_comments"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: FollowUpInstance = row.row as FollowUpInstance;
                return <LightTooltip title={report.commentMessage} placement="left-end" arrow>
                    <span>{report.commentMessage}</span>
                </LightTooltip>
            })
        },
        {
            field: "actionTakenBy", headerName: t("action_taken_by"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: FollowUpInstance = row.row as FollowUpInstance;
                return <Box display="flex">
                    <Box minWidth={30}>
                        <Avatar alt="Remy Sharp" src={report.commentEmployeePhoto} />
                    </Box>
                    <Box ml={2} mt={1}>
                        <Typography variant="h6" component="h2">
                            {report.commentEmployeeName}
                        </Typography>
                    </Box>
                </Box>
            })
        },
    ];

    return (
        <>
            <ReportHeader {...reportHeaderProps} />
            <Paper>
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                    <Button color={state.reportType === 'corrective' ? 'primary' : 'inherit'}
                        style={{ width: 210, height: 60, fontSize: 16 }}
                        onClick={() => {
                            setState({ ...state, reportType: 'corrective' });
                            handleTypeChange('corrective');
                        }}>{t("corrective_actions")}
                    </Button>
                    <Button color={state.reportType === 'follow' ? 'primary' : 'inherit'}
                        style={{ width: 210, height: 60, fontSize: 16 }}
                        onClick={() => {
                            setState({ ...state, reportType: 'follow' });
                            handleTypeChange('follow');
                        }}
                    >{t("follow_up")}
                    </Button>
                </ButtonGroup>
            </Paper>
            {state.reportType === "corrective" ?
                <CommonGridPage<CorrectiveActionInstance>
                    key={state.gridId}
                    columns={correctiveColums}
                    useAction={false}
                    store={correctiveActionInstanceStore}
                    rowHeight={80}
                /> :
                <CommonGridPage<FollowUpInstance>
                    key={state.gridId}
                    columns={followColums}
                    useAction={false}
                    store={followUpInstanceStore}
                    rowHeight={80}
                />
            }
        </>
    )
});

export default correctiveAndFollowReport;