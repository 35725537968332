const LanguagesModels = [
    {
        key: "english",
        value: "English"
    },
    // {
    //     key: "china",
    //     value: "简体中文"
    // },
    // {
    //     key: "france",
    //     value: "Français"
    // },
    // {
    //     key: "german",
    //     value: "Deutsch"
    // },
    {
        key: "italy",
        value: "Italiano"
    },
    {
        key: "spanish",
        value: "Español"
    },
];
export default LanguagesModels