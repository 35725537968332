import {Cloudlibrary} from "../Models/Cloudlibrary";
import {CloudlibraryService} from "../Services/CloudlibraryService";
import {extendObservable} from "mobx";
import {BaseGridDataStore} from "./BaseGridDataStore";

export class CloudLibraryStore extends BaseGridDataStore<Cloudlibrary> {
    private _service: CloudlibraryService;

    constructor() {
        let service = new CloudlibraryService();
        super(service);
        this._service = service;
        extendObservable(this, {});
    }
}