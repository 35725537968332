import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import React, { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, createStyles, FormControlLabel, Grid, Link, makeStyles, MenuItem, Select, TextField, Typography, Switch } from "@material-ui/core";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { observer } from "mobx-react";
import { Category } from "../../Models/Category";
import { Link as RouteLink } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { CategoryImportDialog } from "./CategoryImportDialog";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import BackButton from "../../Components/BackToButton";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Refresh';
import { RowStatus } from "../../Models/RowStatus";

interface PageState {
    dialogOpen: boolean,
    importDialogOpen: boolean,
    gridId: string,
    dialogId: string,
    importDialogId: string,
    editRow: Category
    editMode: FormMode,
    templates: Category[],
    errors: ValidationError[],
    language: string,
}

const initAction: Category = {
    id: '',
    name: '',
    language: ''
};

const initState: PageState = {
    dialogOpen: false,
    importDialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    importDialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initAction,
    errors: [],
    templates: [],
    language: ""
};

const useStyles = makeStyles(() =>
    createStyles({
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },

    }),
);

const CategoryPage = observer(() => {
    const { categoryStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { hasActionPermission, isSuperAdmin } = appStore.userAuth as AuthenticateResponseModel;
    const classes = useStyles();
    const { t } = useTranslation();

    const preFilters = [
        {
          field: "status",
          value: `${RowStatus.Active}`,
        },
      ];
    categoryStore.setPreFilter([...preFilters]);

    useEffect(() => {
        var lang = localStorage.getItem("lang") ?? LanguagesModels[0].key;
        categoryStore.setLanguage(lang);
        setState({
            ...state,
            language: lang
        });

    }, []);

    const columns: GridColDef[] = [
        {
            field: "name", headerName: t('category_name'), flex: 6, renderCell: ((row: GridCellParams) => {
                const category: Category = row.row as Category;
                return <LightTooltip title={category.name} placement="bottom-start" arrow>
                    <span>{category.name}</span>
                </LightTooltip>;
            })
        },
        { field: "checklistCount", headerName: t('checklists'), flex: 1, align: "left" },
        { field: "taskCount", headerName: t('tasks'), flex: 1, align: "left" },
    ];

    if (hasActionPermission) {
        columns.push({
            field: 'action', headerName: t('actions'), width: 450, editable: false, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const category: Category = row.row as Category;
                return <Box style={{ display: 'flex', justifyContent: '', width: '100%' }}>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            handleUpdate(FormMode.Edit, category)
                        }}
                    >{t('edit_category')}</Link>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        component={RouteLink} to={`/app/checklists/${category.id}/categories?checklistName=${category.name}&language=${category.language}`}>{t('manage_checkList')}</Link>
                    <Link style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleDelete(category.id);
                            event.stopPropagation();
                        }}>{t('delete_category')}</Link>
                </Box>;
            })
        });
    }

    const confirm = useConfirm();
    const handleDelete = (id: string) => {
        confirm({
            title: t('warning'),
            description: t('delete_category_tip'),
        }).then(() => {
            categoryStore.delete(id);
        })
    }

    const handleUpdate = (editMode: FormMode, category: Category) => {
        setState({
            ...state, dialogOpen: true,
            editMode: editMode,
            editRow: { ...category },
            dialogId: Guid.create().toString()
        })
    }

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('category_name_required')
                });
            } else if (state.editRow.name.trim().length > 100) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('category_name_max_length')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initAction, id: Guid.create().toString(), language: state.language }
        })
    }

    const handleImport = () => {
        if (false && state.templates.length) {
            setState({ ...state, importDialogOpen: true, importDialogId: Guid.create().toString() })
        } else {
            categoryStore.queryTemplate(state.language).then(x => {
                setState({ ...state, importDialogOpen: true, templates: x, importDialogId: Guid.create().toString() });
            });
        }
    }

    const handleSubmitImport = (templateIds: string[], importOption: boolean, language: String) => {
        categoryStore.importTemplateAsync(templateIds, importOption, language).then(x => {
            setState({ ...state, importDialogOpen: false });
            categoryStore.query();
        });
    }

    const handleLangChange = (item: any) => {
        setState({
            ...state,
            language: item.target.value
        });

        categoryStore.setLanguage(item.target.value).then(x => {
            categoryStore.query();
        });
    }

    const handleRefreshTasks = () => {
        categoryStore.refresh().then((x: any) => {});
    };

    return (<>
        <CommonPageTitle title={t('categories')} subTitle={t('configure_categories')}></CommonPageTitle>
        <Grid item xs={12} style={{ display: "flex" }}>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                {isSuperAdmin &&
                    <>
                        <Typography style={{ marginRight: 10, color: "#999" }}>
                            {t("managing_language")}:
                        </Typography>
                        <Select fullWidth
                            displayEmpty
                            disableUnderline
                            style={{ width: 180 }}
                            className={classes.selected}
                            value={state.language}
                            onChange={handleLangChange}
                        >
                            {LanguagesModels.map(x =>
                                <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                            )}
                        </Select>
                    </>
                }
            </Grid>
            <Grid item xs={6}>
                <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
                    <BackButton />
                    {appStore.userAuth?.isSuperAdmin === false && hasActionPermission && <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<PlaylistAddIcon />}
                        onClick={handleImport}
                        style={{ marginRight: 16 }}
                    >
                        {t('import_preset_category')}
                    </Button>
                    }
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<RefreshIcon />}
                        style={{ marginRight: 16 }}
                        onClick={handleRefreshTasks}
                    >
                        {t('refresh_tasks')}
                    </Button>
                    {hasActionPermission &&
                        <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleCreate}
                        >
                            {t('create_category')}
                        </Button>
                    }
                </Box>
            </Grid>
        </Grid>


        <CommonGridPage<Category>
            key={state.gridId}
            columns={columns}
            store={categoryStore}
            onEdit={(row: Category) => setState({
                ...state,
                dialogOpen: true,
                editRow: row,
                editMode: FormMode.Edit
            })}
            defaultSortBy={"createdTime"}
            defaultSortDirection={"desc"}
            useAction={hasActionPermission}
        />

        <CommonFormDialog<Category>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={categoryStore}
            title={t('category')}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        value={state.editRow?.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value },
                            errors: []
                        })}
                        placeholder={t('category_name')}
                        label={t('category_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </CommonFormDialog>

        <CategoryImportDialog
            key={state.importDialogId}
            open={state.importDialogOpen}
            onClose={() => setState({ ...state, importDialogOpen: false })}
            templates={state.templates}
            onSubmitImport={(templateIds: string[], importOption: boolean, language: String) => handleSubmitImport(templateIds, importOption, language)}
        />
    </>
    );
});

export default CategoryPage
