import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z"
          fill="#9094A9"
        />
      </svg>
    </SvgIcon>
  );
}

export default DashboardIcon;
