import axios from "axios";
import { License } from "../Models/License";
import { LicenseLog } from "../Models/Licenselog";
import { BaseService } from "./BaseService";
import { QueryCriteria } from "../Models/QueryCriteria";

export class LicenseService extends BaseService<License> {
    constructor() {
        super('companies');
    }


    assignCompanyLicense = async (licenses: any): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/assignCompanyLicense`
        const response = await axios.post<boolean>(url, licenses);
        console.log(response)
        return response.data;
    }

    queryEnterpriseLicenses = async (): Promise<License[]> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetEnterpriseLicenses`
        const response = await axios.get<License[]>(url);
        return response.data;
    }

    queryCompanyLicenseInfor = async (companyId: string): Promise<License> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetCompanyLicenseInfor/${companyId}`
        const response = await axios.get<License>(url);
        return response.data;
    }

    queryLicenses = async (criteria: QueryCriteria): Promise<License[]> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetLicenses`
        const response = await axios.post<License[]>(url, criteria);
        return response.data;
    }
    queryUnUseLicense = async (companyId: string): Promise<LicenseLog[]> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetUnUseLicenses/${companyId}`
        const response = await axios.get<LicenseLog[]>(url);
        return response.data;
    }
    activateLicenseAsync = async (LicenseId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/activate/${LicenseId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }


}