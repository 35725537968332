import React, { useEffect, useState, useRef } from 'react';
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import { CheckListDetailsReport } from "../../Models/Reports";
import CommonGridPage from "../../Components/CommonGridPage";
import { Guid } from "guid-typescript";
import useStores from "../../Stores/useStores";
import ReportHeader, { ReportHeaderState, ReportHeaderProps } from './Comments/ReportHeader';
import moment from 'moment';
import { Box, Typography } from "@material-ui/core";
import { useReactToPrint } from 'react-to-print';
import { excelDownload, getCompletedTimeText, getRecurringTimeText } from "../../Utils/utils";
import { LightTooltip } from "../../Components/ToolTips";
import { useTranslation } from 'react-i18next';
import { TimeType } from '../../Models/TimeType';
import { RecurringType } from '../../Models/RecurringType';

interface PageState {
    gridId: string,
}

const initState: PageState = {
    gridId: Guid.create().toString(),
};

const checklistDetailReport = observer(() => {
    const [state] = useState<PageState>(initState);
    const { checklistDetailsReportsStore, appStore } = useStores();
    const componentRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const preFilters = [
            {
                field: 'selectedDate',
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
            }
        ]
        checklistDetailsReportsStore.setPreFilter([...preFilters]);
    }, []);

    const handleDownload = (filters: ReportHeaderState) => {
        const fields = [`${t("checklist")}`, `${t("task")}`, `${t("due")} | ${t("completed")}`];
        checklistDetailsReportsStore.excel(fields)
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('checklist_details_report'));
                }
            })
    }
    const handlePrint = (filters: ReportHeaderState) => {
        if (typeof handlePrintSelf == "function") {
            handlePrintSelf();
        }
    }
    const handlePrintSelf = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleFilterChange = (filters: ReportHeaderState) => {
        checklistDetailsReportsStore.setPreFilter([
            {
                field: 'departmentId',
                value: filters.deptId
            },
            {
                field: 'employeeId',
                value: filters.employId
            },
            {
                field: 'selectedDate',
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss")
            }
        ]);
        checklistDetailsReportsStore.changePage(0);
    }

    const reportHeaderProps: ReportHeaderProps = {
        title: t("checklist_details_report"),
        description: t("generate_checklist_details_report_to_see_the_details_of_your_category"),
        showFromDate: true,
        showToDate: false,
        showDepartment: true,
        showEmployee: true,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    }

    const getColors = (recurringType: RecurringType, timeType: TimeType, dueTime: any, completedTime: any) => {

        if (timeType === TimeType.OnDemand) {
            return { color: "#1CAD5E" };
        }
        if (recurringType === RecurringType.Daily || recurringType === RecurringType.Monthly) {
            if (dueTime > completedTime) {
                return { color: "#1CAD5E" };
            } else {
                return { color: "red" };
            }
        } else if (recurringType === RecurringType.Weekly) {
            if (moment(dueTime).format('d') > moment(completedTime).format('d')) {
                return { color: "#1CAD5E" };
            } else {
                return { color: "red" };
            }
        }
    }

    const columns: GridColDef[] = [
        {
            field: "checklistInstanceName", headerName: t("checklist"), width: 200, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const report: CheckListDetailsReport = row.row as CheckListDetailsReport;
                return <LightTooltip title={report.checklistInstanceName} placement="top-start" arrow>
                    <Typography component={"span"}>
                        {report.checklistInstanceName}
                    </Typography>
                </LightTooltip>;
            })
        },
        {
            field: "taskInstanceName", headerName: t("task"), width: 360, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CheckListDetailsReport = row.row as CheckListDetailsReport;
                return <LightTooltip title={report.taskInstanceName} placement="top-start" arrow>
                    <Typography component={"span"} style={{ whiteSpace: 'normal' }}>
                        {report.taskInstanceName}
                    </Typography>
                </LightTooltip>;
            })
        },
        {
            field: "dueTime", headerName: `${t("due")} | ${t("completed")}`, width: 200, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CheckListDetailsReport = row.row as CheckListDetailsReport;
                return <Box>
                    {getRecurringTimeText(report)} | {report.completedTime ?
                        <span style={getColors(report.recurringType, report.timeType, report.dueTime, report.completedTime)}
                        >{getCompletedTimeText(report.recurringType, report.timeType, report.completedTime)}</span> : '-'}
                </Box>;
            })
        },
        {
            field: "selectedAction", headerName: t("corrective_action"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CheckListDetailsReport = row.row as CheckListDetailsReport;
                return <LightTooltip title={report.selectedAction} placement="top-start" arrow>
                    <Typography component={"span"}>
                        {report.selectedAction}
                    </Typography>
                </LightTooltip>;
            })
        },
        {
            field: "lastFollowUpComment", headerName: t("latest_comment"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const report: CheckListDetailsReport = row.row as CheckListDetailsReport;
                return <LightTooltip title={report.lastFollowUpComment} placement="top-start" arrow>
                    <Typography component={"span"}>
                        {report.lastFollowUpComment}
                    </Typography>
                </LightTooltip>;
            })
        },
    ]


    return (
        <>
            <ReportHeader {...reportHeaderProps} />
            <div ref={componentRef}>
                <CommonGridPage<CheckListDetailsReport>
                    key={state.gridId}
                    columns={columns}
                    cancelSearch={false}
                    useAction={false}
                    store={checklistDetailsReportsStore}
                    rowHeight={120}

                />
            </div>

        </>
    )
});

export default checklistDetailReport;