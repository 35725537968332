import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Department } from "../Models/Department";
import { Category } from "../Models/Category";
import useStores from "../Stores/useStores";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { AuthenticateResponseModel } from "../Models/AuthenticateModel";
export interface InstanceFiltersProps {
    handleFilterChange: (entity: InstanceFiltersPageState) => void,
    noDepartment?: boolean,
    noCategory?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 20,
        },
    })
);

export interface InstanceFiltersPageState {
    deptId: string,
    categoryId: string,
    filterDate: Date,
    invalidDateError: string,
    depts: Department[],
    categories: Category[],
}

const initState: InstanceFiltersPageState = {
    filterDate: new Date(),
    deptId: '',
    categoryId: '',
    depts: [],
    invalidDateError: "",
    categories: [],
};

function InstanceFilters(props: InstanceFiltersProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const { appStore } = useStores();
    initState.filterDate = appStore.getFilterDate();

    const [state, setState] = useState<InstanceFiltersPageState>(initState);
    const { categoryStore, departmentStore } = useStores();
    const { handleFilterChange, noDepartment = false, noCategory = false } = props;


    useEffect(() => {
        getSelectList();
    }, []);

    const getSelectList = () => {
        Promise.all([departmentStore.queryAll(), categoryStore.queryAll()]).then(values => {
            setState({ ...state, depts: values[0], categories: values[1] });
        });
    };

    const handleDateChange = (date: any, value?: string | null) => {
        if (!date || date.invalid) {
            setState({ ...state, invalidDateError: t('invalid_date') });
            return;
        }
        const mkDate = date.toJSDate();
        const maxDate = new Date('2999-12-31 23:59:59').valueOf();
        const minDate = new Date('1900-01-01 00:00:00').valueOf();
        if (mkDate.valueOf() > maxDate || mkDate.valueOf() < minDate) {
            setState({
                ...state, invalidDateError: t('valid_range') + ':1999-2999.'
            });
            return;
        }

        setState({ ...state, filterDate: mkDate, invalidDateError: "" });
        handleFilterChange({ ...state, filterDate: mkDate });
    }

    const handleForwardDate = () => {
        const { filterDate } = state;
        const lastDate = new Date(filterDate);
        const forwardDate = new Date(lastDate.setDate(lastDate.getDate() + 1));
        setState({ ...state, filterDate: forwardDate, invalidDateError: "" });
        handleFilterChange({ ...state, filterDate: forwardDate });
    }

    const handleBackDate = () => {
        const { filterDate } = state;
        const lastDate = new Date(filterDate);
        const backDate = new Date(lastDate.setDate(lastDate.getDate() - 1));
        setState({ ...state, filterDate: backDate, invalidDateError: "" });
        handleFilterChange({ ...state, filterDate: backDate });
    }

    const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const categoryId = event.target.value as string;
        setState({ ...state, categoryId: categoryId });
        handleFilterChange({ ...state, categoryId: categoryId });
    }

    const handleDeptChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const deptId = event.target.value as string;
        setState({ ...state, deptId: deptId });
        handleFilterChange({ ...state, deptId: deptId });
    };

    return <Grid container spacing={1}>
        <Grid item xs={12}>
            <Box>
                <FormControl className={classes.formControl} style={{ marginLeft: 0 }}>
                    <IconButton onClick={handleBackDate}
                        style={{
                            marginTop: 18,
                            border: "1px solid #9094A9",
                            borderRadius: "10%",
                            width: 30,
                            height: 50,
                            marginRight: -10
                        }}>
                        <ArrowBackIosIcon style={{ color: "#9094A9" }} />
                    </IconButton>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        style={{ width: 180 }}
                        InputAdornmentProps={{ position: "start" }}
                        value={state.filterDate}
                        maxDate={new Date('2999-12-31')}

                        onChange={handleDateChange}
                    />
                    {!!state.invalidDateError &&
                        <span style={{ color: "red" }}>{state.invalidDateError}</span>}
                </FormControl>
                <FormControl className={classes.formControl}>
                    <IconButton
                        onClick={handleForwardDate}
                        style={{
                            marginTop: 18,
                            border: "1px solid #9094A9",
                            borderRadius: "10%",
                            width: 30,
                            height: 50,
                            marginLeft: -10
                        }}>
                        <ArrowForwardIosIcon style={{ color: "#9094A9" }} />
                    </IconButton>
                </FormControl>
                {!noCategory &&
                    <FormControl className={classes.formControl}>
                        <InputLabel></InputLabel>
                        <Select fullWidth
                            displayEmpty
                            disableUnderline
                            style={{ width: 180 }}
                            value={state.categoryId}
                            onChange={handleCategoryChange}
                        >
                            <MenuItem value="">{t('all_categories')}</MenuItem>
                            {state.categories.map(x =>
                                <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
                {!noDepartment &&
                    <FormControl className={classes.formControl}>
                        <InputLabel></InputLabel>
                        <Select fullWidth
                            displayEmpty
                            disableUnderline
                            style={{ width: 180 }}
                            value={state.deptId}
                            onChange={handleDeptChange}
                        >
                            <MenuItem value="">{t('all_departments')}</MenuItem>
                            {state.depts.map(x =>
                                <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
            </Box>
        </Grid>
    </Grid>;
}

export { InstanceFilters }