import { Scorecard } from "../Models/Reports";
import { BizScorecardService } from "../Services/BizScorecardService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class BizScorecardStore extends BaseGridDataStore<Scorecard>{
    private _service: BizScorecardService;

    constructor() {
        let service = new BizScorecardService();
        super(service);
        this._service = service;
    }

    download(type: string, fromDate: string, toDate: string, companyIds: Array<string>) {
        return this._service.downloadAsync(type, fromDate, toDate, companyIds);
    }
}