import axios from "axios";
import { CorrectiveActionBzReport, FollowUpBzReport } from "../Models/Reports";
import { BaseService } from "./BaseService";
import { QueryCriteria } from "../Models/QueryCriteria";
import { PagedResult } from "../Models/PagedResult";

export class CorrectiveAndFollowBZReportService extends BaseService<CorrectiveActionBzReport> {
    constructor() {
        super('CorrectiveActionReports');
    }

    queryBZFollowUpReports = async (criteria: QueryCriteria): Promise<PagedResult<FollowUpBzReport>> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/GetBusinessFollowUpReports`
        const response = await axios.post<PagedResult<FollowUpBzReport>>(url, criteria);
        return response.data;
    }
}