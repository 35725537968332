import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function CloudLibraryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6Z" fill="#9094A9" />
        <path
          d="M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 12L17.5 10.5L15 12V4H20V12Z"
          fill="#9094A9"
        />
      </svg>
    </SvgIcon>
  );
}

export default CloudLibraryIcon;
