import { useEffect, useRef, useState } from "react";
import { DataGrid, GridValueFormatterParams, } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import useStores from "../../Stores/useStores";
import ReportHeader, {
  ReportHeaderState,
  ReportHeaderProps,
} from "../Reports/Comments/ReportHeader";
import moment from "moment";
import ReactEcharts from "echarts-for-react";
import { Paper, Grid, Box, Tabs, Tab, Typography } from "@material-ui/core";
import _ from "lodash";
import { t } from "i18next";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CustomerAnalytics = observer(() => {
  const { saReportsCustomerAnalyticsStore } = useStores();
  const [allData, setAllData] = useState<any>({});
  const [enterpriseChartOption, setEnterpriseChartOption] = useState<any>({});
  const [licenseChartOption, setLicenseChartOption] = useState<any>({});
  const [licenseCountryChartOption, setLicenseCountryChartOption] =
    useState<any>({});
  const [licenseStateChartOption, setLicenseStateChartOption] = useState<any>(
    {}
  );
  const [licenseCountryCountChartOption, setLicenseCountryCountChartOption] =
    useState<any>({});
  const [licenseStateCountChartOption, setLicenseStateCountChartOption] =
    useState<any>({});

  const [enterpriseCountryChartOption, setEnterpriseCountryChartOption] =
    useState<any>({});
  const [enterpriseStateChartOption, setEnterpriseStateChartOption] =
    useState<any>({});
  const [
    enterpriseCountryCountChartOption,
    setEnterpriseCountryCountChartOption,
  ] = useState<any>({});
  const [enterpriseStateCountChartOption, setEnterpriseStateCountChartOption] =
    useState<any>({});
  const componentRef = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const preFilters = [
      {
        field: "filterFromDate",
        value: moment()
          .startOf("month")
          .add(-5, "months")
          .format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        field: "filterToDate",
        value: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
      },
    ];
    saReportsCustomerAnalyticsStore.setPreFilter([...preFilters]);
    loadChart();
  }, []);

  const handleFilterChange = (filters: ReportHeaderState) => {
    saReportsCustomerAnalyticsStore.setPreFilter([
      {
        field: "filterFromDate",
        value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        field: "filterToDate",
        value: moment(filters.toDate).format("YYYY-MM-DD HH:mm:ss"),
      },
    ]);

    loadChart();
  };

  const reportHeaderProps: ReportHeaderProps = {
    title: t("customer_analytics"),
    description: t("generate_customer_analytics"),
    showFromDate: true,
    showToDate: true,
    showDepartment: false,
    showEmployee: false,
    showWeekPicker: false,
    showDownload: false,
    showPrint: false,
    handleChange: handleFilterChange,
    handleDownload: () => { },
    handlePrint: () => { },
    initValues: {
      fromDate: moment().startOf("month").add(-5, "months").toDate(),
      toDate: moment().endOf("month").toDate(),
    },
  };

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: "rgba(0,0,0,0.3)",
    },
  };

  const genMonthlyChartOption = (
    monthlyStatistics: any[],
    isEnterprise?: boolean
  ) => {
    const fromDate = _.find(saReportsCustomerAnalyticsStore.preFilters, { field: "filterFromDate" })?.value;
    const toDate = _.find(saReportsCustomerAnalyticsStore.preFilters, { field: "filterToDate" })?.value;
    const yearMonths: Array<string> = [];
    const monthlyAmounts: Array<number> = [];
    const annuallyAmounts: Array<number> = [];
    const enterpriseAmounts: Array<number> = [];
    const monthlyCounts: Array<number> = [];
    const annuallyCounts: Array<number> = [];
    const enterpriseCounts: Array<number> = [];
    const fromYM = moment(fromDate).startOf("month");
    const toYM = moment(toDate).startOf("month");
    while (fromYM.diff(toYM) <= 0) {
      yearMonths.push(fromYM.format("YYYY.MM"));
      const row = _.head(
        monthlyStatistics.filter((x) => moment(x.yearMonth).diff(fromYM) === 0)
      );
      monthlyAmounts.push(row ? row.monthlyAmount : 0);
      annuallyAmounts.push(row ? row.annuallyAmount : 0);
      enterpriseAmounts.push(row ? row.enterpriseAmount : 0);
      monthlyCounts.push(row ? row.monthlyCount : 0);
      annuallyCounts.push(row ? row.annuallyCount : 0);
      enterpriseCounts.push(row ? row.enterpriseCount : 0);
      fromYM.add(1, "months");
    }

    const legendData = isEnterprise
      ? ["Enterprise Revenue", "Enterprise Subsription"]
      : [
        "Monthly License Revenue",
        "Annually License Revenue",
        "Monthly License Subsription",
        "Annually License Subsription",
      ];

    const series = isEnterprise
      ? [
        {
          name: t("enterprise_revenue"),
          type: "bar",
          yAxisIndex: 0,
          barWidth: 25,
          stack: "one",
          label: { show: true },
          emphasis: emphasisStyle,
          data: enterpriseAmounts,
        },
        {
          name: t("enterprise_subsription"),
          type: "line",
          yAxisIndex: 1,
          data: enterpriseCounts,
        },
      ]
      : [
        {
          name: t("monthly_license_revenue"),
          type: "bar",
          yAxisIndex: 0,
          stack: "one",
          barWidth: 25,
          label: { show: true },
          emphasis: emphasisStyle,
          data: monthlyAmounts,
        },
        {
          name: t("annually_license_revenue"),
          type: "bar",
          yAxisIndex: 0,
          barWidth: 25,
          stack: "one",
          label: { show: true },
          emphasis: emphasisStyle,
          data: annuallyAmounts,
        },
        {
          name: t("monthly_license_subsription"),
          type: "line",
          yAxisIndex: 1,
          data: monthlyCounts,
        },
        {
          name: t("annually_license_subsription"),
          type: "line",
          yAxisIndex: 1,
          data: annuallyCounts,
        },
      ];

    const option = {
      legend: {
        data: legendData,
        left: "10%",
      },
      toolbox: {
        feature: {
          saveAsImage: { show: true },
        },
      },
      tooltip: {},
      xAxis: {
        data: yearMonths,
        name: "month",
        axisLine: { onZero: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: [
        {
          type: "value",
          name: "Revenue",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: "$ {value}",
          },
        },
        {
          type: "value",
          name: "Subsription",
          position: "right",
          alignTicks: true,
          offset: 80,
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      grid: {
        bottom: 100,
      },
      series,
    };

    if (isEnterprise) {
      setEnterpriseChartOption(option);
    } else {
      setLicenseChartOption(option);
    }
  };

  const genCountryChartOption = (
    countryStatistics: Array<any>,
    byCount?: boolean
  ) => {
    const data = _.sortBy(
      countryStatistics.filter((c) => c.enterpriseCount === 0),
      (x) => (_.isEmpty(x.countryCode) ? "ZZZZZZZZ" : x.countryCode)
    ).map((x) => ({
      code: x.countryCode,
      name: `${_.isEmpty(x.countryCode) ? "Unknown" : x.countryCode} - ${byCount ? "" : "$"
        }${byCount
          ? x.monthlyCount + x.annuallyCount
          : x.monthlyAmount + x.annuallyAmount
        }`,
      value: byCount
        ? x.monthlyCount + x.annuallyCount
        : x.monthlyAmount + x.annuallyAmount,
    }));

    return {
      title: {
        text: t("country_license_statistics"),
        subtext: byCount ? t("by_subsription") : t("by_revenue"),
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Customer From",
          type: "pie",
          radius: "50%",
          data,
          emphasis: emphasisStyle,
        },
      ],
    };
  };

  const genEnterpriseCountryChartOption = (
    countryStatistics: Array<any>,
    byCount?: boolean
  ) => {
    const data = _.sortBy(
      countryStatistics.filter((c) => c.enterpriseCount !== 0),
      (x) => (_.isEmpty(x.countryCode) ? "ZZZZZZZZ" : x.countryCode)
    ).map((x) => ({
      code: x.countryCode,
      name: `${_.isEmpty(x.countryCode) ? "Unknown" : x.countryCode} - ${byCount ? "" : "$"
        }${byCount ? x.enterpriseCount : x.enterpriseAmount}`,
      value: byCount ? x.enterpriseCount : x.enterpriseAmount,
    }));

    return {
      title: {
        text: t("country_enterperise_statistics"),
        subtext: byCount ? t("by_subsription") : t("by_revenue"),
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Customer From",
          type: "pie",
          radius: "50%",
          data,
          emphasis: emphasisStyle,
        },
      ],
    };
  };

  const genStateChartOption = (countryCode: string, byCount?: boolean) => {
    const { countryStatistics } = allData;
    if (!countryStatistics) {
      return {};
    }

    const country: any = _.head(
      countryStatistics.filter((x: any) => x.countryCode === countryCode)
    );

    if (_.isEmpty(country)) {
      return {};
    }

    const { stateStatistics } = country;

    const data = _.sortBy(stateStatistics, (x) =>
      _.isEmpty(x.stateCode) ? "ZZZZZZZZ" : x.stateCode
    ).map((x) => ({
      code: x.stateCode,
      name: `${_.isEmpty(x.stateCode) ? "Unknown" : x.stateName} - ${byCount ? "" : "$"
        }${byCount
          ? x.monthlyCount + x.annuallyCount
          : x.monthlyAmount + x.annuallyAmount
        }`,
      value: byCount
        ? x.monthlyCount + x.annuallyCount
        : x.monthlyAmount + x.annuallyAmount,
    }));

    return {
      title: {
        text: `${t('license_statistics_of')} ${_.isEmpty(country.countryCode) ? "Unknown" : country.countryCode
          }`,
        subtext: byCount ? t("by_subsription") : t("by_revenue"),
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Customer From",
          type: "pie",
          radius: "50%",
          data,
          emphasis: emphasisStyle,
        },
      ],
    };
  };

  const genEnterpriseStateChartOption = (
    countryCode: string,
    byCount?: boolean
  ) => {
    const { countryStatistics } = allData;
    if (!countryStatistics) {
      return {};
    }

    const country: any = _.head(
      countryStatistics.filter((x: any) => x.countryCode === countryCode)
    );

    if (_.isEmpty(country)) {
      return {};
    }

    const { stateStatistics } = country;

    const data = _.sortBy(stateStatistics, (x) =>
      _.isEmpty(x.stateCode) ? "ZZZZZZZZ" : x.stateCode
    ).map((x) => ({
      code: x.stateCode,
      name: `${_.isEmpty(x.stateCode) ? "Unknown" : x.stateName} - ${byCount ? "" : "$"
        }${byCount ? x.enterpriseCount : x.enterpriseAmount}`,
      value: byCount ? x.enterpriseCount : x.enterpriseAmount,
    }));

    return {
      title: {
        text: `${t("enterprise_statistics_of")} ${_.isEmpty(country.countryCode) ? "Unknown" : country.countryCode
          }`,
        subtext: byCount ? t("by_subsription") : t("by_revenue"),
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Customer From",
          type: "pie",
          radius: "50%",
          data,
          emphasis: emphasisStyle,
        },
      ],
    };
  };

  const loadChart = () => {
    saReportsCustomerAnalyticsStore.queryCSV().then((resp: any) => {
      setAllData(resp);

      const { monthlyStatistics, countryStatistics } = resp as any;
      genMonthlyChartOption(monthlyStatistics);
      genMonthlyChartOption(monthlyStatistics, true);
      setLicenseCountryChartOption(genCountryChartOption(countryStatistics));
      setLicenseCountryCountChartOption(
        genCountryChartOption(countryStatistics, true)
      );
      setLicenseStateChartOption({});
      setLicenseStateCountChartOption({});
      setEnterpriseCountryChartOption(
        genEnterpriseCountryChartOption(countryStatistics)
      );
      setEnterpriseCountryCountChartOption(
        genEnterpriseCountryChartOption(countryStatistics, true)
      );
      setEnterpriseStateChartOption({});
      setEnterpriseStateCountChartOption({});
    });
  };

  const onLicenseCountryChartClick = (param: any) => {
    const { data } = param;
    const option = genStateChartOption(data.code);
    setLicenseStateChartOption(option);
  };

  const onLicenseCountryChartEvent = {
    click: onLicenseCountryChartClick,
  };

  const onLicenseCountryCountChartClick = (param: any) => {
    const { data } = param;
    const option = genStateChartOption(data.code, true);
    setLicenseStateCountChartOption(option);
  };

  const onLicenseCountryCountChartEvent = {
    click: onLicenseCountryCountChartClick,
  };

  const onEnterpriseCountryChartClick = (param: any) => {
    const { data } = param;
    const option = genEnterpriseStateChartOption(data.code);
    setEnterpriseStateChartOption(option);
  };

  const onEnterpriseCountryChartEvent = {
    click: onEnterpriseCountryChartClick,
  };

  const onEnterpriseCountryCountChartClick = (param: any) => {
    const { data } = param;
    const option = genEnterpriseStateChartOption(data.code, true);
    setEnterpriseStateCountChartOption(option);
  };

  const onEnterpriseCountryCountChartEvent = {
    click: onEnterpriseCountryCountChartClick,
  };

  const enterpriseColumns = [
    {
      field: "countryName",
      headerName: t("country"),
      width: 150,
    },
    {
      field: "stateName",
      headerName: t("state"),
      width: 150,
    },
    {
      field: "name",
      headerName: t("name"),
      width: 150,
    },
    {
      field: "emailAddress",
      headerName: t("email_address"),
      width: 300,
    },
    {
      field: "amount",
      headerName: t("amount"),
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (!params.value) {
          return "";
        }
        return `$${params.value as number}`;
      },
    },
    {
      field: "date",
      headerName: t("date"),
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (!params.value) {
          return "";
        }
        const valueFormatted = moment(params.value as string).format(
          "YYYY-MM-DD"
        );
        return valueFormatted;
      },
    },
  ];

  return (
    <>
      <ReportHeader {...reportHeaderProps} />
      <div
        ref={componentRef}
        className="section-to-print"
        style={{ width: 1200 }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Box sx={{ width: "100%" }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                  <Tab value={0} label={t("license")} />
                  <Tab value={1} label={t("enterprise")} />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: "#374359",
                    marginTop: 12,
                    marginBottom: 16,
                  }}
                >
                  {`${t("subsription")}: ${allData.licenseTotalCount || 0}`}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: "#374359",
                    marginTop: 12,
                    marginBottom: 16,
                  }}
                >
                  {`${t("revenue")}: $${allData.licenseTotalAmount || 0}`}
                </Typography>
                <ReactEcharts option={licenseChartOption} />
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <ReactEcharts
                      option={licenseCountryChartOption}
                      onEvents={onLicenseCountryChartEvent}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactEcharts option={licenseStateChartOption} />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactEcharts
                      option={licenseCountryCountChartOption}
                      onEvents={onLicenseCountryCountChartEvent}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactEcharts option={licenseStateCountChartOption} />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: "#374359",
                    marginTop: 12,
                    marginBottom: 16,
                  }}
                >
                  {`${t("subsription")}: ${allData.enterpriseTotalCount || 0}`}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: "#374359",
                    marginTop: 12,
                    marginBottom: 16,
                  }}
                >
                  {`${t("revenue")}: $${allData.enterpriseTotalAmount || 0}`}
                </Typography>
                <ReactEcharts option={enterpriseChartOption} />
                <DataGrid
                  rows={allData.enterpriseData}
                  columns={enterpriseColumns}
                  autoHeight
                />
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <ReactEcharts
                      option={enterpriseCountryChartOption}
                      onEvents={onEnterpriseCountryChartEvent}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactEcharts option={enterpriseStateChartOption} />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactEcharts
                      option={enterpriseCountryCountChartOption}
                      onEvents={onEnterpriseCountryCountChartEvent}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactEcharts option={enterpriseStateCountChartOption} />
                  </Grid>
                </Grid>
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default CustomerAnalytics;
