import axios from "axios";
import { CompanyShareLink } from "../Models/CompanyShareLink";
import { BaseService } from "./BaseService";

export class CompanyShareLinkService extends BaseService<CompanyShareLink> {
    constructor() {
        super('companyshareLinks');
    }

    queryByCode = async (code: string) =>  {
        const url = `${process.env.REACT_APP_API_URL}/companyshareLinks/queryByCode/${code}`
        const response = await axios.get<CompanyShareLink>(url);
        return response.data;
    }
}