import axios from "axios";
import { Scorecard } from "../Models/Reports";
import { BaseService } from "./BaseService";

export class ScorecardService extends BaseService<Scorecard> {
    constructor() {
        super('Scorecard');
    }

    downloadAsync = async(date: string, type: string) => {
        const url = `${process.env.REACT_APP_API_URL}/scorecard/${date}/${type}`;
        await axios.get(url, {responseType: 'blob'}).then((res: any) => {
            const { data } = res;
            const blob = new Blob([data]);
            let dom = document.createElement('a') as any;
            let url = window.URL.createObjectURL(blob);
            dom.href = url;
            dom.download = decodeURI(`scorecard.${date}.${type}`);
            dom.style.display = 'none';
            document.body.appendChild(dom);
            dom.click();
            dom.parentNode.removeChild(dom);
            window.URL.revokeObjectURL(url);
        });
    }
}