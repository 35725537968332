import { TaskStatusBizReport } from "../Models/Reports";
import { TaskStatusBizReportService } from "../Services/TaskStatusBizReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class TaskStatusBizReportsStore extends BaseGridDataStore<TaskStatusBizReport>{
    private _service: TaskStatusBizReportService;

    constructor() {
        let service = new TaskStatusBizReportService();
        super(service);
        this._service = service;
    }
}