import React, { SyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import { BusinessRounded, ExpandMore, Menu, MenuOpen } from '@material-ui/icons';
import { Avatar, Box, Typography, createStyles, SvgIconProps } from '@material-ui/core';
import Company from '../Company';
import Employee from '../Employee';
import CorrectiveAction from '../CorrectiveAction';
import Department from '../Department';
import useStores from '../../Stores/useStores';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Dashboard from '../Dashboard/index';
import MainDrawer, { drawerFullWidth } from './MainDrawer';
import CompanyRole from '../CompanyRole';
import { Organization } from '../Organization';
import { Setting } from '../Setting';
import Category from '../Category';
import { Checklist } from '../ChecklistInstance';
import { ChecklistInstanceDetail } from '../ChecklistInstance/Detail';
import { CorrectiveActionInstancePage } from '../CorrectiveActionInstance';
import { FollowUpInstancePage } from '../FollowUpInstance';
import Template from '../Template';
import { Reports } from '../Reports';
import { BusinessReports } from '../BusinessReports';
import scorecard from '../Scorecard';
import BizScorecardReport from '../BizScorecard';
import taskStatusReport from '../Reports/taskStatusReport';
import taskStatusBizReport from '../BusinessReports/taskStatusBizReport';
import taskAssignmentReport from '../Reports/taskAssignmentReport';
import correctiveAndFollowReport from '../Reports/correctiveAndFollowReport';
import trendReport from '../Reports/trendReport';
import checklistDetailReport from '../Reports/checklistDetailsReport';
import DayChangeTime from '../DayChangeTime';
import CloudLibrary from '../CloudLibrary';
import { EmployeeProfile } from '../Employee/profile';
import { ChichatPage } from '../Chichat';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { observer } from 'mobx-react';
import companyLicenses from '../License/companyLicense';
import Expire from '../Expire/expire';
import FAQ from '../FAQ/index';
import Enterprise from '../Enterprise/index';
import CompanyStructure from '../CompanyStructure';
import { AuthenticateResponseModel } from '../../Models/AuthenticateModel';
import { Company as CompanyModel } from '../../Models/Company';
import { TreeItem, TreeItemProps, TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { LightTooltip } from '../../Components/ToolTips';
import Languages from '../../Components/Languages';
import BookIcon from '../../Components/Icons/BookIcon';
import taskAssignmentBizReport from '../BusinessReports/taskAssignmentBizReport';
import checklistDetailsBizReport from '../BusinessReports/checklistDetailsBizReport';
import correctiveAndFollowBizReport from '../BusinessReports/correctiveAndFollowBizReport';
import { SaReports } from '../SaReports';
import CustomerAnalytics from '../SaReports/CustomerAnalytics';
import trendBizReport from '../BusinessReports/trendBizReport';
import { useTranslation } from 'react-i18next';
import coolingReport from '../Reports/coolingReport';
import BusinessIcon from '@material-ui/icons/Business';
import ReportsIcon from '../../Components/Icons/ReportsIcon';
import DashboardIcon from '../../Components/Icons/DashBoardIcon';
import ChitchatIcon from '../../Components/Icons/ChitchatIcon';
import CloudLibraryIcon from '../../Components/Icons/CloudLibraryIcon';
import FollowUpIcon from '../../Components/Icons/FollowUpIcon';
import DomainIcon from '@material-ui/icons/Domain';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import StructureIcon from '../../Components/Icons/StructureIcon';
import Notifications from '../Notifications';
import NotificationPopper from './NotificationPopper';
import ShareLink from '../ShareLink';
import Sensors from '../Sensors';

let hasExpaned = false;
const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "3px 0 3px 0",
      margin: 0,
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      width: '',
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      '& $content': {
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: "7px 0px",
      width: "100%"
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      fontSize: "1.2rem",
      width: "100%",
      whiteSpace: "nowrap"
    },
    rightBtnLay: {
      display: "flex",
      flexBasis: '0%',
    },
    iconContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexGrow: 1,
      width: "70%",
      overflow: "hidden"
    },
  }),
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(55, 67, 89, 0.1)',
    height: theme.spacing(8),
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9) + 1,
      width: `calc(100% - ${theme.spacing(6) + 2}px)`,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerFullWidth,
    width: `calc(100% - ${drawerFullWidth + 1}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "48px !important"
  },
  topbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  viewAs: {
    '&:hover': {
      borderRadius: "10px",
      backgroundColor: "unset"
    }
  },
}));

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelText: string,
  hideAction?: boolean,
  hideAdd?: boolean,
  hideDelete?: boolean,
  disabledAdd?: boolean,
  disabledDelete?: boolean,
  itemId: string,
  childNum: number
};

interface LayoutState {
  drawerOpen: boolean;
  userMenuOpen: boolean;
  viewAsMenuOpen: boolean;
  aboutUsMenuOpen: boolean;
  // companyTree: CompanyModel | undefined;
}

const initialState: LayoutState = {
  drawerOpen: true,
  userMenuOpen: false,
  viewAsMenuOpen: false,
  aboutUsMenuOpen: false,
  // companyTree: undefined
};

const Layout = observer(() => {
  const classes = useStyles();
  const { appStore, companyStore } = useStores();
  const [state, setState] = React.useState<LayoutState>(initialState);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const viewAsRef = React.useRef<HTMLButtonElement>(null);
  const aboutUsRef = React.useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const { companyId, isSuperAdmin, orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
  const [expanded, setExpanded] = useState(['']);
  const classesTree = useTreeItemStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isSuperAdmin) {
      companyStore.getCompanyStructure(orgCompanyId).then((data: any) => {
        appStore.setCompanyTree(data.topCompany);
      })
    }
  }, []);


  const handleLogout = () => {
    appStore.logout().then((x) => {
      history.replace('/login');
    });
  };

  const handLicense = () => {
    if (appStore.userAuth) {
      if (!appStore.userAuth.isSuperAdmin) {
        history.replace('/app/companyLicenses')
      } else {
        history.replace('/app/companies')
      }
    }

  };
  const handProfile = () => {
    history.replace('/app/profile');
  };

  const handleUserMenuToggle = () => {
    setState({ ...state, userMenuOpen: !state.userMenuOpen });
  };

  const handleAboutUsMenuToggle = () => {
    setState({ ...state, aboutUsMenuOpen: !state.aboutUsMenuOpen });
  };

  const handleViewAsMenuToggle = () => {
    if (!isSuperAdmin) {
      if (!appStore.companyTree?.id) {
        companyStore.getCompanyStructure(orgCompanyId).then((data: any) => {
          appStore.setCompanyTree(data.topCompany);
          setState({
            ...state,
            viewAsMenuOpen: !state.viewAsMenuOpen
          })
        })
      } else {
        setState({
          ...state,
          viewAsMenuOpen: !state.viewAsMenuOpen
        })
      }
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setState({ ...state, userMenuOpen: false, viewAsMenuOpen: false, aboutUsMenuOpen: false });
    }
  };

  const handleCloseUserMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setState({ ...state, userMenuOpen: false });
  };

  const handleCloseAboutUsMenu = (event: React.MouseEvent<EventTarget>) => {
    if (aboutUsRef.current && aboutUsRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setState({ ...state, aboutUsMenuOpen: false });
  };

  const handleCloseViewAs = (event: React.MouseEvent<EventTarget>) => {
    if (viewAsRef.current && viewAsRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setState({ ...state, viewAsMenuOpen: false });
  };

  const handTest = (e: any, id: any) => {
    if (companyId !== id && e.target && e.target.nodeName === "P") {
      companyStore.switchCompany(id).then((data: any) => {
        appStore.setSwitchCompany(id, data.name);
        hasExpaned = false;
      })
    }

    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { itemId, labelText, childNum, labelIcon: LabelIcon, hideAction, hideDelete, hideAdd, disabledAdd, disabledDelete, color, bgColor, ...other } = props;

    return (
      <TreeItem
        onLabelClick={e => handTest(e, itemId)}
        onIconClick={(e: SyntheticEvent) => onIconClick(e, itemId, childNum)}
        icon={
          <div className={classes.labelRoot}>
            {childNum && childNum > 0 ? (expanded.includes(itemId) ? <ArrowDropDownIcon /> : <ArrowRightIcon />) : <div style={{ width: '18px' }}></div>}
            {LabelIcon ? <LabelIcon color="inherit" className={classes.labelIcon} /> : <BusinessRounded className={classes.labelIcon} />}

            <LightTooltip title={labelText} placement="bottom-start" arrow>
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
            </LightTooltip>

          </div>
        }

        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classesTree.root,
          content: classesTree.content,
          expanded: classesTree.expanded,
          selected: classesTree.selected,
          group: classesTree.group,
          label: classesTree.label,
          iconContainer: classesTree.iconContainer
        }}
        {...other}
      />
    );
  }


  function treeItem(children: Array<CompanyModel>, startIndex: any) {
    return (
      children
        ? children.map((item, index) => (
          <StyledTreeItem hideAction={isSuperAdmin}
            onClick={(e: any) => handTest(e, item.id)}
            disabledDelete={isSuperAdmin} hideDelete={item.childCompanies && item.childCompanies.length > 0}
            childNum={item.childCompanies && item.childCompanies.length > 0 ? item.childCompanies.length : 0}
            key={item.id} itemId={item.id} nodeId={item.id} labelText={item.name}>
            {item.childCompanies ? treeItem(item.childCompanies, 0) : ''}
          </StyledTreeItem>
        ))
        : '')
  }

  const onNodeToggle = (event: object, expands: Array<string>) => {
    setExpanded(expands)
    hasExpaned = true;
  }

  const onIconClick = (e: any, itemId: string, childNum: number) => {
    if (e.target && e.target.nodeName === "P") {
      if (companyId !== itemId) {
        companyStore.switchCompany(itemId).then((data: any) => {
          appStore.setSwitchCompany(itemId, data.name);
          hasExpaned = false;
        })
      }
    }
  }

  const handTreeSelect = (event: object, value: string) => {
  }

  const getUserGuide = () => {
    var lang = localStorage.getItem("lang");
    if (lang === "english") {
      return "https://static.mykitcheninspector.com/guide/en-us/userguide.pdf";
    } else if (lang === "spanish") {
      return "https://static.mykitcheninspector.com/guide/es-mx/userguide.pdf";
    } else if (lang === "italy") {
      return "https://static.mykitcheninspector.com/guide/en-us/userguide.pdf";
    } else {
      return "https://static.mykitcheninspector.com/guide/en-us/userguide.pdf";
    }
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: state.drawerOpen,
        })}
      >
        <Toolbar className={classes.topbar}>
          <IconButton
            style={{ color: '#374359' }}
            aria-label="open drawer"
            onClick={() => setState({ ...state, drawerOpen: !state.drawerOpen })}
            edge="start"
          >
            {state.drawerOpen ? <MenuOpen /> : <Menu />}
          </IconButton>
          <Box>
            {!(appStore.userAuth && appStore.userAuth.isSuperAdmin) &&
              <>
                <IconButton ref={viewAsRef} className={classes.viewAs} onClick={() => handleViewAsMenuToggle()}>
                  <Typography>{t('view_as')}</Typography> <IconButton style={{
                    color: companyId === orgCompanyId ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 122, 21)",
                    border: "rgba(0, 0, 0, 0.2) 1px solid",
                    padding: "5px 8px",
                    marginLeft: "5px",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px"
                  }}>{appStore.userAuth?.companyName} <ExpandMore style={{ color: "rgba(136,143,147" }}></ExpandMore></IconButton>
                </IconButton>
                <Popper open={state.viewAsMenuOpen} style={{ width: "300px" }} anchorEl={viewAsRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseViewAs}>
                          <TreeView
                            onNodeSelect={handTreeSelect}
                            onNodeToggle={onNodeToggle}
                            expanded={expanded}
                            className={classesTree.root}
                            defaultEndIcon={<div style={{ width: 30 }} />}
                          >
                            <StyledTreeItem hideAction={isSuperAdmin}
                              childNum={appStore.companyTree?.childCompanies && appStore.companyTree.childCompanies.length > 0 ? appStore.companyTree.childCompanies.length : 0}
                              itemId={appStore.companyTree?.id ?? ""} nodeId={appStore.companyTree?.id ?? ""} labelText={appStore.companyTree?.name ?? ""} hideDelete >
                              {appStore.companyTree?.childCompanies ? treeItem(appStore.companyTree?.childCompanies, 1) : ''}
                            </StyledTreeItem>
                          </TreeView>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            }

            {!(appStore.userAuth && appStore.userAuth.isSuperAdmin) && (
              <NotificationPopper></NotificationPopper>
            )}

            <LightTooltip title={`${t('user_guide')}`} placement="bottom-start" arrow>
              <IconButton
                onClick={() => window.open(getUserGuide(), '_blank')}
              >
                <BookIcon style={{ color: '#374359' }}></BookIcon>
              </IconButton>
            </LightTooltip>
            <LightTooltip title={`${t('help')}`} placement="bottom-start" arrow>
              <IconButton ref={aboutUsRef} onClick={() => handleAboutUsMenuToggle()}>
                <HelpIcon style={{ color: '#374359' }}></HelpIcon>
              </IconButton>
            </LightTooltip>
            <Popper open={state.aboutUsMenuOpen} anchorEl={aboutUsRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseAboutUsMenu}>
                      <MenuList autoFocusItem={state.aboutUsMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={() => window.open('/about', '_blank')}>{t("about_us")}</MenuItem>
                        <MenuItem onClick={() => history.replace('/app/faq')}>{t("faq")}</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            {!(appStore.userAuth && appStore.userAuth.isSuperAdmin) && (
              <LightTooltip title={`${t('settings')}`} placement="bottom-start" arrow>
                <IconButton onClick={() => history.replace('/app/settings')}>
                  <SettingsIcon style={{ color: '#374359' }}></SettingsIcon>
                </IconButton>
              </LightTooltip>
            )}

            <Languages></Languages>

            <IconButton ref={anchorRef} onClick={() => handleUserMenuToggle()}>
              <Avatar alt="Remy Sharp" src={appStore.userAuth?.photoUrl} style={{ width: 24, height: 24 }} />
            </IconButton>
            <Popper open={state.userMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseUserMenu}>
                      <MenuList autoFocusItem={state.userMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={handProfile}>{t("my_account")}</MenuItem>
                        {!appStore.userAuth?.isSuperAdmin && !appStore.userAuth?.isEnterprise &&
                          <MenuItem onClick={handLicense}>{t("my_license")}</MenuItem>
                        }
                        <MenuItem onClick={handleLogout}>{t("sign_out")}</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>
      {
        appStore.userAuth?.isSuperAdmin === true ?
          <MainDrawer drawerOpen={state.drawerOpen} menuItems={[
            {
              text: t('companies'),
              icon: <BusinessIcon style={{ color: '#9094A9' }} />,
              link: 'companies',
              visable: true,
            },
            {
              text: t('enterprise'),
              icon: <BusinessIcon style={{ color: '#9094A9' }} />,
              link: 'enterprise',
              visable: true,
            },
            {
              text: t('templates'),
              icon: <SettingsIcon style={{ color: '#9094A9' }} />,
              link: 'templates',
              visable: true,
            },
            {
              text: t('reports'),
              icon: <ReportsIcon />,
              link: 'sareports',
              visable: true,
            },
          ]} />
          :
          <MainDrawer drawerOpen={state.drawerOpen} menuItems={[
            {
              text: t('dashbord'),
              icon: <DashboardIcon />,
              link: 'dashboard',
              visable: true,
            },
            {
              text: t('companies_structure'),
              icon: <StructureIcon style={{ color: '#9094A9' }} />,
              link: 'companyStructure',
              visable: true,
            },
            {
              text: t('organization'),
              icon: <DomainIcon style={{ color: '#9094A9' }} />,
              link: 'organizations',
              visable: true,
            }, {
              text: t('settings'),
              icon: <SettingsIcon style={{ color: '#9094A9' }} />,
              link: 'settings',
              visable: true,
            }, {
              text: t('checklist'),
              icon: <ListAltIcon style={{ color: '#9094A9' }} />,
              link: 'categories',
              visable: true,
            }, {
              text: t('corrective_actions'),
              icon: <ThumbUpIcon style={{ color: '#9094A9' }} />,
              link: 'correctiveActionInstance',
              visable: true,
            }, {
              text: t('follow_up'),
              icon: <FollowUpIcon />,
              link: 'followupInstance',
              visable: true,
            }, {
              text: t("analytic"),
              icon: <ReportsIcon />,
              link: 'reports',
              visable: true,
              childrens: [
                {
                  text: t('reports'),
                  icon: <ReportsIcon />,
                  link: 'reports',
                  visable: true,
                },
                {
                  text: t("business_reports"),
                  icon: <ReportsIcon />,
                  link: 'businessreports',
                  visable: true,
                },
              ].concat(
                appStore.userAuth?.isAdvanceAccount === true
                  ? [
                      {
                        text: t("scorecard"),
                        icon: <ReportsIcon />,
                        link: "scorecard",
                        visable: true,
                      },
                    ]
                  : []
              )
            }, {
              text: t('cloud_library'),
              icon: <CloudLibraryIcon />,
              link: 'cloudlibrary',
              visable: true,
            }, {
              text: t('chitchat'),
              icon: <ChitchatIcon />,
              link: 'chichat',
              visable: true,
            }]} />
      }
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route path="/app/dashboard" component={Dashboard} />
        <Route path="/app/companies" component={Company} />
        <Route path="/app/templates" component={Template} />
        <Route path="/app/organizations" component={Organization} />
        <Route path="/app/settings" component={Setting} />
        <Route path="/app/departments" component={Department} />
        <Route path="/app/employees" component={Employee} />
        <Route path="/app/companyroles" component={CompanyRole} />
        <Route path="/app/checklists" component={Category} />
        <Route path="/app/sharelinks" component={ShareLink} />
        <Route path="/app/sensors" component={Sensors} />
        <Route path="/app/correctiveactions" component={CorrectiveAction} />
        <Route path="/app/daychangetime" component={DayChangeTime} />
        <Route path="/app/categories" component={Checklist} />
        <Route path="/app/checklist/:checklistId" component={ChecklistInstanceDetail} />
        <Route path="/app/correctiveActionInstance" component={CorrectiveActionInstancePage} />
        <Route path="/app/followupInstance" component={FollowUpInstancePage} />
        <Route path="/app/reports" component={Reports} />
        <Route path="/app/businessreports" component={BusinessReports} />
        <Route path="/app/scorecard" component={scorecard} />
        <Route path="/app/sareports" component={SaReports} />
        <Route path="/app/sareportscustomeranalytics" component={CustomerAnalytics} />
        <Route path="/app/taskstatusreports" component={taskStatusReport} />
        <Route path="/app/companiestaskstatusreports" component={taskStatusBizReport} />
        <Route path="/app/taskassignmentreports" component={taskAssignmentReport} />
        <Route path="/app/companiestaskassignmentreports" component={taskAssignmentBizReport} />
        <Route path="/app/checklistDetailsBizReport" component={checklistDetailsBizReport} />
        <Route path="/app/correctiveAndFollowBizReport" component={correctiveAndFollowBizReport} />
        <Route path="/app/scorecardBizReport" component={BizScorecardReport} />
        <Route path="/app/correctiveAndFollowReports" component={correctiveAndFollowReport} />
        <Route path="/app/trendreports" component={trendReport} />
        <Route path="/app/companiestrendreports" component={trendBizReport} />
        <Route path="/app/checklistDetailReports" component={checklistDetailReport} />
        <Route path="/app/cloudlibrary" component={CloudLibrary} />
        <Route path="/app/chichat" component={ChichatPage} />
        <Route path="/app/profile" component={EmployeeProfile} />
        <Route path="/app/notification" component={Notifications} />
        <Route path="/app/companyStructure" component={CompanyStructure} />
        <Route path="/app/companyLicenses" component={companyLicenses} />
        <Route path="/app/expire" component={Expire} />
        <Route path="/app/faq" component={FAQ} />
        <Route path="/app/enterprise" component={Enterprise} />
        <Route path="/app/coolingReport" component={coolingReport} />

        <Route exact path="/app">
          <Redirect to={appStore.userAuth?.isHQ ?
            (appStore.userAuth?.hasLicense ? '/app/dashboard' : appStore.userAuth?.isSuperAdmin ? "/app/companies" : "/app/companyStructure") :
            (appStore.userAuth?.hasLicense ? '/app/dashboard' : appStore.userAuth?.isSuperAdmin ? "/app/companies" : "/app/companyStructure")} />
        </Route>
      </main>
    </div>
  );
});
export default Layout;
