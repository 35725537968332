
import { extendObservable } from "mobx";
import { Category } from "../Models/Category";
import { QueryCriteria } from "../Models/QueryCriteria";
import { CategoryService } from "../Services/CategoryService";
import LanguagesModels from "../Utils/languages";
import { AppStore } from "./AppStore";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CategoryStore extends BaseGridDataStore<Category>{
    private _categoryService: CategoryService;
    private _appStore: AppStore;
    language?: string;

    constructor(appStore: AppStore) {
        let categoryService = new CategoryService();
        super(categoryService);
        this._categoryService = categoryService;
        this._appStore = appStore;
        this.language = localStorage.getItem('lang') ?? LanguagesModels[0].key;
        extendObservable(this, {
            queryTemplate: this.queryTemplate,
        });
    }

    setLanguage(language: any) {
        return new Promise((resolve, reject) => {
            this.language = language;
            resolve(language);
        })
    }

    override query() {
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }

        if (this._appStore.userAuth?.isSuperAdmin) {
            criteria.filters.push({
                field: 'Language',
                value: this.language ?? LanguagesModels[0].key
            });
        }

        this._serivce.queryAsync(criteria).then(x => {
            this.models = x.data;
            this.count = x.count;
            this.pages = ((x.count + this.pageSize - 1) / this.pageSize);
        })
    }

    //query templates
    queryTemplate(language: any) {
        return this._categoryService.queryTemplatesAsync(language);
    }

    //import templates
    importTemplateAsync(templateIds: string[], importOption: boolean, language: String) {
        return this._categoryService.importTemplateAsync(templateIds, importOption, language);
    }

    refresh() {
        return this._categoryService.refresh();
    }
}