import {extendObservable} from "mobx";
import {ChecklistInstance} from "../Models/ChecklistInstance";
import {ChecklistInstanceService} from "../Services/ChecklistInstanceService";
import {BaseGridDataStore} from "./BaseGridDataStore";
import {QueryCriteria} from "../Models/QueryCriteria";

export class ChecklistInstanceStore extends BaseGridDataStore<ChecklistInstance> {
    private _checklistInstanceService: ChecklistInstanceService;

    constructor() {
        let checklistInstanceService = new ChecklistInstanceService();
        super(checklistInstanceService);
        this._checklistInstanceService = checklistInstanceService;
        extendObservable(this, {
            getCompleteProgress: this.getCompleteProgress,
            getChecklistDetail: this.getChecklistDetail,
            getTaskDetail: this.getTaskDetail,
            getTaskFollowUps: this.getTaskFollowUps,
            replayComment:this.replayComment,
        });
    }

    // get the complete data
    getCompleteProgress() {
        const criteria: QueryCriteria = {skip: this.pageIndex * this.pageSize, take: this.pageSize};
        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }
        return this._checklistInstanceService.getCompleteProgressAsync(criteria);
    }

    getChecklistDetail(checklistInstanceId: string) {
        return this._checklistInstanceService.getChecklistDetailAsync(checklistInstanceId);
    }

    getChichatChecklistInstances() {
        const criteria: QueryCriteria = {skip: this.pageIndex * this.pageSize, take: this.pageSize};
        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }
        return this._checklistInstanceService.GetChichatCheklistDetailsAsync(criteria);
    }

    getTaskDetail(taskInstanceId?: string) {
        return this._checklistInstanceService.getTaskDetailAsync(taskInstanceId);
    }

    getTaskFollowUps(taskInstanceId?: string) {
        return this._checklistInstanceService.getTaskFollowUpsAsync(taskInstanceId);
    }

    getTaskComments(taskInstanceId= "", isChichat=false) {
        const criteria: QueryCriteria = {skip: this.pageIndex * this.pageSize, take: this.pageSize};

        return this._checklistInstanceService.getTaskCommentsAsync(Object.assign({}, criteria, {taskInstanceId,isChichat}));
    }

    replayComment(taskInstanceId: string, groupId: string, replayTo: string, content: string) {
        return this._checklistInstanceService.replayCommentAsync(taskInstanceId, groupId, replayTo, content);
    }

    refresh(checklistId: string) {
        return this._checklistInstanceService.refresh(checklistId);
    }
}