import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { useEffect, useState } from "react";
import { ValidationError } from "../../Models/ValidationError";
import { makeStyles, createStyles, Theme, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Box, Grid, List, ListItem, ListItemText, TextField, Typography, Paper } from "@material-ui/core";
import MuiPhoneNumber from 'material-ui-phone-number';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import { green, orange } from "@material-ui/core/colors";
import { isValidEmail } from "../../Utils/StringUtils";
import useStores from "../../Stores/useStores";
import { Employee } from "../../Models/Employee";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import debounce from "lodash/debounce";
import TextFieldPassword from "../../Components/TextFieldPassword";
import { useHistory } from "react-router-dom";
import { t } from "i18next";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupList: {
            paddingTop: '0',
            '& .MuiListItem-root.Mui-selected': {
                background: '#1CAD5E',
                color: '#FFFFFF'
            }
        },
        avatarInput: {
            display: 'none',
        },
        editContainer: {
            minHeight: 340,
            '& .MuiGrid-item': {
                marginTop: 14
            },
            padding: '10px 34px'
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
    }),
);

const theme = createTheme({
    palette: {
        primary: green,
        secondary: orange,
    },
});

interface ProfilePageState {
    editGroup: number,
    userInfo: Employee,
    repeatPwd?: string
    originalPwd?: string,
    photoUrl?: string,
    avatarChanged: boolean,
    newPwd?: string,
    errors: ValidationError[],
}

const initEmployeeInfo: Employee = {
    id: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
    companyRoleId: "",
    companyRoleName: "",
    isManager: false,
    canLoginApp: false,
    canLoginWeb: false,
    personalPhotoStr: '',
    healthPermitStr: ''
}

const EmployeeProfile = observer(() => {
    const { employeeStore, appStore } = useStores();
    const { photoUrl, employeeId } = appStore.userAuth as AuthenticateResponseModel;
    const [state, setState] = useState<ProfilePageState>({
        editGroup: !(appStore.userAuth && appStore.userAuth.isSuperAdmin) ? 1 : 2,
        errors: [],
        avatarChanged: false,
        userInfo: initEmployeeInfo
    });
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = () => {
        if (!appStore.userAuth) {
            return;
        }
        const userId = appStore.userAuth ? appStore.userAuth.id : "";
        employeeStore.getEmployeeByUserId(userId)
            .then(data => {
                setState({
                    ...state,
                    photoUrl: photoUrl,
                    userInfo: { ...state.userInfo, ...data },
                });
            });
    }
    const updateInformation = () => {
        handleInformationValidate().then(() => {
            employeeStore.update({ ...state.userInfo })
                .then(done => {
                    appStore.infoMsg = t("update_successfully");
                    appStore.showInfo = !!done;
                });
        });
    }
    const handleInformationValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.userInfo.firstName.trim().length === 0) {
                valdiationErrors.push({
                    field: 'firstName',
                    message: t("first_name_required")
                });
            }

            if (state.userInfo.firstName.trim().length > 50) {
                valdiationErrors.push({
                    field: 'firstName',
                    message: t("first_name_max_length")
                });
            }

            if (state.userInfo.lastName.trim().length === 0) {
                valdiationErrors.push({
                    field: 'lastName',
                    message: t("last_name_required")
                });
            }

            if (state.userInfo.lastName.trim().length > 50) {
                valdiationErrors.push({
                    field: 'lastName',
                    message: t("last_name_max_length")
                });
            }

            if (state.userInfo.emailAddress.trim().length === 0) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t("email_address_required")
                });
            } else if (!isValidEmail(state.userInfo.emailAddress.trim())) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t("email_format_error")
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
                setState({ ...state, errors: valdiationErrors })
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    }

    const handleUploadAvatar = (event: any) => {
        const file = event.target.files[0];
        const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/webp',];
        if (!file || allowTypes.indexOf(file.type) < 0) {
            appStore.error = t("file_type_not_allowed");
            appStore.showError = true;
            return;
        }
        let maxSize = 1024 * 1000 * 10; //10m for image and docment

        if (file.size > maxSize) {
            appStore.error = `${t("max_allowed_size")}:${maxSize / 1000 / 1024} mb`;
            appStore.showError = true;
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data) {
                const base64Str = base64data.toString();
                setState({ ...state, photoUrl: base64Str, avatarChanged: true });
            }
        }
    }

    const handleSaveAvatar = () => {
        const { avatarChanged, photoUrl = "" } = state;
        if (!avatarChanged) {
            appStore.infoMsg = t("please_choice_a_new_avatar_first");
            appStore.showInfo = true;
            return;
        }
        if (!employeeId) {
            appStore.error = t("cant_get_your_login_auth_info");
            appStore.showError = true;
            return;
        }
        employeeStore.updateAvatar(employeeId, photoUrl).then((data: any) => {
            if (data && data.done) {
                setState({ ...state, photoUrl: data.url, avatarChanged: false });
                appStore.setAuthenticated({ ...appStore.userAuth, photoUrl: photoUrl } as AuthenticateResponseModel);
                appStore.infoMsg = t("update_avatar_successfully");
                appStore.showInfo = true;
            }
        })
    }
    const debounceSaveAvatar = debounce(handleSaveAvatar, 500);

    const handleUpdatePwd = () => {
        handlePasswordValidate().then(() => {
            const { originalPwd = '', newPwd = '' } = state;
            return employeeStore.changeEmployeePassword(originalPwd.trim(), newPwd.trim())
        })
            .then(done => {
                appStore.logout().then(x => {
                    history.replace('/login');
                    appStore.infoMsg = t("change_password_successfully");
                    appStore.showInfo = !!done;
                });
            })
            .catch((error => {
                console.log(error);
            }))
    }
    const handlePasswordValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            const { originalPwd = '', newPwd = '', repeatPwd = '' } = state;
            if (originalPwd.trim().length === 0) {
                valdiationErrors.push({
                    field: 'originalPwd',
                    message: t("original_password_is_required")
                });
            }
            if (newPwd.trim().length === 0) {
                valdiationErrors.push({
                    field: 'newPwd',
                    message: t("new_password_is_required")
                });
            } else if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/.test(newPwd.trim())) {
                valdiationErrors.push({
                    field: 'newPwd',
                    message: `${t("min_password_length_is_8_and_must_consist_of_characters_numbers")}, ~!@#$%^&*`
                });
            }

            if (repeatPwd.trim().length === 0) {
                valdiationErrors.push({
                    field: 'repeatPwd',
                    message: t("repeat_password_is_required")
                });
            }

            if (repeatPwd !== newPwd) {
                valdiationErrors.push({
                    field: 'repeatPwd',
                    message: t("new_password_and_repeat_password_are_inconsistency")
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
                setState({ ...state, errors: valdiationErrors })
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    }

    const debounceUpdatePwd = debounce(handleUpdatePwd, 500);

    return <>
        <CommonPageTitle title={t("profile")}
            subTitle={t("edit_your_personal_information")}
        />
        <Paper style={{ marginTop: 20, padding: 10 }}>
            <Grid container style={{ borderTop: 'solid 1px #cfcfcf' }}>
                <Grid item xs={2} style={{ borderRight: 'solid 1px #ccc' }}>
                    <List className={classes.groupList}>
                        {!(appStore.userAuth && appStore.userAuth.isSuperAdmin) && <ListItem
                            selected={state.editGroup === 1}
                            onClick={() => setState({ ...state, editGroup: 1 })}
                            button
                        >
                            <ListItemText primary={t("basic_setting")} />
                        </ListItem>}

                        <ListItem
                            selected={state.editGroup === 2}
                            onClick={() => setState({ ...state, editGroup: 2 })}
                            button
                        >
                            <ListItemText primary={t("password_setting")} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    {state.editGroup === 1 && <>{!(appStore.userAuth && appStore.userAuth.isSuperAdmin) ?
                        <Box className={classes.editContainer}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 500, color: "#374359", marginTop: 12, marginBottom: 16 }}
                            >
                                {t("basic_setting")}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required={true}
                                        error={state.errors.findIndex(x => x.field === "firstName") > -1}
                                        helperText={state.errors.find(x => x.field === "firstName")?.message || ''}
                                        label={t("first_name")}
                                        value={state.userInfo.firstName}
                                        onChange={(event) => setState({
                                            ...state,
                                            userInfo: { ...state.userInfo, firstName: event.target.value },
                                            errors: [...state.errors].filter(x => x.field !== "firstName")
                                        })}
                                        placeholder={t("first_name")}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required={true}
                                        error={state.errors.findIndex(x => x.field === "lastName") > -1}
                                        helperText={state.errors.find(x => x.field === "lastName")?.message || ''}
                                        label={t("last_name")}
                                        value={state.userInfo.lastName}
                                        onChange={(event) => setState({
                                            ...state,
                                            userInfo: { ...state.userInfo, lastName: event.target.value },
                                            errors: [...state.errors].filter(x => x.field !== "lastName")
                                        })}
                                        placeholder={t("last_name")}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPhoneNumber
                                        fullWidth
                                        defaultCountry={'us'}
                                        error={state.errors.findIndex(x => x.field === "phone") > -1}
                                        helperText={state.errors.find(x => x.field === "phone")?.message || ''}
                                        label={t("phone_number")}
                                        value={state.userInfo.phone}
                                        onChange={(value) => setState({
                                            ...state,
                                            userInfo: { ...state.userInfo, phone: value },
                                            errors: [...state.errors].filter(x => x.field !== "phone")
                                        })}
                                        placeholder={t("phone_number")}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        error={state.errors.findIndex(x => x.field === "emailAddress") > -1}
                                        helperText={state.errors.find(x => x.field === "emailAddress")?.message || ''}
                                        label={t("email_address")}
                                        value={state.userInfo.emailAddress}
                                        onChange={(event) => setState({
                                            ...state,
                                            userInfo: { ...state.userInfo, emailAddress: event.target.value },
                                            errors: [...state.errors].filter(x => x.field !== "emailAddress")
                                        })}
                                        placeholder={t("email_address")}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ width: "168px", marginTop: 8 }}
                                        onClick={updateInformation}
                                    >
                                        {t("update_information")}
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box> : <Box></Box>
                    }</>}
                    {state.editGroup === 2 && <Box className={classes.editContainer}>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 500, color: "#374359", marginTop: 12, marginBottom: 16 }}
                        >
                            {t("password_setting")}
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextFieldPassword
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "originalPwd") > -1}
                                    helperText={state.errors.find(x => x.field === "originalPwd")?.message || ''}
                                    label={t("original_password")}
                                    value={state.originalPwd}
                                    onChange={(event) => setState({
                                        ...state,
                                        originalPwd: event.target.value,
                                        errors: [...state.errors].filter(x => x.field !== "originalPwd")
                                    })}
                                    placeholder={t("original_password")}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldPassword
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "newPwd") > -1}
                                    helperText={state.errors.find(x => x.field === "newPwd")?.message || ''}
                                    label={t("new_password")}
                                    value={state.newPwd}
                                    onChange={(event) => setState({
                                        ...state,
                                        newPwd: event.target.value,
                                        errors: [...state.errors].filter(x => x.field !== "newPwd")
                                    })}
                                    placeholder={t("new_password")}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextFieldPassword
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "repeatPwd") > -1}
                                    helperText={state.errors.find(x => x.field === "repeatPwd")?.message || ''}
                                    label={t("repeat_new_password")}
                                    value={state.repeatPwd}
                                    onChange={(event) => setState({
                                        ...state,
                                        repeatPwd: event.target.value,
                                        errors: [...state.errors].filter(x => x.field !== "repeatPwd")
                                    })}
                                    placeholder={t("repeat_new_password")}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ width: "168px", marginTop: 8 }}
                                    onClick={debounceUpdatePwd}
                                >
                                    {t("reset_password")}
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
                    }
                </Grid>
                {state.editGroup === 1 && <>{
                    !(appStore.userAuth && appStore.userAuth.isSuperAdmin) &&
                    <Grid item xs={4} style={{ paddingLeft: 100 }}>

                        <Typography
                            variant="h6"
                            style={{ fontWeight: 500, color: "#374359", marginTop: 84, marginBottom: 16 }}
                        >
                            {t("avatar")}
                        </Typography>
                        <Avatar alt="Remy Sharp" src={state.photoUrl} className={classes.large}
                            style={{ cursor: "default" }} />
                        <input
                            accept="image/png,image/jpg,image/jpeg,image/gif,image/webp"
                            className={classes.avatarInput}
                            id="contained-profile-avatar"
                            type="file"
                            value={''}
                            onChange={handleUploadAvatar}
                        />
                        <label htmlFor="contained-profile-avatar">
                            <ThemeProvider theme={theme}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                    style={{ width: "155px", height: "48px", textTransform: "capitalize", marginTop: 18, marginLeft: 10 }}
                                    startIcon={<CloudUploadIcon />}

                                >
                                    {t("change_avatar")}
                                </Button>

                            </ThemeProvider>
                        </label>
                        <br />

                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            disabled={!state.avatarChanged}
                            style={{ width: "155px", marginTop: 18, marginLeft: 10 }}
                            startIcon={<SaveIcon />}
                            onClick={debounceSaveAvatar}
                        >
                            {t("save_avatar")}
                        </Button>


                    </Grid>
                }</>
                }
            </Grid>
        </Paper>
    </>
});
export { EmployeeProfile }