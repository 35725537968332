import {observer} from "mobx-react";
import {useHistory, useParams} from "react-router-dom";
import {Box, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import LinearProgress, {LinearProgressProps} from '@material-ui/core/LinearProgress';
import {createStyles, createTheme, makeStyles, Theme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, {TreeItemProps} from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import {TransitionProps} from '@material-ui/core/transitions';
import {green, pink, red} from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useStores from "../../Stores/useStores";
import {Guid} from "guid-typescript";
import {ChecklistInstance} from "../../Models/ChecklistInstance";
import {RecurringType} from "../../Models/RecurringType";
import {TimeType} from "../../Models/TimeType";

import {getRecurringTimeText} from "../../Utils/utils"
import TaskDetailInfo, {TaskDetailState, initTaskDetailState} from "../../Components/TaskDetailInfo";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTranslation } from "react-i18next";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


function TransitionComponent(props: TransitionProps) {
    return (
        <Collapse {...props} />
    );
}

const StyledTreeItem = withStyles((theme: Theme) =>
    createStyles({
        iconContainer: {
            '& .close': {
                opacity: 0.3,
            },
        },
        group: {
            marginLeft: 7,
            paddingLeft: 18,
            borderLeft: `1px dashed green `,
        },
    }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent}/>);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tree: {
            minHeight: 800,
            flexGrow: 1,
            maxWidth: 400,
        },
        pink: {
            color: '#FFFFFF',
            backgroundColor: pink[500],
        },
        orange: {
            color: '#FFFFFF',
            backgroundColor: green[500],
        },
        purple: {
            color: '#FFFFFF',
            backgroundColor: red[500],
        },
        green: {
            color: '#FFFFFF',
            backgroundColor: green[500],
        },
        imageRoot: {
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        imageList: {
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
        },
    }));

const theme = createTheme({
    palette: {
        primary: green,
        secondary: red,
    },
});

interface DetailPageState {
    gridId: string,
    checklist: ChecklistInstance,
    selectedTaskId?: string,
}

const initState: DetailPageState = {
    gridId: Guid.create().toString(),
    checklist: {
        id: "",
        checklistInstanceId: "",
        checklistInstanceName: "",
        categoryName: "",
        departmentName: "",
        recurringType: RecurringType.Daily,
        timeType: TimeType.OneTime,
        checklistCount: 0,
        taskCompletedCount: 0,
        taskDelayCount: 0,
        taskDelayCompletedCount:0,
        taskFailedCount: 0,
        failedCriticalCount:0,
        taskCompletedRate:0,
        totalTask: 0,
        groups: [],
        totalCriticalCount: 0
    },
    selectedTaskId: ""
};

const ChecklistInstanceDetail = observer(() => {
    const {checklistId} = useParams<{ checklistId: string }>();
    const [state, setState] = useState<DetailPageState>(initState);
    const [taskState, setTaskState] = useState<TaskDetailState>(initTaskDetailState);
    const classes = useStyles();
    const {checklistInstanceStore} = useStores();
    const { t } = useTranslation();
    
    useEffect(() => {
        getChecklistInstance();
    }, []);

    const getChecklistInstance = () => {
        checklistInstanceStore.getChecklistDetail(checklistId)
            .then(data => {
                const firstGroup = data.groups?.[0];
                const firstTask = firstGroup?.tasks?.[0];
                setState({...state, checklist: data, selectedTaskId: firstTask?.taskInstanceId});
                getTaskInstance(data,firstGroup?.name, firstTask?.taskInstanceId, false);
            });
    }

    const getTaskInstance = (checklist:ChecklistInstance, groupName?: string, taskId?: string, needSetState: boolean = true) => {
        if (!taskId) {
            return;
        }
        if (needSetState && taskId) {
            setState({...state, selectedTaskId: taskId});
        }
        Promise.all([
            checklistInstanceStore.getTaskDetail(taskId),
            checklistInstanceStore.getTaskComments(taskId),
            checklistInstanceStore.getTaskFollowUps(taskId),])
            .then(values => {
                setTaskState({
                    ...taskState,
                    taskInfo: values[0],
                    comments: values[1].data,
                    totalComments: values[1].count,
                    followups: values[2],
                    checklist: checklist,
                    groupName: groupName
                });
                window.scrollTo(0, 0);
            });
    }

    const afterReply = (taskId:string) => {
        checklistInstanceStore.getTaskComments(taskId)
            .then(x=>{
                setTaskState({
                    ...taskState,
                    comments: x.data,
                });
            });
    }

    const history = useHistory();

    return <>
        <Typography variant="h5" style={{fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16}}>
            {t("category")} - {state.checklist.categoryName}
        </Typography>
        <Typography
            variant="h5"
            style={{fontWeight: 400, color: "#9094A9", fontSize: 14}}
        >
            {t("there")} {state.checklist.groups && state.checklist.groups.length > 1 ? t("are") : t("is")} {state.checklist.groups?.length} {(t("checklist")).toLowerCase()} {state.checklist.groups && state.checklist.groups.length > 1  ? (t("groups")).toLowerCase() : t("group")}
        </Typography>
         <Box style={{display: "flex", marginBottom: 16, justifyContent: "flex-end"}}>
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<ArrowBackIosIcon/>}
                    onClick={() => history.goBack()}
                    style={{marginRight: 16, background: '#374359', color: '#fff'}}
                >
                    {t("back_to_checklist")}
                </Button>
         </Box>
        <Paper variant="outlined" square style={{marginTop: 30}}>
            <Paper>
                <Box display="flex" alignItems="center" style={{paddingBottom: "15px"}}>
                    <Box ml={2} mr={1} minWidth={500}>
                        <Typography variant="h5"
                                    style={{fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16}}>
                            {t("checklist")} - {state.checklist.checklistInstanceName}
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{fontWeight: 400, color: "#9094A9", fontSize: 14}}
                        >
                            {getRecurringTimeText(state.checklist as ChecklistInstance)} <ThemeProvider theme={theme}>
                            <Button variant="outlined" size="small" color="primary" disableRipple
                                    style={{height: 28, marginLeft: 5}}>
                                {RecurringType[state.checklist.recurringType]}
                            </Button>

                        </ThemeProvider>
                        </Typography>
                    </Box>
                    <Box width="100%" mr={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={4}>
                                <Typography style={{fontWeight: 500, color: "#374359", fontSize: 16, marginBottom: 16}}>
                                    {t("task_completed")}:
                                </Typography>
                                <LinearProgressWithLabel value={
                                    state.checklist.totalTask > 0 ?
                                    Math.round(state.checklist.taskCompletedCount / state.checklist.totalTask * 100) :0
                                }
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Typography style={{fontWeight: 500, color: "#374359", fontSize: 16, marginBottom: 16}}>
                                    {t("failed_critical")}:
                                </Typography>
                                <LinearProgressWithLabel value={
                                    state.checklist.totalTask > 0 ?
                                    Math.round(state.checklist.failedCriticalCount / state.checklist.totalTask * 100) : 0
                                }/>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Typography style={{fontWeight: 500, color: "#374359", fontSize: 16, marginBottom: 16}}>
                                    {t("delayed_completion")}:
                                </Typography>
                                <LinearProgressWithLabel value={
                                    state.checklist.taskDelayCount > 0 ?
                                    Math.round(state.checklist.taskDelayCompletedCount / state.checklist.taskDelayCount * 100) : 0
                                }/>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>
            <Paper style={{marginTop: 10}}>

                <Box display="flex">

                    <Box ml={2} mr={1} minWidth={400}>
                        <Typography variant="h5"
                                    style={{fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16}}>
                            {t("groups")} ({state.checklist.groups?.length})
                        </Typography>
                        <TreeView
                            className={classes.tree}
                            defaultExpanded={["0"]}
                            defaultCollapseIcon={<Avatar className={classes.green}><AssignmentIcon/></Avatar>}
                            defaultExpandIcon={<Avatar className={classes.green}><AssignmentIcon/></Avatar>}
                        >
                            {state.checklist.groups?.map((group, groupIndex) => (
                                <StyledTreeItem nodeId={`${groupIndex}`} label={
                                    <Typography variant="h5" style={{
                                        fontWeight: 500,
                                        color: "#374359",
                                        marginLeft: 10,
                                        marginTop: 12,
                                        marginBottom: 16
                                    }}>
                                        { state.checklist.groups?.length === 1 ? state.checklist.checklistInstanceName : group.name}
                                    </Typography>
                                }>
                                    {group.tasks?.map(task => (
                                        <TreeItem nodeId={task.id}
                                                  label={
                                                      <div>
                                                          <Button style={{
                                                              backgroundColor: state.selectedTaskId === task.taskInstanceId ? "#1CAD5E" : "#F8F9FB",
                                                              height: "auto",
                                                              width: "100%"
                                                          }}>
                                                              <Typography component={"span"} style={{
                                                                  width: "90%",
                                                                  textAlign: "left",
                                                                  fontWeight: 500,
                                                                  color: state.selectedTaskId === task.taskInstanceId ? "#FFFFFF" : "#374359",
                                                                  marginTop: 12,
                                                                  marginBottom: 16
                                                              }}>
                                                                  {task.taskInstanceName}
                                                              </Typography>
                                                          </Button>
                                                      </div>
                                                  }
                                                  icon={<></>}
                                                  style={{marginTop: 10, marginBottom: 10}}
                                                  onLabelClick={() => getTaskInstance(state.checklist, group.name, task.taskInstanceId)}
                                        />
                                    ))}
                                </StyledTreeItem>
                            ))}

                        </TreeView>
                    </Box>
                    <Box width="100%" ml={2}>
                        {!!state.selectedTaskId && !!taskState.groupName &&
                        <TaskDetailInfo data={{...taskState}}  onlyChichat={false} afterReply={afterReply}/>
                        }
                    </Box>
                </Box>
            </Paper>
        </Paper>
    </>
})

export {ChecklistInstanceDetail}