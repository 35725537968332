import { CorrectiveFollowReport } from "../Models/Reports";
import { CorrectiveFollowReportService } from "../Services/CorrectiveFollowReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CorrectiveFollowReportsStore extends BaseGridDataStore<CorrectiveFollowReport>{
    private _service: CorrectiveFollowReportService;

    constructor() {
        let service = new CorrectiveFollowReportService();
        super(service);
        this._service = service;
    }
}