
import { CompanyStructure } from "../Models/CompanyStructure";
import { CompanyStructureService } from "../Services/CompanyStructureService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CompanyStructureStore extends BaseGridDataStore<CompanyStructure>{
    private _companyStructureService: CompanyStructureService;

    constructor() {
        let companyStructureService = new CompanyStructureService();
        super(companyStructureService);
        this._companyStructureService = companyStructureService;
        // extendObservable(this, {
        //     activate: this.activate,
        //     deactivate: this.deactivate,
        // });
    }



    getCompanyStructure(id: string) {
        return this._companyStructureService.getCompanyStructureAsync(id);
    }

    // getCompanyStructureInfo() {
    //     return this._companyStructureService.getCompanyStructureInfoAsync();
    // }
}