

export const GetOrdinal = (day: number) => {
    const sufix=["TH","ST","ND","RD"];
    const v= day % 100;
    return `${day} ${sufix[(v-20)%10] || sufix[v]|| sufix[0]}`;
}

export const Range = (start: number, stop: number) => {
    if (start < stop) {
        return [...Array(stop + 1).keys()]
            .filter(i => i >= start);
    }
    return [...Array(start + 1).keys()]
            .filter(i => i >= stop);
}

export const DayOrdinalRange = Range(1,31).map(x => GetOrdinal(x));


