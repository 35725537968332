import {IHasId} from "./IHasId";

export enum NotificationType {
		Corrective,
		Comment,
		System,
	}

export interface Notification extends IHasId {
    fullName:string,
    notifyTime:Date,
    message:string,
    taskInstanceName:string,
    checklistInstanceName:string,
    categoryInstanceName:string,
    executeTime:string,
    isRead:boolean,
    notificationType:NotificationType,
    employeePhoto:string
}