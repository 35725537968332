import { ChangeEvent } from 'react';
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import { Employee } from "../../Models/Employee";
import { useState, useEffect } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { LightTooltip } from "../../Components/ToolTips";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
    Box, Button, IconButton, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel,
    makeStyles, MenuItem, Select, Switch, TextField, Typography
} from "@material-ui/core";
import { Department } from "../../Models/Department";
import { CompanyRole } from "../../Models/CompanyRole";
import AddIcon from '@material-ui/icons/Add';
import { ValidationError } from "../../Models/ValidationError";
import { isValidEmail } from "../../Utils/StringUtils";
import { RowStatus } from "../../Models/RowStatus";
import { observer } from "mobx-react";
import MuiPhoneNumber from 'material-ui-phone-number';
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import BackButton from "../../Components/BackToButton";
import DeleteIcon from '@material-ui/icons/Delete';
import { AuthenticateResponseModel } from '../../Models/AuthenticateModel';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    accessContainer: {
        borderRadius: 6,
        border: '1px solid #9094A9'
    },
    attachPhotoContainer: {
        marginTop: 16,
        borderRadius: 6,
        position: 'relative',
        border: '1px solid #9094A9',
        width: '100%',
        height: '145px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            maxWidth: '100%'
        }
    },
    attachPhotoNext: {
        position: 'absolute',
        right: -12,
        top: -12
    },
    attachPhotoPre: {
        position: 'absolute',
        left: -12,
        top: -12
    },
    attachPhotoRemove: {
        position: 'absolute',
        right: -12,
        bottom: -12
    }
}));

interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: Employee,
    editMode: FormMode,
    base64data: any,
    depts: Department[],
    companyRoles: CompanyRole[],
    companyId: string,
    errors: ValidationError[]
}

const initEmployee: Employee = {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phone: '',
    departmentId: '',
    departmentName: '',
    companyRoleId: '',
    companyRoleName: '',
    isManager: false,
    canLoginApp: true,
    canLoginWeb: false,
    personalPhotoStr: '',
    healthPermitStr: '',
}

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initEmployee,
    depts: [],
    companyRoles: [],
    companyId: '',
    errors: [],
    base64data: '',
};

const EmployeePage = observer(() => {
    const classes = useStyles();
    const { employeeStore, departmentStore, companyRoleStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { hasActionPermission } = appStore.userAuth as AuthenticateResponseModel;
    const { t } = useTranslation();
    //load select department and role data
    useEffect(() => {
        getSelectList();
    }, []);

    const getSelectList = () => {
        Promise.all([departmentStore.queryAll(), companyRoleStore.queryAll()]).then(values => {
            setState({ ...state, depts: values[0], companyRoles: values[1] });
        });
    };

    const handleUpdateStatus = (employee: Employee, status?: boolean, canLoginWeb?: boolean, canLoginApp?: boolean) => {
        employeeStore.updateStatus(employee.id, status, canLoginWeb, canLoginApp);
    };

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.firstName.trim().length === 0) {
                valdiationErrors.push({
                    field: 'firstName',
                    message: t('first_name_required')
                });
            } else if (state.editRow.firstName.trim().length > 50) {
                valdiationErrors.push({
                    field: 'firstName',
                    message: t('first_name_max_length')
                });
            }

            if (state.editRow.lastName.trim().length === 0) {
                valdiationErrors.push({
                    field: 'lastName',
                    message: t('last_name_required')
                });
            } else if (state.editRow.lastName.trim().length > 50) {
                valdiationErrors.push({
                    field: 'lastName',
                    message: t('last_name_max_length')
                });
            }

            if (state.editRow.emailAddress.length === 0) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t('email_address_required')
                });
            } else if (!isValidEmail(state.editRow.emailAddress)) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t('email_format_error')
                });
            } else if (state.editRow.emailAddress.trim().length > 200) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t('email_address_max_length')
                });
            }
            if (state.editRow.companyRoleId.length === 0) {
                valdiationErrors.push({
                    field: 'companyRole',
                    message: t('role_required')
                });
            }
            if (state.editRow.departmentId?.length === 0) {
                valdiationErrors.push({
                    field: 'departmentId',
                    message: t('department_required')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const handleMangerChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
        employeeStore.updateManager(id);
    };


    const columns: GridColDef[] = [
        {
            field: "firstName", headerName: t('first_name'), flex: 1, renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <LightTooltip title={employee.firstName} placement="bottom-start" arrow>
                    <span>{employee.firstName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "lastName", headerName: t('last_name'), flex: 1, renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <LightTooltip title={employee.lastName} placement="bottom-start" arrow>
                    <span>{employee.lastName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "emailAddress", headerName: t('email'), flex: 1, renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <LightTooltip title={employee.emailAddress} placement="bottom-start" arrow>
                    <span>{employee.emailAddress}</span>
                </LightTooltip>;
            })
        },
        {
            field: "phone", headerName: t('phone'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <LightTooltip title={employee.phone ? employee.phone : ""} placement="bottom-start" arrow>
                    <span>{employee.phone}</span>
                </LightTooltip>;
            })
        },
        {
            field: "personalPhotoStr", headerName: t("personal_photo"), flex: 1, sortable: false, align: "center",
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                if (!employee.personalPhotoStr) {
                    return "";
                }
                return <a href={employee.personalPhotoStr} target="_blank" rel="noreferrer" >
                    <img src={employee.personalPhotoStr} alt={""} style={{ width: 60, height: 60, marginTop: 30, cursor: 'pointer' }} />

                </a>

            })
        },
        {
            field: "healthPermitStr", headerName: t("health_permit"), flex: 1, sortable: false, align: "center",
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                if (!employee.healthPermitStr) {
                    return "";
                }
                return <a href={employee.healthPermitStr} target="_blank" rel="noreferrer" >
                    <PictureAsPdfIcon style={{ width: 60, height: 60, marginTop: 30, cursor: 'pointer', color: "gray" }} />
                </a>

            })
        },
        {
            field: "departmentName",
            headerName: t("department"),
            flex: 1,
            sortable: false,
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <LightTooltip title={employee.departmentName ? employee.departmentName : ""} placement="bottom-start" arrow>
                    <span>{employee.departmentName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "companyRoleName",
            headerName: t('roles'),
            flex: 1,
            sortable: false,
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <LightTooltip title={employee.companyRoleName ? employee.companyRoleName : ""}
                    placement="bottom-start" arrow>
                    <span>{employee.companyRoleName}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'isManager', headerName: t('manager'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <Checkbox
                    checked={employee.isManager === true}
                    color="primary"
                    name="isManager"
                    onChange={(event) => handleMangerChange(event, employee.id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            })
        },
        {
            field: 'access', headerName: t('access'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        disabled={!hasActionPermission}
                        control={<Switch checked={employee.canLoginWeb === true}
                            onChange={() => handleUpdateStatus(employee, undefined, !employee.canLoginWeb)}
                            color="primary"
                            name="loginApp"
                        ></Switch>}
                        label={t('web')}
                    />
                    <FormControlLabel
                        disabled={!hasActionPermission}
                        control={<Switch checked={employee.canLoginApp === true}
                            onChange={() => handleUpdateStatus(employee, undefined, undefined, !employee.canLoginApp)}
                            color="primary"
                            name="loginApp"
                        ></Switch>}
                        label={t('app')}
                    />
                </Box>
            })
        },
        {
            field: 'status', headerName: t('status'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const employee: Employee = row.row as Employee;
                return <Switch
                    checked={employee.status === RowStatus.Active}
                    onChange={() => handleUpdateStatus(employee, employee.status === RowStatus.Active ? false : true)}
                    color="primary"
                    name="status"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={!hasActionPermission}
                />
            })
        },
    ];

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initEmployee, id: Guid.create().toString() }
        })
    }

    const handleUploadFile = (event: any) => {
        const file = event.target.files[0];
        if (!checkUploadFile(file)) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data) {
                setState({ ...state, editRow: { ...state.editRow, personalPhotoStr: base64data.toString() } })
            }
        }
    }

    const handlePhotoRemove = () => {
        setState({ ...state, editRow: { ...state.editRow, personalPhotoStr: '' } })
    }

    const handleHealthPermitRemove = () => {
        setState({ ...state, editRow: { ...state.editRow, healthPermitStr: '' } })
    }

    const handleUploadHealthPermit = (event: any) => {
        const file = event.target.files[0];
        const allowTypes = ['application/pdf'];
        if (!file || allowTypes.indexOf(file.type) < 0) {
            appStore.error = t('upload_pdf_only');
            appStore.showError = true;
            return;
        }
        let maxSize = 1024 * 1000 * 10; //10m for image and docment
        if (file.size > maxSize) {
            appStore.error = `${t('max_allowed_size')}:${maxSize / 1000 / 1024} mb`;
            appStore.showError = true;
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data) {
                setState({ ...state, base64data: file, editRow: { ...state.editRow, healthPermitStr: base64data.toString() } })
            }
        }
    }
    const openPDF = () => {
        const dadastr = state.editRow.healthPermitStr;
        if (/^http/.test(dadastr)) {
            window.open(dadastr, "_blank");
            return;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(state.base64data);
        window.open(link.href, '_blank')
        window.URL.revokeObjectURL(link.href);
    }

    const checkUploadFile = (file: any): boolean => {
        const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
        if (!file || allowTypes.indexOf(file.type) < 0) {
            appStore.error = `${t('upload_image_type')}`;
            appStore.showError = true;
            return false;
        }
        let maxSize = 1024 * 1000 * 10; //10m for image and docment
        if (file.size > maxSize) {
            appStore.error = `${t('max_allowed_size')}:${maxSize / 1000 / 1024} mb`;
            appStore.showError = true;
            return false;
        }
        return true;
    }

    return (<>
        <CommonPageTitle title={t('employee')}
            subTitle={t('manage_employees')}></CommonPageTitle>
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <BackButton isOrganization={true} />
            {hasActionPermission &&
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleCreate}
                >
                    {t('create_employees')}
                </Button>
            }
        </Box>
        <CommonGridPage<Employee>
            key={state.gridId}
            columns={columns}
            store={employeeStore}
            rowHeight={80}
            delConfirmMsg={t('delete_employee_tip')}
            onEdit={(row: Employee) => setState({
                ...state,
                dialogOpen: true,
                editRow: row,
                editMode: FormMode.Edit
            })}
            useAction={hasActionPermission}
        />

        <CommonFormDialog<Employee>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={employeeStore}
            title={t('employee')}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "firstName") > -1}
                        helperText={state.errors.find(x => x.field === "firstName")?.message || ''}
                        label={t('first_name')}
                        value={state.editRow?.firstName}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, firstName: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "firstName")
                        })}
                        placeholder={t('first_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "lastName") > -1}
                        helperText={state.errors.find(x => x.field === "lastName")?.message || ''}
                        label={t('last_name')}
                        value={state.editRow?.lastName}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, lastName: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "lastName")
                        })}
                        placeholder={t('last_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "emailAddress") > -1}
                        helperText={state.errors.find(x => x.field === "emailAddress")?.message || ''}
                        label={t('email_address')}
                        value={state.editRow?.emailAddress}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, emailAddress: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "emailAddress")
                        })}
                        placeholder={t("email_address")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiPhoneNumber
                        fullWidth
                        defaultCountry={'us'}
                        value={state.editRow.phone}
                        onChange={(value) => setState({ ...state, editRow: { ...state.editRow, phone: value } })}
                        placeholder={t("phone")}
                        label={t("phone")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth required={true}
                        error={state.errors.findIndex(x => x.field === "departmentId") > -1}>
                        <InputLabel id="lblDepartment" shrink>{t("department")}</InputLabel>
                        <Select fullWidth
                            error={state.errors.findIndex(x => x.field === "departmentId") > -1}
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, departmentId: event.target.value as string },
                                errors: [...state.errors].filter(x => x.field !== "departmentId")
                            })}
                            value={state.editRow.departmentId}
                            displayEmpty
                            disableUnderline>
                            <MenuItem value="" disabled>{t('please_select_department')}</MenuItem>
                            {state.depts.map(x =>
                                <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{state.errors.find(x => x.field === "departmentId")?.message || ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth required={true}
                        error={state.errors.findIndex(x => x.field === "companyRole") > -1}>
                        <InputLabel id="lblRole" shrink>{t('roles')}</InputLabel>
                        <Select fullWidth
                            required
                            error={state.errors.findIndex(x => x.field === "companyRole") > -1}
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, companyRoleId: event.target.value as string },
                                errors: [...state.errors].filter(x => x.field !== "companyRole")
                            })}
                            value={state.editRow.companyRoleId}
                            displayEmpty
                            disableUnderline>
                            <MenuItem value="" disabled>{t('please_select_role')}</MenuItem>
                            {state.companyRoles.map(companyRole =>
                                <MenuItem value={companyRole.id} key={companyRole.id}>{companyRole.name}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{state.errors.find(x => x.field === "companyRole")?.message || ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="lblPhoto" shrink>{t('personal_photo')}</InputLabel>
                        {!!state.editRow.personalPhotoStr ?
                            <Box className={classes.attachPhotoContainer}>
                                <IconButton className={classes.attachPhotoRemove}
                                    onClick={() => handlePhotoRemove()}>
                                    <DeleteIcon />
                                </IconButton>
                                <img src={state.editRow.personalPhotoStr} alt='' style={{ maxHeight: "100%" }} />
                            </Box> :
                            <Box className={classes.attachPhotoContainer}>
                                <input accept="image/png,image/jpg,image/jpeg,image/webp" hidden id="icon-button-file11" type="file" value={''}
                                    onChange={handleUploadFile} />
                                <label htmlFor="icon-button-file11" style={{ textAlign: "center" }} >
                                    <IconButton color="primary" aria-label={t('upload_picture')} component="span">
                                        <AddCircleIcon style={{ fontSize: 32, marginLeft: 0 }} />
                                    </IconButton>
                                    <Typography style={{ fontSize: 14, color: '#9094A9' }}>
                                        {t('attach_personal_photo')}
                                    </Typography>
                                </label>
                            </Box>
                        }
                        <FormHelperText>{state.errors.find(x => x.field === "personalPhotoStr")?.message || ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="lblHealthPermit" shrink>{t('health_permit')}</InputLabel>
                        {!!state.editRow.healthPermitStr ?
                            <Box className={classes.attachPhotoContainer}>
                                <IconButton className={classes.attachPhotoRemove}
                                    onClick={() => handleHealthPermitRemove()}>
                                    <DeleteIcon />
                                </IconButton>
                                <PictureAsPdfIcon style={{ width: 60, height: 60, cursor: 'pointer', color: "gray" }} onClick={openPDF} />
                            </Box> :
                            <Box className={classes.attachPhotoContainer}>
                                <input accept="application/pdf" hidden id="icon-button-file22" type="file" value={''}
                                    onChange={handleUploadHealthPermit} />
                                <label htmlFor="icon-button-file22" style={{ textAlign: "center" }} >
                                    <IconButton color="primary" aria-label="upload pdf" component="span">
                                        <AddCircleIcon style={{ fontSize: 32, marginLeft: 0 }} />
                                    </IconButton>
                                    <Typography style={{ fontSize: 14, color: '#9094A9' }}>
                                        {t('attach_health_permit')}
                                    </Typography>
                                </label>
                            </Box>
                        }
                        <FormHelperText>{state.errors.find(x => x.field === "healthPermitStr")?.message || ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <fieldset className={classes.accessContainer}>
                        <legend>{t('access')}</legend>
                        <div>
                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Switch checked={state.editRow.isManager}
                                            onChange={(event) => setState({
                                                ...state,
                                                editRow: {
                                                    ...state.editRow,
                                                    isManager: event.target.checked
                                                }
                                            })} color="primary" />}
                                        label={t('manager')} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Switch checked={state.editRow.canLoginApp}
                                            onChange={(event) => setState({
                                                ...state,
                                                editRow: {
                                                    ...state.editRow,
                                                    canLoginApp: event.target.checked
                                                }
                                            })} color="primary" />}
                                        label={t('app_login')} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Switch checked={state.editRow.canLoginWeb}
                                            onChange={(event) => setState({
                                                ...state,
                                                editRow: {
                                                    ...state.editRow,
                                                    canLoginWeb: event.target.checked
                                                }
                                            })}
                                            color="primary" />}
                                        label={t('web_login')} />
                                </Grid>
                            </Grid>
                        </div>
                    </fieldset>
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
});

export default EmployeePage;


