import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function FollowUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.4 6L14 4H5V21H7V14H12.6L13 16H20V6H14.4Z" fill="#9094A9" />
      </svg>
    </SvgIcon>
  );
}

export default FollowUpIcon;