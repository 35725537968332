import { Grid, makeStyles, Typography, TextField, Button } from "@material-ui/core";
import { useState } from "react";
import { observer } from 'mobx-react'
import useStores from "../../Stores/useStores";
import { useHistory, useParams } from "react-router-dom";
import { ValidationError } from "../../Models/ValidationError";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    background: '#E5E5E5',
  },
  top: {
    // height: '100px',
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    'box-shadow': '0px 4px 4px rgba(55, 67, 89, 0.1)',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    background: '#E5E5E5',
  },
  logo: {
    height: '90px',
    width: '311px',
    paddingLeft: '70px',
    // paddingTop: '17px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetTitle: {
    // paddingTop: 216,
    fontWeight: 500,
    fontSize: 32
  },
  resetSubtitle: {
    fontWeight: 400,
    color: '#374359'
  },
  page: {
    height: '100vh',
    background: '#E5E5E5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // 'box-shadow': '0px 4px 4px rgba(55, 67, 89, 0.1)',
  },
  logoimg:{
    'max-width': '311px',
    'max-height': '90px',
  },
  header: {
    height: '90px',
    background: '#fff',
    'box-shadow': '0px 4px 4px rgba(55, 67, 89, 0.1)',
    zIndex: 2,
  },
  body: {
    flex: 1,
    boxSizing: 'border-box',
    padding: '0 10px',
    
  }
  // form: {
  //   width: '100%',
  //   height: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  // }
}));


interface InputNewPasswordState {
  password: string,
  repassword: string,
  passwordError: boolean,
  repasswordError: boolean,
  verifycodeError: boolean,
  errors: ValidationError[],
}

const initState: InputNewPasswordState = {
  password: '',
  repassword: '',
  passwordError: false,
  repasswordError: false,
  verifycodeError: false,
  errors: []
};

const ResetPassword = observer((props: any) => {
  interface ParamTypes {
    verifycode: string;
  }

  const { verifycode } = useParams<ParamTypes>();
  const classes = useStyles();
  const { accountStore, appStore } = useStores();
  const [state, setState] = useState<InputNewPasswordState>(initState);
  const history = useHistory();
  const { t } = useTranslation();

  const handleSave = () => {
    handlePasswordValidate()
      .then(x => {
        if (x) {
          //verify and send reset email
          if (verifycode.length > 0) {
            accountStore.resetPassword(verifycode, state.password).then(done => {
              if (done) {
                appStore.infoMsg = t("reset_password_successfully");
                appStore.showInfo = true;
                history.replace('/login');
              }
            });
          } else {
            appStore.error = t("verify_code_is_missing");
            appStore.showError = true;
          }
        }
      });
  }

  const handlePasswordValidate = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const valdiationErrors: ValidationError[] = [];
      const { password = '', repassword = '' } = state;
      if (password.trim().length === 0) {
        valdiationErrors.push({
          field: 'password',
          message: t("new_password_is_required")
        });
      } else if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/.test(password.trim())) {
        valdiationErrors.push({
          field: 'password',
          message: `${t("min_password_length_is_8_and_must_consist_of_characters_numbers")}, ~!@#$%^&*`
        });
      }

      if (repassword.trim().length === 0) {
        valdiationErrors.push({
          field: 'repassword',
          message: t("repeat_password_is_required")
        });
      }

      if (repassword !== password) {
        valdiationErrors.push({
          field: 'repassword',
          message: t("new_password_and_repeat_password_are_inconsistency")
        });
      }

      if (valdiationErrors.length === 0) {
        resolve(true);
        setState({ ...state, errors: valdiationErrors })
      } else {
        reject();
        setState({ ...state, errors: valdiationErrors })
      }
    })
  }
  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.logo}><img className={classes.logoimg} alt="Klean Kitchen" src="/MKI-logo-web.svg" /></Typography>
      </div>
      <div className={classes.body}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid className={classes.bottom} item xs={12} md={8} container >
            <Typography variant="h5" className={classes.resetTitle}>
              {t("reset_password")}
            </Typography>
            <Typography variant="h5" className={classes.resetSubtitle}>
              {t("fill_in_your_pew_password")}
            </Typography>
            <TextField
              value={state.password}
              error={state.errors.findIndex(x => x.field === "password") > -1}
              helperText={state.errors.find(x => x.field === "password")?.message || ''}
              onChange={(event) => setState({
                ...state,
                password: event.target.value,
                errors: [...state.errors].filter(x => x.field !== "password")
              })}
              type="password"
              placeholder={t("new_password")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                autoComplete: '',
                form: {
                  autocomplete: 'off',
                },
              }}
              style={{ marginTop: 32 }}
            />
            <TextField
              value={state.repassword}
              error={state.errors.findIndex(x => x.field === "repassword") > -1}
              helperText={state.errors.find(x => x.field === "repassword")?.message || ''}
              onChange={(event) => setState({
                ...state,
                repassword: event.target.value,
                errors: [...state.errors].filter(x => x.field !== "repassword")
              })}
              type="password"
              placeholder={t("repeat_new_password")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                autoComplete: '',
                form: {
                  autocomplete: 'off',
                },
              }}
              style={{ marginTop: 32 }}
            />
            <Button fullWidth variant="contained" style={{ marginTop: 32 }} disableElevation color="primary"
              onClick={() => handleSave()}>
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  // return (<Grid container className={classes.container} >
  //   <Grid className={classes.top}>
  //     <Typography variant="h5" className={classes.logo}><img alt="Klean Kitchen" src="/MKI-logo-web.svg" /></Typography>
  //   </Grid>
  //   <Grid className={classes.bottom} item xs={8} container spacing={2} >
  //     <Typography variant="h5" className={classes.resetTitle}>
  //       {t("reset_password")}
  //     </Typography>
  //     <Typography variant="h5" className={classes.resetSubtitle}>
  //       {t("fill_in_your_pew_password")}
  //     </Typography>
  //     <TextField
  //       value={state.password}
  //       error={state.errors.findIndex(x => x.field === "password") > -1}
  //       helperText={state.errors.find(x => x.field === "password")?.message || ''}
  //       onChange={(event) => setState({
  //         ...state,
  //         password: event.target.value,
  //         errors: [...state.errors].filter(x => x.field !== "password")
  //       })}
  //       type="password"
  //       placeholder={t("new_password")}
  //       InputProps={{ disableUnderline: true }}
  //       InputLabelProps={{ shrink: true }}
  //       inputProps={{
  //         autoComplete: '',
  //         form: {
  //           autocomplete: 'off',
  //         },
  //       }}
  //       style={{ marginTop: 32 }}
  //     />
  //     <TextField
  //       value={state.repassword}
  //       error={state.errors.findIndex(x => x.field === "repassword") > -1}
  //       helperText={state.errors.find(x => x.field === "repassword")?.message || ''}
  //       onChange={(event) => setState({
  //         ...state,
  //         repassword: event.target.value,
  //         errors: [...state.errors].filter(x => x.field !== "repassword")
  //       })}
  //       type="password"
  //       placeholder={t("repeat_new_password")}
  //       InputProps={{ disableUnderline: true }}
  //       InputLabelProps={{ shrink: true }}
  //       inputProps={{
  //         autoComplete: '',
  //         form: {
  //           autocomplete: 'off',
  //         },
  //       }}
  //       style={{ marginTop: 32 }}
  //     />
  //     <Button fullWidth variant="contained" style={{ marginTop: 32 }} disableElevation color="primary"
  //       onClick={() => handleSave()}>
  //       {t("save")}
  //     </Button>
  //   </Grid>
  // </Grid>);
});

export default ResetPassword;