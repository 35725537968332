import axios from "axios";
import { CompanyStructure } from "../Models/CompanyStructure";
import { BaseService } from "./BaseService";

export class CompanyStructureService extends BaseService<CompanyStructure> {
    constructor() {
        super('companies');
    }
    
    getCompanyStructureAsync =  async(companyStructureId: string): Promise<CompanyStructure> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetCompanyStructure/${companyStructureId}`
        const response = await axios.get<CompanyStructure>(url);
        return response.data;
    }

   
}