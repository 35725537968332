import { extendObservable } from "mobx";
import { Employee } from "../Models/Employee";
import { EmployeeService } from "../Services/EmployeeService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class EmployeeStore extends BaseGridDataStore<Employee>{
    private _employeeService: EmployeeService;

    constructor() {
        let employeeService = new EmployeeService();
        super(employeeService);
        this._employeeService = employeeService;
        extendObservable(this, {
            updateStatus: this.updateStatus,
            getEmployeeByUserId:this.getEmployeeByUserId,
            updateAvatar:this.updateAvatar,
            changeEmployeePassword:this.changeEmployeePassword,
            updateManager:this.updateManager
        });
    }


    //active or disactive user's login account
    updateStatus(employeeId:string, status?: boolean, canLoginWeb?: boolean, canLoginApp?: boolean){
        return this._employeeService.updateStatusAsync(employeeId, status, canLoginWeb, canLoginApp).then(x => {
            if (x) {
                this.query();
            }
        });
    }

    updateManager(employeeId:string){
        return this._employeeService.updateManagerAsync(employeeId).then(x => {
            if (x) {
                this.query();
            }
        });
    }

    getEmployeeByUserId(employeeId:string) {
        return this._employeeService.getEmployeeByUserIdAsync(employeeId);
    }

    updateAvatar(employeeId:string,avatar:string){
        return this._employeeService.updateAvatarAsync(employeeId,avatar);
    }

    changeEmployeePassword(oldPassword:string,newPassword:string){
        return this._employeeService.changeEmployeePasswordAsync(oldPassword,newPassword);
    }

}