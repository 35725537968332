import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import DvrIcon from '@material-ui/icons/Dvr';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DnsIcon from '@material-ui/icons/Dns';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    featureList: {
        display: 'flex',
        '& a:not(:last-child)': {
            borderRight: 'solid 1px rgba(0, 0, 0, 0.12)'
        },
        '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: 20,
            color: '#374359 !important'
        }
    },
});

const listItemIconStyle = {
    color: '#1CAD5E',
    fontSize: 40
}


export function Reports() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <Typography
                variant="h5"
                style={{ fontWeight: 700, color: "#374359", fontSize: 24, marginTop: 12, marginBottom: 10 }}
            >
                {t('reports')}
            </Typography>
            <Typography
                variant="h5"
                style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
            >
                {t('check_out_latest_reports')}
            </Typography>
            <Paper elevation={3} style={{ marginTop: 32 }}>
                <List className={classes.featureList}>
                    <ListItem component={Link} to="/app/taskstatusreports">
                        <ListItemAvatar>
                            <DvrIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t('task_status_report')} secondary={t('cenerate_task_status_report')} />
                    </ListItem>
                    <ListItem component={Link} to="/app/taskassignmentreports">
                        <ListItemAvatar>
                            <AssignmentIndIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t('task_assignment_report')} secondary={t('generate_task_assignment_report')} />
                    </ListItem>
                    <ListItem component={Link} to="/app/trendreports">
                        <ListItemAvatar>
                            <DnsIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t('trend_reports')} secondary={t('generate_trend_reports')} />
                    </ListItem>
                </List>
                <List className={classes.featureList}>
                    <ListItem component={Link} to="/app/checklistDetailReports">
                        <ListItemAvatar>
                            <LibraryAddCheckIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("checklist_details_report")} secondary={t("generate_checklist_details_report")} />
                    </ListItem>
                    <ListItem component={Link} to="/app/correctiveAndFollowReports">
                        <ListItemAvatar>
                            <CollectionsBookmarkIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t('corrective_actions_follow_up_reports')} secondary={t("generate_corrective_actions_follow_up_reports")} />
                    </ListItem>
                    <ListItem component={Link} to="/app/coolingReport?sensor=0">
                        <ListItemAvatar>
                            <CollectionsBookmarkIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t('cooling_temperature_report')} secondary={t("generate_cooling_temperature_reports")} />
                    </ListItem>
                </List>
                <List className={classes.featureList}>
                    <ListItem component={Link} to="/app/coolingReport?sensor=1">
                        <ListItemAvatar>
                            <CollectionsBookmarkIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t('cooling_sensor_report')} secondary={t("generate_cooling_sensor_reports")} />
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}