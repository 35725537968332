import { Checklist } from "../Models/Checklist";
import { ChecklistGroup } from "../Models/ChecklistGroup";
import { Task } from "../Models/Task";
import { BaseService } from "../Services/BaseService";
import { AccountStore } from "./AccountStore";
import { AppStore } from "./AppStore";
import { BaseGridDataStore } from "./BaseGridDataStore";
import { CategoryStore } from "./CategoryStore";
import { CompanyStore } from "./CompanyStore";
import { DepartmentStore } from "./DepartmentStore";
import { EmployeeStore } from "./EmployeeStore";
import { TaskStatusReportsStore } from "./TaskStatusReportsStore";
import { TaskAssignmentStore } from "./TaskAssignmentStore";
import { CorrectiveFollowReportsStore } from "./CorrectiveFollowReportsStore";
import { ChecklistInstanceStore } from "./ChecklistInstanceStore";
import { CorrectiveActionInstanceStore } from "./CorrectiveActionInstanceStore";
import { FollowUpInstanceStore } from "./FollowUpInstanceStore";
import { TrendReportsStore } from "./TrendReportsStore";
import { TrendBizReportsStore } from "./TrendBizReportsStore";
import { CheckklistDetailReportsStore } from "./CheckklistDetailReportsStore";
import { CloudLibraryStore } from "./CloudLibraryStore";
import { NotificationStore } from "./NotificationStore";
import { CustomerStore } from "./CustomerStore";
import { LicenseStore } from "./LicenseStore";
import { CompanyStructureStore } from "./CompanyStrutureStore";
import { CorrectiveActionStore } from "./CorrectionActionStore";
import { CompanyRoleStore } from "./CompanyRoleStore";
import { TaskAssignmentBizStore } from "./TaskAssignmentBizStore";
import { TaskStatusBizReportsStore } from "./TaskStatusBizReportsStore";
import { SaReportsCustomerAnalyticsStore } from "./SaReportsCustomerAnalyticsStore";
import { ChecklistDetailBizReportStore } from './ChecklistDetailBizReportStore';
import { CorrectiveAndFollowBZReportStore } from "./CorrectiveAndFollowBZReportStore";
import { CoolingReportsStore } from "./CoolingReportsStore";
import { ScorecardStore } from "./ScorecardStore";
import { CompanyShareLinkStore } from "./CompanyShareLinkStore";
import { ChecklistStore } from "./ChecklistStore";
import { BizScorecardStore } from "./BizScorecardStore";
import { CompanySlotStore } from "./CompanySlotStore";

export interface RootStore {
    appStore: AppStore,
    accountStore: AccountStore,
    companyStore: CompanyStore,
    correctiveActionStore: CorrectiveActionStore,
    departmentStore: DepartmentStore,
    companyRoleStore: CompanyRoleStore,
    employeeStore: EmployeeStore,
    categoryStore: CategoryStore,
    checklistStore: ChecklistStore,
    checklistGroupStore: BaseGridDataStore<ChecklistGroup>,
    taskStore: BaseGridDataStore<Task>,
    taskDefineStore: BaseGridDataStore<Task>,
    taskStatusReportsStore: TaskStatusReportsStore,
    taskStatusBizReportsStore: TaskStatusBizReportsStore,
    taskAssignmentStore: TaskAssignmentStore,
    taskAssignmentBizStore: TaskAssignmentBizStore,
    saReportsCustomerAnalyticsStore: SaReportsCustomerAnalyticsStore,
    correctiveFollowReportsStore: CorrectiveFollowReportsStore,
    trendReportsStore: TrendReportsStore,
    trendBizReportsStore: TrendBizReportsStore,
    checklistDetailsReportsStore: CheckklistDetailReportsStore,
    checklistInstanceStore: ChecklistInstanceStore,
    correctiveActionInstanceStore: CorrectiveActionInstanceStore,
    followUpInstanceStore: FollowUpInstanceStore,
    cloudLibraryStore: CloudLibraryStore,
    notificationStore: NotificationStore,

    customerStore: CustomerStore,
    licenseStore: LicenseStore,
    companyStructureStore: CompanyStructureStore,
    checklistDetailBizReportStore: ChecklistDetailBizReportStore,
    correctiveAndFollowBZReportStore: CorrectiveAndFollowBZReportStore,
    coolingReportsStore: CoolingReportsStore,
    scorecardStore: ScorecardStore,
    companyShareLinkStore: CompanyShareLinkStore,
    companySlotStore: CompanySlotStore,
    bizScorecardStore: BizScorecardStore,
}

const appStore = new AppStore();
const stores: RootStore = {
    appStore: appStore,
    accountStore: new AccountStore(appStore),
    companyStore: new CompanyStore(),
    correctiveActionStore: new CorrectiveActionStore(appStore),
    departmentStore: new DepartmentStore(),
    companyRoleStore: new CompanyRoleStore(appStore),
    employeeStore: new EmployeeStore(),
    categoryStore: new CategoryStore(appStore),
    checklistStore: new ChecklistStore(),
    checklistGroupStore: new BaseGridDataStore<ChecklistGroup>(new BaseService<ChecklistGroup>('checklistGroups')),
    taskStore: new BaseGridDataStore<Task>(new BaseService<Task>('tasks')),
    taskDefineStore: new BaseGridDataStore<Task>(new BaseService<Task>('tasks')),
    taskStatusReportsStore: new TaskStatusReportsStore(),
    taskStatusBizReportsStore: new TaskStatusBizReportsStore(),
    taskAssignmentStore: new TaskAssignmentStore(),
    taskAssignmentBizStore: new TaskAssignmentBizStore(),
    correctiveFollowReportsStore: new CorrectiveFollowReportsStore(),
    trendReportsStore: new TrendReportsStore(),
    trendBizReportsStore: new TrendBizReportsStore(),
    checklistDetailsReportsStore: new CheckklistDetailReportsStore(),
    checklistInstanceStore: new ChecklistInstanceStore(),
    saReportsCustomerAnalyticsStore: new SaReportsCustomerAnalyticsStore(),
    correctiveActionInstanceStore: new CorrectiveActionInstanceStore(),
    followUpInstanceStore: new FollowUpInstanceStore(),
    cloudLibraryStore: new CloudLibraryStore(),
    notificationStore: new NotificationStore(),
    customerStore: new CustomerStore(),
    licenseStore: new LicenseStore(),
    companyStructureStore: new CompanyStructureStore(),
    checklistDetailBizReportStore: new ChecklistDetailBizReportStore(),
    correctiveAndFollowBZReportStore: new CorrectiveAndFollowBZReportStore(),
    coolingReportsStore: new CoolingReportsStore(),
    scorecardStore: new ScorecardStore(),
    companyShareLinkStore: new CompanyShareLinkStore(appStore),
    companySlotStore: new CompanySlotStore(appStore),
    bizScorecardStore:new BizScorecardStore()
};

export default stores;
