import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Paper, InputAdornment, TextField, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Category } from "../../Models/Category";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useStores from "../../Stores/useStores";
import { green, pink, red } from "@material-ui/core/colors";
import { ChecklistInstance } from "../../Models/ChecklistInstance";
import { TransitionProps } from "@material-ui/core/transitions";
import Collapse from "@material-ui/core/Collapse";
import debounce from "lodash/debounce";
import TaskDetailInfo, { TaskDetailState, initTaskDetailState } from "../../Components/TaskDetailInfo";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const StyledTreeItem = withStyles((theme: Theme) =>
    createStyles({
        iconContainer: {
            '& .close': {
                opacity: 0.3,
            },
        },
        group: {
            marginLeft: 7,
            paddingLeft: 18,
            borderLeft: `1px dashed green `, 
        },
    }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

function TransitionComponent(props: TransitionProps) {
    return (
        <Collapse {...props} />
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            maxWidth: 345,
        },
        formControl: {
            margin: theme.spacing(1),
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        searchTextField: {
            width: 200,
            '& .MuiInputBase-input': {
                padding: '9px 0px !important',
                height: "32px",
            },
            '& .MuiInputAdornment-positionStart': {
                marginLeft: '17px'
            }
        },
        tree: {
            minHeight: 800,
            flexGrow: 1,
            maxWidth: 400,
        },
        pink: {
            color: '#FFFFFF',
            backgroundColor: pink[500],
        },
        orange: {
            color: '#FFFFFF',
            backgroundColor: green[500],
        },
        purple: {
            color: '#FFFFFF',
            backgroundColor: red[500],
        },
        green: {
            color: '#FFFFFF',
            backgroundColor: green[500],
        },
    }),
);

interface ChichatPageState {
    categoryId: string,
    search: string,
    isReply: number,
    selectedDate: Date,
    categories: Category[],
    selectedTaskId?: string,
    lists: ChecklistInstance[],
}

const initPageState: ChichatPageState = {
    categoryId: "",
    search: "",
    isReply: 0,
    selectedDate: new Date(),
    categories: [],
    selectedTaskId: "",
    lists: []
}


const ChichatPage = observer(() => {
    const classes = useStyles();
    const { appStore } = useStores();
    initPageState.selectedDate = appStore.getFilterDate();
    const [state, setState] = useState<ChichatPageState>(initPageState);
    const [taskState, setTaskState] = useState<TaskDetailState>(initTaskDetailState);
    const { categoryStore, checklistInstanceStore } = useStores();
    const { t } = useTranslation();
    useEffect(() => {
        getSelectList();
    }, []);

    const getSelectList = () => {
        Promise.all([categoryStore.queryAll()]).then(values => {
            getChichatChecklistInstances({ categories: values[0] });
        });
    };
    const getChichatChecklistInstances = (changed: {}) => {
        const lastState = Object.assign({}, { ...state }, changed)
        checklistInstanceStore.setPreFilter([
            {
                field: "isReply",
                value: lastState.isReply.toString()
            },
            {
                field: "search",
                value: lastState.search.toString()
            },
            {
                field: "categoryId",
                value: lastState.categoryId.toString()
            },
            {
                field: "selectedDate",
                value: moment(lastState.selectedDate).format("YYYY-MM-DD HH:mm:ss")
            },
        ])
        checklistInstanceStore.getChichatChecklistInstances()
            .then(data => {
                if (data.length) {
                    const firstGroup = data[0].groups?.[0];
                    const firstTask = firstGroup?.tasks?.[0];
                    setState({
                        ...state,
                        lists: data,
                        selectedTaskId: firstTask?.taskInstanceId,
                        ...changed
                    });
                    getTaskInstance(data[0] as ChecklistInstance, firstGroup?.name, firstTask?.taskInstanceId, false)
                } else {
                    setState({
                        ...state,
                        lists: [],
                        ...changed
                    });
                    setTaskState({
                        ...taskState,
                        ...initTaskDetailState
                    });
                }
            });
    }
    const getData = debounce(getChichatChecklistInstances, 500);

    const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const categoryId = event.target.value as string;
        getChichatChecklistInstances({ categoryId: categoryId });
    }

    const changeSearch = (search: string) => {
        setState({ ...state, search: search });
        getData({ search: search });
    }

    const handleDateChange = (date: any) => {
        const mkDate = date.toJSDate();
        getChichatChecklistInstances({ selectedDate: mkDate });
    }

    const handleForwardDate = () => {
        const { selectedDate } = state;
        const lastDate = new Date(selectedDate);
        const forwardDate = new Date(lastDate.setDate(lastDate.getDate() + 1));
        getChichatChecklistInstances({ selectedDate: forwardDate });
    }

    const handleBackDate = () => {
        const { selectedDate } = state;
        const lastDate = new Date(selectedDate);
        const backDate = new Date(lastDate.setDate(lastDate.getDate() - 1));
        getChichatChecklistInstances({ selectedDate: backDate });
    }

    const handleReplyChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        getChichatChecklistInstances({ isReply: newValue });
    }

    const getTaskInstance = (checklist: ChecklistInstance, groupName?: string, taskId?: string, needStateSet: boolean = true) => {
        if (!taskId) {
            return;
        }
        if (needStateSet) {
            setState({ ...state, selectedTaskId: taskId });
        }
        Promise.all([
            checklistInstanceStore.getTaskDetail(taskId),
            checklistInstanceStore.getTaskComments(taskId, true),
            checklistInstanceStore.getTaskFollowUps(taskId),])
            .then(values => {
                setTaskState({
                    ...taskState,
                    taskInfo: values[0],
                    comments: values[1].data,
                    totalComments: values[1].count,
                    followups: values[2],
                    groupName: groupName,
                    checklist: checklist
                });
                window.scrollTo(0, 0);
            });
    }

    const afterReply = (taskId: string) => {
        checklistInstanceStore.getTaskComments(taskId, true)
            .then(x => {
                setTaskState({
                    ...taskState,
                    comments: x.data,
                });
            });
    }


    return <>
        <CommonPageTitle title={t('chitchat')}
            subTitle={t('follow_up_the_new_comments')}
        />
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box>
                    <FormControl className={classes.formControl}>
                        <IconButton onClick={handleBackDate}
                            style={{
                                marginTop: 18,
                                border: "1px solid #9094A9",
                                borderRadius: "10%",
                                width: 30,
                                height: 50,
                                marginRight: -10
                            }}>
                            <ArrowBackIosIcon style={{ color: "#9094A9" }} />
                        </IconButton>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            style={{ width: 180 }}
                            InputAdornmentProps={{ position: "start" }}
                            value={state.selectedDate}
                            onChange={handleDateChange}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <IconButton
                            onClick={handleForwardDate}
                            style={{
                                marginTop: 18,
                                border: "1px solid #9094A9",
                                borderRadius: "10%",
                                width: 30,
                                height: 50,
                                marginLeft: -10
                            }}>
                            <ArrowForwardIosIcon style={{ color: "#9094A9" }} />
                        </IconButton>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel></InputLabel>
                        <Select fullWidth
                            displayEmpty
                            disableUnderline
                            style={{ width: 300 }}
                            value={state.categoryId}
                            onChange={handleCategoryChange}
                        >
                            <MenuItem value="">{t("all_categories")}</MenuItem>
                            {state.categories.map(x =>
                                <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl} style={{ marginTop: 25 }}>
                        <InputLabel></InputLabel>
                        <TextField
                            placeholder={t("search")}
                            style={{ width: 300 }}
                            value={state.search}
                            onChange={(event) => changeSearch(event.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {<SearchIcon width="24" height="24" viewBox="0 0 24 24" />}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {state.search.length > 0 &&
                                            <ClearIcon width="24" height="24" viewBox="0 0 24 24"
                                                onClick={() => changeSearch('')} style={{ cursor: 'pointer' }} />}
                                    </InputAdornment>
                                )
                            }}

                            InputLabelProps={{ shrink: true }}
                            className={classes.searchTextField}
                        />
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
        <Paper square style={{ marginTop: 10 }}>
            <Box display="flex">
                <Box ml={2} mr={1} minWidth={400}>
                    <Tabs indicatorColor="primary"
                        textColor="primary"
                        value={state.isReply}
                        onChange={handleReplyChange}
                        aria-label="simple tabs example">
                        <Tab label={t('all')} value={0} />
                        <Tab label={t('replied')} value={1} />
                    </Tabs>
                    <Typography variant="h5"
                        style={{ fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16 }}>
                        {t('Checklists')} ({state.lists.length})
                    </Typography>
                    <TreeView
                        className={classes.tree}
                        defaultExpanded={["0"]}
                        defaultCollapseIcon={<Avatar className={classes.green}><AssignmentIcon /></Avatar>}
                        defaultExpandIcon={<Avatar className={classes.green}><AssignmentIcon /></Avatar>}
                    >
                        {state.lists.map((item, listIndex) => (
                            <StyledTreeItem key={listIndex} nodeId={`${listIndex}`} label={
                                <Typography variant="h5" style={{
                                    fontWeight: 500,
                                    color: "#374359",
                                    marginLeft: 10,
                                    marginTop: 12,
                                    marginBottom: 16
                                }}>
                                    {item.checklistInstanceName}
                                </Typography>
                            }>
                                {item.groups?.map(group => (
                                    <>
                                        {group.tasks?.map(task => (
                                            <TreeItem nodeId={task.id}
                                                key={task.id}
                                                label={
                                                    <div>
                                                        <Button style={{
                                                            backgroundColor: state.selectedTaskId === task.taskInstanceId ? "#1CAD5E" : "#F8F9FB",
                                                            height: "auto",
                                                            width: "100%"
                                                        }}>
                                                            <Typography component={"span"} style={{
                                                                width: "90%",
                                                                textAlign: "left",
                                                                fontWeight: 500,
                                                                color: state.selectedTaskId === task.taskInstanceId ? "#FFFFFF" : "#374359",
                                                                marginTop: 12,
                                                                marginBottom: 16
                                                            }}>
                                                                {task.taskInstanceName}
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                }
                                                icon={<></>}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                onLabelClick={() => getTaskInstance(item as ChecklistInstance, group.name, task.taskInstanceId)}
                                            />
                                        ))
                                        }
                                    </>
                                ))}
                            </StyledTreeItem>
                        ))}
                    </TreeView>
                </Box>
                <Box width="100%" ml={2}>
                    {!!state.selectedTaskId && !!taskState.groupName && <TaskDetailInfo data={{ ...taskState }} onlyChichat={true} afterReply={afterReply} />
                    }
                </Box>
            </Box>
        </Paper>
    </>
});

export { ChichatPage }