import { QueryCriteria } from "../Models/QueryCriteria";
import { CoolingReportModel } from "../Models/Reports";
import { CoolingReportService } from "../Services/CoolingReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CoolingReportsStore extends BaseGridDataStore<CoolingReportModel>{
    private _service: CoolingReportService;

    constructor() {
        let service = new CoolingReportService();
        super(service);
        this._service = service;
    }

    downloadTemplate() {
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }

        return this._service.downloadTemplateAsync(criteria);
    }

    getExcel(params: string){
        return this._serivce.getExcelAsync(params);
    }

}