import { TaskStatusReport } from "../Models/Reports";
import { TaskStatusReportService } from "../Services/TaskStatusReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class TaskStatusReportsStore extends BaseGridDataStore<TaskStatusReport>{
    private _service: TaskStatusReportService;

    constructor() {
        let service = new TaskStatusReportService();
        super(service);
        this._service = service;
    }
}