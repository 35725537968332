import { makeAutoObservable } from "mobx";
import Cookies from "universal-cookie/es6";
import { AuthenticateResponseModel } from "../Models/AuthenticateModel";
import { Company } from "../Models/Company";
import moment from "moment-timezone";

export class AppStore {
    //auth
    loggedIn: boolean = false;
    userAuth: AuthenticateResponseModel | undefined = undefined;
    //global ajax handling
    isLoading: boolean = false;
    error: string = '';
    showError: boolean = false;
    showInfo: boolean = false;
    infoMsg: string = '';
    companyTree: Company | undefined = undefined;
    lang: string = 'english';

    constructor() {
        this.preAuthenticate();
        makeAutoObservable(this);
    }

    private preAuthenticate() {
        const cookies = new Cookies();
        //get stored auth info from session or cookie
        const authString = sessionStorage.getItem('user');
        if (authString) {
            const auth = JSON.parse(authString) as AuthenticateResponseModel;
            this.setAuthenticated(auth);
        } else if (cookies.get('user')) {
            this.setAuthenticated(cookies.get('user'));
        }
    }

    setLang(lang: string) {
        this.lang = lang;
    }

    setAuthenticated(auth: AuthenticateResponseModel, remember: boolean = false) {
        return new Promise((resolve, reject) => {
            this.loggedIn = true;
            this.userAuth = auth;

            const userString = JSON.stringify(Object.assign({}, auth, { remember: true }));
            //save user info into session
            sessionStorage.setItem('user', userString);

            if (remember) {
                //set user authen info into cookie
                const cookies = new Cookies();
                let d = new Date();
                d.setTime(d.getTime() + (60 * 24 * 60 * 1000));
                cookies.set('user', userString, { path: '/', expires: d });
            }
            resolve(this.userAuth);
        })
    }

    setSwitchCompany(id: any, companyName: any) {
        const authString = sessionStorage.getItem('user');
        if (authString) {
            const authString = sessionStorage.getItem('user') || "";
            const auth = JSON.parse(authString) as AuthenticateResponseModel;
            auth.companyId = id;
            auth.companyName = companyName;
            if (auth.orgCompanyId === id) {
                auth.hasLicense = auth.orgHasLicense;
                auth.hasActionPermission = true;
            } else {
                auth.hasActionPermission = false;
                auth.hasLicense = true;
            }

            this.setAuthenticated(auth);
            if (auth.hasLicense) {
                window.location.reload();
            } else {
                window.location.href = "/app/companyStructure";
            }
        }
    }

    setCompanyName(companyName: any) {
        const authString = sessionStorage.getItem('user');
        if (authString) {
            const authString = sessionStorage.getItem('user') || "";
            const auth = JSON.parse(authString) as AuthenticateResponseModel;

            auth.companyName = companyName;
            this.setAuthenticated(auth);
        }
    }

    setCompanyDayChangeTime(dayChangeTime: any) {
        const authString = sessionStorage.getItem('user');
        if (authString) {
            const authString = sessionStorage.getItem('user') || "";
            const auth = JSON.parse(authString) as AuthenticateResponseModel;

            auth.companyDayChangeTime = dayChangeTime;
            this.setAuthenticated(auth);
        }
    }

    logout() {
        return new Promise((resolve, rejecct) => {
            this.loggedIn = false;
            this.userAuth = undefined;
            sessionStorage.removeItem('user');
            const cookies = new Cookies();
            cookies.remove('memberId', { path: '/' });
            cookies.remove('user');
            resolve('');
        });
    }

    //ajax handling 
    setLoading(isLoading: boolean, errorMessage: string = '') {
        this.isLoading = isLoading;
        if (errorMessage && errorMessage.length > 0) {
            this.error = errorMessage;
            this.showError = true;
        }
    }

    hideError() {
        this.showError = false;
    }

    hideInfo() {
        this.showInfo = false;
    }

    setCompanyTree(companyTree: Company) {
        this.companyTree = companyTree;
    }

    getFilterDate() {
        const auth = this.userAuth as AuthenticateResponseModel;
        const timezoneId = auth.timezoneId;
        const dayChangeTime = auth.companyDayChangeTime;
        const mmnt = moment().tz(timezoneId);
        const chgMmnt = moment(dayChangeTime);
        const hhmm = mmnt.hours() * 100 + mmnt.minutes();
        const chgHhmm = chgMmnt.hours() * 100 + chgMmnt.minutes();
        return moment()
          .add(hhmm >= chgHhmm ? 0 : -1, "days")
          .toDate();
      }
}