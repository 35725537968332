
import { License } from "../Models/License";
import { QueryCriteria } from "../Models/QueryCriteria";
import { LicenseService } from "../Services/LicenseService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class LicenseStore extends BaseGridDataStore<License>{
    private _LicenseService: LicenseService;
    companyId?: string;
    name?: string;
    isExpire?: string;

    constructor() {
        let licenseService = new LicenseService();
        super(licenseService);
        this._LicenseService = licenseService;
    }

    queryEnterpriseLicenses() {
        this._LicenseService.queryEnterpriseLicenses().then(x => {
            this.models = x;
            this.count = x.length;
            this.pages = 1;
            this.pageSize = 1000;
        });
    }

    setParams(companyId?: string, name?: string, isExpire?: string) {
        this.companyId = companyId;
        this.name = name;
        this.isExpire = isExpire;
    }

    updateModels(models: any) {
        this.models = models;
    }

    override query(): void {
        this.queryLicenses(this.companyId, this.name, this.isExpire);
    }

    queryLicenses(companyId?: string, name?: string, isExpire?: string) {
        this.models = [];
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };

        criteria.filters = [];
        if (companyId) {
            this.companyId = companyId;
            criteria.filters.push({
                field: 'Id',
                value: companyId
            });
        }

        if (name) {
            this.name = name;
            criteria.filters.push({
                field: 'Name',
                value: name
            });
        }

        if (isExpire) {
            this.isExpire = isExpire;
            criteria.filters.push({
                field: 'IsExpire',
                value: isExpire
            });
        }

        this._LicenseService.queryLicenses(criteria).then(x => {
            this.models = x;
            this.count = x.length;
            this.pages = ((x.length + this.pageSize - 1) / this.pageSize);
        })
    }
    queryUnUseLicense(companyId: string) {
        return this._LicenseService.queryUnUseLicense(companyId)
    }
    setLicenseKey(modelId: string, licenseKey: any) {
        var finding = this.models.find(x => x.id === modelId);
        if (finding) {
            finding.licenseKey = licenseKey;
        }
    }

    assignCompanyLicense(licenses: any) {
        return this._LicenseService.assignCompanyLicense(licenses);
    }

    queryCompanyLicenseInfor(companyId: string) {
        return this._LicenseService.queryCompanyLicenseInfor(companyId);
    }


}