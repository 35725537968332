import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { DrawerMenuItem } from '../../Models/DrawerMenuItem';
import { useTranslation } from 'react-i18next';

export const drawerFullWidth = 200;
const version  = process.env.REACT_APP_VERSION;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerFullWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerPapper: {
        background: '#374359',
    },
    drawerOpen: {
        width: drawerFullWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: "hidden"
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6) + 1,
        },
    },
    menuItemText: {
        color: '#FFFFFF',
        '& span.MuiTypography-root': {
            fontSize: '14px'
        }
    },
    childMenuItemText: {
        color: '#FFFFFF',
        marginLeft: 20,
        '& span.MuiTypography-root': {
            fontSize: '14px'
        }
    },
    logo: {
        color: '#fff',
        textAign: 'center',
        margin: '20px',
        [theme.breakpoints.up('sm')]: {
            margin: '10px',
        }
    },
    version: {
        display: 'block',
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        color: '#fff',
        textAlign: 'center',
    }
}));

interface DrawerProps {
    drawerOpen: boolean,
    menuItems: DrawerMenuItem[]
}

interface PageState {
    showReports: any,

}

const initState: PageState = {
    showReports: false,

};

export default function AdminDrawer(props: DrawerProps) {
    const classes = useStyles();
    const [selectedMenu, setSelectedMenu] = React.useState('');
    const { drawerOpen } = props;
    const [state, setState] = useState<PageState>(initState);
    const { t } = useTranslation();

    const clickMenu = (text: any, item: any) => {
        setSelectedMenu(text)

        if (text === t("analytic")) {
            setState({
                ...state,
                showReports: !state.showReports
            })
        }
    }

    useEffect(() => {

    });

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.drawerOpen,
                [classes.drawerClose]: !props.drawerOpen,
            })}
            classes={{
                paper: clsx(classes.drawerPapper, {
                    [classes.drawerOpen]: props.drawerOpen,
                    [classes.drawerClose]: !props.drawerOpen,
                }),
            }}
        >
            <Typography variant="h4" className={classes.logo}>
                {
                    drawerOpen ? <img alt="Klean Kitchen" src="/MKI-logo-web.svg" /> : <img alt="Klean Kitchen" src="/MKI-Tilted-Icon-Color.svg" />
                }

            </Typography>
            <List>
                {props.menuItems.filter((x: any) => x.visable).map((item: any) => (
                    <>
                        <ListItem button key={item.link}
                            selected={selectedMenu === item.text}
                            onClick={() => clickMenu(item.text, item)}
                            component={Link}
                            to={'/app/' + item.link}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} className={classes.menuItemText} />
                        </ListItem>
                        <>
                            {state.showReports &&
                                item.childrens && item.childrens.length > 0 &&
                                item.childrens.map((child: any) => (
                                    <ListItem button key={child.link}
                                        style={{ marginLeft: 20 }}
                                        selected={selectedMenu === child.text}
                                        onClick={() => setSelectedMenu(child.text)}
                                        component={Link} to={'/app/' + child.link}>
                                        <ListItemIcon>{child.icon}</ListItemIcon>
                                        <ListItemText primary={child.text} className={classes.menuItemText} />
                                    </ListItem>

                                ))
                            }
                        </>
                    </>
                ))}
            </List>
            <div className={classes.version}>{version}</div>
        </Drawer>
    )
}