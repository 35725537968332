import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import { useState } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { DatePicker } from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
// this guy required only on the docs site to work with dynamic date library
import { makeJSDateObject } from "../Utils/utils";
import { IconButton } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

interface WeekPickerState {
  selectedDate: Date,
}

const initState: WeekPickerState = {
  selectedDate: new Date(),
};

interface WeekPickerProps {
  handleChange: (entity: WeekPickerState) => void,
}

export default function WeekPicker(props: WeekPickerProps) {
  const classes = useStyles();
  const [state, setState] = useState<WeekPickerState>(initState);
  const { handleChange } = props;

  const handleWeekChange = (date: any) => {
    setState({ selectedDate: startOfWeek(makeJSDateObject(date), { weekStartsOn: 0 }) });
    handleChange({ selectedDate: startOfWeek(makeJSDateObject(date), { weekStartsOn: 0 }) });
  };

  const formatWeekSelectLabel = (date: any, invalidLabel: any) => {
    let dateClone = makeJSDateObject(date);

    return dateClone && isValid(dateClone)
      ? `${format(startOfWeek(dateClone, { weekStartsOn: 0 }), "MMM do")} ~ ${format(endOfWeek(dateClone, { weekStartsOn: 0 }), "MMM do")}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date: any, selectedDate: any, dayInCurrentMonth: any) => {
    let dateClone = makeJSDateObject(date);
    let selectedDateClone = makeJSDateObject(selectedDate);

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 0 });
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 0 });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };
  return (
    <DatePicker
      label="Select Week"
      // rightArrowIcon={<Filter7Icon/>}
      value={state.selectedDate}
      onChange={handleWeekChange}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
    />
  );

}