import React, { useEffect, useState } from 'react';
import { GridColDef } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import { TaskAssignmentReport } from "../../Models/Reports";
import CommonGridPage from "../../Components/CommonGridPage";
import { Guid } from "guid-typescript";
import useStores from "../../Stores/useStores";
import ReportHeader, { ReportHeaderState, ReportHeaderProps } from './Comments/ReportHeader';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { excelDownload } from "../../Utils/utils"

interface PageState {
    gridId: string,
}

const initState: PageState = {
    gridId: Guid.create().toString(),
};

const taskAssignmentReport = observer(() => {
    const { taskAssignmentStore, appStore } = useStores();
    const { t } = useTranslation();
    useEffect(() => {
        const preFilters = [
            {
                field: 'filterFromDate',
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                field: 'filterToDate',
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
            }
        ]
        taskAssignmentStore.setPreFilter([...preFilters]);
    }, []);
    const [state] = useState<PageState>(initState);
    const handleDownload = (filters: ReportHeaderState) => {
        const fields = [`${t('employee')}`, `${t('daily')} ${t("task")}`, `${t('weekly')} ${t("task")}`, `${t('monthly')} ${t("task")}`];
        taskAssignmentStore.excel(fields)
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('task_assignment_report'));
                }
            });
    }
    const handlePrint = (filters: ReportHeaderState) => {
        // TODO
    }

    const handleFilterChange = (filters: ReportHeaderState) => {
        taskAssignmentStore.setPreFilter([
            {
                field: 'filterDeptId',
                value: filters.deptId
            },
            {
                field: 'filterFromDate',
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss")
            }
            ,
            {
                field: 'filterToDate',
                value: moment(filters.toDate).format("YYYY-MM-DD HH:mm:ss")
            }
        ]);
        taskAssignmentStore.changePage(0);
    }

    const reportHeaderProps: ReportHeaderProps = {
        title: t('task_assignment_report'),
        description: t('generate_task_assignment_report'),
        showFromDate: true,
        showToDate: true,
        showDepartment: true,
        showEmployee: false,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    }

    const columns: GridColDef[] = [
        {
            field: "role", headerName: t("employee"), flex: 1, sortable: true,
        },
        {
            field: "daily", headerName: t("daily") + ' ' + t('task'), flex: 1, sortable: false,
        },
        {
            field: "weekly", headerName: t("weekly") + ' ' + t('task'), flex: 1, sortable: false,
        },
        {
            field: "monthly", headerName: t("monthly") + ' ' + t('task'), flex: 1, sortable: false,
        },
    ]


    return (
        <>
            <ReportHeader {...reportHeaderProps} />
            <CommonGridPage<TaskAssignmentReport>
                key={state.gridId}
                columns={columns}
                cancelSearch={true}
                useAction={false}
                store={taskAssignmentStore}
                rowHeight={80}
            />

        </>
    )
});

export default taskAssignmentReport;