import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { useEffect, useState } from "react";
import Paper from '@material-ui/core/Paper';
import { Typography, Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useStores from "../../Stores/useStores";
import { observer } from "mobx-react";
import { Line } from "react-chartjs-2";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from 'react-i18next';
import TemperatureChart from "./temperature-chart";

const Colors = ["#BB6BD9", "#FF6E00", "#1CAD5E", "#2F80ED", "#FF5B7B", "#FB6BD9", "#BF6E00", "#ACAD5E", "#4F80ED", "#9F5B7B"];
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            maxWidth: 345,
        },
        pagerTitle: {
            fontWeight: 700,
            color: "#374359",
            marginTop: 12, padding: "24px"
        }
    }),
);

interface PageState {
    datas: any,
}

const initState: PageState = {
    datas: "",
};

export const Dashboard = observer(() => {
    const classes = useStyles();
    const { appStore, companyStore } = useStores();
    const { companyId } = appStore.userAuth as AuthenticateResponseModel;
    const [state, setState] = useState<PageState>(initState);
    const { t } = useTranslation();

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                },
                min: 0,
            }
        }
    };

    useEffect(() => {
        companyStore.dashboardCompany(companyId).then((data: any) => {
            if (data && data.length > 0) {
                var datasets: any = [];
                data.forEach((item: any, index: any) => {
                    datasets.push({
                        label: item.name,
                        data: item.failed,
                        borderColor: Colors[index],
                        backgroundColor: Colors[index],
                    });
                })
                const datas = {
                    labels: data[0].labels,
                    datasets: datasets,
                };

                setState({
                    ...state,
                    datas,
                })
            }
        })
    }, []);


    return (<>
        <CommonPageTitle title={t('dashbord')}
            subTitle={t('dashbord_desc')}
        />
        <Paper elevation={3} square={true}>
            <Grid container style={{ marginTop: 10, boxSizing: "unset", padding: "20px", width: "calc(100% - 40px)" }}>
                <TemperatureChart />
            </Grid>
            </Paper><Paper elevation={3} square={true}>
            <Typography
                variant="h6"
                className={classes.pagerTitle}
            >
                {t('task_failed_dashbord')}
            </Typography>
            <Divider light style={{ width: '100%' }} />
            <Grid container style={{ marginTop: 10, boxSizing: "unset", padding: "20px", width: "calc(100% - 40px)" }}>
                <Line data={state.datas} options={options} height={85} />
            </Grid>
        </Paper>
    </>)
});
export default Dashboard;
