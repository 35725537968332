import { TaskAssignmentReport } from "../Models/Reports";
import { TaskAssignmentReportService } from "../Services/TaskAssignmentReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class TaskAssignmentStore extends BaseGridDataStore<TaskAssignmentReport>{
    private _service: TaskAssignmentReportService;

    constructor() {
        let service = new TaskAssignmentReportService();
        super(service);
        this._service = service;
    }
}