
import axios from 'axios';
import { Company } from '../Models/Company';

export class AccountService {
    authenticateAsync = async (email: string, password: string) => {
        const url = `${process.env.REACT_APP_API_URL}/users/authenticate`

        try {
            const response = await axios.post(url, {
                userName: email,
                password: password
            });
            return response;
        } catch (err) {
            throw err
        }
    }

    signUpAsync = async (company: Company, shareCode?: string) => {
        const url = shareCode ? `${process.env.REACT_APP_API_URL}/users/signup?shareCode=${shareCode}` : `${process.env.REACT_APP_API_URL}/users/signup`;

        try {
            const response = await axios.post(url, company);
            return response;
        } catch (err) {
            throw err
        }
    }

    sendResetEmailAsync = async (email: string) => {
        const url = `${process.env.REACT_APP_API_URL}/users/sendResetEmail`
        try {
            const response = await axios.post(url, {
                code:"",
                email: email,
                password:""
            });
            return response;
        } catch (err) {
            throw err
        }
    }

    getAppURLAsync = async () => {
        const url = `${process.env.REACT_APP_API_URL}/users/appUrls`
        try {
            const response = await axios.get(url);
            return response;
        } catch (err) {
            throw err
        }
    }

    resetPasswordAsync = async (verifyCode: string, password: string) => {
        const url = `${process.env.REACT_APP_API_URL}/users/resetUserPassword`
        try {
            const response = await axios.post(url, {
                code:verifyCode,
                email: "",
                password:password
            });
            return response;
        } catch (err) {
            throw err
        }
    }
}