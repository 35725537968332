import { Cloudlibrary } from "../../Models/Cloudlibrary";
import { ValidationError } from "../../Models/ValidationError";
import { IDataStore } from "../../Stores/IDataStore";
import { FormMode } from "../Category/ChecklistFormDialog";
import { Category } from "../../Models/Category";
import { useState } from "react";
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import {
    Box, Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid,
    IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Typography
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import useStores from "../../Stores/useStores";


const useStyles = makeStyles({
    dialogHeader: {
        '& .MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    gridContainer: {
        display: 'flex',
        height: '420px'
    },
    dialogPaper: {
        width: 773
    },
    cancelButton: {
        '& .MuiButton-label': {
            color: '#9094A9'
        }
    },
    groupList: {
        paddingTop: '0',
        '& .MuiListItem-root.Mui-selected': {
            background: '#1CAD5E',
            color: '#FFFFFF'
        }
    },
    editContainer: {
        '& .MuiGrid-item': {
            marginTop: 14
        },
        padding: '8px 34px'
    },
    timeTypeContainer: {
        marginBottom: 25,
        '& .MuiButton-colorInherit': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            '& > span': {
                color: '#9094A9'
            }
        }
    },
    optionContainer: {
        background: '#F8F9FB',
        border: '1px solid rgba(144, 148, 169, 0.2)',
        borderRadius: '6px',
        marginTop: 15,
        padding: '10px 14px',
    },
    actionContainer: {
        borderTop: 'solid 1px #cfcfcf',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px 25px'
    },
    input: {
        display: 'none',
    },
});

interface DialogProps {
    open: boolean,
    title: string,
    mode: FormMode,
    row: Cloudlibrary,
    store: IDataStore<Cloudlibrary>,
    categories: Category[],
    onClose: () => void,
    onSubmit: (result: boolean) => void,
}

interface DialogState {
    editRow: Cloudlibrary,
    editGroup: number,
    fileContent: string | ArrayBuffer | null | undefined,
    errors: ValidationError[],
    type: string
}

export function CloudLibraryFormDialog(props: DialogProps) {
    const { open, onClose, store, mode } = props;
    const { appStore } = useStores();
    const { t } = useTranslation();
    const [state, setState] = useState<DialogState>({
        editRow: props.row,
        editGroup: 1,
        errors: [],
        fileContent: null,
        type: props.row.url !== "" ? "file" : "url"
    });
    const classes = useStyles();
    const handleClose = (value: any) => {
        if (value != "") {
            return;
        }
        onClose();
    };
    const handleValidation = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0 && state.type !== 'url') {
                valdiationErrors.push({
                    field: 'name',
                    message: `${t('need_to_upload_a_file')}`
                });
            }

            if (state.editRow.name.trim().length === 0 && state.type === 'url') {
                valdiationErrors.push({
                    field: 'name',
                    message: `${t('name_required')}`
                });
            }

            if (state.editRow.videoUrl.trim().length === 0 && state.type === 'url') {
                valdiationErrors.push({
                    field: 'videoUrl',
                    message: `${t('videourl_required')}`
                });
            }


            if (state.editRow.description.trim().length === 0) {
                valdiationErrors.push({
                    field: 'description',
                    message: `${t('description_is_required')}`
                });
            } else if (state.editRow.description.length > 200) {
                valdiationErrors.push({
                    field: 'description',
                    message: `${t('description_maxlength_is_200')}`
                });
            }

            if (state.editRow.categoryId.length === 0) {
                valdiationErrors.push({
                    field: 'categoryId',
                    message: `${t('category_is_required')}`
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        });
    }

    const handleSubmit = (entity: Cloudlibrary) => {
        handleValidation().then(x => {
            if (x) {
                handleCreateOrUpdate(entity);
            }
        })
    }

    const handleCreateOrUpdate = (entity: Cloudlibrary) => {
        if (mode === FormMode.Add) {
            store.add(entity).then(x => {
                props.onSubmit(x);
            });
        } else if (mode === FormMode.Edit) {
            store.update(entity).then(x => {
                props.onSubmit(x);
            });
        }

    }

    const handleUploadFile = (event: any) => {
        const file = event.target.files[0];

        const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif',
            'image/webp', 'video/mp4', 'audio/mp4', 'application/msword', 'application/pdf', 'application/x-msword'];
        if (!file || allowTypes.indexOf(file.type) < 0) {
            if (!/(docx)$/.test(file.name)) {
                appStore.error = `${t('not_allowed_file_type')}`;
                appStore.showError = true;
                return;
            }
        }
        let maxSize = 1024 * 1000 * 10;
        if (file.size > maxSize) {
            appStore.error = `${t('max_allowed_size_is')}:${maxSize / 1000 / 1024} mb`;
            appStore.showError = true;
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function () {
            const base64data = reader.result;
            setState({
                ...state,
                editRow: { ...state.editRow, name: file.name, fileContent: base64data?.toString() },
                errors: [...state.errors].filter(x => x.field !== "name")
            });
        }
    }

    return (<Dialog open={open} onClose={() => handleClose("not")} aria-labelledby="form-dialog-title" fullWidth maxWidth={"md"}
        classes={{
            paper: classes.dialogPaper
        }}>
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            <span>{(props.mode === FormMode.Add ? t('upload_file') : state.editRow.name)}</span>
            <IconButton onClick={() => handleClose("")}>
                <ClearIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid container style={{ borderTop: 'solid 1px #cfcfcf' }}>
                <Grid item xs={12}>
                    <Box className={classes.editContainer}>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                >
                                    <InputLabel id="lblType" shrink>{t('type_name')}</InputLabel>
                                    <Select fullWidth
                                        value={state.type || ''}
                                        onChange={(event) => setState({
                                            ...state,
                                            type: event.target.value as string,
                                        })}
                                        displayEmpty
                                        disableUnderline>
                                        <MenuItem value="" disabled>{t('please_select_a_type')}</MenuItem>
                                        <MenuItem value={'file'} key={'file'}>{t('type_file')}</MenuItem>
                                        <MenuItem value={'url'} key={'url'}>{t('type_url')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {props.categories.length > 0 && <FormControl
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "categoryId") > -1}
                                >
                                    <InputLabel id="lblDepartment" shrink>{t('category')}</InputLabel>
                                    <Select fullWidth
                                        value={state.editRow.categoryId || ''}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, categoryId: event.target.value as string },
                                            errors: [...state.errors].filter(x => x.field !== "categoryId")
                                        })}
                                        displayEmpty
                                        disableUnderline>
                                        <MenuItem value="" disabled>{t('please_select_a_category')}</MenuItem>
                                        {props.categories.filter(x => !/Deleted@/.test(x.name)).map(x =>
                                            <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>{state.errors.find(x => x.field === "categoryId")?.message || ''}</FormHelperText>
                                </FormControl>}
                            </Grid>

                            {state.type === 'url' &&
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={state.errors.findIndex(x => x.field === "name") > -1}
                                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                                        label={t('name')}
                                        value={state.editRow.name}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, name: event.target.value },
                                            errors: [...state.errors].filter(x => x.field !== "name")
                                        })}
                                        placeholder={t('name')}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            }

                            {state.type === 'url' &&
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={state.errors.findIndex(x => x.field === "videoUrl") > -1}
                                        helperText={state.errors.find(x => x.field === "videoUrl")?.message || ''}
                                        label={t('file_url')}
                                        value={state.editRow.videoUrl}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, videoUrl: event.target.value },
                                            errors: [...state.errors].filter(x => x.field !== "videoUrl")
                                        })}
                                        placeholder={t('file_url')}
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "description") > -1}
                                    helperText={state.errors.find(x => x.field === "description")?.message || ''}
                                    label={t('file_description')}
                                    multiline
                                    minRows={2}
                                    maxRows={4}
                                    value={state.editRow.description}
                                    onChange={(event) => setState({
                                        ...state,
                                        editRow: { ...state.editRow, description: event.target.value },
                                        errors: [...state.errors].filter(x => x.field !== "description")
                                    })}
                                    placeholder={t('file_description')}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {state.type !== 'url' &&
                                <>
                                    <Divider style={{ width: '100%', marginTop: 10, marginBottom: 24 }} />
                                    <Grid item xs={3}>
                                        <FormControl fullWidth={true}
                                            error={state.errors.findIndex(x => x.field === "name") > -1}>
                                            <InputLabel id="lblFile" shrink>{t('choice_file')}</InputLabel>
                                            <>
                                                <input
                                                    accept=".doc,.docx,image/png,image/jpg,image/jpeg,image/gif,image/webp,video/mp4,audio/mp4,application/msword,application/pdf,application/x-msword"
                                                    className={classes.input}
                                                    id="contained-button-fi1le"
                                                    type="file"
                                                    value={''}
                                                    onChange={handleUploadFile}
                                                />
                                                <label htmlFor="contained-button-fi1le">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                        startIcon={<AddIcon />}
                                                    >
                                                        {t('browse')}
                                                    </Button>
                                                </label>
                                            </>
                                            <FormHelperText>{state.errors.find(x => x.field === "name")?.message || ''}</FormHelperText>
                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={8}>
                                        <Typography
                                            style={{ color: "#374359", marginTop: 2, marginBottom: 2 }}
                                        >

                                            {t('file_name')}: {state.editRow.name ? state.editRow.name : ''}
                                        </Typography>
                                        <Typography
                                            style={{ color: "#9094A9" }}
                                        >
                                            {t('file_formats')}: *.png,*.jpg,*.jpeg,*.gif,*.webp,*.mp4,*.doc,*.docx,*.pdf
                                            <br />
                                            {t('max_file_size')}: 10M
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                    <Box className={classes.actionContainer}>
                        <Button variant="outlined" disableElevation className={classes.cancelButton}
                            onClick={() => handleClose("")} style={{ marginRight: 10 }}>
                            {t('cancel')}
                        </Button>
                        <Button color="primary" variant="contained" disableElevation
                            onClick={() => handleSubmit(state.editRow)}>
                            {t('save')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>);
}