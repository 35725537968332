import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import React, { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import {
  Box,
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from "@material-ui/icons/Add";
import { observer } from "mobx-react";
import { CompanyShareLink } from "../../Models/CompanyShareLink";
import { useConfirm } from "material-ui-confirm";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import BackButton from "../../Components/BackToButton";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { ShareLinkAction } from "../../Models/ShareLinkAction";

interface PageState {
  dialogOpen: boolean;
  importDialogOpen: boolean;
  gridId: string;
  dialogId: string;
  importDialogId: string;
  editRow: CompanyShareLink;
  editMode: FormMode;
  templates: CompanyShareLink[];
  errors: ValidationError[];
  language: string;
}

const initAction: CompanyShareLink = {
  id: "",
  code: "",
  // language: ''
};

const initState: PageState = {
  dialogOpen: false,
  importDialogOpen: false,
  gridId: Guid.create().toString(),
  dialogId: Guid.create().toString(),
  importDialogId: Guid.create().toString(),
  editMode: FormMode.Add,
  editRow: initAction,
  errors: [],
  templates: [],
  language: "",
};

const useStyles = makeStyles(() =>
  createStyles({
    selected: {
      "& .MuiSelect-select": {
        padding: "10px",
      },
      "&::before": {
        border: 0,
      },
    },
  })
);

const ShareLinkPage = observer(() => {
  const { companyShareLinkStore, appStore } = useStores();
  const [state, setState] = useState<PageState>(initState);
  const { hasActionPermission, isSuperAdmin } =
    appStore.userAuth as AuthenticateResponseModel;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    var lang = localStorage.getItem("lang") ?? LanguagesModels[0].key;
    companyShareLinkStore.setLanguage(lang);
    setState({
      ...state,
      language: lang,
    });
  }, []);

  const clickToCopyToClipboard = (e: any, text: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    appStore.infoMsg = t("copy_to_clipboard_success");
    appStore.showInfo = true;
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: t("sharelink"),
      flex: 9,
      renderCell: (row: GridCellParams) => {
        const record: CompanyShareLink = row.row as CompanyShareLink;
        const title = t("click_to_copy_to_clipboard");
        const link = `${window.location.origin}/signup?sharecode=${record.code}`;
        return (
          <LightTooltip title={title} placement="bottom-start" arrow>
            <a href="void(0)" onClick={(e) => clickToCopyToClipboard(e, link)}>
              {link}
            </a>
          </LightTooltip>
        );
      },
    },
    {
      field: "type",
      headerName: t("sharelink_type"),
      flex: 3,
      renderCell: ((row: GridCellParams) => {
        const record: CompanyShareLink = row.row as CompanyShareLink;
        return <Box>
            {t(`sharelink_type_${ShareLinkAction[record.action || 0]}`)}
        </Box>;
      })
    },
    {
      field: "createdTime",
      headerName: t("created_time"),
      flex: 3,
      renderCell: ((row: GridCellParams) => {
        const record: CompanyShareLink = row.row as CompanyShareLink;
        return <Box>
            <Typography variant="h6">
                {moment(record.createdTime).format('DD/MM/YYYY')} {moment(record.createdTime).format('LT')}
            </Typography>
        </Box>;
      })
    },
  ];

  if (hasActionPermission) {
    columns.push({
      field: "action",
      headerName: t("actions"),
      width: 450,
      editable: false,
      sortable: false,
      renderCell: (row: GridCellParams) => {
        const category: CompanyShareLink = row.row as CompanyShareLink;
        return (
          <Box style={{ display: "flex", justifyContent: "", width: "100%" }}>
            <Link
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                handleDelete(category.id);
                event.stopPropagation();
              }}
            >
              {t("delete_sharelink")}
            </Link>
          </Box>
        );
      },
    });
  }

  const confirm = useConfirm();
  const handleDelete = (id: string) => {
    confirm({
      title: t("warning"),
      description: t("delete_delete_sharelink_tip"),
    }).then(() => {
      companyShareLinkStore.delete(id);
    });
  };

  const handleUpdate = (editMode: FormMode, row: CompanyShareLink) => {
    setState({
      ...state,
      dialogOpen: true,
      editMode: editMode,
      editRow: { ...row },
      dialogId: Guid.create().toString(),
    });
  };

  const handleValidate = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const valdiationErrors: ValidationError[] = [];
      if (state.editRow.code.trim().length === 0) {
        valdiationErrors.push({
          field: "code",
          message: t("sharelink_code_required"),
        });
      } else if (state.editRow.code.trim().length > 100) {
        valdiationErrors.push({
          field: "code",
          message: t("sharelink_code_max_length"),
        });
      }

      if (valdiationErrors.length === 0) {
        resolve(true);
      } else {
        reject();
        setState({ ...state, errors: valdiationErrors });
      }
    });
  };

  const handleSubmit = (result: boolean) => {
    if (result) {
      setState({
        ...state,
        dialogOpen: false,
        dialogId: Guid.create().toString(),
        errors: [],
      });
    }
  };

  const handleCreate = () => {
    const code = Guid.create().toString().replaceAll('-', '');
    setState({
      ...state,
      dialogOpen: true,
      editMode: FormMode.Add,
      editRow: { ...initAction, id: Guid.create().toString(), code },
    });
  };

  const handleLangChange = (item: any) => {
    setState({
      ...state,
      language: item.target.value,
    });

    companyShareLinkStore.setLanguage(item.target.value).then((x) => {
      companyShareLinkStore.query();
    });
  };

  return (
    <>
      <CommonPageTitle
        title={t("sharelinks")}
        subTitle={t("configure_sharelinks")}
      ></CommonPageTitle>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          {isSuperAdmin && (
            <>
              <Typography style={{ marginRight: 10, color: "#999" }}>
                {t("managing_language")}:
              </Typography>
              <Select
                fullWidth
                displayEmpty
                disableUnderline
                style={{ width: 180 }}
                className={classes.selected}
                value={state.language}
                onChange={handleLangChange}
              >
                {LanguagesModels.map((x) => (
                  <MenuItem value={x.key} key={x.key}>
                    {x.value}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box
            style={{
              display: "flex",
              marginBottom: 16,
              justifyContent: "flex-end",
            }}
          >
            <BackButton />
            {hasActionPermission && (
              <Button
                variant="contained"
                disableElevation
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreate}
              >
                {t("create_sharelink")}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      <CommonGridPage<CompanyShareLink>
        key={state.gridId}
        columns={columns}
        store={companyShareLinkStore}
        onEdit={(row: CompanyShareLink) =>
          setState({
            ...state,
            dialogOpen: true,
            editRow: row,
            editMode: FormMode.Edit,
          })
        }
        defaultSortBy={"createdTime"}
        defaultSortDirection={"desc"}
        useAction={hasActionPermission}
      />

      <CommonFormDialog<CompanyShareLink>
        key={state.dialogId}
        open={state.dialogOpen}
        row={state.editRow}
        mode={state.editMode}
        store={companyShareLinkStore}
        title={t("sharelink")}
        onClose={() =>
          setState({
            ...state,
            dialogOpen: false,
            dialogId: Guid.create().toString(),
            errors: [],
          })
        }
        onSubmit={handleSubmit}
        onValidation={handleValidate}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required={true}
              error={state.errors.findIndex((x) => x.field === "code") > -1}
              helperText={
                state.errors.find((x) => x.field === "code")?.message || ""
              }
              value={state.editRow?.code}
              onChange={(event) => {
                const code = event.target.value;
                if (!_.isEmpty(code) && !/^[a-z0-9]+$/.test(code)) {
                  return;
                }

                setState({
                  ...state,
                  editRow: { ...state.editRow, code },
                  errors: [],
                });
              }}
              placeholder={t("sharelink_code")}
              label={t("sharelink_code")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="lblTy[e" shrink>
                {t("sharelink_type")}
              </InputLabel>
              <Select
                fullWidth
                onChange={(event) =>
                  setState({
                    ...state,
                    editRow: {
                      ...state.editRow,
                      action: event.target.value as ShareLinkAction,
                    },
                  })
                }
                value={state.editRow.action || ShareLinkAction.InviteCompany}
                displayEmpty
                disableUnderline
              >
                {[
                  ShareLinkAction.InviteCompany,
                  ShareLinkAction.InviteEmployee,
                ].map((x) => (
                  <MenuItem value={x}>
                    {t(`sharelink_type_${ShareLinkAction[x]}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CommonFormDialog>
    </>
  );
});

export default ShareLinkPage;
