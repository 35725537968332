import axios from "axios";
import { CompanyRole } from "../Models/CompanyRole";
import { BaseService } from "./BaseService";

export class CompanyRoleService extends BaseService<CompanyRole> {
    constructor() {
        super('CompanyRoles');
    }

    getAllByLanguageAsync =  async(language?: string): Promise<CompanyRole[]> => {
        const url = `${process.env.REACT_APP_API_URL}/CompanyRoles/getAllByLanguage/${language}`
        const response = await axios.get<CompanyRole[]>(url);
        return response.data;
    }
}