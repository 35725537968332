import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TaskStatus } from "../../../Models/Reports";
import { Box, Typography, LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function TaskStatusCell(props: TaskStatus) {
    const classes = useStyles();
    const { totalMandatory, completedMandatory, totalOptional, completedOptional } = props;
    const progressValue = (totalMandatory + totalOptional) > 0 && (completedMandatory + completedOptional) > 0 ?
        (completedMandatory + completedOptional) / (totalMandatory + totalOptional) : 0
    const [progress] = React.useState(_.round((progressValue * 100), 2));
    const { t } = useTranslation();

    return (

        <Box>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                <span style={{ color: "#374359" }}>{completedMandatory}/{totalMandatory}</span> {t("mandatory_completed")}
            </Typography>
            <Typography className={classes.title} color="textSecondary">
                <span style={{ color: "#374359" }}>{completedOptional}/{totalOptional}</span> {t("optional_completed")}
            </Typography>
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">
                        <span style={{ color: "#1CAD5E" }}>{`${progress}%`}</span></Typography>
                </Box>
            </Box>
        </Box>
    );
}