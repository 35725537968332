import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import { CompanyRole } from "../../Models/CompanyRole";
import { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { observer } from "mobx-react";
import BackButton from "../../Components/BackToButton";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from 'react-i18next';

interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: CompanyRole
    editMode: FormMode,
    roles: CompanyRole[],
    errors: ValidationError[],
    language: string
}

const initRole: CompanyRole = {
    id: '',
    name: '',
    parentId: '',
    parentName: '',
    language: ''
};

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    roles: [],
    editRow: initRole,
    errors: [],
    language: ''
};

const useStyles = makeStyles(() =>
    createStyles({
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },

    }),
);

const CompanyRolePage = observer(() => {
    const { companyRoleStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { hasActionPermission, isSuperAdmin } = appStore.userAuth as AuthenticateResponseModel;
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        var lang = localStorage.getItem("lang") ?? LanguagesModels[0].key;
        companyRoleStore.setLanguage(lang).then(data => {
            companyRoleStore.getAllByLanguage(lang).then(x => {
                setState({
                    ...state,
                    roles: x,
                    language: lang
                });
            })
        })
    }, []);

    const columns: GridColDef[] = [
        {
            field: "name", headerName: t('role_name'), flex: 2, renderCell: ((row: GridCellParams) => {
                const role: CompanyRole = row.row as CompanyRole;
                return <LightTooltip title={role.name} placement="bottom-start" arrow>
                    <span>{role.name}</span>
                </LightTooltip>;
            })
        },
        {
            field: "parentName", headerName: t('parent_role_name'), flex: 2, renderCell: ((row: GridCellParams) => {
                const role: CompanyRole = row.row as CompanyRole;
                return <LightTooltip title={role.parentName} placement="bottom-start" arrow>
                    <span>{role.parentName}</span>
                </LightTooltip>;
            })
        }
    ];

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('role_name_required')
                });
            }
            if (state.editRow.name.trim().length > 50) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('role_name_max_length')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const handleSubmit = (result: boolean) => {
        if (result) {
            companyRoleStore.getAllByLanguage(state.language).then(x => {
                setState({ ...state, roles: x, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
                appStore.infoMsg = `${state.editMode === FormMode.Edit ? "Update" : "Add"} Role Successfully.`;
                appStore.showInfo = true;
            })
        }
    }

    const afterDelete = (row: CompanyRole) => {
        if (row) {
            setTimeout(() => {
                companyRoleStore.getAllByLanguage(state.language).then(x => {
                    setState({
                        ...state,
                        roles: x,
                        dialogOpen: false,
                        dialogId: Guid.create().toString(),
                        errors: []
                    });
                })
            }, 500);
        }
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initRole, id: Guid.create().toString(), language: state.language }
        })
    }

    const handleLangChange = (item: any) => {
        companyRoleStore.setLanguage(item.target.value).then(x => {
            companyRoleStore.query();
            companyRoleStore.getAllByLanguage(item.target.value).then(x => {
                setState({
                    ...state,
                    roles: x,
                    language: item.target.value
                });
            })
        });
    }

    return (<>
        <CommonPageTitle title={t('roles')} subTitle={t('manage_roles')}></CommonPageTitle>

        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                    {isSuperAdmin &&
                        <>
                            <Typography style={{ marginRight: 10, color: "#999" }}>
                                {t("managing_language")}:
                            </Typography>
                            <Select fullWidth
                                displayEmpty
                                disableUnderline
                                style={{ width: 180 }}
                                className={classes.selected}
                                value={state.language}
                                onChange={handleLangChange}
                            >
                                {LanguagesModels.map(x =>
                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <BackButton isOrganization={true} />
                    {hasActionPermission &&
                        <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleCreate}
                        >
                            {t('create_roles')}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Box>

        <CommonGridPage<CompanyRole>
            key={state.gridId}
            columns={columns}
            useAction={hasActionPermission}
            store={companyRoleStore}
            delConfirmMsg={t('delete_role_tip')}
            defaultSortBy={"name"}
            defaultSortDirection={"asc"}
            onEdit={(row: CompanyRole) => setState({
                ...state,
                dialogOpen: true,
                editRow: row,
                editMode: FormMode.Edit
            })}
            cbDelete={afterDelete}
        />

        <CommonFormDialog<CompanyRole>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={companyRoleStore}
            title={t('roles')}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        value={state.editRow?.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value },
                            errors: []
                        })}
                        placeholder={t('role_name')}
                        label={t('role_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="lblRole" shrink>{t('parent_role')}</InputLabel>
                        <Select fullWidth
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, parentId: event.target.value as string }
                            })}
                            value={state.editRow.parentId ? state.editRow.parentId : ""}
                            displayEmpty
                            disableUnderline>
                            <MenuItem value={""}>{t("none")}</MenuItem>
                            {state.roles.filter(x => x.id !== state.editRow.id).map(companyRole =>
                                <MenuItem value={companyRole.id} key={companyRole.id}>{companyRole.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
});

export default CompanyRolePage
