import axios from "axios";
import { Company } from "../Models/Company";
import { BaseService } from "./BaseService";
import { QueryCriteria } from "../Models/QueryCriteria";
export class CompanyService extends BaseService<Company> {
    constructor() {
        super('companies');
    }

    activateCompanyAsync = async (companyId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/activate/${companyId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    deactivateCompanyAsync = async (companyId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/deactivate/${companyId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    getShareWithAsync = async (shareCode: string) =>  {
        const url = `${process.env.REACT_APP_API_URL}/companies/getShareWith/${shareCode}`
        const response = await axios.get<string>(url);
        return response.data;
    }

    resendPasswordAsync = async (companyId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/resendPassword/${companyId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    downloadInvoiceAsync = async (companyId: string): Promise<any> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/DownloadInvoice/${companyId}`
        const response = await axios.get<any>(url, { responseType: 'blob' });
        return response;
    }

    switchCompanyAsync = async (companyId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/switchCompany/${companyId}`
        const response = await axios.post<boolean>(url);
        return response.data;
    }

    dashboardCompanyAsync = async (companyId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/dashboard/${companyId}`
        const response = await axios.get<boolean>(url);
        return response.data;
    }

    getTimezoneListAsync = async (): Promise<any[]> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/timezones`
        const response = await axios.get<any[]>(url);
        return response.data;
    }

    getCompanyInfoAsync = async (): Promise<Company> => {
        const url = `${process.env.REACT_APP_API_URL}/companies`
        const response = await axios.get<Company>(url);
        return response.data;
    }

    getCompanyLicensesAsync = async (companyId: string, criteria: QueryCriteria): Promise<Array<Company>> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetCompanyLicenses/${companyId}`
        const response = await axios.get<Array<Company>>(url, { params: criteria });
        return response.data;
    }

    getCompanyStructureAsync = async (companyId: string): Promise<Company> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetCompanyStructure/${companyId}`
        const response = await axios.get<Company>(url);
        return response.data;
    }

    getCompanyUnuseLicenseAsync = async (companyId: string): Promise<Company> => {
        const url = `${process.env.REACT_APP_API_URL}/companies/GetUnUseLicenses/${companyId}`
        const response = await axios.get<Company>(url);
        return response.data;
    }
}