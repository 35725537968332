import {
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    Dialog,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    ListItemSecondaryAction,
    InputAdornment,
    OutlinedInput
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { useEffect, useState } from "react";
import { ValidationError } from "../../Models/ValidationError";
import { IDataStore } from "../../Stores/IDataStore";
import { EnumToMap } from "../../Utils/EnumUtil";
import { Task, TaskType } from "../../Models/Task";
import { CorrectiveAction } from "../../Models/CorrectiveAction";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { CommonFormDialog } from "../../Components/CommonFormDialog";
import { observer } from "mobx-react";
import useStores from "../../Stores/useStores";
import { Guid } from "guid-typescript";
import { CompanyRole } from "../../Models/CompanyRole";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { Sensor } from "../../Models/Sensor";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";

const useStyles = makeStyles({
    dialogHeader: {
        '& .MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    gridContainer: {
        display: 'flex',
        height: '420px'
    },
    dialogPaper: {
        width: 773
    },
    cancelButton: {
        '& .MuiButton-label': {
            color: '#9094A9'
        }
    },
    groupList: {
        paddingTop: '0',
        '& .MuiListItem-root.Mui-selected': {
            background: '#1CAD5E',
            color: '#FFFFFF'
        }
    },
    editContainer: {
        minHeight: 340,
        '& .MuiGrid-item': {
            marginTop: 14
        },
        padding: '10px 34px'
    },
    timeTypeContainer: {
        marginBottom: 25,
        '& .MuiButton-colorInherit': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            '& > span': {
                color: '#9094A9'
            }
        }
    },
    optionContainer: {
        background: '#F8F9FB',
        border: '1px solid rgba(144, 148, 169, 0.2)',
        borderRadius: '6px',
        marginTop: 15,
        padding: '10px 14px',
    },
    actionContainer: {
        borderTop: 'solid 1px #cfcfcf',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px 25px'
    },
    attachPhotoContainer: {
        borderRadius: 6,
        position: 'relative',
        border: '1px solid #9094A9',
        width: '100%',
        height: '145px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            maxWidth: '100%'
        }
    },
    attachPhotoNext: {
        position: 'absolute',
        right: -12,
        top: -12
    },
    attachPhotoPre: {
        position: 'absolute',
        left: -12,
        top: -12
    },
    attachPhotoRemove: {
        position: 'absolute',
        right: -12,
        bottom: -12
    }
});

interface DialogProps {
    open: boolean,
    title: string,
    mode: FormMode,
    row: Task,
    store: IDataStore<Task>,
    correctiveActions: CorrectiveAction[],
    companyRoles: CompanyRole[],
    onClose: () => void,
    onSubmit: (result: boolean) => void,
}

interface DialogState {
    editRow: Task,
    editGroup: number,
    errors: ValidationError[],
    editCorrectiveAction: CorrectiveAction,
    correctiveActionDialogOpen: boolean,
    correctiveActionDialogId: string,
    correctiveActions: CorrectiveAction[],
    companyRoles: CompanyRole[],
}

export enum FormMode {
    Add,
    Edit
}

const convertTemperature = (temperature: number, isCentigrade: boolean) => {
    return _.round(isCentigrade ? ((temperature - 32) / 1.8) : (temperature * 1.8 + 32), 2);
}

const TaskFormDialog = observer((props: DialogProps) => {
    const { correctiveActionStore, appStore, companySlotStore } = useStores();
    const { companyIsProjectTemplate } = appStore.userAuth as AuthenticateResponseModel;
    const { t } = useTranslation();
    const { open, onClose } = props;
    const editRow = _.cloneDeep(props.row);
    const { isCentigrade, temperatureStart, temperatureEnd } = editRow;
    isCentigrade && typeof temperatureStart !== 'undefined' && temperatureStart !== '' && (editRow.temperatureStart = convertTemperature(temperatureStart as number, isCentigrade));
    isCentigrade && typeof temperatureEnd !== 'undefined' && temperatureEnd !== '' && (editRow.temperatureEnd = convertTemperature(temperatureEnd as number, isCentigrade));
    const [state, setState] = useState<DialogState>({
        editRow,
        editGroup: 1,
        errors: [],
        correctiveActions: props.correctiveActions,
        companyRoles: props.companyRoles,
        editCorrectiveAction: { id: Guid.create().toString(), name: '', language: '', code: undefined },
        correctiveActionDialogOpen: false,
        correctiveActionDialogId: Guid.create().toString()
    });
    const [sensors, setSensors] = useState<Sensor[]>([]);

    useEffect(() => {
        open && companySlotStore.querySensors().then((result) => setSensors(result));
    }, [open]);

    const classes = useStyles();
    const handleClose = (value: any) => {
        if (value != "") {
            return;
        }

        onClose();
    };

    const handleValidation = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('task_name_required')
                });
            } else if (state.editRow.name.trim().length > 500) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('task_name_max_length')
                });
            }

            if (!companyIsProjectTemplate){
            if (state.editRow.taskType !== TaskType.YesOrNo &&
                (state.editRow.temperatureStart === '' && state.editRow.temperatureEnd === '')) {
                valdiationErrors.push({
                    field: 'temperatureStart',
                    message: t('temperature_required')
                });
                valdiationErrors.push({
                    field: 'temperatureEnd',
                    message: t('temperature_required')
                });
            }

            if (state.editRow.taskType !== TaskType.YesOrNo
                && state.editRow.temperatureStart !== '' && state.editRow.temperatureEnd !== ''
                && Number(state.editRow.temperatureEnd) < Number(state.editRow.temperatureStart)) {
                valdiationErrors.push({
                    field: 'temperatureEnd',
                    message: t('temperature_upper_greater_than_lower')
                });
            }

            if (state.editRow.taskType === TaskType.Sensor
                && state.editRow.sensorId === '') {
                valdiationErrors.push({
                    field: 'sensorId',
                    message: t('task_sensor_is_required')
                });
            }
            }

            if (state.editRow.explanation.length > 500) {
                valdiationErrors.push({
                    field: 'explanation',
                    message: t('explanation_max_legth')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                let gotoTab = 1;
                if (valdiationErrors.findIndex(x => x.field === "explanation") > -1) {
                    gotoTab = 5;
                }
                setState({ ...state, errors: valdiationErrors, editGroup: gotoTab })
            }
        });
    }

    const handleSubmit = (entity: Task) => {
        handleValidation().then(x => {
            if (x) {
                handleCreateOrUpdate(entity);
            }
        })
    }

    const handleCreateOrUpdate = (entity: Task) => {
        const _entity = _.cloneDeep(entity);
        const { isCentigrade, temperatureStart, temperatureEnd, score } = _entity;
        score === '' && (_entity.score = 0);
        isCentigrade && typeof temperatureStart !== 'undefined' && temperatureStart !== '' && (_entity.temperatureStart = convertTemperature(temperatureStart as number, !isCentigrade));
        isCentigrade && typeof temperatureEnd !== 'undefined' && temperatureEnd !== '' && (_entity.temperatureEnd = convertTemperature(temperatureEnd as number, !isCentigrade));
        const toUpdateData: Task = { ..._entity };
        if (props.mode === FormMode.Add) {
            props.store.add(toUpdateData).then(x => {
                props.onSubmit(x);
            });
        } else {
            props.store.update(toUpdateData).then(x => {
                props.onSubmit(x);
            });
        }
    }

    const handleSelectAction = (id: string) => {
        const correctiveActionIds = state.editRow.correctiveActionIds;
        correctiveActionIds.push(id);
        setState({ ...state, editRow: { ...state.editRow, correctiveActionIds: correctiveActionIds } })
    }

    const handleDeleteAction = (id: string) => {
        setState({
            ...state,
            editRow: { ...state.editRow, correctiveActionIds: state.editRow.correctiveActionIds.filter(x => x !== id) }
        });
    }

    const handleAddNewAction = () => {
        setState({ ...state, correctiveActionDialogOpen: true })
    }

    const handleSelectRole = (id: string) => {
        const notificationRoleIds = state.editRow.notificationRoleIds;
        notificationRoleIds.push(id);
        setState({ ...state, editRow: { ...state.editRow, notificationRoleIds: notificationRoleIds } })
    }

    const handleDeleteRole = (id: string) => {
        setState({
            ...state,
            editRow: { ...state.editRow, notificationRoleIds: state.editRow.notificationRoleIds.filter(x => x !== id) }
        });
    }

    const handleUploadFile = (event: any) => {
        if (state.editRow.photos.length >= 6) {
            appStore.error = `${t('max_allowed_upload_images')}`;
            appStore.showError = true;
            return;
        }
        const file = event.target.files[0];
        const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
        if (!file || allowTypes.indexOf(file.type) < 0) {
            appStore.error = `${t('allowed_upload_image_type')}`;
            appStore.showError = true;
            return;
        }
        let maxSize = 1024 * 1000 * 10; //10m for image and docment
        if (file.size > maxSize) {
            appStore.error = `${t('max_allowed_size')}:${maxSize / 1000 / 1024} mb`;
            appStore.showError = true;
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data) {
                const photos = state.editRow.photos;
                photos.push(base64data.toString());
                setState({ ...state, editRow: { ...state.editRow, photos: photos } })
            }
        }
    }

    const handlePhotoRemove = (index: number) => {
        const photos = state.editRow.photos;
        photos.splice(index, 1);
        setState({ ...state, editRow: { ...state.editRow, photos: photos } })
    }

    const handlePhotoNext = (index: number) => {
        const photos = state.editRow.photos;
        const tempValue = photos[index + 1];
        photos[index + 1] = photos[index];
        photos[index] = tempValue;
        setState({ ...state, editRow: { ...state.editRow, photos: photos } })
    }

    const handlePhotoPre = (index: number) => {
        const photos = state.editRow.photos;
        const tempValue = photos[index - 1];
        photos[index - 1] = photos[index];
        photos[index] = tempValue;
        setState({ ...state, editRow: { ...state.editRow, photos: photos } })
    }

    const handleValidateCorrectiveAction = () => {
        return new Promise<boolean>((resolve, reject) => {
            if (state.editCorrectiveAction.name.length === 0) {
                reject();
            } else {
                resolve(true);
            }
        })
    }

    const handleSubmitCorrectiveAction = (result: boolean) => {
        if (result) {
            correctiveActionStore.queryAll().then((data: any) => {
                const correctiveActionIds = state.editRow.correctiveActionIds;
                correctiveActionIds.push(state.editCorrectiveAction.id);
                setState(
                    {
                        ...state,
                        editRow: { ...state.editRow, correctiveActionIds: correctiveActionIds },
                        correctiveActionDialogOpen: false,
                        correctiveActionDialogId: Guid.create().toString(),
                        correctiveActions: data,
                        editCorrectiveAction: { id: Guid.create().toString(), name: '', language: '' },
                    }
                );
            })
        }
    }

    return (<><Dialog open={open} onClose={() => handleClose("notclose")} aria-labelledby="form-dialog-title" fullWidth maxWidth={"md"}
        classes={{
            paper: classes.dialogPaper
        }}>
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            <span>{(props.mode === FormMode.Add ? t('create') : t("edit")) + ' ' + t('task')}</span>
            <IconButton onClick={() => handleClose("")}>
                <ClearIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid container style={{ borderTop: 'solid 1px #cfcfcf' }}>
                <Grid item xs={4} style={{ borderRight: 'solid 1px #ccc' }}>
                    <List className={classes.groupList}>
                        <ListItem
                            selected={state.editGroup === 1}
                            onClick={() => setState({ ...state, editGroup: 1 })}
                            button
                        >
                            <ListItemText primary={t('general_information')} />
                        </ListItem>
                        <ListItem
                            selected={state.editGroup === 2}
                            onClick={() => setState({ ...state, editGroup: 2 })}
                            button
                        >
                            <ListItemText primary={t('corrective_actions')} />
                        </ListItem>
                        <ListItem
                            selected={state.editGroup === 3}
                            onClick={() => setState({ ...state, editGroup: 3 })}
                            button
                        >
                            <ListItemText primary={t('notification_roles')} />
                        </ListItem>
                        <ListItem
                            selected={state.editGroup === 4}
                            onClick={() => setState({ ...state, editGroup: 4 })}
                            button
                        >
                            <ListItemText primary={t("photos")} />
                        </ListItem>
                        <ListItem
                            selected={state.editGroup === 5}
                            onClick={() => setState({ ...state, editGroup: 5 })}
                            button
                        >
                            <ListItemText primary={t('explanation')} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={8}>
                    {state.editGroup === 1 && <Box className={classes.editContainer}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "name") > -1}
                                    helperText={state.errors.find(x => x.field === "name")?.message || ''}
                                    multiline
                                    rows={4}
                                    label={t('task_name')}
                                    value={state.editRow.name}
                                    onChange={(event) => setState({
                                        ...state,
                                        editRow: { ...state.editRow, name: event.target.value },
                                        errors: [...state.errors].filter(x => x.field !== "name")
                                    })}
                                    placeholder={t('task_name')}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="lblTaskType" shrink>{t('task_type')}</InputLabel>
                                    <Select fullWidth
                                        value={state.editRow.taskType}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, taskType: event.target.value as TaskType }
                                        })}
                                        displayEmpty
                                        disableUnderline>
                                        <MenuItem value="" disabled>{t('please_select_task_type')}</MenuItem>
                                        {EnumToMap(TaskType).map(x =>
                                            <MenuItem value={x.value}>{t(`${x.text}`)}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText></FormHelperText>
                                </FormControl>
                            </Grid>
                            {
                                state.editRow.taskType === TaskType.Sensor &&
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="lblTaskSensor" shrink>{t('task_sensor')}</InputLabel>
                                        <Select fullWidth
                                            value={state.editRow.sensorId}
                                            error={state.errors.findIndex(x => x.field === "sensorId") > -1}
                                            onChange={(event) => setState({
                                                ...state,
                                                editRow: { ...state.editRow, sensorId: event.target.value as string },
                                                errors: [...state.errors].filter(x => x.field !== "sensorId")
                                            })}
                                            displayEmpty
                                            disableUnderline>
                                            <MenuItem value="" disabled>{t('please_select_task_sensor')}</MenuItem>
                                            {sensors.map(x =>
                                                <MenuItem value={x.sensorId}>{t(`${x.sensorName}`)}</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                            }
                            {state.editRow.taskType !== TaskType.YesOrNo && <><Grid item xs={12}>
                                <OutlinedInput
                                    fullWidth
                                    notched={false}
                                    error={state.errors.findIndex(x => x.field === "temperatureStart") > -1}
                                    label={t('temperature_lower')}
                                    value={state.editRow.temperatureStart}
                                    onChange={(event) => setState({
                                        ...state,
                                        editRow: {
                                            ...state.editRow,
                                            temperatureStart: /^-$|^[-]?([0-9]+\.?)?[0-9]+$/.test(event.target.value) ? event.target.value : '',
                                        },
                                        errors: [...state.errors].filter(x => x.field !== "temperatureStart")
                                    })}
                                    placeholder={t('temperature_lower')}
                                    endAdornment={<InputAdornment position="end">{state.editRow.isCentigrade ? '°C' : '°F'}</InputAdornment>}
                                />
                            </Grid>
                                <Grid item xs={12}>
                                    <OutlinedInput
                                        fullWidth
                                        notched={false}
                                        error={state.errors.findIndex(x => x.field === "temperatureEnd") > -1}
                                        label={t('temperature_upper')}
                                        value={state.editRow.temperatureEnd}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: {
                                                ...state.editRow,
                                                temperatureEnd: /^-$|^[-]?([0-9]+\.?)?[0-9]+$/.test(event.target.value) ? event.target.value : '',
                                            },
                                            errors: [...state.errors].filter(x => x.field !== "temperatureEnd")
                                        })}
                                        placeholder={t('temperature_upper')}
                                        endAdornment={<InputAdornment position="end">{state.editRow.isCentigrade ? '°C' : '°F'}</InputAdornment>}
                                    />
                                    {state.errors.findIndex(x => x.field === "temperatureEnd") > -1 &&
                                        <Typography style={{ color: "red" }}>
                                            {t("the_temperature_end_is_wrong")}
                                        </Typography>
                                    }
                                </Grid></>}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={state.editRow.isMandatory}
                                        onChange={() => setState({
                                            ...state,
                                            editRow: {
                                                ...state.editRow,
                                                isMandatory: !state.editRow.isMandatory
                                            }
                                        })}
                                        color="primary"
                                        name="mandatory"
                                    ></Switch>}
                                    label={t('mandatory')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={state.editRow.isCritical}
                                        onChange={() => setState({
                                            ...state,
                                            editRow: {
                                                ...state.editRow,
                                                isCritical: !state.editRow.isCritical
                                            }
                                        })}
                                        color="primary"
                                        name="critical"
                                    ></Switch>}
                                    label={t('critical')}
                                />
                            </Grid>
                            {state.editRow.taskType !== TaskType.YesOrNo &&
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch checked={state.editRow.isIncludeTemperatureReport}
                                            onChange={() => setState({
                                                ...state,
                                                editRow: {
                                                    ...state.editRow,
                                                    isIncludeTemperatureReport: !state.editRow.isIncludeTemperatureReport
                                                }
                                            })}
                                            color="primary"
                                            name="critical"
                                        ></Switch>}
                                        label={t('include_temperature_report')}
                                    />
                                </Grid>
                            }

                            {state.editRow.taskType !== TaskType.YesOrNo && <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={state.editRow.isCentigrade}
                                        onChange={() => {
                                            const isCentigrade = !state.editRow.isCentigrade;
                                            const { temperatureStart, temperatureEnd } = state.editRow;
                                            const temperature: any = {};
                                            typeof temperatureStart !== 'undefined' && temperatureStart !== '' && (temperature.temperatureStart = convertTemperature(temperatureStart as number, isCentigrade));
                                            typeof temperatureEnd !== 'undefined' && temperatureEnd !== '' && (temperature.temperatureEnd = convertTemperature(temperatureEnd as number, isCentigrade));
                                            setState({
                                                ...state,
                                                editRow: {
                                                    ...state.editRow,
                                                    isCentigrade,
                                                    ...temperature,
                                                },
                                            });
                                        }}
                                        color="primary"
                                        name="centigrade"
                                    ></Switch>}
                                    label={t('centigrade')}
                                />
                            </Grid>}

                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={<Switch checked={state.editRow.isScorecard}
                                        onChange={() => setState({
                                            ...state,
                                            editRow: {
                                                ...state.editRow,
                                                isScorecard: !state.editRow.isScorecard
                                            }
                                        })}
                                        color="primary"
                                        name="scorecard"
                                    ></Switch>}
                                    label={t('scorecard')}
                                />
                            </Grid>
                            {state.editRow.isScorecard && <Grid item xs={12} sm={6}>
                                <OutlinedInput
                                    fullWidth
                                    notched={false}
                                    defaultValue={0}
                                    error={state.errors.findIndex(x => x.field === "score") > -1}
                                    label={t('score')}
                                    value={state.editRow.score}
                                    onChange={(event) => setState({
                                        ...state,
                                        editRow: {
                                            ...state.editRow,
                                            score: /^-$|^[-]?([0-9]+\.?)?[0-9]+$/.test(event.target.value) ? event.target.value.substr(0, 3) : '',
                                        },
                                        errors: [...state.errors].filter(x => x.field !== "score")
                                    })}
                                    placeholder={t('score')}
                                    endAdornment={<InputAdornment position="end">{t("points")}</InputAdornment>}
                                />
                            </Grid>}
                        </Grid>
                    </Box>}
                    {state.editGroup === 2 && <Box className={classes.editContainer}>
                        <Box style={{ display: 'flex' }}>
                            <Select fullWidth
                                value={""}
                                onChange={(event) => handleSelectAction(event.target.value as string)}
                                displayEmpty
                                disableUnderline>
                                <MenuItem value="" disabled>{t('please_select_corrective_action')}</MenuItem>
                                {state.correctiveActions.filter(x => state.editRow.correctiveActionIds.findIndex(y => y === x.id) === -1).map(x =>
                                    <MenuItem value={x.id}>{x.name}</MenuItem>
                                )}
                            </Select>
                            <IconButton style={{ borderRadius: 6, border: '1px solid #1CAD5E', marginLeft: 24 }}
                                onClick={() => handleAddNewAction()}>
                                <AddIcon color='primary' />
                            </IconButton>
                        </Box>
                        <List style={{ marginTop: 24 }}>
                            {
                                state.correctiveActions.filter(x => state.editRow.correctiveActionIds.findIndex(y => y === x.id) > -1)
                                    .map(x => {
                                        return <ListItem style={{
                                            background: '#F8F9FB',
                                            border: '1px solid rgba(144, 148, 169, 0.2)',
                                            borderRadius: 6,
                                            marginBottom: 14
                                        }}>
                                            <ListItemText primary={x.name}></ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleDeleteAction(x.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })
                            }
                        </List>
                    </Box>}
                    {state.editGroup === 3 && <Box className={classes.editContainer}>
                        <Box>
                            <span>{t('notification_will_be_sent')}</span>
                            <br />
                        </Box>
                        <Box style={{ display: 'flex', marginTop: "10px" }}>

                            <Select fullWidth
                                value={""}
                                onChange={(event) => handleSelectRole(event.target.value as string)}
                                displayEmpty
                                disableUnderline>
                                <MenuItem value="" disabled>{t('please_select_role')}</MenuItem>
                                {state.companyRoles.filter(x => state.editRow.notificationRoleIds.findIndex(y => y === x.id) === -1).map(x =>
                                    <MenuItem value={x.id}>{x.name}</MenuItem>
                                )}
                            </Select>
                        </Box>
                        <List style={{ marginTop: 24 }}>
                            {
                                state.companyRoles.filter(x => state.editRow.notificationRoleIds.findIndex(y => y === x.id) > -1)
                                    .map(x => {
                                        return <ListItem style={{
                                            background: '#F8F9FB',
                                            border: '1px solid rgba(144, 148, 169, 0.2)',
                                            borderRadius: 6,
                                            marginBottom: 14
                                        }}>
                                            <ListItemText primary={x.name}></ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleDeleteRole(x.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })
                            }
                        </List>
                    </Box>}
                    {state.editGroup === 4 && <Box className={classes.editContainer}>
                        <Grid container spacing={2}>
                            {
                                state.editRow.photos.map((x, index) => {
                                    return <Grid item xs={4} key={index}>
                                        <Box className={classes.attachPhotoContainer}>
                                            <IconButton className={classes.attachPhotoRemove}
                                                onClick={() => handlePhotoRemove(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                            {index !== (state.editRow.photos.length - 1) &&
                                                <IconButton className={classes.attachPhotoNext}
                                                    onClick={() => handlePhotoNext(index)}>
                                                    <NavigateNextIcon />
                                                </IconButton>}
                                            {index !== 0 && <IconButton className={classes.attachPhotoPre}
                                                onClick={() => handlePhotoPre(index)}>
                                                <NavigateBeforeIcon />
                                            </IconButton>}
                                            <img src={x} alt='' style={{ maxHeight: "100%" }} />
                                        </Box>
                                    </Grid>
                                })
                            }
                            {state.editRow.photos.length < 6 &&
                                <Grid item xs={4}>
                                    <Box className={classes.attachPhotoContainer}>
                                        <input accept="image/png,image/jpg,image/jpeg,image/webp" hidden id="icon-button-file" type="file" value={''}
                                            onChange={handleUploadFile} />
                                        <label htmlFor="icon-button-file">
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                                <AddCircleIcon style={{ fontSize: 32, marginLeft: 12 }} />
                                            </IconButton>
                                            <Typography style={{ fontSize: 14, color: '#9094A9' }}>
                                                {t('attach_photo')}
                                            </Typography>
                                        </label>
                                    </Box>
                                </Grid>
                            }

                        </Grid>
                    </Box>}
                    {state.editGroup === 5 && <Box className={classes.editContainer}>
                        <TextField
                            fullWidth
                            error={state.errors.findIndex(x => x.field === "explanation") > -1}
                            helperText={state.errors.find(x => x.field === "explanation")?.message || ''}
                            multiline
                            rows={17}
                            value={state.editRow.explanation}
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, explanation: event.target.value }
                            })}
                            placeholder={t('task_explanation')}
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>}
                    <Box className={classes.actionContainer}>
                        <Button variant="outlined" disableElevation className={classes.cancelButton}
                            onClick={() => handleClose("")} style={{ marginRight: 10 }}>
                            {t('cancel')}
                        </Button>
                        <Button color="primary" variant="contained" disableElevation
                            onClick={() => handleSubmit(state.editRow)}>
                            {t('submit')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>

        <CommonFormDialog<CorrectiveAction>
            key={state.correctiveActionDialogId}
            open={state.correctiveActionDialogOpen}
            row={state.editCorrectiveAction}
            mode={FormMode.Add}
            store={correctiveActionStore}
            title={t('corrective_action')}
            onValidation={handleValidateCorrectiveAction}
            onClose={() => setState({
                ...state,
                correctiveActionDialogOpen: false,
                correctiveActionDialogId: Guid.create().toString()
            })}
            onSubmit={handleSubmitCorrectiveAction}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={state.editCorrectiveAction.name}
                        onChange={(event) => setState({
                            ...state,
                            editCorrectiveAction: { ...state.editCorrectiveAction, name: event.target.value }
                        })}
                        placeholder={t('corrective_action_name')}
                        label={t('corrective_action_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
});

export default TaskFormDialog;