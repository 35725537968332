import { observer } from "mobx-react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useStores from "../Stores/useStores";
import { useTranslation } from 'react-i18next';

const BackButton = observer((props: { isOrganization?: boolean, isReport?: boolean }) => {
    const { appStore } = useStores();
    const { t } = useTranslation();
    const { isOrganization = false, isReport = false } = props;
    const history = useHistory();
    const companyStr = isOrganization ? t('organization') : isReport ? t("reports"): t('settings');

    return <>
        <Button
            variant="contained"
            disableElevation
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => history.goBack()}
            style={{ marginRight: 16, background: '#374359', color: '#fff' }}
        >
            {t("back_to")} {appStore.userAuth && appStore.userAuth.isSuperAdmin ? t('templates') : companyStr}
        </Button>
    </>
});
export default BackButton;