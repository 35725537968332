import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'fontsource-roboto';
import "fontsource-roboto/300.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";
import "fontsource-roboto/700.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core';
import CustomTheme from './Theme';
import { Provider } from 'mobx-react';
import stores from './Stores';
import { ConfirmProvider } from 'material-ui-confirm';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import './i18n/config'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={"loading"} >
      <Provider {...stores}>
        <ThemeProvider theme={CustomTheme}>
          <ConfirmProvider defaultOptions={{
            confirmationButtonProps: { autoFocus: true, color: 'primary', variant: 'contained', disableElevation: true },
            cancellationButtonProps: { color: 'default', variant: 'contained', disableElevation: true }
          }}>
            <MuiPickersUtilsProvider utils={LuxonUtils} >
              <App />
            </MuiPickersUtilsProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
