import { Grid, makeStyles, Typography, TextField, Box, FormControlLabel, Checkbox, Link, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, } from "@material-ui/core";
import { useEffect, useState } from "react";
import { observer } from 'mobx-react'
import useStores from "../../Stores/useStores";
import { useHistory } from "react-router-dom";
import TextFieldPassword from "../../Components/TextFieldPassword";
import Cookies from "universal-cookie/es6";
import { useTranslation } from 'react-i18next';
import Languages from "../../Components/Languages";
import { useLocation } from 'react-router';
import SignUp from "../SignUp";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        minWidth: '1020px'
    },
    phoneContainer: {
        height: '100vh',
    },
    left: {
        background: '#1CAD5E',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 5%',
    },
    logo: {
        maxHeight: '70px',
        maxWidth: '400px',
        borderRadius: '20px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '-40px'
    },
    right: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: "5%",
        paddingRight: "5%",
        background: '#E5E5E5'
    },
    signInTitle: {
        fontWeight: 500,
        fontSize: 32
    },
    signInSubtitle: {
        fontWeight: 400,
        marginTop: 14,
        color: '#9094A9'
    },
    loginExtra: {
        marginTop: 26,
        display: 'flex',
        justifyContent: 'space-between'
    },
    downloadItem: {
        border: '1px solid #FFFFFF',
        background: '#FFF',
        borderRadius: 6,
        width: 200,
        height: 64,
        cursor: 'pointer',
        marginRight: '1rem',
        '& .MuiListItemText-primary': {
            fontSize: 12,
            color: '#374359'
        },
        '& .MuiListItemText-secondary': {
            fontSize: 18,
            fontWeight: 500,
            color: '#374359'
        },
    },
    downloadItemPhone: {
        border: '1px solid #FFFFFF',
        background: '#FFF',
        borderRadius: 6,
        width: 200,
        height: 64,
        cursor: 'pointer',
        marginRight: '1rem',
        '& .MuiListItemText-primary': {
            fontSize: 12,
            color: '#374359'
        },
        '& .MuiListItemText-secondary': {
            fontSize: 14,
            fontWeight: 500,
            color: '#374359'
        },
    },
    requirement: {
        position: 'absolute',
        fontSize: '10px',
        bottom: '1px',
        right: '10px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

interface LoginState {
    email: string,
    password: string,
    remember: boolean,
    emailError: boolean,
    passwordError: boolean,
    ios: string,
    android: string,
    isMobile: any
}

const initState: LoginState = {
    email: '',
    password: '',
    remember: false,
    emailError: false,
    passwordError: false,
    ios: '',
    android: '',
    isMobile: false,
};

const Login = observer(() => {
    const classes = useStyles();
    const { accountStore, appStore } = useStores();
    const [state, setState] = useState<LoginState>(initState);
    const { t } = useTranslation();
    const location = useLocation();

    const handleLogin = () => {
        if (state.email.length > 0 && state.password.length > 0) {
            accountStore.authenticate(state.email, state.password, state.remember);
        } else {
            setState({
                ...state,
                emailError: state.email.length === 0,
                passwordError: state.password.length === 0
            })
            //hide error
            setTimeout(() => {
                setState({
                    ...state,
                    emailError: false,
                    passwordError: false
                })
            }, 4000)
        }
    }

    const history = useHistory();
    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isMobile = userAgent.match(/(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*)/i);

        if (appStore.loggedIn) {
            if (appStore.userAuth && appStore.userAuth.isSuperAdmin) {
                history.replace('/app/companies');
            } else {
                history.replace('/app');
            }
            setState({
                ...state,
                isMobile: isMobile
            })
        } else {
            accountStore.getAppUrl()
                .then((x: any) => {

                    const cookies = new Cookies();
                    const user = cookies.get('user');
                    if (user && user.remember) {
                        setState({
                            ...state,
                            email: user.emailAddress,
                            remember: true,
                            android: x.data.android,
                            ios: x.data.ios,
                            isMobile: isMobile,
                        })
                    } else {
                        setState({
                            ...state,
                            android: x.data.android,
                            ios: x.data.ios,
                            isMobile: isMobile,
                        })
                    }
                })
        }
    }, [appStore.loggedIn, history]);

    const handlerDownloadApp = (isAndroid: boolean) => {
        const downloadUrl = isAndroid ? state.android : state.ios;
        console.log(state);
        window.open(downloadUrl, "_blank");
    }

    return (<Grid container className={state.isMobile ? classes.phoneContainer : classes.container}>
        <Grid item xs={state.isMobile ? 12 : 6} className={classes.left} style={state.isMobile ? { justifyContent: "space-between" } : {}}>
            {state.isMobile &&
                <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Languages></Languages>
                </Grid>
            }
            <Grid>
                <Typography variant="h5" className={classes.logo}><img alt="Klean Kitchen" src="/MKI-logo-web2.svg" /></Typography>
                <Typography variant="h5" style={{ fontWeight: 700, fontSize: 32, color: '#FFF', paddingTop: 24 }}>{t('welcome')}</Typography>
                <Typography variant="subtitle1" style={{ fontSize: 16, fontWeight: 400, color: '#FFF', paddingTop: 14 }}>
                    {t('login_note')}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400, fontSize: "1.2rem", color: '#FFF', paddingTop: 47 }}>{t('download_note')}</Typography>
                <List style={{ display: 'flex', marginTop: 42 }}>
                    <ListItem className={state.isMobile ? classes.downloadItemPhone : classes.downloadItem} onClick={() => handlerDownloadApp(true)}>
                        <ListItemAvatar >
                            <Avatar alt="Google Play" src="/GooglePlay.png" />
                        </ListItemAvatar>
                        <ListItemText primary={t('get_it_on')} secondary="Google Play" />
                        <span className={classes.requirement}>Need Android 13+</span>
                    </ListItem>
                    <ListItem className={state.isMobile ? classes.downloadItemPhone : classes.downloadItem} onClick={() => handlerDownloadApp(false)}>
                        <ListItemAvatar>
                            <Avatar alt="Apple Store" src="/AppStore.png" />
                        </ListItemAvatar>
                        <ListItemText primary={t('download_on_the')} secondary="App Store" />
                        <span className={classes.requirement}>Need IOS 12+</span>
                    </ListItem>
                </List>
            </Grid>
            {state.isMobile &&
                <Grid></Grid>
            }
        </Grid>
        {!state.isMobile &&
            location.pathname === '/login' &&
            <Grid item xs={6} className={classes.right} style={{ justifyContent: "space-between" }}>
                <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Languages></Languages>
                </Grid>
                <Grid className={classes.right}>
                    <Typography variant="h5" className={classes.signInTitle}>
                        {t('sign_in')}
                    </Typography>
                    <Typography variant="h5" className={classes.signInSubtitle}>
                        {t('fill_fields')}
                    </Typography>
                    <TextField
                        value={state.email}
                        error={state.emailError}
                        onChange={(event) => setState({ ...state, email: event.target.value })}
                        placeholder={t('email_address')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            autoComplete: '',
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        style={{ marginTop: 32 }}
                    />

                    <TextFieldPassword
                        value={state.password}
                        error={state.passwordError}
                        onChange={(event) => setState({ ...state, password: event.target.value })}
                        placeholder={t('password')}
                        style={{ marginTop: 14 }}
                    />

                    <Box className={classes.loginExtra}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.remember}
                                    onChange={() => setState({ ...state, remember: !state.remember })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={t('remember')}
                        />
                        <Link href="/forgetPassword">
                            {t('forgot_password')}
                        </Link>
                    </Box>

                    <Button fullWidth variant="contained" style={{ marginTop: 32 }} disableElevation color="primary"
                        onClick={() => handleLogin()}>
                        {t('sign_in')}
                    </Button>
                </Grid>
                <Grid></Grid>
            </Grid>
        }
        {!state.isMobile &&
            location.pathname === '/signup' &&
            <SignUp />
        }
    </Grid>);
});

export default Login;