import { ChecklistDetailBzReport } from "../Models/Reports";
import { ChecklistDetailBizReportService } from "../Services/ChecklistDetailBizReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class ChecklistDetailBizReportStore extends BaseGridDataStore<ChecklistDetailBzReport>{
    private _service: ChecklistDetailBizReportService;

    constructor() {
        let service = new ChecklistDetailBizReportService();
        super(service);
        this._service = service;
    }
}