import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function StructureIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z" />
      </svg>
    </SvgIcon>
  );
}

export default StructureIcon;
