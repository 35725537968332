import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '../../Components/Icons/ExpandMore';
import { CommonPageTitle } from '../../Components/CommonPageTitle';
import { useTranslation } from 'react-i18next';

interface PageState {
    expanded: string,
}

const initState: PageState = {
    expanded: "",
};



export default function ControlledAccordions() {
    const [state, setState] = React.useState<PageState>(initState);
    const { t } = useTranslation();

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setState({
            ...state,
            expanded: isExpanded ? panel : false
        })
    };

    return (
        <div className="accordion-style">
            <CommonPageTitle title={t('frequently_asked_question')}
                subTitle={""}></CommonPageTitle>
            <Accordion expanded={state.expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {t('organization')}
                    </Typography>
                    <Typography className="accordion-second-title">{t('create_roles')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>
                            {t('create_roles_desc')}
                        </li>
                        <li>
                            {t('creating_a_role')}
                            <ol>
                                <li>{t('go_to_organization')}</li>
                                <li>{t('roles')}</li>
                                <li>{t('create_role')}</li>
                            </ol>
                        </li>
                        <li>{t('create_role_note')}</li>
                    </ul>

                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('organization')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('create_departments')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>
                            {t('create_departments_desc')}
                        </li>
                        <li>
                            {t('creating_a_department')}
                            <ol>
                                <li>{t('go_to_organization')}</li>
                                <li>{t('departments')}</li>
                                <li>{t('create_department')}</li>
                            </ol>
                        </li>
                        <li>
                            {t('create_departments_note')}
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {t('organization')}
                    </Typography>
                    <Typography className="accordion-second-title">
                        {t('create_employees')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <h3>{t('create_employees')}</h3>
                    <ul>
                        <li>{t('create_employees_desc')}
                        </li>
                        <li>{t('creating_an_employee')}
                            <ol>
                                <li>{t('go_to_organization')}</li>
                                <li>{t('employee')}</li>
                                <li>{t('create_employee')}</li>
                            </ol>
                        </li>
                    </ul>
                    <h3>{t('access_control')}</h3>
                    <ul>
                        <li>{t('access_control_options')}
                            <ul>
                                <li>{t('manager')}
                                    <ul>
                                        <li>{t('state_user_manager')}</li>
                                        <li>{t('analytics_tasks_assign')}</li>
                                    </ul>
                                </li>
                                <li>{t('app_login')}
                                    <ul>
                                        <li>{t('ability_login_app')}</li>
                                    </ul>
                                </li>
                                <li>{t('web_login')}
                                    <ul>
                                        <li>{t('ability_login_web')}</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3>
                        {t('personal_photo_health_permit')}
                    </h3>
                    <ul>
                        <li>{t('format_accepted_images_pdf')}</li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('categories_checklists_tasks')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('pre_set_categories')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>{t('categories_includes_checklists_tasks')}</li>
                        <li>{t('importing_pre_set_categories')}
                            <ol>
                                <li>{t('settings')}</li>
                                <li>{t('category_settings')}</li>
                                <li>{t('import_pre_set_category')}</li>
                                <li>{t('select_pre_set_categories')}</li>
                                <li>{t('click_right_arrow_button')}</li>
                                <li>{t('tick_pre_set_categories')}</li>
                                <li>{t('import_roles_corrective_actions')}</li>
                                <li>{t('submit')}</li>
                            </ol>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('categories_checklists_tasks')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('create_category')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>{t('settings')}</li>
                        <li>{t('category_settings')}</li>
                        <li>{t('create_category')}
                            <ol>
                                <li>{t('fill_checklist_name')}</li>
                                <li>{t('choose_appropriate_options_below')}</li>
                            </ol>
                        </li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('categories_checklists_tasks')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('create_a_task')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>{t('settings')}</li>
                        <li>{t('category_settings')}</li>
                        <li>{t('manage_checklist')}</li>
                        <li>{t('manage_task')}</li>
                        <li>{t('create_task')}
                            <ol>
                                <li>{t('fill_in_task_name')}</li>
                                <li>{t('choose_appropriate_options_task')}</li>
                                <li>{t('photos_explanation_task')}</li>
                            </ol>
                        </li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('corrective_actions')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('create_corrective_actions')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>{t('either_use_pre_set_corrective_actions')}</li>

                        <li>{t('creating_a_corrective_action')}
                            <ol>
                                <li>{t('settings')}</li>
                                <li>{t('corrective_actions')}</li>
                                <li>{t('create_corrective_actions')}</li>
                            </ol>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8h-content"
                    id="panel8bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('daily_refresh_time_timezone')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('setting_daily_refresh_timezone')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>{t('settings')}</li>
                        <li>{t('daily_refresh_timezone')}</li>
                        <li>{t('choose_your_organization_timezone')}</li>
                        <li>{t('change_your_daily_checklist_refresh_time')}</li>
                        <li>{t('save')}</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={state.expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9h-content"
                    id="panel9bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('cloud_library')}</Typography>
                    <Typography className="accordion-second-title">
                        {t('upload_resources')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>{t('cloud_library')}</li>
                        <li>{t('upload_file')}</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}