import { Typography } from "@material-ui/core";

export interface CommonPageTitleProps {
    title: string,
    subTitle?: string,
}

function CommonPageTitle(props: CommonPageTitleProps) {
    const { title, subTitle } = props;
    return <>
        <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 10 }}
        >
            {title}
        </Typography>
        {subTitle ?
            <Typography
                variant="h5"
                style={{ fontWeight: 400, color: "#9094A9", fontSize: 14, marginBottom: 6 }}
            >
                {subTitle}
            </Typography> : null
        }
    </>

}

export { CommonPageTitle };