import { createTheme } from '@material-ui/core/styles'

const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#1CAD5E'
        },
    },
    overrides: {
        MuiTextField: {
            root: {
                '& .MuiInputBase-root': {
                    border: '1px solid #9094A9',
                    borderRadius: '6px',
                    background: '#FFFFFF',
                    '&.Mui-error': {
                        borderColor: '#f44336'
                    },
                    '& .MuiInputBase-input': {
                        padding: '17px 14px',
                    },
                    '& .MuiInput-underline:after': {
                        border: '0',
                    },
                    '& .MuiInput-underline:before': {
                        border: '0',
                    }
                }
            },
        },

        MuiButton: {
            root: {
                textTransform: 'none',
                height: '44px',
                '& .MuiButton-label': {
                    color: '#FFFFFF',
                }
            }
        },
        MuiSelect: {
            select: {
                padding: "17px 14px",
                borderRadius: 6,
                border: '1px solid #9094A9',
                '&:focus': {
                    borderRadius: '6px !important',
                },
            },
            icon: {
                marginRight: 12
            }
        },

        MuiInputBase: {
            root: {
                '&.Mui-error > .MuiSelect-select': {
                    borderColor: '#f44336'
                },
            },
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: "0px !important"
                }
            }
        },
        MuiPaper: {
            elevation3: {
                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 2%), 0px 3px 4px 0px rgb(0 0 0 / 1%), 1px 2px 10px 5px rgb(0 0 0 / 7%)"
            },
            elevation1: {
                boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 2%), 0px 3px 4px 0px rgb(0 0 0 / 1%), 1px 2px 10px 5px rgb(0 0 0 / 7%)"
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: "26px"
            }
        },
        MuiInputLabel: {
            asterisk: {
                color: "red"
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: "10px",
                paddingRight: "10px"
            }
        },
        MuiListItemText: {
            secondary: {
                overflow: "hidden",
                width: "90%",
                textOverflow: 'ellipsis',
            }
        },
    },
});

export default CustomTheme