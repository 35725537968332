import { CorrectiveActionBzReport } from "../Models/Reports";
import { CorrectiveAndFollowBZReportService } from "../Services/CorrectiveAndFollowBZReportService";
import { BaseGridDataStore } from "./BaseGridDataStore";
import { QueryCriteria } from "../Models/QueryCriteria";
export class CorrectiveAndFollowBZReportStore extends BaseGridDataStore<CorrectiveActionBzReport>{
    private _service: CorrectiveAndFollowBZReportService;

    constructor() {
        let service = new CorrectiveAndFollowBZReportService();
        super(service);
        this._service = service;
    }

    queryCSVFollowUp() {
        const criteria: QueryCriteria = { skip: 0, take: 1000 };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }
        return this._service.queryBZFollowUpReports(criteria)
    }

    download(titles: string[]) {
        const criteria: QueryCriteria = { skip: 0, take: 1000 };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }
        criteria.filters.push({
            field: 'followup',
            value: 'followup'
        });
        criteria.titles = titles;
        return this._service.excelAsync(criteria)
    }
}