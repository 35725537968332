import axios from "axios";
import { Checklist } from "../Models/Checklist";
import { BaseService } from "./BaseService";

export class ChecklistService extends BaseService<Checklist> {
    constructor() {
        super('checklists');
    }

    deleteByOptionAsync =  async(id: string, catgoryId?: string | undefined): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/checklists/delete`;
        const response = await axios.post<boolean>(url, {
            id: id,
            categoryId: catgoryId,
        });
        return response.data;
    }

    async refresh(categoryId:string) {
        const url = `${process.env.REACT_APP_API_URL}/checklists/refresh/${categoryId}`;
        const response = await axios.post<boolean>(url);
        return response.data;
    }
}
