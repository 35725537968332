import { extendObservable } from "mobx";
import { Notification } from "../Models/Notification";
import { NotificationService } from "../Services/NotificationService";
import { BaseGridDataStore } from "./BaseGridDataStore";
import { QueryCriteria } from "../Models/QueryCriteria";
import moment from "moment";

export class NotificationStore extends BaseGridDataStore<Notification> {
    private _notificationService: NotificationService;

    constructor() {
        let notificationService = new NotificationService();
        super(notificationService);
        this._notificationService = notificationService;
        extendObservable(this, {
            getLastUnread: this.getLastUnread,
        });
    }

    getLastUnread(token?: string, companyId?: string) {
        const criteria: QueryCriteria = { skip: 0, take: 5 };
        criteria.filters = [{
            field: 'filterDate',
            value: moment().format("YYYY-MM-DD HH:mm:ss")
        }, {
            field: "companyId",
            value: companyId || ""
        }];

        return this._notificationService.getLastUnreadAsync(criteria, token);
    }
}