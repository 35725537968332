import { Route, Switch, useRouteMatch } from "react-router-dom";
import ShareLinkPage from "./ShareLinkPage";

export default function ShareLink() {
    const { path } = useRouteMatch();
    return <>
         <Switch>
            <Route exact path={path}>
                <ShareLinkPage/>
            </Route>
        </Switch>
    </>
}