import { Route, Switch, useRouteMatch } from "react-router-dom";
import SensorPage from "./SensorPage";

export default function Sensors() {
    const { path } = useRouteMatch();
    return <>
         <Switch>
            <Route exact path={path}>
                <SensorPage/>
            </Route>
        </Switch>
    </>
}