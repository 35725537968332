import {BaseGridDataStore} from "./BaseGridDataStore";
import {FollowUpInstance} from "../Models/FollowUpInstance";
import {FollowUpInstanceService} from "../Services/FollowUpInstanceService";
import {extendObservable} from "mobx";
import { QueryCriteria } from "../Models/QueryCriteria";

export class FollowUpInstanceStore extends BaseGridDataStore<FollowUpInstance> {
    private _followUpInstanceService: FollowUpInstanceService;

    constructor() {
        let followUpInstanceService = new FollowUpInstanceService();
        super(followUpInstanceService);
        this._followUpInstanceService = followUpInstanceService;
        extendObservable(this, {

        });
    }

    donwload(titles: string[]) {
        const criteria: QueryCriteria = {
            skip: 0,
            take: 1000,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
        };
        criteria.filters = [];
        console.log(this.preFilters);
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }
        criteria.titles = titles;
        return this._followUpInstanceService.downloadExcelAsync(criteria);
    }

}