import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import React, { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from "@material-ui/icons/Add";
import { observer } from "mobx-react";
import { CompanySlot } from "../../Models/CompanySlot";
import { useConfirm } from "material-ui-confirm";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import BackButton from "../../Components/BackToButton";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from "react-i18next";
import { SlotType } from "../../Models/SlotType";
import _ from "lodash";

interface PageState {
  dialogOpen: boolean;
  importDialogOpen: boolean;
  gridId: string;
  dialogId: string;
  importDialogId: string;
  editRow: CompanySlot;
  editMode: FormMode;
  templates: CompanySlot[];
  errors: ValidationError[];
  language: string;
}

const initAction: CompanySlot = {
  id: "",
  slotType: SlotType.Sensor,
  sensors: 1,
  // language: ''
};

const initState: PageState = {
  dialogOpen: false,
  importDialogOpen: false,
  gridId: Guid.create().toString(),
  dialogId: Guid.create().toString(),
  importDialogId: Guid.create().toString(),
  editMode: FormMode.Add,
  editRow: initAction,
  errors: [],
  templates: [],
  language: "",
};

const useStyles = makeStyles(() =>
  createStyles({
    selected: {
      "& .MuiSelect-select": {
        padding: "10px",
      },
      "&::before": {
        border: 0,
      },
    },
  })
);

const SensorPage = observer(() => {
  const { companySlotStore, appStore } = useStores();
  const [state, setState] = useState<PageState>(initState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [taskDigest, setTaskDigest] = useState<Array<string>>([]);
  const { hasActionPermission, isSuperAdmin } =
    appStore.userAuth as AuthenticateResponseModel;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    var lang = localStorage.getItem("lang") ?? LanguagesModels[0].key;
    companySlotStore.setLanguage(lang);
    const preFilters = [
      {
        field: "slotType",
        value: `${SlotType.Sensor}`,
      },
    ];
    companySlotStore.setPreFilter([...preFilters]);
    setState({
      ...state,
      language: lang,
    });
  }, []);

  const handleChangeActive = (companySlot: CompanySlot) => {
    if (companySlot.isActive === true) {
      companySlot.isActive = false;
    } else {
      companySlot.isActive = true;
    }
    companySlotStore.update(companySlot);
    companySlotStore.query();
  }

  const columns: GridColDef[] = [
    {
      field: "deviceType",
      headerName: t("deviceType"),
      flex: 8,
    },
    {
      field: "name",
      headerName: t("deviceName"),
      flex: 8,
      renderCell: (row: GridCellParams) => {
        const record: CompanySlot = row.row as CompanySlot;
        if (_.isEmpty(record.taskDigest)){
          return record.name;
        }
        const title = record.taskDigest!;
        return (
          <a href="javascript:void(0)" aria-describedby={id} onClick={(e) => showDigest(e, record.taskDigest!)}>
          {record.name}
        </a>
        );
      },
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 8,
    },
    {
      field: "sensors",
      headerName: t("sensors"),
      flex: 4,
    },
    // {
    //   field: "serialNumber",
    //   headerName: t("serialNumber"),
    //   flex: 6,
    // },
    // {
    //   field: "readKey",
    //   headerName: t("readKey"),
    //   flex: 6,
    // },
    // {
    //   field: "connectionID",
    //   headerName: t("connectionID"),
    //   flex: 8,
    // },
    {
      field: "isVirtual",
      headerName: t("is_virtual"),
      flex: 8,
      renderCell: (row: GridCellParams) => {
        const slot: CompanySlot = row.row as CompanySlot;
        return slot.isVirtual ? 'Y' : <>&nbsp;</>;
      },
    },
    // {
    //   field: "isActive",
    //   headerName: t("is_active"),
    //   flex: 8,
    //   renderCell: (row: GridCellParams) => {
    //     const slot: CompanySlot = row.row as CompanySlot;
    //     if (slot.isVirtual) {
    //       return <>&nbsp;</>;
    //     }
    //     return slot.isActive ? 'Y' : 'N';
    //   },
    // },
    {
      field: 'isActive', headerName: t('is_active'), width: 150,
      renderCell: ((row: GridCellParams) => {
          const slot: CompanySlot = row.row as CompanySlot;
          return <Switch
              checked={slot.isActive === true}
              onChange={() => handleChangeActive(slot)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
          />
      })
  },
];

  const handleValidate = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const valdiationErrors: ValidationError[] = [];

      if ((state.editRow.deviceType || "").trim().length === 0) {
        valdiationErrors.push({
          field: "deviceType",
          message: t("device_type_required"),
        });
      }

      if (!_.isNumber(state.editRow.sensors)) {
        valdiationErrors.push({
          field: "sensors",
          message: t("sensors_required"),
        });
      }

      if ((state.editRow.name || "").trim().length === 0) {
        valdiationErrors.push({
          field: "name",
          message: t("device_name_required"),
        });
      }

      if (state.editRow.isVirtual !== true) {
        if ((state.editRow.serialNumber || "").trim().length === 0) {
          valdiationErrors.push({
            field: "serialNumber",
            message: t("serial_number_required"),
          });
        }

        if ((state.editRow.readKey || "").trim().length === 0) {
          valdiationErrors.push({
            field: "readKey",
            message: t("read_key_required"),
          });
        }
      }

      if (valdiationErrors.length === 0) {
        resolve(true);
      } else {
        reject();
        setState({ ...state, errors: valdiationErrors });
      }
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const showDigest = (e: any, digest: Array<string>) => {
    e.preventDefault();
    setTaskDigest(digest);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (result: boolean) => {
    if (result) {
      setState({
        ...state,
        dialogOpen: false,
        dialogId: Guid.create().toString(),
        errors: [],
      });
    }
  };

  const handleCreate = () => {
    setState({
      ...state,
      dialogOpen: true,
      editMode: FormMode.Add,
      editRow: {
        ...initAction,
        id: Guid.create().toString(),
        slotType: SlotType.Sensor,
      },
    });
  };

  const handleLangChange = (item: any) => {
    setState({
      ...state,
      language: item.target.value,
    });

    companySlotStore.setLanguage(item.target.value).then((x) => {
      companySlotStore.query();
    });
  };

  return (
    <>
      <CommonPageTitle
        title={t("sensor_devices")}
        subTitle={t("configure_sensor_device")}
      ></CommonPageTitle>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          {isSuperAdmin && (
            <>
              <Typography style={{ marginRight: 10, color: "#999" }}>
                {t("managing_language")}:
              </Typography>
              <Select
                fullWidth
                displayEmpty
                disableUnderline
                style={{ width: 180 }}
                className={classes.selected}
                value={state.language}
                onChange={handleLangChange}
              >
                {LanguagesModels.map((x) => (
                  <MenuItem value={x.key} key={x.key}>
                    {x.value}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box
            style={{
              display: "flex",
              marginBottom: 16,
              justifyContent: "flex-end",
            }}
          >
            <BackButton />
            {hasActionPermission && (
              <Button
                variant="contained"
                disableElevation
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreate}
              >
                {t("create_device")}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      <CommonGridPage<CompanySlot>
        key={state.gridId}
        columns={columns}
        store={companySlotStore}
        onEdit={(row: CompanySlot) =>
          setState({
            ...state,
            dialogOpen: true,
            editRow: row,
            editMode: FormMode.Edit,
          })
        }
        defaultSortBy={"createdTime"}
        defaultSortDirection={"desc"}
        useAction={hasActionPermission}
      />

      <CommonFormDialog<CompanySlot>
        key={state.dialogId}
        open={state.dialogOpen}
        row={state.editRow}
        mode={state.editMode}
        store={companySlotStore}
        title={t("sensor_device")}
        onClose={() =>
          setState({
            ...state,
            dialogOpen: false,
            dialogId: Guid.create().toString(),
            errors: [],
          })
        }
        onSubmit={handleSubmit}
        onValidation={handleValidate}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required={true}
              error={
                state.errors.findIndex((x) => x.field === "deviceType") > -1
              }
              helperText={
                state.errors.find((x) => x.field === "deviceType")?.message ||
                ""
              }
              value={state.editRow?.deviceType}
              onChange={(event) => {
                const deviceType = event.target.value;

                setState({
                  ...state,
                  editRow: { ...state.editRow, deviceType },
                  errors: [],
                });
              }}
              placeholder={t("deviceType")}
              label={t("deviceType")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required={true}
              error={state.errors.findIndex((x) => x.field === "name") > -1}
              helperText={
                state.errors.find((x) => x.field === "name")?.message || ""
              }
              value={state.editRow?.name}
              onChange={(event) => {
                const name = event.target.value;

                setState({
                  ...state,
                  editRow: { ...state.editRow, name },
                  errors: [],
                });
              }}
              placeholder={t("deviceName")}
              label={t("deviceName")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
              <FormControlLabel
                  control={<Checkbox checked={state.editRow.isVirtual === true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setState({
                          ...state,
                          editRow: {
                              ...state.editRow,
                              isVirtual: !state.editRow.isVirtual
                          }
                      })}
                      color="primary" />}
                  label={t('is_virtual')} />
          </Grid>

          { state.editRow?.isVirtual !== true && 
          <Grid item xs={12}>
            <TextField
              fullWidth
              required={true}
              error={
                state.errors.findIndex((x) => x.field === "serialNumber") > -1
              }
              helperText={
                state.errors.find((x) => x.field === "serialNumber")?.message ||
                ""
              }
              value={state.editRow?.serialNumber}
              onChange={(event) => {
                const serialNumber = event.target.value;

                setState({
                  ...state,
                  editRow: { ...state.editRow, serialNumber },
                  errors: [],
                });
              }}
              placeholder={t("serialNumber")}
              label={t("serialNumber")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          }
          { state.editRow?.isVirtual !== true &&  
          <Grid item xs={12}>
            <TextField
              fullWidth
              required={true}
              error={state.errors.findIndex((x) => x.field === "readKey") > -1}
              helperText={
                state.errors.find((x) => x.field === "readKey")?.message || ""
              }
              value={state.editRow?.readKey}
              onChange={(event) => {
                const readKey = event.target.value;

                setState({
                  ...state,
                  editRow: { ...state.editRow, readKey },
                  errors: [],
                });
              }}
              placeholder={t("readKey")}
              label={t("readKey")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          }
          <Grid item xs={12}>
          <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true">
              Sensor Numbers<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
            </label>
            <OutlinedInput
              fullWidth
              notched={false}
              error={state.errors.findIndex((x) => x.field === "sensors") > -1}
              label={t("sensors")}
              value={state.editRow.sensors}
              onChange={(event) => {
                let sensors:any = '';
                if (!_.isEmpty(event.target.value)){
                  sensors = parseInt(
                    /^-$|^[-]?([1-9]+\.?)?[1-9]+$/.test(event.target.value)
                      ? event.target.value
                      : ''
                  );
                  if (_.isNaN(sensors)){
                    sensors = '';
                  }
                }
                setState({
                  ...state,
                  editRow: {
                    ...state.editRow,
                    sensors,
                  },
                  errors: [...state.errors].filter(
                    (x) => x.field !== "sensors"
                  ),
                });
              }
              }
              placeholder={t("sensors")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required={false}
              multiline
              rows={4}
              error={
                state.errors.findIndex((x) => x.field === "description") > -1
              }
              helperText={
                state.errors.find((x) => x.field === "description")?.message ||
                ""
              }
              value={state.editRow?.description}
              onChange={(event) => {
                const description = event.target.value;

                setState({
                  ...state,
                  editRow: { ...state.editRow, description },
                  errors: [],
                });
              }}
              placeholder={t("description")}
              label={t("description")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </CommonFormDialog>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography>
          {taskDigest.map(x => <div>{x}</div>)}
        </Typography>
      </Popover>
    </>
  );
});

export default SensorPage;
