import { TrendReport,TrendQueryCriteria } from "../Models/Reports";
import { BaseService } from "./BaseService";
import axios from "axios";

export class TrendReportsService extends BaseService<TrendReport> {
    constructor() {
        super('TrendReports');
    }

    async getTrendReportsSummaryAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendReports/GetTrendReportsSummary`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

    async getCompletionReportsAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendReports/getCompletionReports`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

    async getHealthCheckReportsAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendReports/GetHealthCheckReports`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

    async getCorrectiveReportsAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendReports/GetCorrectiveReports`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

}