import { observer } from "mobx-react";
import { ClickAwayListener, createStyles, Grow, IconButton, makeStyles, Paper, Popper, SvgIconProps, Theme, Typography } from "@material-ui/core";
import React, { SyntheticEvent, useEffect, useState } from "react";
import useStores from "../Stores/useStores";
import ExpandMore from "./Icons/ExpandMore";
import { TreeItem, TreeView } from "@material-ui/lab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { TreeItemProps } from '@material-ui/lab/TreeItem';
import { LightTooltip } from "./ToolTips";
import { BusinessRounded } from "@material-ui/icons";
import { Company } from "../Models/Company";
import { AuthenticateResponseModel } from "../Models/AuthenticateModel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewAs: {
            '&:hover': {
                borderRadius: "10px",
                backgroundColor: "unset"
            },
            padding: "0px",
        },

    }),
);

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "3px 0 3px 0",
            margin: 0,
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label, &$selected:focus > $content $label': {
                backgroundColor: 'unset',
            },
            '&:focus > $content $label:hover, &:hover > $content $label:hover, &$selected > $content $label:hover': {
                backgroundColor: 'unset',
            },
        },
        content: {
            width: '',
            color: theme.palette.text.secondary,
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            '& $content': {
                // paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: "7px 0px",
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            fontSize: "1.2rem",
            whiteSpace: "nowrap"
        },
        rightBtnLay: {
            // fontWeight: 'inherit',
            display: "flex",
            flexBasis: '0%',
            // justifyContent: "flex-end",
            // flexGrow: 1,
            // width: "200px"
        },
        iconContainer: {
            display: "flex",
            justifyContent: "flex-start",
            flexGrow: 1,
            width: "70%",
            overflow: "hidden"
        },
    }),
);

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon?: React.ElementType<SvgIconProps>;
    labelText: string,
    hideAction?: boolean,
    hideAdd?: boolean,
    hideDelete?: boolean,
    disabledAdd?: boolean,
    disabledDelete?: boolean,
    itemId: string,
    childNum: number
};

const initCompany: Company = {
    id: '',
    name: '',
    contactFirstName: '',
    contactLastName: '',
    phone: '',
    emailAddress: '',
    address: '',
    expireDate: new Date() || null,
    isFreeTrial: undefined,
    parentCompanyId: undefined,
    parentCompanyName: undefined,
    licenseId: undefined,
    childCompanies: [],
    isHQ: undefined,
    isExpire: undefined,
    licenseKey: undefined,
    newExpire: undefined
}

interface PageState {
    selectedCompanyId: string,
    companyName: string | undefined,
    viewAsMenuOpen: boolean,
    companyTree: Company,
}

const initState: PageState = {
    selectedCompanyId: "",
    companyName: "",
    viewAsMenuOpen: false,
    companyTree: initCompany,
};

let hasExpaned = false;
const StructureTree = observer((props: { callback?: Function }) => {
    const { appStore, companyStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const viewAsRef = React.useRef<HTMLButtonElement>(null);
    const classes = useStyles();
    const classesTree = useTreeItemStyles();
    const [expanded, setExpanded] = useState(['']);
    const { isSuperAdmin, orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;

    useEffect(() => {
        appStore.isLoading = true;
        companyStore.getCompanyStructure(orgCompanyId).then((values: any) => {
            setState({
                ...state,
                companyTree: values.topCompany,
                selectedCompanyId: orgCompanyId,
                companyName: appStore.userAuth?.companyName
            });
        });
    }, [])

    const handleCloseViewAs = (event: React.MouseEvent<EventTarget>) => {
        if (viewAsRef.current && viewAsRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setState({ ...state, viewAsMenuOpen: false });
    };

    const handTest = (e: any, id: any, name: any) => {
        if (state.selectedCompanyId !== id && e.target && e.target.nodeName === "P") {
            setState({
                ...state,
                selectedCompanyId: id,
                companyName: name,
                viewAsMenuOpen: !state.viewAsMenuOpen
            })
            if (props.callback && typeof props.callback === 'function') {
                props.callback({
                    id: id
                });
            }
        }

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    function StyledTreeItem(props: StyledTreeItemProps) {
        const classes = useTreeItemStyles();
        const { itemId, labelText, childNum, labelIcon: LabelIcon, hideAction, hideDelete, hideAdd, disabledAdd, disabledDelete, color, bgColor, ...other } = props;

        return (
            <TreeItem
                onLabelClick={e => handTest(e, itemId, labelText)}
                onIconClick={(e: SyntheticEvent) => onIconClick(e, itemId, labelText)}
                icon={
                    <div className={classes.labelRoot}>
                        {childNum && childNum > 0 ? (expanded.includes(itemId) ? <ArrowDropDownIcon /> : <ArrowRightIcon />) : <div style={{ width: '18px' }}></div>}
                        {LabelIcon ? <LabelIcon color="inherit" className={classes.labelIcon} /> : <BusinessRounded className={classes.labelIcon} />}

                        <LightTooltip title={labelText} placement="bottom-start" arrow>
                            <Typography variant="body2" className={classes.labelText}>
                                {labelText}
                            </Typography>
                        </LightTooltip>

                    </div>
                }

                style={{
                    '--tree-view-color': color,
                    '--tree-view-bg-color': bgColor,
                }}
                classes={{
                    root: classesTree.root,
                    content: classesTree.content,
                    expanded: classesTree.expanded,
                    selected: classesTree.selected,
                    group: classesTree.group,
                    label: classesTree.label,
                    iconContainer: classesTree.iconContainer
                }}
                {...other}
            />
        );
    }


    function treeItem(children: Array<Company>, startIndex: any) {
        return (
            children
                ? children.map((item, index) => (
                    <StyledTreeItem hideAction={isSuperAdmin}
                        onClick={(e: any) => handTest(e, item.id, item.name)}
                        disabledDelete={isSuperAdmin} hideDelete={item.childCompanies && item.childCompanies.length > 0}
                        childNum={item.childCompanies && item.childCompanies.length > 0 ? item.childCompanies.length : 0}
                        key={item.id} itemId={item.id} nodeId={item.id} labelText={item.name}>
                        {item.childCompanies ? treeItem(item.childCompanies, 0) : ''}
                    </StyledTreeItem>
                ))
                : '')
    }

    const onNodeToggle = (event: object, expands: Array<string>) => {
        setExpanded(expands)
        hasExpaned = true;
    }

    const onIconClick = (e: any, itemId: string, name: any) => {
        if (e.target && e.target.nodeName === "P") {
            if (state.selectedCompanyId !== itemId) {
                setState({
                    ...state,
                    selectedCompanyId: itemId,
                    companyName: name,
                    viewAsMenuOpen: !state.viewAsMenuOpen
                })
                if (props.callback && typeof props.callback === 'function') {
                    props.callback({
                        id: itemId
                    });
                }
            }
        }
    }

    const handTreeSelect = (event: object, value: string) => {
    }

    const handleViewAsMenuToggle = () => {
        if (!appStore.companyTree?.id) {
            companyStore.getCompanyStructure(orgCompanyId).then((data: any) => {
                appStore.setCompanyTree(data.topCompany);
                setState({
                    ...state,
                    viewAsMenuOpen: !state.viewAsMenuOpen
                })
            })
        } else {
            setState({
                ...state,
                viewAsMenuOpen: !state.viewAsMenuOpen
            })
        }
    };

    return <>
        <IconButton ref={viewAsRef} className={classes.viewAs} onClick={() => handleViewAsMenuToggle()}>
            <IconButton style={{
                color: "rgba(0, 0, 0, 0.54)",
                border: "rgba(0, 0, 0, 0.2) 1px solid",
                padding: "10.5px 8px",
                marginLeft: "5px",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                minWidth: 200,
                justifyContent: "space-between"
            }}>{state.companyName} <ExpandMore style={{ color: "rgba(136,143,147" }}></ExpandMore></IconButton>
        </IconButton>
        <Popper open={state.viewAsMenuOpen} style={{ width: "300px", zIndex: 99999 }} anchorEl={viewAsRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseViewAs}>
                            <TreeView
                                onNodeSelect={handTreeSelect}
                                onNodeToggle={onNodeToggle}
                                expanded={expanded}
                                className={classesTree.root}
                                defaultEndIcon={<div style={{ width: 30 }} />}
                            >
                                <StyledTreeItem hideAction={isSuperAdmin}
                                    childNum={appStore.companyTree?.childCompanies && appStore.companyTree.childCompanies.length > 0 ? appStore.companyTree.childCompanies.length : 0}
                                    itemId={appStore.companyTree?.id ?? ""} nodeId={appStore.companyTree?.id ?? ""} labelText={appStore.companyTree?.name ?? ""} hideDelete >
                                    {appStore.companyTree?.childCompanies ? treeItem(appStore.companyTree?.childCompanies, 1) : ''}
                                </StyledTreeItem>
                            </TreeView>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>

    </>
});
export default StructureTree;