import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ValidationError } from "../../Models/ValidationError";
import { KeyboardTimePicker } from "@material-ui/pickers";
import useStores from "../../Stores/useStores";
import { Company } from "../../Models/Company";
import { DateTime } from "luxon";
import BackButton from "../../Components/BackToButton";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from 'react-i18next';
interface Timezone {
    value: string,
    text: string
}

interface DayChangePageState {
    dayChangeTime?: Date,
    timeZoneId?: string,
    company?: Company,
    timeZoneIds: Timezone[],
    errors: ValidationError[],
}

const initState: DayChangePageState = {
    timeZoneId: "",
    timeZoneIds: [],
    errors: []
};

const DayChangeTime = observer(() => {
    const [state, setState] = useState<DayChangePageState>(initState);
    const { companyStore, appStore } = useStores();
    const { hasActionPermission } = appStore.userAuth as AuthenticateResponseModel;
    const { t } = useTranslation();
    useEffect(() => {
        getSelectList();
    }, []);
    const getSelectList = () => {
        Promise.all([companyStore.getTimezoneList(), companyStore.getCompanyInfo()])
            .then(values => {
                setState({
                    ...state,
                    timeZoneIds: values[0],
                    company: values[1],
                    timeZoneId: values[1].timeZoneId ? values[1].timeZoneId : "",
                    dayChangeTime: values[1].dayChangeTime ? values[1].dayChangeTime : new Date()
                });
            });
    };
    const handleSave = () => {
        const { timeZoneId, dayChangeTime } = state;
        let utcDayChangeTime = dayChangeTime;
        if (dayChangeTime && dayChangeTime.constructor === Date) {
            let tempNewDate = DateTime.fromJSDate(dayChangeTime);
            utcDayChangeTime = new Date(Date.UTC(tempNewDate?.year || 2021, (tempNewDate?.month || 1) - 1, tempNewDate?.day, tempNewDate?.hour, tempNewDate?.minute));
        }
        companyStore.update(Object.assign({}, state.company, { timeZoneId, dayChangeTime: utcDayChangeTime }))
            .then((data) => {
                console.log(data);
                appStore.setCompanyDayChangeTime(utcDayChangeTime);
            })
    }
    return <>
        <CommonPageTitle title={t('daily_refresh_timezone_setting')}
            subTitle={t('setting_daily_refreshtime_checklist')} />
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <BackButton />
        </Box>
        <Paper style={{ padding: "inherit", marginTop: 20 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="lblRole" shrink>{t('organization_timezon')}</InputLabel>
                        <Select fullWidth
                            onChange={(event) => setState({
                                ...state,
                                timeZoneId: event.target.value as string
                            })}
                            value={state.timeZoneId}
                            displayEmpty
                            disableUnderline>

                            {state.timeZoneIds.map(timezone =>
                                <MenuItem value={timezone.value} key={timezone.value}>{timezone.text}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel shrink>{t('daily_checklist_refresh')}</InputLabel>
                        <KeyboardTimePicker
                            error={state.errors.findIndex(x => x.field === "startTimeOfDay") > -1}
                            helperText={state.errors.find(x => x.field === "startTimeOfDay")?.message || ''}
                            fullWidth
                            margin="normal"
                            id="time-picker"
                            orientation="landscape"
                            openTo="hours"
                            value={state.dayChangeTime}
                            onChange={(newDate) => {

                                setState({
                                    ...state,
                                    dayChangeTime: newDate?.toJSDate()
                                })
                            }}
                            KeyboardButtonProps={{
                                'aria-label': t('change_time'),
                            }}
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        <InputLabel shrink></InputLabel>
                        {hasActionPermission &&
                            <Button color="primary" variant="contained" style={{ marginTop: 20, width: 80 }} disableElevation
                                onClick={handleSave}>
                                {t('save')}
                            </Button>
                        }
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    </>;
})
export default DayChangeTime;