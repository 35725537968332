import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import { useState } from "react";
import { Guid } from "guid-typescript";
import { FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, createStyles, FormControlLabel, Grid, Link, makeStyles, MenuItem, Select, Switch, Typography } from "@material-ui/core";
import { Task, TaskType } from "../../Models/Task";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { observer } from "mobx-react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import TaskFormDialog from "./TaskFormDialog";
import { CorrectiveAction } from "../../Models/CorrectiveAction";
import { CompanyRole } from "../../Models/CompanyRole";
import { QueryFilter } from "../../Models/QueryCriteria"
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import qs from "query-string";
import LanguagesModels from "../../Utils/languages";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from 'react-i18next';

interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: Task,
    companyRoles: CompanyRole[],
    correctiveActions: CorrectiveAction[],
    editMode: FormMode,
    errors: ValidationError[]
}

const initTask: Task = {
    id: '',
    name: '',
    checklistId: '',
    checklistGroupId: '',
    taskType: TaskType.YesOrNo,
    isMandatory: false,
    isCritical: false,
    isCentigrade: false,
    temperatureEnd: '',
    temperatureStart: '',
    isScorecard: false,
    score: 0,
    explanation: '',
    photos: [],
    correctiveActionIds: [],
    notificationRoleIds: [],
    isIncludeTemperatureReport: false,
    sensorId: '',
};

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initTask,
    errors: [],
    correctiveActions: [],
    companyRoles: [],
};

const useStyles = makeStyles(() =>
    createStyles({
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },

    }),
);

const TaskPage = observer(() => {
    const { taskStore, correctiveActionStore, companyRoleStore, appStore, categoryStore, checklistInstanceStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { checklistGroupId, checklistId } = useParams<{ checklistGroupId: string, checklistId: string }>();
    const { isSuperAdmin } = appStore.userAuth as AuthenticateResponseModel;
    const classes = useStyles();
    const { t } = useTranslation();
    const preFilters: QueryFilter[] = []
    if (checklistId) {
        initTask.checklistId = checklistId;
        preFilters.push({
            field: 'checklistId',
            value: checklistId
        });
    }
    if (checklistGroupId) {
        initTask.checklistGroupId = checklistGroupId;
        preFilters.push({
            field: 'checklistGroupId',
            value: checklistGroupId
        });
    } else {
        initTask.checklistGroupId = "";
    }

    taskStore.setPreFilter(preFilters);
    const { search } = useLocation();
    const qObj = qs.parse(search);
    const { language, checklistGroup } = qObj;
    const categoryName = qObj.checklistName;
    const checklistName = qObj.categoryName;
    const columns: GridColDef[] = [
        { field: "name", headerName: t('task_name'), flex: 10 },
        {
            field: "taskType", headerName: t('task_type'), width: 140,
            renderCell: ((row: GridCellParams) => {
                const task: Task = row.row as Task;
                return t(`${TaskType[task.taskType]}`);
            })
        },
        {
            field: "isMandatory", headerName: t('mandatory'), width: 140,
            renderCell: ((row: GridCellParams) => {
                const task: Task = row.row as Task;
                return <FormControlLabel
                    control={<Switch checked={task.isMandatory}
                        color="primary"
                        name="loginApp"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={() => handleMandatoryChange(task)}
                    ></Switch>}
                    label=""
                />
            })
        },
        {
            field: "isIncludeTemperatureReport", headerName: t('include_temperature_report'), flex: 3,
            renderCell: ((row: GridCellParams) => {
                const task: Task = row.row as Task;
                return <FormControlLabel
                    control={<Switch checked={task.isIncludeTemperatureReport}
                        color="primary"
                        name="loginApp"
                        disabled={task.taskType === TaskType.YesOrNo}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={() => handleIncludeReportChange(task)}
                    ></Switch>}
                    label=""
                />
            })
        },
        {
            field: "isScorecard", headerName: t('scorecard'), width: 140,
            renderCell: ((row: GridCellParams) => {
                const task: Task = row.row as Task;
                return <FormControlLabel
                    control={<Switch checked={task.isScorecard}
                        color="primary"
                        name="loginApp"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={() => handleScorecardChange(task)}
                    ></Switch>}
                    label=""
                />
            })
        },
        { field: "score", headerName: t('points'), width: 140 },
    ];

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }
    const handleCreateOrUpdate = (editMode: FormMode, task: Task) => {
        const entityId = editMode === FormMode.Edit ? task.id : Guid.create().toString();
        console.log({ categoryStore_language: categoryStore.language});
        Promise.all([
            correctiveActionStore.getAllByLanguage(isSuperAdmin ? categoryStore.language : (language as string)),
            companyRoleStore.getAllByLanguage(isSuperAdmin ? categoryStore.language : "0")
        ]).then(x => {
            setState({
                ...state, dialogOpen: true,
                editRow: {
                    ...task,
                    id: entityId,
                    photos: [...task.photos],
                    correctiveActionIds: [...task.correctiveActionIds],
                    notificationRoleIds: [...task.notificationRoleIds]
                },
                editMode: editMode,
                companyRoles: x[1],
                correctiveActions: x[0], dialogId: Guid.create().toString()
            });
        });
    }

    const handleRefreshTasks = () => {
        checklistInstanceStore.refresh(checklistId).then(x => {
        });
    }

    const history = useHistory();

    const handleMandatoryChange = (task: Task) => {
        const taskModel = { ...task, isMandatory: !task.isMandatory };
        taskStore.update(taskModel).then(x => {
        });
    }

    const handleScorecardChange = (task: Task) => {
        const taskModel = { ...task, isScorecard: !task.isScorecard };
        taskStore.update(taskModel).then(x => {
        });
    }

    const handleIncludeReportChange = (task: Task) => {
        const taskModel = { ...task, isIncludeTemperatureReport: !task.isIncludeTemperatureReport };
        taskStore.update(taskModel);
    }

    return (<>

        <CommonPageTitle title={`${t('tasks')} - ${categoryName} -> ${checklistName} -> ${checklistGroup}`}
            subTitle={t('manage_checklist_tasks')}></CommonPageTitle>
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                    {isSuperAdmin &&
                        <>
                            <Typography style={{ marginRight: 10, color: "#999" }}>
                                {t('view_as')}
                            </Typography>
                            <Select fullWidth
                                displayEmpty
                                disableUnderline
                                disabled={true}
                                style={{ width: 180 }}
                                className={classes.selected}
                                value={categoryStore.language}
                            >
                                {LanguagesModels.map((x:any) =>
                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>

                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.goBack()}
                        style={{ marginRight: 16, background: '#374359', color: '#fff' }}
                    >
                        {checklistGroupId ? t('back_to_checklist_group') : t('back_to_checklist')}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<RefreshIcon />}
                        style={{ marginRight: 16 }}
                        onClick={handleRefreshTasks}
                    >
                        {t('refresh_tasks')}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleCreateOrUpdate(FormMode.Add, initTask)}
                    >
                        {t('create_task')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
        <CommonGridPage<Task>
            key={state.gridId}
            columns={columns}
            store={taskStore}
            delConfirmMsg={t('delete_task_tip')}
            onEdit={(task: Task) => handleCreateOrUpdate(FormMode.Edit, task)}
        />
        <TaskFormDialog
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            correctiveActions={state.correctiveActions}
            companyRoles={state.companyRoles}
            store={taskStore}
            title={t('task')}
            onClose={() => setState({ ...state, dialogOpen: false, errors: [] })}
            onSubmit={handleSubmit}
        />
    </>
    );
});

export default TaskPage
