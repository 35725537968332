import { Grid, makeStyles, Typography, TextField, Button } from "@material-ui/core";
import { useState } from "react";
import { observer } from 'mobx-react'
import useStores from "../../Stores/useStores";
import { isValidEmail } from "../../Utils/StringUtils";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
    },
    top: {
        height: '100px',
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        'box-shadow': '0px 4px 4px rgba(55, 67, 89, 0.1)',
    },
    bottom: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100% - 64px)',
        paddingLeft: 470,
        paddingRight: 470,
        background: '#E5E5E5'
    },
    logo: {
        height: '70px',
        width: '311px',
        paddingLeft: '93px',
        paddingTop: '17px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    resetTitle: {
        paddingTop: 216,
        fontWeight: 500,
        fontSize: 32
    },
    resetSubtitle: {
        fontWeight: 400,
        color: '#374359'
    }
}));


interface ResetPasswordState {
    email: string,
    emailError: boolean,
    errorMessage: string,
}

const initState: ResetPasswordState = {
    email: '',
    emailError: false,
    errorMessage: "",
};

const ResetPassword = observer(() => {
    const classes = useStyles();
    const { accountStore, appStore } = useStores();
    const [state, setState] = useState<ResetPasswordState>(initState);
    const { t } = useTranslation();
    const history = useHistory();
    const handleNext = () => {
        if (state.email.trim().length === 0) {
            setState({
                ...state,
                emailError: true,
                errorMessage: t('email_address_required')
            });
            return;
        } else if (!isValidEmail(state.email)) {
            setState({
                ...state,
                emailError: true,
                errorMessage: t('email_format_error')
            });
            return;
        }
        setState({
            ...state,
            emailError: false,
            errorMessage: ""
        });
        accountStore.sendResetEmail(state.email.trim())
            .then(done => {
                if (done) {
                    history.replace('/login');
                    appStore.infoMsg = t('email_send_success');
                    appStore.showInfo = true;
                }
            })

    }

    return (<Grid container className={classes.container}>
        <Grid className={classes.top}>
            <Typography variant="h5" className={classes.logo}><img alt="Klean Kitchen" src="/MKI-logo-web.svg" /></Typography>
        </Grid>
        <Grid className={classes.bottom}>
            <Typography variant="h5" className={classes.resetTitle}>
                {t('forgot_password')}
            </Typography>
            <Typography variant="h5" className={classes.resetSubtitle}>
                {t('fill_email')}
            </Typography>
            <TextField
                value={state.email}
                error={state.emailError}
                helperText={state.errorMessage}
                onChange={(event) => setState({ ...state, email: event.target.value })}
                placeholder={t('email_address')}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    autoComplete: '',
                    form: {
                        autocomplete: 'off',
                    },
                }}
                style={{ marginTop: 32 }}
            />
            <Button fullWidth variant="contained" style={{ marginTop: 32 }} disableElevation color="primary"
                onClick={() => handleNext()}>
                {t('next')}
            </Button>
        </Grid>
    </Grid>);
});

export default ResetPassword;