import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography, } from "@material-ui/core";
import { Link } from "react-router-dom";
import DvrIcon from "@material-ui/icons/Dvr";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  featureList: {
    display: "flex",
    "& a:not(:last-child)": {
      borderRight: "solid 1px rgba(0, 0, 0, 0.12)",
    },
    "& .MuiListItemText-primary": {
      fontWeight: 700,
      fontSize: 20,
      color: "#374359 !important",
    },
  },
});

const listItemIconStyle = {
  color: "#1CAD5E",
  fontSize: 40,
};

export function SaReports() {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <>
      <Typography
        variant="h5"
        style={{
          fontWeight: 700,
          color: "#374359",
          fontSize: 24,
          marginTop: 12,
          marginBottom: 10,
        }}
      >
        {t("reports")}
      </Typography>
      <Typography
        variant="h5"
        style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
      >
        {t("check_out_latest_business_reports")}
      </Typography>
      <Paper elevation={3} style={{ marginTop: 32 }}>
        <List className={classes.featureList}>
          <ListItem component={Link} to="/app/sareportscustomeranalytics">
            <ListItemAvatar>
              <DvrIcon style={listItemIconStyle} />
            </ListItemAvatar>
            <ListItemText
              primary={t("customer_analytics")}
              secondary={t("generate_customer_analytics")}
            />
          </ListItem>
          <ListItem>
          </ListItem>
          <ListItem>
          </ListItem>
        </List>
      </Paper>
    </>
  );
}
