import { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useStores from "../../Stores/useStores";
import { observer } from "mobx-react";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from "react-i18next";
import ReactEcharts from "echarts-for-react";
import { SensorRecord } from "../../Models/SensorRecord";
import moment from "moment";
import "moment-timezone";
import { Company } from "../../Models/Company";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import _ from "lodash";

const lastDays = -30;

const Colors = [
  "#BB6BD9",
  "#FF6E00",
  "#1CAD5E",
  "#2F80ED",
  "#FF5B7B",
  "#FB6BD9",
  "#BF6E00",
  "#ACAD5E",
  "#4F80ED",
  "#9F5B7B",
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      maxWidth: 345,
    },
    pagerTitle: {
      fontWeight: 700,
      color: "#374359",
      marginTop: 12,
      padding: "24px",
    },
  })
);

interface PageState {
  datas: any;
}

const initState: PageState = {
  datas: "",
};

export const TemperatureChart = observer(() => {
  const classes = useStyles();
  const { appStore, companySlotStore, companyStore } = useStores();
  const { companyId } = appStore.userAuth as AuthenticateResponseModel;
  const [chartOption, setChartOption] = useState<any>({});
  const [timezoneId, setTimezoneId] = useState<string>();
  const [unit, setUnit] = useState<string>("fahrenheit");
  const [data, setData] = useState<SensorRecord[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    companyStore.getCompanyInfo().then((data: Company) => {
      setTimezoneId(data.timeZoneId!);
    });
  }, []);

  useEffect(() => {
    timezoneId && loadChart();
  }, [timezoneId]);

  useEffect(() => {
    updateChartOption();
  }, [data, unit]);

  const loadChart = () => {
    setDataLoading(true);
    const recordAtTo = moment.utc().format("YYYY-MM-DD HH:59:59");
    const recordAtFrom = moment.utc().add(lastDays, 'days').format("YYYY-MM-DD HH:00:00");
    companySlotStore
      .querySensorRecords({
        recordAtFrom: moment(recordAtFrom).format("YYYY-MM-DD HH:mm:ss"),
        recordAtTo: moment(recordAtTo).format("YYYY-MM-DD HH:mm:ss"),
        isUTCTime: true,
      })
      .then((data: SensorRecord[]) => {
        setData(data);
      }).finally(()=>{
        // setTimeout(() => {
        //   setDataLoading(false);
        // }, 500);
        setDataLoading(false);
      });
  };

  const updateChartOption = () => {
    const recordAtTo = moment.utc().endOf('hour');
    const recordAtFrom = moment.utc().startOf('hour').add(lastDays, 'days');
    const xAxisData: any[] = [];
    while (recordAtFrom.diff(recordAtTo) <= 0) {
      xAxisData.push(recordAtFrom.format("YYYY-MM-DD HH:00"));
      recordAtFrom.add({ hours: 1 });
    }
    const legendData: any[] = [];
    const series: any[] = [];
    data.forEach((sensorRecord: SensorRecord) => {
      legendData.push(sensorRecord.sensorName);
      const seriesData: any[] = [];
      xAxisData.forEach((x) => {
        const record = _.head(
          sensorRecord.data.filter(
            (d) => moment(d.recordAt).format("YYYY-MM-DD HH:00") == x
          )
        );
        if (record) {
          seriesData.push(
            unit === "fahrenheit" ? record.fahrenheit : record.celsius
          );
        } else {
          seriesData.push(null);
        }
      });

      series.push({
        name: sensorRecord.sensorName,
        type: "line",
        smooth: true,
        data: seriesData,
      });
    });

    let _unit = "";
    if (unit === "celsius") {
      _unit = "℃";
    } else if (unit === "fahrenheit") {
      _unit = "°F";
    }
    const yAxisFormatter = `{value}${_unit}`;

    const option = {
      title: {
        text: "Sensor Temperature",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: legendData,
        orient: "vertical",
        bottom: 0,
        height: "60",
        // type: 'scroll',
      },
      grid: {
        left: "3%",
        right: "5%",
        containLabel: true,
        top: 100,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: timezoneId ? xAxisData.map((x) =>
          moment.utc(x, 'YYYY-MM-DD HH:mm').clone().tz(timezoneId!).format("MM/DD HH:mm")
        ) : xAxisData,
        axisLabel: {
          show: true,
          interval: 0,
          width: 35,
          overflow: "breakAll",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: yAxisFormatter,
        },
      },
      series,
      dataZoom: [
        {
          type: "inside",
          start: 96.5,
          end: 100,
        },
        {
          show: true,
          type: "slider",
          start: 96.5,
          end: 100,
          top: 30,
        },
      ],
    };

    setChartOption(option);
  };

  return (
    <>
      <div style={{ width: "100%", position: 'relative' }}>
        {dataLoading && <div className="css-xuaqpw" style={{
          position: 'absolute', top: 0,
          bottom: 0, right: 0, left: 0, zIndex: 2,
          transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          opacity: 1
        }}>
          <CircularProgress color="inherit" />
        </div>}

        <ReactEcharts option={chartOption} />
        <div style={{ textAlign: "center" }}>
          <Radio
            checked={unit === "celsius"}
            onChange={() => setUnit("celsius")}
            value="celsius"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
          Celsius
          <Radio
            checked={unit === "fahrenheit"}
            onChange={() => setUnit("fahrenheit")}
            value="fahrenheit"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
          Fahrenheit
        </div>
      </div>
    </>
  );
});
export default TemperatureChart;
