import { makeAutoObservable } from "mobx";
import { AuthenticateResponseModel } from "../Models/AuthenticateModel";
import { AccountService } from "../Services/AccountService";
import { AppStore } from "./AppStore";
import { Company } from "../Models/Company";

export class AccountStore {
    private _accountService: AccountService;
    private _appStore: AppStore;
    userAuth: AuthenticateResponseModel | undefined = undefined;

    constructor(appStore: AppStore) {
        this._accountService = new AccountService();
        this._appStore = appStore;
        makeAutoObservable(this);
    }

    authenticate(email: string, password: string, remember: boolean) {
        this._accountService.authenticateAsync(email, password).then((x) => {
            const result = x.data as AuthenticateResponseModel;
            if (result) {
                this._appStore.setAuthenticated(result, remember);
            }
        });
    }

    signUp(company: Company, shareCode?: string) {
        return this._accountService.signUpAsync(company, shareCode);
    }

    resetPassword(verifycode: string, password: string) {
        return this._accountService.resetPasswordAsync(verifycode, password)
    }

    sendResetEmail(email: string) {
        return this._accountService.sendResetEmailAsync(email);
    }

    getAppUrl() {
        return this._accountService.getAppURLAsync();
    }
}