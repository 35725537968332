
import { extendObservable } from "mobx";
import { Department } from "../Models/Department";
import { DepartmentService } from "../Services/DepartmentService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class DepartmentStore extends BaseGridDataStore<Department>{
    private _departmentService: DepartmentService;

    constructor() {
        let departmentService = new DepartmentService();
        super(departmentService);
        this._departmentService = departmentService;
        extendObservable(this, {
            queryTemplate: this.queryKitchens,
        });
    }

    //query kitchens
    queryKitchens() {
        return this._departmentService.quertyKitchenAsync();
    }
}