import axios from "axios";
import {CheckListDetailsReport} from "../Models/Reports";
import { BaseService } from "./BaseService";
import {QueryCriteria} from "../Models/QueryCriteria";
import {PagedResult} from "../Models/PagedResult";
import moment from "moment";

interface ChceklistReportQueryCriteria extends QueryCriteria {
    selectedDate:string,
    categoryId:string,
    departmentId:string,
    employeeId:string,
    toDate:string,
}

export class ChecklistDetailReportsService extends BaseService<CheckListDetailsReport> {
    constructor() {
        super('checklistInstances');
    }
     queryAsync = async (criteria: QueryCriteria): Promise<PagedResult<CheckListDetailsReport>> =>  {
        const url = `${process.env.REACT_APP_API_URL}/taskInstances/getTaskInstanceReportList`;
        const myCriteria = criteria as ChceklistReportQueryCriteria;
        let isSetDate = false;
        criteria.filters?.map((x)=>{
            if (x.field === "selectedDate") {
                myCriteria.selectedDate = x.value;
                isSetDate = true;
            } else if (x.field === "departmentId") {
                myCriteria.departmentId = x.value;
            }else if (x.field === "employeeId") {
                myCriteria.employeeId = x.value;
            }
            return true;
        });
        if(!isSetDate) {
            myCriteria.selectedDate = moment().format("YYYY-MM-DD HH:mm:ss");
        }
        const response = await axios.post<PagedResult<CheckListDetailsReport>>(url, myCriteria);
        return response.data;
    }
}