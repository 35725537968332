import { Box, Button, Card, CardHeader, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import React, { useEffect, useState } from "react";
import { Category } from "../../Models/Category";
import useStores from "../../Stores/useStores";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from 'react-i18next';

interface DialogProps {
    open: boolean,
    templates: Category[],
    onClose: () => void,
    onSubmitImport: (templateIds: string[], importOption: boolean, language: String) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        cardHeader: {
            padding: theme.spacing(1, 2),
        },
        list: {
            height: 400,
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
            "& span": {
                color: 'rgba(0, 0, 0, 0.26) !important'
            }
        },
        dialogHeader: {
            '& .MuiTypography-root': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }
        },
        dialogPaper: {
            width: 900,
            maxWidth: "unset !important"
        },
        cancelButton: {
            '& .MuiButton-label': {
                color: '#9094A9'
            }
        },
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },
    }),
);


function not(a: Category[], b: Category[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Category[], b: Category[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: Category[], b: Category[]) {
    return [...a, ...not(b, a)];
}

export function CategoryImportDialog(props: DialogProps) {
    const { open, onClose, onSubmitImport } = props;
    const { t } = useTranslation();
    const handleClose = (value: any) => {
        if (value != "") {
            return;
        }
        onClose();
    };

    const classes = useStyles();
    const [checked, setChecked] = React.useState<Category[]>([]);
    const [left, setLeft] = React.useState<Category[]>(props.templates);
    const [right, setRight] = React.useState<Category[]>([]);
    const [checkedOption, setCheckedOption] = React.useState(true);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const { categoryStore } = useStores();
    const [language, setLanguage] = useState<String>("");

    useEffect(() => {
        var lang = localStorage.getItem("lang") ?? LanguagesModels[0].key;
        setLanguage(lang);
    }, []);


    const handleImport = () => {
        if (onSubmitImport) {
            onSubmitImport(right.map(x => x.id), checkedOption, language);
        }
    }

    const handleToggle = (value: Category) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: Category[]) => intersection(checked, items).length;

    const handleToggleAll = (items: Category[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title: React.ReactNode, items: Category[]) => (
        <Card variant="outlined">
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': t('all_item_selected') }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value: Category) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    color="primary"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    const handleLangChange = (item: any) => {
        setLanguage(item.target.value);

        categoryStore.queryTemplate(item.target.value).then(x => {
            setLeft(x);
            setRight([]);
        });
    }

    return (<Dialog open={open} onClose={()=> handleClose("not")} aria-labelledby="form-dialog-title" fullWidth maxWidth={"sm"} classes={{
        paper: classes.dialogPaper
    }}>
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            <span>{t('import_preset_category')}</span>
            <IconButton onClick={()=> handleClose("")}>
                <ClearIcon />
            </IconButton>
        </DialogTitle>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ marginRight: 10, color: "#999", marginLeft: 24 }}>
                {t("import_as")}:
            </Typography>
            <Select fullWidth
                displayEmpty
                disableUnderline
                style={{ width: 180 }}
                className={classes.selected}
                value={language}
                onChange={handleLangChange}
            >
                {LanguagesModels.map(x =>
                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                )}
            </Select>
        </Grid>
        <DialogContent style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item style={{ flex: "1" }}>{customList(t('choices'), left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label={t('move_selected_right')}
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label={t('move_selected_left')}
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item style={{ flex: "1" }}>{customList(t('chosen'), right)}</Grid>
            </Grid>
            <Box style={{ paddingLeft: 8, paddingTop: 16 }}>
                <FormControlLabel
                    control={<Checkbox checked={checkedOption}
                        color="primary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCheckedOption(!checkedOption)}
                    />}
                    label={t('import_role_corrective_tip')} />
            </Box>

        </DialogContent>
        <DialogActions style={{ paddingRight: 24, marginTop: 24 }}>
            <Button variant="outlined" disableElevation className={classes.cancelButton} onClick={()=> handleClose("")}>
                {t('cancel')}
            </Button>
            <Button color="primary" variant="contained" disableElevation onClick={() => handleImport()} >
                {t('submit')}
            </Button>
        </DialogActions>
    </Dialog>
    );
}