import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ListAltIcon from '@material-ui/icons/ListAlt';
import CategoryIcon from '@material-ui/icons/Category';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles({
    featureList: {
        display: 'flex',
        '& a:not(:last-child)': {
            borderRight: 'solid 1px rgba(0, 0, 0, 0.12)'
        },
        '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: 20,
            color: '#374359 !important'
        }
    },
});

export default function Template() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (<>
        <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#374359", fontSize: 24, marginTop: 12, marginBottom: 10 }}
        >
            {t('templates')}
        </Typography>
        <Typography
            variant="h5"
            style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
        >
            {t('templates_desc')}
        </Typography>
        <Paper elevation={3} style={{ marginTop: 32 }}>
            <List className={classes.featureList}>
                <ListItem component={Link} to="/app/companyroles">
                    <ListItemAvatar>
                        <AccountBoxIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('roles')} secondary={t('manage_role_templates')} />
                </ListItem>
                <ListItem component={Link} to="/app/correctiveactions">
                    <ListItemAvatar>
                        <ListAltIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('corrective_actions')} secondary={t('corrective_actions')} />
                </ListItem>
                <ListItem component={Link} to="/app/categories">
                    <ListItemAvatar>
                        <CategoryIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('category_settings')} secondary={t('category_part_desc')} />
                </ListItem>
            </List>
        </Paper>
    </>)
}