import type { SyntheticEvent } from 'react';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import useStores from '../../Stores/useStores';
import { useState, useEffect } from 'react';
import { Guid } from 'guid-typescript';
import { FormMode, CommonFormDialog } from '../../Components/CommonFormDialog';
import { createStyles, makeStyles, TextField, Grid, Box, Typography, TablePagination, Link, Select, MenuItem, FormControl, InputLabel, Avatar, Button, FormHelperText } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ValidationError } from '../../Models/ValidationError';
import moment from 'moment';
import { useConfirm } from 'material-ui-confirm';
import { isValidEmail } from "../../Utils/StringUtils";
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Theme } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { AuthenticateResponseModel } from '../../Models/AuthenticateModel';
import AddIcon from '@material-ui/icons/Add';
import { LightTooltip } from "../../Components/ToolTips";
import { Company } from '../../Models/Company';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { DeleteForever, BusinessRounded } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SortDirection } from "../../Models/QueryCriteria";
import {
  DataGrid,
  GridSortModelParams
} from "@material-ui/data-grid";
import { Autocomplete } from '@material-ui/lab';
import countryList from '../../Utils/country-list';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelText: string,
  hideAction?: boolean,
  hideAdd?: boolean,
  hideDelete?: boolean,
  disabledAdd?: boolean,
  disabledDelete?: boolean,
  itemId: string,
  childNum: number
};

let dialogOpen = false;
let queryCompanyId = '';
const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "3px 0 3px 0",
      margin: 0,
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label, &$selected:focus > $content $label': {
        backgroundColor: 'unset',
      },
      '&:focus > $content $label:hover, &:hover > $content $label:hover, &$selected > $content $label:hover': {
        backgroundColor: 'unset',
      },
    },
    content: {
      width: '',
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      '& $content': {
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: "7px 0px",
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      fontSize: "1.2rem",
      whiteSpace: "nowrap"
    },
    rightBtnLay: {
      display: "flex",
      flexBasis: '0%',
    },
    iconContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexGrow: 1,
      width: "70%",
      overflow: "hidden"
    },
  }),
);

interface PageState {
  dialogOpen: boolean;
  dialogId: string;
  editRow: Company;
  editMode: FormMode;
  errors: ValidationError[];
  licenses: any;
}
const initCompany: Company = {
  id: '',
  name: '',
  contactFirstName: '',
  contactLastName: '',
  phone: '',
  emailAddress: '',
  address: '',
  expireDate: new Date() || null,
  isFreeTrial: undefined,
  parentCompanyId: undefined,
  parentCompanyName: undefined,
  licenseId: undefined,
  childCompanies: [],
  isHQ: undefined,
  isExpire: undefined,
  licenseKey: undefined,
  newExpire: undefined,
  isNew: true,
  language: undefined,
}

interface CompanyTreeState {
  companyTree: Company,
  companyLicense: Array<Company>,
  companyList: Array<Company>,
  pageState: PageState
}

const initState: PageState = {
  dialogOpen: false,
  dialogId: Guid.create().toString(),
  editMode: FormMode.Add,
  editRow: initCompany,
  errors: [],
  licenses: undefined
};

const initCompanyTreeState: CompanyTreeState = {
  companyTree: initCompany,
  companyLicense: [],
  pageState: initState,
  companyList: []
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '83vh',
    marginTop: '20px',
  },
  tree: {},
  left: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: "0px !important"
  },

  right: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: "1px solid #eeeeee",
    paddingTop: "0px !important"
  },
  expireItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  picker: {
    marginTop: "0px",
    marginBottom: "0px"
  },
  gridRoot: {
    fontSize: 16,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      textOverflow: 'initial',
      overflowX: 'auto'
    },
    '& .MuiDataGrid-columnHeaderWrapper': {
      background: '#F8F9FB'
    },
  },
  pagnation: {
    border: 'none',
    '& .MuiSelect-select': {
      border: 'none',
    },
    '& .MuiTablePagination-selectRoot': {
      '& .MuiSelect-icon': {
        marginRight: '0px'
      }
    }
  },
  autoText: {
    '& .MuiInputBase-input': {
      padding: '17px 14px !important'
    },
  },
  input: {
    display: 'none',
  },
}));


interface queryIns {
  companyId?: string
}
export default function CompanyStructurePage() {
  const { t } = useTranslation();
  const { companyStore, appStore } = useStores();
  const [state, setState] = useState<CompanyTreeState>(initCompanyTreeState);
  const [expanded, setExpanded] = useState(['']);
  const { id, isSuperAdmin, companyId, isHQ, hasLicense, hasActionPermission, haveExpireCompany, orgCompanyId, isEnterprise } = appStore.userAuth as AuthenticateResponseModel;
  const confirm = useConfirm();
  const classes = useStyles();
  const classesTree = useTreeItemStyles();
  const location = useLocation<queryIns>();
  let paramsCompanyId = location?.state?.companyId;

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: t('name'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={company.name} placement="bottom-start" arrow>
          <span>{company.name}</span>
        </LightTooltip>;
      })
    },
    {
      field: "logoFilePath", headerName: t("company_logo"), width: 170, sortable: false,
      renderCell: ((row: GridCellParams) => {
        const report: Company = row.row as Company;
        return <Box display="flex" style={{ alignItems: "center", justifyContent: "center" }}>
          <Box minWidth={30}>
            <Avatar alt="Remy Sharp" src={report.logoFilePath} />
          </Box>
        </Box>
      })
    },
    {
      field: 'contactFirstName', headerName: t('contact'), width: 200,
      renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={`${company.contactFirstName} ${company.contactLastName}`}
          placement="bottom-start" arrow>
          <span>{`${company.contactFirstName} ${company.contactLastName}`}</span>
        </LightTooltip>;
      })
    },
    {
      field: 'phone', headerName: t('phone'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={company.phone} placement="bottom-start" arrow>
          <span>{company.phone}</span>
        </LightTooltip>;
      })
    },
    {
      field: 'emailAddress', headerName: t('email_address'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={company.emailAddress} placement="bottom-start" arrow>
          <span>{company.emailAddress}</span>
        </LightTooltip>;
      })
    },
    {
      field: 'country', headerName: t('country'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        var defaultValue = company.country ? countryList.find(x => x.countryCode === company.country) : { countryCode: "", countryName: "" };
        return <LightTooltip title={defaultValue?.countryName ?? ""} placement="bottom-start" arrow>
          <span>{defaultValue?.countryName ?? ""}</span>
        </LightTooltip>;
      })
    },
    {
      field: 'state', headerName: t('state'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={company?.state ?? ""} placement="bottom-start" arrow>
          <span>{company?.state ?? ""}</span>
        </LightTooltip>;
      })
    },
    {
      field: 'address', headerName: t('address'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={company.address} placement="bottom-start" arrow>
          <span>{company.address}</span>
        </LightTooltip>;
      })
    },
    {
      field: 'expireDate', headerName: t('expire_date'), width: 200, renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <LightTooltip title={company.expireDate ? moment(company.expireDate).format("YYYY-MM-DD") : ''}
          style={company.isExpire ? { color: "red" } : {}}
          placement="bottom-start" arrow>
          <span>{company.expireDate ? moment(company.expireDate).format("YYYY-MM-DD") : ''}</span>
        </LightTooltip>;
      })
    },
  ];

  if (isHQ && (hasActionPermission || (!hasActionPermission && orgCompanyId === companyId))) {
    columns.push({
      field: 'action', headerName: t('actions'), width: 150, editable: false, sortable: false,
      renderCell: ((row: GridCellParams) => {
        const company: Company = row.row as Company;
        return <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleEdit(company);
              event.stopPropagation();
            }}
          >{t('edit')}</Link>
        </Box>;
      })
    });
  }

  useEffect(() => {
    getStructure().then((values: any) => {
      dialogOpen = false
      setState({
        ...state,
        companyTree: values[0].topCompany,
        companyList: values[0].companyList,
        companyLicense: values[1],
        pageState: {
          ...state.pageState,
          dialogOpen: false
        }
      });

      if (!isSuperAdmin) {
        companyStore.getCompanyStructure(orgCompanyId).then((data: any) => {
          appStore.setCompanyTree(data.topCompany);
        });
      }
    })
  }, []);

  //get structure
  const getStructure = () => {
    let cid = paramsCompanyId ? paramsCompanyId : (isSuperAdmin ? id : companyId);
    queryCompanyId = cid;
    setExpanded(expanded.length > 1 ? expanded : ([cid]));
    return Promise.all([
      companyStore.getCompanyStructure(cid),
      companyStore.getCompanyLicenses(cid)
    ]);
  };

  // getLicense
  const getLicense = (companyId: string) => {
    if (dialogOpen) {
      return;
    }
    if (!appStore.userAuth) {
      return;
    }
    queryCompanyId = companyId;
    companyStore.getCompanyLicenses(companyId).then((data) => {
      setState({
        ...state,
        companyLicense: data
      });

    });
  };

  const setStatePageState = (item: Object) => {
    let pg = { ...state.pageState, ...item };
    setState({
      ...state,
      pageState: pg
    })

  }
  const handleCreate = (disabled: boolean, itemId: string, pCompanyName: string) => {
    if (disabled || !itemId) {
      return;
    }

    dialogOpen = true;
    setStatePageState({
      dialogOpen: true,
      editMode: FormMode.Add,
      editRow: {
        ...initCompany,
        id: Guid.create().toString(),
        IsHQ: isSuperAdmin ? true : undefined,
        parentCompanyId: itemId,
        parentCompanyName: pCompanyName,
      }
    })

  }

  const handleEdit = (model: Company) => {
    model.newExpire = model.expireDate;
    setStatePageState({
      dialogOpen: true,
      editRow: model,
      editMode: FormMode.Edit,
    });
  }

  const getUnuseLicense = () => {
    if (state.pageState.licenses === undefined || state.pageState.licenses === null) {
      companyStore.getCompanyUnuseLicense(orgCompanyId).then(x => {
        setStatePageState({
          licenses: x
        })
      });
    }
  }

  const handleDelete = (disabled: boolean, itemId: string, pCompanyName: string) => {
    if (disabled) {
      return;
    }
    confirm({
      title: t('warning'),
      description: t('delete_company_tip'),
    }).then(() => {
      companyStore.delete(itemId).then(data => {
        getStructure().then((values: any) => {
          dialogOpen = false
          setState({
            ...state,
            companyTree: values[0].topCompany,
            companyList: values[0].companyList,
            companyLicense: values[1],
            pageState: {
              ...state.pageState,
              dialogOpen: false
            }
          });

          companyStore.getCompanyStructure(orgCompanyId).then((data: any) => {
            appStore.setCompanyTree(data.topCompany);
          });
        })
      });
    })
  }

  const handleUploadFile = (event: any) => {
    const file = event.target.files[0];

    const allowTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
    if (!file || allowTypes.indexOf(file.type) < 0) {
      if (!/(docx)$/.test(file.name)) {
        appStore.error = `${t('not_allowed_file_type')}`;
        appStore.showError = true;
        return;
      }
    }
    let maxSize = 1024 * 1000 * 10;
    if (file.size > maxSize) {
      appStore.error = `${t('max_allowed_size_is')}:${maxSize / 1000 / 1024} mb`;
      appStore.showError = true;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      const base64data = reader.result;
      setStatePageState({
        ...state,
        editRow: { ...state.pageState.editRow, logoFileName: file.name, logoFileContent: base64data?.toString() },
        errors: [...state.pageState.errors].filter(x => x.field !== "logoFileContent")
      });
    }
  }

  const handleSubmit = (result: boolean) => {
    if (result) {
      dialogOpen = false;
      getStructure().then((values: any) => {
        if (state.pageState.editRow.id === appStore.userAuth?.companyId && state.pageState.editRow.name !== appStore.userAuth.companyName) {
          appStore.setCompanyName(state.pageState.editRow.name);
        }

        companyStore.getCompanyStructure(orgCompanyId).then((data: any) => {
          appStore.setCompanyTree(data.topCompany);
        });

        if (state.pageState.editRow.licenseKey) {
          companyStore.getCompanyUnuseLicense(orgCompanyId).then(x => {
            dialogOpen = false
            setState({
              ...state,
              companyTree: values[0].topCompany,
              companyList: values[0].companyList,
              companyLicense: values[1],
              pageState: {
                ...state.pageState,
                dialogOpen: false,
                dialogId: Guid.create().toString(),
                errors: [],
                licenses: x
              }
            });
          });
        } else {
          dialogOpen = false
          setState({
            ...state,
            companyTree: values[0].topCompany,
            companyList: values[0].companyList,
            companyLicense: values[1],
            pageState: {
              ...state.pageState,
              dialogOpen: false,
              dialogId: Guid.create().toString(),
              errors: []
            }
          });
        }
      });
    }
  }
  const handleValidate = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const valdiationErrors: ValidationError[] = [];

      if (!state.pageState.editRow.parentCompanyId || state.pageState.editRow.parentCompanyId.trim().length === 0) {
        valdiationErrors.push({
          field: 'parentCompanyId',
          message: t("paren_company_name_required")
        });
      }

      if (state.pageState.editRow.name.trim().length === 0) {
        valdiationErrors.push({
          field: 'name',
          message: t('company_name_required')
        });
      }
      if (state.pageState.editRow.name.trim().length > 100) {
        valdiationErrors.push({
          field: 'name',
          message: t('company_name_max_length')
        });
      }

      if (state.pageState.editRow.address.trim().length > 255) {
        valdiationErrors.push({
          field: 'address',
          message: t('address_max_length')
        });
      }

      if (state.pageState.editRow.contactFirstName.trim().length === 0 || state.pageState.editRow.contactLastName.trim().length === 0) {
        valdiationErrors.push({
          field: 'contactFirstName',
          message: t('contact_person_required')
        });
        valdiationErrors.push({
          field: 'contactLastName',
          message: t('contact_person_required')
        });
      } else if (state.pageState.editRow.contactFirstName.trim().length > 100 || state.pageState.editRow.contactLastName.trim().length > 100) {
        valdiationErrors.push({
          field: 'contactFirstName',
          message: t('contact_person_max_lenth')
        });
        valdiationErrors.push({
          field: 'contactLastName',
          message: t('contact_person_max_lenth')
        });
      }
      if (state.pageState.editRow.emailAddress.trim().length === 0) {
        valdiationErrors.push({
          field: 'emailAddress',
          message: t('email_address_required')
        });
      } else if (!isValidEmail(state.pageState.editRow.emailAddress)) {
        valdiationErrors.push({
          field: 'emailAddress',
          message: t('email_format_error')
        });
      } else if (state.pageState.editRow.emailAddress.trim().length > 50) {
        valdiationErrors.push({
          field: 'emailAddress',
          message: t('email_address_max_length')
        });
      }


      if (valdiationErrors.length === 0) {
        resolve(true);
      } else {
        reject();
        setStatePageState({ errors: valdiationErrors })
      }
    })
  };

  // tree
  const handTest = (e: SyntheticEvent) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }

  const onNodeToggle = (event: any, expands: Array<string>) => {
    const expandingNodes = expands.filter(i => !expanded.includes(i));

    setExpanded(expands)
    if (expandingNodes && expandingNodes.length > 0 && event.target && event.target.nodeName === "P")
      getLicense(expandingNodes[0]);
  }

  const onIconClick = (e: any, itemId: string, childNum: number) => {
    if (e.target && e.target.nodeName === "P") {
      if (itemId && childNum <= 0) {
        getLicense(itemId);
      }
      console.log(itemId, childNum)
    }
  }
  const handTreeSelect = (event: object, value: string) => {
  }

  const handleSortModelChange = (newModelParams: GridSortModelParams) => {
    console.log(newModelParams)
    if (newModelParams.sortModel.length) {
      const { field, sort } = newModelParams.sortModel[0];
      companyStore.setSort(field, sort === 'desc' ? SortDirection.Descending : SortDirection.Ascending);
      getLicense(queryCompanyId)
    } else {
      companyStore.setSort("createdTime", SortDirection.Descending);
      getLicense(queryCompanyId)
    }
  };

  function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { itemId, labelText, childNum, labelIcon: LabelIcon, hideAction, hideDelete, hideAdd, disabledAdd, disabledDelete, color, bgColor, ...other } = props;

    return (
      <TreeItem
        onLabelClick={e => handTest(e)}
        onIconClick={(e: SyntheticEvent) => onIconClick(e, itemId, childNum)}
        label={
          hideAction ? '' : (
            <Grid container spacing={0} style={{ justifyContent: 'flex-end' }}>
              <Grid item xs={6} className={classes.rightBtnLay}>
                {hideAdd ? '' : (
                  <AddIcon color={disabledAdd ? 'disabled' : 'primary'} onClick={() => handleCreate(disabledAdd || false, itemId, labelText)} />
                )}
              </Grid>
              <Grid item xs={6} className={classes.rightBtnLay}>
                {hideDelete ? '' : (
                  <DeleteForever color={disabledDelete ? 'disabled' : 'error'} style={{ marginLeft: '0px' }}
                    onClick={() => handleDelete(disabledDelete || false, itemId, labelText)} />
                )}
              </Grid>
            </Grid>
          )

        }
        icon={
          <div className={classes.labelRoot}>
            {childNum && childNum > 0 ? (expanded.includes(itemId) ? <ArrowDropDownIcon /> : <ArrowRightIcon />) : <div style={{ width: '18px' }}></div>}
            {LabelIcon ? <LabelIcon color="inherit" className={classes.labelIcon} /> : <BusinessRounded className={classes.labelIcon} />}
            <LightTooltip title={labelText} placement="bottom-start" arrow>
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
            </LightTooltip>
          </div>
        }

        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
          iconContainer: classes.iconContainer
        }}
        {...other}
      />
    );
  }


  function treeItem(children: Array<Company>, startIndex: any) {
    return (
      children
        ? children.map((item, index) => (
          <StyledTreeItem hideAction={isSuperAdmin || (!hasActionPermission && companyId !== orgCompanyId)}
            onLabelClick={(e) => handTest(e)}
            disabledDelete={isSuperAdmin} hideDelete={item.childCompanies && item.childCompanies.length > 0}
            childNum={item.childCompanies && item.childCompanies.length > 0 ? item.childCompanies.length : 0}
            key={item.id} itemId={item.id} nodeId={item.id} labelText={item.name}>
            {item.childCompanies ? treeItem(item.childCompanies, 0) : ''}
          </StyledTreeItem>
        ))
        : '')

  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {

  };

  return (
    <>
      {/* title */}
      <Grid container style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h5"
          style={{ fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16 }}>
          {t('companies_structure')}
        </Typography>
        {(isHQ && haveExpireCompany) && !isSuperAdmin &&
          <Typography variant="body2" style={{ fontWeight: 500, fontSize: 14, backgroundColor: "rgb(247,215,219)", color: "rgb(152,65,75)", padding: "10px 20px" }}>
            {t("license_expired_in_your_organization")}
          </Typography>
        }

        {!hasLicense && !isSuperAdmin &&
          <Typography variant="body2" style={{ fontWeight: 500, fontSize: 14, backgroundColor: "rgb(247,215,219)", color: "rgb(152,65,75)", padding: "10px 20px" }}>
            {t("your_company_license_has_been_expired")}
          </Typography>
        }
      </Grid>
      {/* container */}
      <Grid container className={classes.container} spacing={5}>
        {/* left tree */}
        <Grid item lg={3} className={classes.left}>
          {/* tree view */}
          {state.companyTree.id ?
            <TreeView
              onNodeSelect={handTreeSelect}
              onNodeToggle={onNodeToggle}
              expanded={expanded}
              className={classesTree.root}
              defaultEndIcon={<div style={{ width: 30 }} />}
            >
              <StyledTreeItem hideAction={isSuperAdmin || (!hasActionPermission && companyId !== orgCompanyId)}
                childNum={state.companyTree.childCompanies && state.companyTree.childCompanies.length > 0 ? state.companyTree.childCompanies.length : 0}
                itemId={state.companyTree.id} nodeId={state.companyTree.id} labelText={state.companyTree.name} hideDelete >
                {state.companyTree.childCompanies ? treeItem(state.companyTree.childCompanies, 1) : ''}
              </StyledTreeItem>
            </TreeView>
            : ''}

          {/* dialog create */}
          <CommonFormDialog<Company>
            key={state.pageState.dialogId}
            open={state.pageState.dialogOpen}
            row={state.pageState.editRow}
            mode={state.pageState.editMode}
            store={companyStore}
            title={t("company")}
            onClose={() => { dialogOpen = false; setStatePageState({ dialogOpen: false, dialogId: Guid.create().toString(), errors: [] }) }}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {state.pageState.editMode === FormMode.Add &&
                  <TextField
                    fullWidth
                    disabled
                    required={true}
                    error={state.pageState.errors.findIndex(x => x.field === "parentCompanyId") > -1}
                    helperText={state.pageState.errors.find(x => x.field === "parentCompanyId")?.message || ''}
                    label={t("parent_company_name")}
                    value={state.pageState.editRow.parentCompanyName}
                    onChange={(event) =>
                      setStatePageState({
                        editRow: { ...state.pageState.editRow, parentCompanyName: event.target.value },
                        errors: [...state.pageState.errors].filter(x => x.field !== "parentCompanyId")
                      })
                    }
                    placeholder={t("parent_company_name")}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                }

                {state.pageState.editMode === FormMode.Edit &&
                  state.pageState.editRow.parentCompanyId &&
                  state.pageState.editRow.parentCompanyId !== "00000000-0000-0000-0000-000000000000" &&
                  <FormControl fullWidth>
                    <InputLabel id="lblRole" shrink>{t("parent_company_name")}:</InputLabel>
                    <Select fullWidth
                      onChange={(event) =>
                        setStatePageState({
                          editRow: { ...state.pageState.editRow, parentCompanyId: event.target.value },
                          errors: [...state.pageState.errors].filter(x => x.field !== "parentCompanyId")
                        })}
                      value={state.pageState.editRow.parentCompanyId}
                      displayEmpty
                      disableUnderline>
                      {state.companyList.filter(x => x.id !== state.pageState.editRow.id).map(item =>
                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                      )}

                    </Select>
                  </FormControl>
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={true}
                  error={state.pageState.errors.findIndex(x => x.field === "name") > -1}
                  helperText={state.pageState.errors.find(x => x.field === "name")?.message || ''}
                  label={t('company_name')}
                  value={state.pageState.editRow.name}
                  onChange={(event) =>
                    setStatePageState({
                      editRow: { ...state.pageState.editRow, name: event.target.value.substring(0, 100) },
                      errors: [...state.pageState.errors].filter(x => x.field !== "name")
                    })
                  }
                  placeholder={t('company_name')}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true, }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={true}
                  value={state.pageState.editRow.contactFirstName}
                  onChange={(event) => setStatePageState({
                    editRow: { ...state.pageState.editRow, contactFirstName: event.target.value.substring(0, 100) },
                    errors: [...state.pageState.errors].filter(x => x.field !== "contactFirstName")
                  })}
                  placeholder={t('contact_first_name')}
                  label={t('contact_first_name')}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  error={state.pageState.errors.findIndex(x => x.field === "contactFirstName") > -1}
                  helperText={state.pageState.errors.find(x => x.field === "contactFirstName")?.message || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={true}
                  value={state.pageState.editRow.contactLastName}
                  onChange={(event) => setStatePageState({
                    editRow: { ...state.pageState.editRow, contactLastName: event.target.value.substring(0, 100) },
                    errors: [...state.pageState.errors].filter(x => x.field !== "contactLastName")
                  })}
                  placeholder={t('contact_last_name')}
                  label={t('contact_last_name')}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  error={state.pageState.errors.findIndex(x => x.field === "contactLastName") > -1}
                  helperText={state.pageState.errors.find(x => x.field === "contactLastName")?.message || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  fullWidth
                  defaultCountry={'us'}
                  value={state.pageState.editRow.phone}
                  onChange={(value) => setStatePageState({ editRow: { ...state.pageState.editRow, phone: value.substring(0, 20) } })}
                  placeholder={t("phone")}
                  label={t("phone")}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required={true}
                  value={state.pageState.editRow.emailAddress}
                  disabled={state.pageState.editMode === FormMode.Edit}
                  onChange={(event) => setStatePageState({
                    editRow: { ...state.pageState.editRow, emailAddress: event.target.value.substring(0, 50) },
                    errors: [...state.pageState.errors].filter(x => x.field !== "emailAddress")
                  })}
                  placeholder={t("email_address")}
                  label={t("email_address")}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  error={state.pageState.errors.findIndex(x => x.field === "emailAddress") > -1}
                  helperText={state.pageState.errors.find(x => x.field === "emailAddress")?.message || ''}
                />
              </Grid>

              <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid xs={5}>
                  <Typography style={{
                    fontSize: "0.8rem !important",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}>{t('country')}</Typography>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    className={classes.autoText}
                    onChange={(event, newValue: any) => {
                      if (newValue) {
                        setStatePageState({
                          editRow: {
                            ...state.pageState.editRow,
                            country: newValue.countryCode,
                            dialogOpen: true,
                          },
                        })
                      } else {
                        setStatePageState({
                          editRow: {
                            ...state.pageState.editRow,
                            country: "",
                            dialogOpen: true,
                          },
                        })
                      }
                    }}
                    getOptionLabel={(option: any) => {
                      return option?.countryName ?? "";
                    }}
                    defaultValue={state.pageState.editRow.country ? countryList.find(x => x.countryCode === state.pageState.editRow.country) : {}}
                    options={(countryList && countryList.length > 0) ? countryList : []}
                    renderInput={(params) =>
                      <TextField {...params}
                        style={{ padding: "17px 14px !important" }}
                        placeholder={t('country')}
                        value={state.pageState.editRow.country}
                        onChange={(event) => setStatePageState({
                          editRow: {
                            ...state.pageState.editRow,
                            country: event.target.value,
                            dialogOpen: true,
                          },
                        })}
                        InputLabelProps={{ shrink: true }}
                      />}
                  />
                </Grid>
                <Grid xs={5}>
                  <TextField
                    fullWidth
                    value={state.pageState.editRow.state}
                    onChange={(event) => setStatePageState({
                      ...state,
                      editRow: { ...state.pageState.editRow, state: event.target.value.substring(0, 50) },
                      errors: [...state.pageState.errors].filter(x => x.field !== "state")
                    })}
                    placeholder={t("state")}
                    label={t("state")}
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ shrink: true }}
                    error={state.pageState.errors.findIndex(x => x.field === "state") > -1}
                    helperText={state.pageState.errors.find(x => x.field === "state")?.message || ''}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={state.pageState.editRow.address}
                  onChange={(event) => setStatePageState({
                    editRow: { ...state.pageState.editRow, address: event.target.value.substring(0, 255) },
                    errors: [...state.pageState.errors].filter(x => x.field !== "address")
                  })}
                  placeholder={t("address")}
                  label={t("address")}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  error={state.pageState.errors.findIndex(x => x.field === "address") > -1}
                  helperText={state.pageState.errors.find(x => x.field === "address")?.message || ''}
                />
              </Grid>
              {!isEnterprise &&
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Grid xs={12}>
                    <Typography style={{
                      fontSize: "0.8rem !important",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}>{t("apply_license")}</Typography>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      className={classes.autoText}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          var newDate = new Date();
                          if (state.pageState.editRow.expireDate && new Date(state.pageState.editRow.expireDate) > newDate) {
                            newDate = moment(state.pageState.editRow.expireDate).add('day', newValue?.days).toDate();
                          } else {
                            newDate = moment(newDate).add('day', newValue?.days).toDate();
                          }

                          setStatePageState({
                            editRow: {
                              ...state.pageState.editRow,
                              licenseKey: newValue.license_key,
                              dialogOpen: true,
                              newExpire: newDate
                            },
                          })
                        } else {
                          setStatePageState({
                            editRow: {
                              ...state.pageState.editRow,
                              licenseKey: "",
                              dialogOpen: true,
                              newExpire: state.pageState.editRow.expireDate
                            },
                          })
                        }
                      }}
                      getOptionLabel={(option: any) => {
                        return option?.displayName ?? "";
                      }}
                      options={(state.pageState.licenses && state.pageState.licenses.length > 0) ? state.pageState.licenses : []}
                      renderInput={(params) =>
                        <TextField {...params}
                          onFocus={getUnuseLicense}
                          style={{ padding: "17px 14px !important" }}
                          placeholder={t("apply_license")}
                          value={state.pageState.editRow.licenseKey}
                          onChange={(event) => setStatePageState({
                            editRow: {
                              ...state.pageState.editRow,
                              licenseKey: event.target.value,
                              dialogOpen: true,
                            },
                          })}
                          InputLabelProps={{ shrink: true }}
                        />}
                    />
                  </ Grid>
                </Grid>
              }
              {!isEnterprise &&
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Grid xs={4} style={{ alignSelf: "flex-end" }}>
                    <Typography style={{
                      fontSize: "0.8rem !important",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}>{t('expire_date')}</Typography>
                    <TextField
                      fullWidth
                      disabled={true}
                      value={moment(state.pageState.editRow.newExpire).format("YYYY-MM-DD")}
                    />
                  </ Grid>
                </Grid>
              }
              { state.pageState.editRow.isNew &&  
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        value={state.pageState.editRow.language}
                        onChange={(event) => setStatePageState({
                            ...state.pageState,
                            editRow: { ...state.pageState.editRow, language: event.target.value },
                            errors: [...state.pageState.errors].filter(x => x.field !== "language")
                        })}
                        placeholder={t('language')}
                        label={t('language')}
                        InputProps={{ disableUnderline: true }}
                        defaultValue='english'
                        InputLabelProps={{ shrink: true }}
                        error={state.pageState.errors.findIndex(x => x.field === "language") > -1}
                        helperText={state.pageState.errors.find(x => x.field === "language")?.message || ''}
                    >
                        <MenuItem value='english'>{t('english')}</MenuItem>
                        <MenuItem value='italy'>{t('italian')}</MenuItem>
                        <MenuItem value='spanish'>{t('spanish')}</MenuItem>
                    </TextField>
                </Grid>
                }
              <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end" }}>
                <Grid>
                  <InputLabel id="lblFile" shrink>{t('company_logo')}</InputLabel>
                  <>
                    <input
                      accept="image/png,image/jpg,image/jpeg,image/gif"
                      className={classes.input}
                      id="contained-button-fi1le"
                      type="file"
                      value={''}
                      onChange={handleUploadFile}
                    />
                    <label htmlFor="contained-button-fi1le">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<AddIcon />}
                      >
                        {t('browse')}
                      </Button>
                    </label>
                  </>
                  <FormHelperText>{state.pageState.errors.find(x => x.field === "logoFileContent")?.message || ''}</FormHelperText>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: "15px" }}>
                  <Typography
                    style={{ color: "#374359", marginTop: 2, marginBottom: 2, fontSize: 12, lineHeight: "15px" }}
                  >
                    {t('file_name')}: {state.pageState.editRow.logoFileName ? state.pageState.editRow.logoFileName : ''}
                  </Typography>
                  <Typography
                    style={{ color: "#9094A9", fontSize: 12, lineHeight: "15px" }}
                  >
                    {t('file_formats')}: *.png,*.jpg,*.jpeg,*.gif
                    <br />
                    {t('max_file_size')}: 10M
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CommonFormDialog>
        </Grid>

        {/* right table */}
        <Grid item lg={9} className={classes.right}>
          <DataGrid
            disableSelectionOnClick
            isCellEditable={(params: GridCellParams) => false}
            scrollbarSize={15}
            className={classes.gridRoot}
            nonce={t("no_data")}
            rows={state.companyLicense.map(x => x)}
            sortingMode="server"
            columns={columns}
            onSortModelChange={handleSortModelChange}
            components={{
              Pagination: function () {
                return <TablePagination
                  className={classes.pagnation}
                  count={state.companyLicense.length}
                  page={0}
                  onPageChange={handleChangePage}
                  labelRowsPerPage={t("rows_per_page")}
                  rowsPerPage={state.companyLicense.length}
                />
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}