import React, { useEffect, useRef, useState } from "react";
import { GridColDef } from "@material-ui/data-grid";
import { observer } from "mobx-react";
import { TaskAssignmentReport } from "../../Models/Reports";
import CommonGridPage from "../../Components/CommonGridPage";
import { Guid } from "guid-typescript";
import useStores from "../../Stores/useStores";
import ReportHeader, {
  ReportHeaderState,
  ReportHeaderProps,
} from "../Reports/Comments/ReportHeader";
import { excelDownload, funCSVDownload } from "../../Utils/utils";
import { Parser } from "json2csv";
import moment from "moment";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import ReactEcharts from "echarts-for-react";
import { Paper, Grid, Box, Tabs, Tab, Typography } from "@material-ui/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface PageState {
  gridId: string;
}

const initState: PageState = {
  gridId: Guid.create().toString(),
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const taskAssignmentBizReport = observer(() => {
  const { taskAssignmentBizStore, appStore } = useStores();
  const [dailyChartOption, setDailyChartOption] = useState<any>({});
  const [weeklyChartOption, setWeeklyChartOption] = useState<any>({});
  const [monthlyChartOption, setMonthlyChartOption] = useState<any>({});
  const componentRef = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    const preFilters = [
      {
        field: "filterOrgCompanyId",
        value: orgCompanyId,
      },
      {
        field: "filterToDate",
        value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ];
    taskAssignmentBizStore.setPreFilter([...preFilters]);
    loadChart();
  }, []);
  const [state] = useState<PageState>(initState);
  const handleDownload = (filters: ReportHeaderState) => {
    const fields = [`${t('employee')}`, `${t('daily')} ${t("task")}`, `${t('weekly')} ${t("task")}`, `${t('monthly')} ${t("task")}`];
    taskAssignmentBizStore.excel(fields)
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('corrective_action_reports'));
                }
            });
    // taskAssignmentBizStore.queryCSV().then((data: any) => {
    //   const fields = [`${t('company')}`, `${t('daily')} ${t("task")}`, `${t('weekly')} ${t("task")}`, `${t('monthly')} ${t("task")}`];
    //   const csvData: any = [];
    //   if (data.count > 0 && data.data && data.data.length) {
    //     data.data.forEach((item: any) => {
    //       const rowData: any = {};
    //       rowData[`${t('company')}`] = item.role;
    //       rowData[`${t('daily')} ${t("task")}`] = item.daily;
    //       rowData[`${t('weekly')} ${t("task")}`] = item.weekly;
    //       rowData[`${t('monthly')} ${t("task")}`] = item.monthly;
    //       csvData.push(rowData);
    //     });
    //     const json2csvParser = new Parser({ fields });
    //     const csv = json2csvParser.parse(csvData);
    //     funCSVDownload(csv, `${t("companies_task_assignment_report")}.csv`);
    //   } else {
    //     appStore.error = t("there_is_no_data_to_download");
    //     appStore.showError = true;
    //   }
    // });
  };
  const handlePrint = (filters: ReportHeaderState) => {
    // TODO
  };

  const handleFilterChange = (filters: ReportHeaderState) => {
    const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    taskAssignmentBizStore.setPreFilter([
      {
        field: "filterOrgCompanyId",
        value: orgCompanyId,
      },
      {
        field: "filterFromDate",
        value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        field: "filterToDate",
        value: moment(filters.toDate).format("YYYY-MM-DD HH:mm:ss"),
      },
    ]);
    taskAssignmentBizStore.query();
    loadChart();
  };

  const reportHeaderProps: ReportHeaderProps = {
    title: t("companies_task_assignment_report"),
    description: t("generate_task_assignment_report_to_see_how_many_tasks_are_assigned"),
    showFromDate: true,
    showToDate: true,
    showDepartment: false,
    showEmployee: false,
    showWeekPicker: false,
    showDownload: true,
    showPrint: false,
    handleChange: handleFilterChange,
    handleDownload: handleDownload,
    handlePrint: handlePrint,
  };

  const columns: GridColDef[] = [
    {
      field: "role",
      headerName: t("company"),
      flex: 1,
      sortable: true,
    },
    {
      field: "daily",
      headerName: t("daily_task"),
      flex: 1,
      sortable: false,
    },
    {
      field: "weekly",
      headerName: t("weekly_task"),
      flex: 1,
      sortable: false,
    },
    {
      field: "monthly",
      headerName: t("monthly_task"),
      flex: 1,
      sortable: false,
    },
  ];

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: "rgba(0,0,0,0.3)",
    },
  };

  const genChartOption = (
    companies: Array<string>,
    assigned: Array<number>,
    unassigned: Array<number>
  ) => {
    return {
      legend: {
        data: [t("assigned"), t("unassigned")],
        left: "10%",
      },
      toolbox: {
        feature: {
          magicType: {
            type: ["stack"],
          },
          dataView: {},
        },
      },
      tooltip: {},
      xAxis: {
        data: companies,
        name: t("companies"),
        axisLine: { onZero: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {},
      grid: {
        bottom: 100,
      },
      series: [
        {
          name: t("assigned"),
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: assigned,
          barWidth: 25,
        },
        {
          name: t("unassigned"),
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: unassigned,
          barWidth: 25,
        },
      ],
    };
  };

  const loadChart = () => {
    taskAssignmentBizStore.queryCSV().then((resp: any) => {
      const { data } = resp;
      const rows = data.filter((d: any) => d.role !== "Total");
      const assignedRows = rows.filter(
        (r: any) => !_.endsWith(r.role, " Unassigned")
      );
      const unassignedRows = rows.filter((r: any) =>
        _.endsWith(r.role, " Unassigned")
      );
      const companies = assignedRows.map((r: any) => r.role);
      ["daily", "weekly", "monthly"].forEach((t) => {
        const assigned = assignedRows.map((r: any) => (r as any)[t]);
        const unassigned = unassignedRows.map((r: any) => (r as any)[t]);
        const option = genChartOption(companies, assigned, unassigned);
        if (t === "daily") {
          setDailyChartOption(option);
          return;
        }
        if (t === "weekly") {
          setWeeklyChartOption(option);
          return;
        }
        if (t === "monthly") {
          setMonthlyChartOption(option);
          return;
        }
      });
    });
  };

  return (
    <>
      <ReportHeader {...reportHeaderProps} />
      <div
        ref={componentRef}
        className="section-to-print"
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Box sx={{ width: "100%" }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                  <Tab value={0} label={t("daily_task")} />
                  <Tab value={1} label={t("weekly_task")} />
                  <Tab value={2} label={t("monthly_task")} />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <ReactEcharts option={dailyChartOption} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ReactEcharts option={weeklyChartOption} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ReactEcharts option={monthlyChartOption} />
              </TabPanel>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <CommonGridPage<TaskAssignmentReport>
              key={state.gridId}
              columns={columns}
              cancelSearch={true}
              useAction={false}
              store={taskAssignmentBizStore}
              rowHeight={80}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default taskAssignmentBizReport;
