
import { extendObservable } from "mobx";
import { CompanyShareLink } from "../Models/CompanyShareLink";
import { QueryCriteria } from "../Models/QueryCriteria";
import { CompanyShareLinkService } from "../Services/CompanyShareLinkService";
import LanguagesModels from "../Utils/languages";
import { AppStore } from "./AppStore";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CompanyShareLinkStore extends BaseGridDataStore<CompanyShareLink>{
    private _companyShareLinkService: CompanyShareLinkService;
    private _appStore: AppStore;
    language?: string;

    constructor(appStore: AppStore) {
        let companyShareLinkService = new CompanyShareLinkService();
        super(companyShareLinkService);
        this._companyShareLinkService = companyShareLinkService;
        this._appStore = appStore;
        this.language = localStorage.getItem('lang') ?? LanguagesModels[0].key;
        extendObservable(this, {
        });
    }

    setLanguage(language: any) {
        return new Promise((resolve, reject) => {
            this.language = language;
            resolve(language);
        })
    }

    override query() {
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }

        if (this._appStore.userAuth?.isSuperAdmin) {
            criteria.filters.push({
                field: 'Language',
                value: this.language ?? LanguagesModels[0].key
            });
        }

        this._serivce.queryAsync(criteria).then(x => {
            this.models = x.data;
            this.count = x.count;
            this.pages = ((x.count + this.pageSize - 1) / this.pageSize);
        })
    }

    queryByCode(code: string) {
        return this._companyShareLinkService.queryByCode(code);
    }
}