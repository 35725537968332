import axios from "axios";
import { Employee } from "../Models/Employee";
import { BaseService } from "./BaseService";

export class EmployeeService extends BaseService<Employee> {
    constructor() {
        super('employees');
    }

    updateStatusAsync =  async(employeeId: string, status?: boolean, canLoginWeb?: boolean, canLoginApp?: boolean): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/employees/updateStatus/${employeeId}`;
        const response = await axios.post<boolean>(url, {
            status: status,
            canLoginWeb: canLoginWeb,
            canLoginApp: canLoginApp
        });
        return response.data;
    }

    updateManagerAsync = async(employeeId: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/employees/updateManager/${employeeId}`;
        const response = await axios.post<boolean>(url);
        return response.data;
    }

    getEmployeeByUserIdAsync = async(employeeId: string): Promise<Employee> => {
        const url = `${process.env.REACT_APP_API_URL}/employees/getByUserId/${employeeId}`;
        const response = await axios.get<Employee>(url);
        return response.data;
    }

    updateAvatarAsync =  async(employeeId: string, avatar: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/employees/updateAvatar/${employeeId}`;
        const response = await axios.post<boolean>(url, {
            avatar: avatar,
        });
        return response.data;
    }
    changeEmployeePasswordAsync = async(oldPassword: string, newPassword: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/users/changeUserPassword`;
        const response = await axios.post<boolean>(url, {
            code: oldPassword,
            password:newPassword
        });
        return response.data;
    }
}