import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, createStyles, Grid, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { CorrectiveAction } from "../../Models/CorrectiveAction";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import BackButton from "../../Components/BackToButton";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from 'react-i18next';

interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: CorrectiveAction
    editMode: FormMode,
    errors: ValidationError[],
    language: string,
}

const initAction: CorrectiveAction = {
    id: '',
    name: '',
    language: ''
};

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initAction,
    errors: [],
    language: ''
};

const useStyles = makeStyles(() =>
    createStyles({
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },

    }),
);

const CorrectiveActionPage = observer(() => {
    const { correctiveActionStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { hasActionPermission, isSuperAdmin } = appStore.userAuth as AuthenticateResponseModel;
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        var lang = localStorage.getItem("lang") ?? LanguagesModels[0].key;
        correctiveActionStore.setLanguage(lang);
        setState({
            ...state,
            language: lang
        });
    }, []);

    const columns: GridColDef[] = [
        {
            field: "name", headerName: t('corrective_action_name'), flex: 3, renderCell: ((row: GridCellParams) => {
                const action: CorrectiveAction = row.row as CorrectiveAction;
                return <LightTooltip title={action.name} placement="bottom-start" arrow>
                    <span>{action.name}</span>
                </LightTooltip>;
            })
        }
    ];

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('corrective_action_name_required')
                });
            } else if (state.editRow.name.trim().length > 50) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('corrective_action_name_max_length')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initAction, id: Guid.create().toString(), language: state.language }
        })
    }

    const handleLangChange = (item: any) => {
        setState({
            ...state,
            language: item.target.value
        });

        correctiveActionStore.setLanguage(item.target.value).then(x => {
            correctiveActionStore.query();
        });
    }

    return (<>
        <CommonPageTitle title={t('corrective_actions')}
            subTitle={t('corrective_actions_desc')}></CommonPageTitle>

        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                    {isSuperAdmin &&
                        <>
                            <Typography style={{ marginRight: 10, color: "#999" }}>
                                {t("managing_language")}:
                            </Typography>
                            <Select fullWidth
                                displayEmpty
                                disableUnderline
                                style={{ width: 180 }}
                                className={classes.selected}
                                value={state.language}
                                onChange={handleLangChange}
                            >
                                {LanguagesModels.map(x =>
                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>

                    <BackButton />
                    {hasActionPermission &&
                        <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleCreate}
                        >
                            {t('create_corrective_action')}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Box>

        <CommonGridPage<CorrectiveAction>
            key={state.gridId}
            columns={columns}
            store={correctiveActionStore}
            delConfirmMsg={t('delete_corrective_tip')}
            onEdit={(row: CorrectiveAction) => setState({
                ...state,
                dialogOpen: true,
                editRow: row,
                editMode: FormMode.Edit
            })}
            useAction={hasActionPermission}
        />

        <CommonFormDialog<CorrectiveAction>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={correctiveActionStore}
            title={t('corrective_actions')}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        value={state.editRow?.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value }
                        })}
                        placeholder={t('corrective_action_name')}
                        label={t('corrective_action_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
});

export default CorrectiveActionPage
