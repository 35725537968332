import axios from "axios";
import { QueryCriteria } from "../Models/QueryCriteria";
import { CoolingReportModel } from "../Models/Reports";
import { BaseService } from "./BaseService";

export class CoolingReportService extends BaseService<CoolingReportModel> {
    constructor() {
        super('CoolingReports');
    }
    
    downloadTemplateAsync = async(criteria: QueryCriteria): Promise<any> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/downloadTemplate`
        const response = await axios.post<any>(url, criteria, {responseType: 'blob'});
        return response.data;
    }
}