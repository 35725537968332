import {
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    Dialog,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    Divider,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { useState } from "react";
import { Department } from "../../Models/Department";
import { RecurringType } from "../../Models/RecurringType";
import { ValidationError } from "../../Models/ValidationError";
import { IDataStore } from "../../Stores/IDataStore";
import { EnumToMap } from "../../Utils/EnumUtil";
import { KeyboardTimePicker } from '@material-ui/pickers';
import { TimeType } from "../../Models/TimeType";
import { Checklist } from "../../Models/Checklist";
import { DateTime } from "luxon";
import { DayOfWeek } from "../../Models/DayOfWeek";
import { DayOrdinalRange } from "../../Utils/NumberUtils";
import { SortDirection } from "../../Models/QueryCriteria";

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles({
    dialogHeader: {
        '& .MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    gridContainer: {
        display: 'flex',
        height: '420px'
    },
    dialogPaper: {
        width: 773
    },
    cancelButton: {
        '& .MuiButton-label': {
            color: '#9094A9'
        }
    },
    groupList: {
        paddingTop: '0',
        '& .MuiListItem-root.Mui-selected': {
            background: '#1CAD5E',
            color: '#FFFFFF'
        }
    },
    editContainer: {
        '& .MuiGrid-item': {
            marginTop: 14
        },
        padding: '8px 34px'
    },
    timeTypeContainer: {
        marginBottom: 25,
        '& .MuiButton-colorInherit': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            '& > span': {
                color: '#9094A9'
            }
        }
    },
    optionContainer: {
        background: '#F8F9FB',
        border: '1px solid rgba(144, 148, 169, 0.2)',
        borderRadius: '6px',
        marginTop: 15,
        padding: '10px 14px',
    },
    actionContainer: {
        borderTop: 'solid 1px #cfcfcf',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px 25px'
    }
});

interface DialogProps {
    open: boolean,
    title: string,
    mode: FormMode,
    row: Checklist,
    store: IDataStore<Checklist>,
    departments: Department[],
    onClose: () => void,
    onSubmit: (result: boolean) => void,
}

interface DialogState {
    editRow: Checklist,
    editGroup: number,
    errors: ValidationError[],
}

export enum FormMode {
    Add,
    Edit
}

export function ChecklistFormDialog(props: DialogProps) {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const [state, setState] = useState<DialogState>({
        editRow: props.row,
        editGroup: 1,
        errors: [],
    });

    const classes = useStyles();
    const handleClose = (value: any) => {
        if (value != "") {
            return;
        }
        onClose();
    };

    const handleValidation = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('checklist_instance_name_required')
                });
            } else if (state.editRow.name.trim().length > 200) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('checklist_instance_name_max_length')
                });
            }

            if (state.editRow.recurringType === RecurringType.Daily && !state.editRow.startTimeOfDay) {
                valdiationErrors.push({
                    field: 'startTimeOfDay',
                    message: t('time_required')
                });
            }

            if (state.editRow.recurringType === RecurringType.Weekly && typeof state.editRow.startDayOfWeek !== 'number') {
                valdiationErrors.push({
                    field: 'startDayOfWeek',
                    message: t('day_required')
                });
            }

            if (state.editRow.recurringType === RecurringType.Monthly && !state.editRow.startDayOfMonth) {
                valdiationErrors.push({
                    field: 'startDayOfMonth',
                    message: t('day_required')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        });
    }

    const handleSubmit = (entity: Checklist) => {
        handleValidation().then(x => {
            if (x) {
                handleCreateOrUpdate(entity);
            }
        })
    }

    const handleCreateOrUpdate = (entity: Checklist) => {
        const toUpdateData: Checklist = { ...entity };
        if (toUpdateData.startTimeOfDay && toUpdateData.startTimeOfDay.constructor === Date) {
            const newDate = DateTime.fromJSDate(toUpdateData.startTimeOfDay);
            toUpdateData.startTimeOfDay = new Date(Date.UTC(newDate?.year || 2021, (newDate?.month || 1) - 1, newDate?.day, newDate?.hour, newDate?.minute));
        }
        props.store.setSort("createdTime", SortDirection.Descending);

        if (props.mode === FormMode.Add) {
            props.store.add(toUpdateData).then(x => {
                props.onSubmit(x);
            });
        } else {
            props.store.update(toUpdateData).then(x => {
                props.onSubmit(x);
            });
        }
    }

    return (<Dialog open={open} onClose={() => handleClose("notclose")} aria-labelledby="form-dialog-title" fullWidth maxWidth={"md"}
        classes={{
            paper: classes.dialogPaper
        }}>
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            <span>{(props.mode === FormMode.Add ? "Create" : "") + ' ' + (props.mode === FormMode.Add ? t("checklist") : state.editRow.name)}</span>
            <IconButton onClick={() => handleClose("")}>
                <ClearIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid container style={{ borderTop: 'solid 1px #cfcfcf' }}>
                <Grid item xs={4} style={{ borderRight: 'solid 1px #ccc' }}>
                    <List className={classes.groupList}>
                        <ListItem
                            selected={state.editGroup === 1}
                            onClick={() => setState({ ...state, editGroup: 1 })}
                            button
                        >
                            <ListItemText primary={t('general_information')} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={8}>
                    {state.editGroup === 1 && <Box className={classes.editContainer}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={true}
                                    error={state.errors.findIndex(x => x.field === "name") > -1}
                                    helperText={state.errors.find(x => x.field === "name")?.message || ''}
                                    label={t('checklist_name')}
                                    value={state.editRow.name}
                                    onChange={(event) => setState({
                                        ...state,
                                        editRow: { ...state.editRow, name: event.target.value }
                                    })}
                                    placeholder={t('checklist_name')}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {props.departments.length > 0 && <FormControl fullWidth>
                                    <InputLabel id="lblDepartment" shrink>{t('department')}</InputLabel>
                                    <Select fullWidth
                                        value={state.editRow.departmentId || ''}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, departmentId: event.target.value as string }
                                        })}
                                        displayEmpty
                                        disableUnderline>
                                        <MenuItem value="" >{t('please_select_department')}</MenuItem>
                                        {props.departments.map(x =>
                                            <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText></FormHelperText>
                                </FormControl>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="lblFrequency" shrink>{t('frequency')}</InputLabel>
                                    <Select fullWidth
                                        value={state.editRow.recurringType}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, recurringType: event.target.value as RecurringType }
                                        })}
                                        displayEmpty
                                        disableUnderline>
                                        <MenuItem value="" disabled>{t('please_select_checklist_frequency')}</MenuItem>
                                        {
                                            EnumToMap(RecurringType)
                                                .map(item => <MenuItem key={item.value}
                                                    value={item.value}>{t(item.text)}</MenuItem>)
                                        }
                                    </Select>
                                    <FormHelperText></FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={state.editRow.hasMultipleGroup}
                                        onChange={() => setState({
                                            ...state,
                                            editRow: {
                                                ...state.editRow,
                                                hasMultipleGroup: !state.editRow.hasMultipleGroup
                                            }
                                        })}
                                        color="primary"
                                        name="multiple"
                                    ></Switch>}
                                    label={t('multiple_groups')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={state.editRow.isCooling}
                                        onChange={() => setState({
                                            ...state,
                                            editRow: {
                                                ...state.editRow,
                                                isCooling: !state.editRow.isCooling
                                            }
                                        })}
                                        color="primary"
                                        name="multiple"
                                    ></Switch>}
                                    label={t('is_cooling')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ width: '100%', marginTop: 10, marginBottom: 24 }} />
                                {state.editRow.recurringType === RecurringType.Daily && state.editRow.timeType !== TimeType.OnDemand &&
                                    <Typography style={{ color: '#9094A9' }}>
                                        {t("generate_tasks_at_the_daily_refresh_time")}
                                    </Typography>
                                }
                                {state.editRow.recurringType === RecurringType.Weekly &&
                                    <Typography style={{ color: '#9094A9' }}>
                                        {t("generate_tasks_on_sunday_at_the_daily_refresh_time")}
                                    </Typography>
                                }
                                {state.editRow.recurringType === RecurringType.Monthly &&
                                    <Typography style={{ color: '#9094A9' }}>
                                        {t("generate_on_the_1st_of_the_month_at_the_daily_refresh_time")}
                                    </Typography>
                                }
                            </Grid>
                            {state.editRow.recurringType === RecurringType.Daily && <Grid item xs={12}>
                                <Box>
                                    <Box className={classes.timeTypeContainer}>
                                        <Button color={state.editRow.timeType === TimeType.OneTime ? "primary" : "inherit"}
                                            variant={state.editRow.timeType === TimeType.OneTime ? "contained" : "outlined"}
                                            disableElevation onClick={() => setState({
                                                ...state,
                                                editRow: { ...state.editRow, timeType: TimeType.OneTime }
                                            })}
                                            style={{ marginRight: 24 }}
                                        >
                                            {t('once_per_day')}
                                        </Button>
                                        <Button color={state.editRow.timeType === TimeType.OnDemand ? "primary" : "inherit"}
                                            variant={state.editRow.timeType === TimeType.OnDemand ? "contained" : "outlined"}
                                            disableElevation onClick={() => setState({
                                                ...state,
                                                editRow: { ...state.editRow, timeType: TimeType.OnDemand }
                                            })}>
                                            {t('on_demand')}
                                        </Button>
                                    </Box>
                                </Box>
                                {state.editRow.timeType === TimeType.OneTime && <Box className={classes.optionContainer}>
                                    <Typography style={{ color: '#9094A9' }}>
                                        {t('daily_task_due_time')}
                                    </Typography>
                                    <KeyboardTimePicker
                                        error={state.errors.findIndex(x => x.field === "startTimeOfDay") > -1}
                                        helperText={state.errors.find(x => x.field === "startTimeOfDay")?.message || ''}
                                        fullWidth
                                        margin="normal"
                                        id="time-picker"
                                        orientation="landscape"
                                        openTo="hours"
                                        value={state.editRow.startTimeOfDay}
                                        onChange={(newDate) => {
                                            setState({
                                                ...state,
                                                editRow: { ...state.editRow, startTimeOfDay: newDate?.toJSDate() }
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        InputProps={{ disableUnderline: true, readOnly: true }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>}
                                {state.editRow.timeType === TimeType.OnDemand && <Box className={classes.optionContainer}
                                    style={{
                                        padding: '46px 14px',
                                        textAlign: 'center'
                                    }}>
                                    <Typography style={{ color: '#9094A9' }}>
                                        {t('checklist_added_on_demand.')}
                                    </Typography>
                                </Box>}
                            </Grid>}

                            {state.editRow.recurringType === RecurringType.Weekly && <Grid item xs={12}>
                                <Box className={classes.optionContainer}>
                                    <Typography style={{ color: '#9094A9' }}>
                                        {t('due_day')}
                                    </Typography>
                                    <FormControl fullWidth
                                        error={state.errors.findIndex(x => x.field === "startDayOfWeek") > -1}>
                                        <Select fullWidth
                                            error={state.errors.findIndex(x => x.field === "startDayOfWeek") > -1}
                                            style={{ marginTop: 16, marginBottom: 16 }}
                                            value={typeof state.editRow.startDayOfWeek === 'number' ? state.editRow.startDayOfWeek : ''}
                                            onChange={(event) => setState({
                                                ...state,
                                                editRow: { ...state.editRow, startDayOfWeek: event.target.value as number }
                                            })}
                                            displayEmpty
                                            disableUnderline>
                                            <MenuItem value="" disabled>{t('please_select')}</MenuItem>
                                            {EnumToMap(DayOfWeek).map(x =>
                                                <MenuItem value={x.value}>{x.text}</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText>{state.errors.find(x => x.field === "startDayOfWeek")?.message || ''}</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                            }

                            {state.editRow.recurringType === RecurringType.Monthly && <Grid item xs={12}>
                                <FormControl fullWidth
                                    error={state.errors.findIndex(x => x.field === "startDayOfMonth") > -1}>
                                    <Select fullWidth
                                        error={state.errors.findIndex(x => x.field === "startDayOfMonth") > -1}
                                        style={{ marginTop: 16, marginBottom: 16 }}
                                        value={state.editRow.startDayOfMonth || ''}
                                        onChange={(event) => setState({
                                            ...state,
                                            editRow: { ...state.editRow, startDayOfMonth: event.target.value as number }
                                        })}
                                        displayEmpty
                                        disableUnderline>
                                        <MenuItem value="" disabled>{t('please_select')}</MenuItem>
                                        {DayOrdinalRange.map((x, index) =>
                                            <MenuItem value={index + 1}>{x.toLocaleLowerCase()}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>{state.errors.find(x => x.field === "startDayOfMonth")?.message || ''}</FormHelperText>
                                </FormControl>
                            </Grid>
                            }
                        </Grid>
                    </Box>}
                    <Box className={classes.actionContainer}>
                        <Button variant="outlined" disableElevation className={classes.cancelButton}
                            onClick={() => handleClose("")} style={{ marginRight: 10 }}>
                            {t('cancel')}
                        </Button>
                        <Button color="primary" variant="contained" disableElevation
                            onClick={() => handleSubmit(state.editRow)}>
                            {t('submit')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>);
}