import { Route, Switch, useRouteMatch } from "react-router-dom";
import CategoryPage from "./CategoryPage";
import ChecklistGroupPage from "./ChecklistGroupPage";
import ChecklistPage from "./ChecklistPage";
import TaskPage from "./TaskPage";

export default function Category() {
    const { path } = useRouteMatch();
    return <>
         <Switch>
            <Route exact path={path}>
                <CategoryPage/>
            </Route>
            <Route path={`${path}/:categoryId/categories`}>
                <ChecklistPage/>
            </Route>
            <Route path={`${path}/:checklistId/checklistgroups`}>
                <ChecklistGroupPage/>
            </Route>
            <Route path={`${path}/:checklistId/:checklistGroupId/tasks`}>
                <TaskPage/>
            </Route>
            <Route path={`${path}/:checklistId/tasks`}>
                <TaskPage />
            </Route>
        </Switch>
    </>
}