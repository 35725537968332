import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import React, { useState } from "react";
import { InstanceFilters } from "../../Components/InstanceFilters";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import List from "@material-ui/core/List";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import ForumIcon from '@material-ui/icons/Forum';
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green, pink, orange, blue } from '@material-ui/core/colors';
import useStores from "../../Stores/useStores";
import { Guid } from "guid-typescript";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { Notification, NotificationType } from "../../Models/Notification";
import CommonGridPage from "../../Components/CommonGridPage";
import Badge from '@material-ui/core/Badge';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        pink: {
            color: theme.palette.getContrastText(pink[500]),
            backgroundColor: pink[500],
        },
        green: {
            color: '#fff',
            backgroundColor: green[500],
        },
        orange: {
            color: '#fff',
            backgroundColor: orange[500],
        },
        blue: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500],
        },
    }),
);

interface NotificationPageState {
    gridId: string,
    deptId: string,
    filterDate: Date,
}

const initState: NotificationPageState = {
    gridId: Guid.create().toString(),
    filterDate: new Date(),
    deptId: '',
};

export function NotificationCellInfo(props: Notification) {
    const classes = useStyles();
    const { notifyTime, notificationType, message, isRead } = props;
    let avatarClassName = classes.green;
    let avatarIcon = <ThumbUpIcon />;
    if (notificationType === NotificationType.Comment) {
        avatarClassName = classes.orange;
        avatarIcon = <AssistantPhotoIcon />;
    }
    if (notificationType === NotificationType.Corrective) {
        avatarClassName = classes.blue;
        avatarIcon = <ForumIcon />;
    }
    return <List style={{ width: "100%", lineHeight: 0 }}>
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                {isRead ? <Avatar alt="Remy Sharp" className={avatarClassName}>
                    {avatarIcon}
                </Avatar> : <Badge color="secondary" variant="dot">
                    <Avatar alt="Remy Sharp" className={avatarClassName}>
                        {avatarIcon}
                    </Avatar>
                </Badge>
                }
            </ListItemAvatar>
            <ListItemText primary={message}
                secondary={`${moment(notifyTime).format("LT, MMM D")}`}
            />
        </ListItem>
    </List>
}

const Notifications = observer(() => {
    const { appStore } = useStores();
    initState.filterDate = appStore.getFilterDate();
    const [state, setState] = useState<NotificationPageState>(initState);
    const { notificationStore } = useStores();
    const { t } = useTranslation();
    notificationStore.setPreFilter([{
        field: 'filterDate',
        value: moment(initState.filterDate).format("YYYY-MM-DD HH:mm:ss")
    }]);

    const handleFilterChange = (filters: { deptId: string, filterDate: Date }) => {
        setState({ ...state, ...filters });
        const changedState = Object.assign({}, { ...state }, { ...filters });
        notificationStore.setPreFilter([{
            field: 'filterDate',
            value: moment(changedState.filterDate).format("YYYY-MM-DD HH:mm:ss")
        }, {
            field: 'deptId',
            value: changedState.deptId
        }]);
        notificationStore.query();
    };
    const columns: GridColDef[] = [
        {
            field: "message", headerName: t('notification'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const instance: Notification = row.row as Notification;
                return <NotificationCellInfo {...instance}></NotificationCellInfo>;
            })
        }
    ];
    return <>
        <CommonPageTitle title={t('notifications')}
            subTitle={t('check_out_the_latest_notifications')}
        />
        <InstanceFilters handleFilterChange={handleFilterChange} noCategory={true} />
        <Divider light style={{ width: '100%' }} />
        <CommonGridPage<Notification>
            key={state.gridId}
            columns={columns}
            cancelSearch={true}
            useAction={false}
            store={notificationStore}
            rowHeight={80}
        />

    </>
});

export default Notifications;