import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  Link,
  Button,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStores from "../../Stores/useStores";
import { useHistory } from "react-router-dom";
import TextFieldPassword from "../../Components/TextFieldPassword";
import Cookies from "universal-cookie/es6";
import { useTranslation } from "react-i18next";
import Languages from "../../Components/Languages";
import { useLocation } from "react-router";
import MuiPhoneNumber from "material-ui-phone-number";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { ValidationError } from "../../Models/ValidationError";
import countryList from "../../Utils/country-list";
import { Guid } from "guid-typescript";
import { isValidEmail } from "../../Utils/StringUtils";
import { CompanyShareLink } from "../../Models/CompanyShareLink";
import { ShareLinkAction } from "../../Models/ShareLinkAction";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    minWidth: "1020px",
  },
  phoneContainer: {
    height: "100vh",
  },
  left: {
    background: "#1CAD5E",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 5%",
  },
  logo: {
    maxHeight: "70px",
    maxWidth: "400px",
    borderRadius: "20px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "-40px",
  },
  right: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: "5%",
    paddingRight: "5%",
    background: "#E5E5E5",
  },
  signInTitle: {
    fontWeight: 500,
    fontSize: 32,
  },
  signInSubtitle: {
    fontWeight: 400,
    marginTop: 14,
    color: "#9094A9",
  },
  loginExtra: {
    marginTop: 26,
    display: "flex",
    justifyContent: "space-between",
  },
  downloadItem: {
    border: "1px solid #FFFFFF",
    background: "#FFF",
    borderRadius: 6,
    width: 200,
    height: 64,
    cursor: "pointer",
    marginRight: "1rem",
    "& .MuiListItemText-primary": {
      fontSize: 12,
      color: "#374359",
    },
    "& .MuiListItemText-secondary": {
      fontSize: 18,
      fontWeight: 500,
      color: "#374359",
    },
  },
  downloadItemPhone: {
    border: "1px solid #FFFFFF",
    background: "#FFF",
    borderRadius: 6,
    width: 200,
    height: 64,
    cursor: "pointer",
    marginRight: "1rem",
    "& .MuiListItemText-primary": {
      fontSize: 12,
      color: "#374359",
    },
    "& .MuiListItemText-secondary": {
      fontSize: 14,
      fontWeight: 500,
      color: "#374359",
    },
  },
  expireItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  picker: {
    marginTop: "0px",
    marginBottom: "0px",
    width: "100%",
  },
  autoText: {
    "& .MuiInputBase-input": {
      padding: "17px 14px !important",
    },
  },
  input: {
    display: "none",
  },
}));

interface SignupState {
  shareCode: string;
  shareWith: string;
  id: string;
  name: string;
  contactFirstName: string;
  contactLastName: string;
  phone: string;
  emailAddress: string;
  address: string;
  // status?: RowStatus
  logoUrl?: string;
  dayChangeTime?: Date;
  timeZoneId?: string;
  expireDate?: Date;
  isFreeTrial?: boolean;
  parentCompanyId?: string;
  parentCompanyName?: string;
  licenseId?: string;
  isHQ?: boolean;
  isExpire?: boolean;
  licenseKey?: string;
  orgLicenseKey?: string;
  isEnterprise?: boolean;
  price?: string;
  newExpire?: Date;
  country?: string;
  state?: string;
  taxRate?: number;
  logoFilePath?: string;
  logoFileContent?: string;
  logoFileName?: string;
  companyLogoId?: string;
  language?: string;
  isNew?: boolean;
  errors: ValidationError[];
  shareLink?: CompanyShareLink;
}

const initState: SignupState = {
  shareCode: "",
  shareWith: "",
  id: Guid.create().toString(),
  name: "",
  contactFirstName: "",
  contactLastName: "",
  phone: "",
  emailAddress: "",
  address: "",
  expireDate: moment().add(7, 'days').local().toDate() || "",
  isFreeTrial: undefined,
  isHQ: undefined,
  isEnterprise: undefined,
  price: "" || undefined,
  country: "" || undefined,
  state: "" || undefined,
  taxRate: "" || undefined,
  logoFilePath: "" || undefined,
  logoFileContent: "" || undefined,
  logoFileName: "" || undefined,
  companyLogoId: "" || undefined,
  isNew: true,
  errors: [],
  shareLink: undefined,
};

const SignUp = observer(() => {
  const classes = useStyles();
  const { accountStore, companyStore, companyShareLinkStore, appStore } = useStores();
  const [state, setState] = useState<SignupState>(initState);
  const { t } = useTranslation();
  const location = useLocation();
  const shareCode = new URLSearchParams(location.search).get("sharecode");

  useEffect(() => {
    if (shareCode) {
      companyShareLinkStore.queryByCode(shareCode).then((shareLink) => {
        const shareWith = shareLink?.company?.name || "";
        const value: any = { shareLink, shareCode, shareWith };
        if (shareLink.action == ShareLinkAction.InviteEmployee) {
          value.name = shareWith;
        }
        setState({ ...state, ...value });
      });
    }
  }, [location]);

  const history = useHistory();

  const handleSignUp = () => {
    handleValidate().then(() => {
      accountStore.signUp(state, state.shareCode).then(() => {
        history.replace("/login");
      });
    });
  };

  const handleValidate = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const valdiationErrors: ValidationError[] = [];
      if (state.name.trim().length === 0) {
        valdiationErrors.push({
          field: "name",
          message: t("company_name_required"),
        });
      }
      if (state.name.trim().length > 100) {
        valdiationErrors.push({
          field: "name",
          message: t("company_name_max_length"),
        });
      }

      if (state.address.trim().length > 255) {
        valdiationErrors.push({
          field: "address",
          message: t("address_max_length"),
        });
      }

      if (
        state.contactFirstName.trim().length === 0 || state.contactLastName.trim().length === 0
      ) {
        valdiationErrors.push({
          field: "contactFirstName",
          message: t("contact_first_name_required"),
        });
      }
      else if (
        state.contactFirstName.trim().length > 100
      ) {
        valdiationErrors.push({
          field: "contactFirstName",
          message: t("contact_person_max_lenth"),
        });
      }

      if (
        state.contactLastName.trim().length === 0
      ) {
        valdiationErrors.push({
          field: "contactLastName",
          message: t("contact_last_name_required"),
        });
      } else if (
        state.contactLastName.trim().length > 100
      ) {
        valdiationErrors.push({
          field: "contactLastName",
          message: t("contact_person_max_lenth"),
        });
      }

      if (state.emailAddress.trim().length === 0) {
        valdiationErrors.push({
          field: "emailAddress",
          message: t("email_address_required"),
        });
      } else if (!isValidEmail(state.emailAddress)) {
        valdiationErrors.push({
          field: "emailAddress",
          message: t("email_format_error"),
        });
      } else if (state.emailAddress.trim().length > 50) {
        valdiationErrors.push({
          field: "emailAddress",
          message: t("email_address_max_length"),
        });
      }

      if (valdiationErrors.length === 0) {
        resolve(true);
      } else {
        reject();
        setState({ ...state, errors: valdiationErrors });
      }
    });
  };

  const handleUploadFile = (event: any) => {
    const file = event.target.files[0];

    const allowTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    if (!file || allowTypes.indexOf(file.type) < 0) {
      if (!/(docx)$/.test(file.name)) {
        appStore.error = `${t("not_allowed_file_type")}`;
        appStore.showError = true;
        return;
      }
    }
    let maxSize = 1024 * 1000 * 10;
    if (file.size > maxSize) {
      appStore.error = `${t("max_allowed_size_is")}:${
        maxSize / 1000 / 1024
      } mb`;
      appStore.showError = true;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      const base64data = reader.result;
      setState({
        ...state,
        logoFileName: file.name,
        logoFileContent: base64data?.toString(),
        errors: [...state.errors].filter((x) => x.field !== "logoFileContent"),
      });
    };
  };

  return (
    <Grid
      item
      xs={6}
      className={classes.right}
      style={{ justifyContent: "space-between" }}
    >
      <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
        <Languages></Languages>
      </Grid>
      <Grid className={classes.right}>
        <Typography variant="h5" className={classes.signInTitle}>
          {t("sign_up")}
        </Typography>
        <Typography variant="h5" className={classes.signInSubtitle}>
          {t("signup_fill_fields")}
        </Typography>
        {(state.shareLink?.action === ShareLinkAction.InviteCompany) && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={state.shareWith}
              placeholder={t("signup_share_with")}
              label={t("signup_share_with")}
              InputProps={{ disableUnderline: true, readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required={true}
            error={state.errors.findIndex((x) => x.field === "name") > -1}
            helperText={
              state.errors.find((x) => x.field === "name")?.message || ""
            }
            label={t("company_name")}
            value={state.name}
            onChange={(event) =>
              setState({
                ...state,
                name: event.target.value.substring(0, 100),
                errors: [...state.errors].filter((x) => x.field !== "name"),
              })
            }
            placeholder={t("company_name")}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required={true}
            value={state.contactFirstName}
            onChange={(event) =>
              setState({
                ...state,
                contactFirstName: event.target.value.substring(0, 100),
                errors: [...state.errors].filter(
                  (x) => x.field !== "contactFirstName"
                ),
              })
            }
            placeholder={t("contact_first_name")}
            label={t("contact_first_name")}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            error={
              state.errors.findIndex((x) => x.field === "contactFirstName") > -1
            }
            helperText={
              state.errors.find((x) => x.field === "contactFirstName")
                ?.message || ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required={true}
            value={state.contactLastName}
            onChange={(event) =>
              setState({
                ...state,
                contactLastName: event.target.value.substring(0, 100),
                errors: [...state.errors].filter(
                  (x) => x.field !== "contactLastName"
                ),
              })
            }
            placeholder={t("contact_last_name")}
            label={t("contact_last_name")}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            error={
              state.errors.findIndex((x) => x.field === "contactLastName") > -1
            }
            helperText={
              state.errors.find((x) => x.field === "contactLastName")
                ?.message || ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPhoneNumber
            fullWidth
            defaultCountry={"us"}
            value={state.phone}
            onChange={(value) =>
              setState({
                ...state,
                phone: value.substring(0, 20),
                errors: [...state.errors].filter((x) => x.field !== "phone"),
              })
            }
            placeholder={t("phone")}
            label={t("phone")}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required={true}
            value={state.emailAddress}
            onChange={(event) =>
              setState({
                ...state,
                emailAddress: event.target.value.substring(0, 50),
                errors: [...state.errors].filter(
                  (x) => x.field !== "emailAddress"
                ),
              })
            }
            placeholder={t("email_address")}
            label={t("email_address")}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            error={
              state.errors.findIndex((x) => x.field === "emailAddress") > -1
            }
            helperText={
              state.errors.find((x) => x.field === "emailAddress")?.message ||
              ""
            }
          />
        </Grid>
        { state.shareLink?.action !== ShareLinkAction.InviteEmployee && (<React.Fragment>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid xs={5}>
            <Typography
              style={{
                fontSize: "0.8rem !important",
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              {t("country")}
            </Typography>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              className={classes.autoText}
              onChange={(event, newValue: any) => {
                if (newValue) {
                  setState({
                    ...state,
                    country: newValue.countryCod,
                  });
                } else {
                  setState({
                    ...state,
                    country: "",
                  });
                }
              }}
              getOptionLabel={(option: any) => {
                return option?.countryName ?? "";
              }}
              defaultValue={
                state.country
                  ? countryList.find((x) => x.countryCode === state.country)
                  : {}
              }
              options={countryList && countryList.length > 0 ? countryList : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ padding: "17px 14px !important" }}
                  placeholder={t("country")}
                  value={state.country}
                  onChange={(event) =>
                    setState({
                      ...state,
                      country: event.target.value,
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid xs={5}>
            <TextField
              fullWidth
              value={state.state}
              onChange={(event) =>
                setState({
                  ...state,
                  state: event.target.value.substring(0, 50),
                  errors: [...state.errors].filter((x) => x.field !== "state"),
                })
              }
              placeholder={t("state")}
              label={t("state")}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              error={state.errors.findIndex((x) => x.field === "state") > -1}
              helperText={
                state.errors.find((x) => x.field === "state")?.message || ""
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            value={state.address}
            onChange={(event) =>
              setState({
                ...state,
                address: event.target.value.substring(0, 255),
                errors: [...state.errors].filter((x) => x.field !== "address"),
              })
            }
            placeholder={t("address")}
            label={t("address")}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            error={state.errors.findIndex((x) => x.field === "address") > -1}
            helperText={
              state.errors.find((x) => x.field === "address")?.message || ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            value={state.language}
            onChange={(event) =>
              setState({
                ...state,
                language: event.target.value.substring(0, 255),
                errors: [...state.errors].filter((x) => x.field !== "address"),
              })
            }
            placeholder={t("language")}
            label={t("language")}
            InputProps={{ disableUnderline: true }}
            defaultValue="english"
            InputLabelProps={{ shrink: true }}
            error={state.errors.findIndex((x) => x.field === "language") > -1}
            helperText={
              state.errors.find((x) => x.field === "language")?.message || ""
            }
          >
            <MenuItem value="english">{t("english")}</MenuItem>
            <MenuItem value="italy">{t("italian")}</MenuItem>
            <MenuItem value="spanish">{t("spanish")}</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end" }}>
          <Grid>
            <InputLabel id="lblFile" shrink>
              {t("company_logo")}
            </InputLabel>
            <>
              <input
                accept="image/png,image/jpg,image/jpeg,image/gif"
                className={classes.input}
                id="contained-button-fi1le"
                type="file"
                value={""}
                onChange={handleUploadFile}
              />
              <label htmlFor="contained-button-fi1le">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                >
                  {t("browse")}
                </Button>
              </label>
            </>
            <FormHelperText>
              {state.errors.find((x) => x.field === "logoFileContent")
                ?.message || ""}
            </FormHelperText>
          </Grid>
          <Grid item xs={8} style={{ marginLeft: "15px" }}>
            <Typography
              style={{
                color: "#374359",
                marginTop: 2,
                marginBottom: 2,
                fontSize: 12,
                lineHeight: "15px",
              }}
            >
              {t("file_name")}: {state.logoFileName ? state.logoFileName : ""}
            </Typography>
            <Typography
              style={{ color: "#9094A9", fontSize: 12, lineHeight: "15px" }}
            >
              {t("file_formats")}: *.png,*.jpg,*.jpeg,*.gif
              <br />
              {t("max_file_size")}: 10M
            </Typography>
          </Grid>
        </Grid>
        </React.Fragment>)}
        <Button
          fullWidth
          variant="contained"
          style={{ marginTop: 32 }}
          disableElevation
          color="primary"
          onClick={() => handleSignUp()}
        >
          {t("sign_up")}
        </Button>
      </Grid>
      <Grid></Grid>
    </Grid>
  );
});

export default SignUp;
