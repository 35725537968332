import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Paper, InputAdornment, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { License } from "../../Models/License";
import { ValidationError } from "../../Models/ValidationError";
import { isValidEmail } from "../../Utils/StringUtils";
import MuiPhoneNumber from 'material-ui-phone-number';
import { SortDirection } from "../../Models/QueryCriteria";
import { LightTooltip } from "../../Components/ToolTips";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { AuthenticateResponseModel } from '../../Models/AuthenticateModel';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { Company } from "../../Models/Company";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import debounce from "lodash/debounce";
import StructureTree from "../../Components/StructureTree";
import { useTranslation } from "react-i18next";
import _ from "lodash";


interface PageState {
    editGroup: number,
    dialogOpen: boolean,
    dialogId: string,
    editRow: License
    editMode: FormMode,
    errors: ValidationError[],
    licenses: any,
    licenseInfo: License,
    companyTree: Company,
    viewAsMenuOpen: boolean,
    searchStr: string,
    isExpired: boolean,
    selectedCompanyId: string,
    companyName: string | undefined,
    refresh: number,
    applyKeys: any
}

const initLicense: License = {
    id: '',
    name: '',
    phone: '',
    address: '',
    contactFirstName: '',
    contactLastName: '',
    emailAddress: '',
    expireDate: new Date() || '',
    licenseId: '',
    pay: undefined,
    price: undefined,
    startDate: new Date() || '',
    isFreeTrial: undefined,
    isHQ: undefined,
    LicenseLogId: '',
    isExpire: false,
    licenseKey: '',
    orgLicenseKey: '',
    defaultLicense: '',
    country: "",
    state: "",
    taxRate: 0
}

const initCompany: Company = {
    id: '',
    name: '',
    contactFirstName: '',
    contactLastName: '',
    phone: '',
    emailAddress: '',
    address: '',
    expireDate: new Date() || null,
    isFreeTrial: undefined,
    parentCompanyId: undefined,
    parentCompanyName: undefined,
    licenseId: undefined,
    childCompanies: [],
    isHQ: undefined,
    isExpire: undefined,
    licenseKey: undefined,
    newExpire: undefined,
    country: "",
    state: "",
    taxRate: 0
}

const initState: PageState = {
    editGroup: 1,
    dialogOpen: false,
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initLicense,
    errors: [],
    licenses: [{
        license_key: ""
    }],
    licenseInfo: initLicense,
    companyTree: initCompany,
    viewAsMenuOpen: false,
    searchStr: "",
    isExpired: false,
    selectedCompanyId: "",
    companyName: "",
    refresh: 0,
    applyKeys: []
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupList: {
            paddingTop: '0',
            '& .MuiListItem-root.Mui-selected': {
                background: '#1CAD5E',
                color: '#FFFFFF'
            }
        },
        avatarInput: {
            display: 'none',
        },
        editContainer: {
            minHeight: 340,
            '& .MuiGrid-item': {
                marginTop: 14
            },
            padding: '10px 34px'
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },
        container: {
            height: '79vh',
            marginTop: '20px',
        },
        left: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: "0px !important"
        },

        right: {
            display: 'flex',
            flexDirection: 'column',
            borderLeft: "1px solid #eeeeee",
            paddingTop: "0px !important",
            "& > div > div": {
                height: "calc(100vh - 230px) !important"
            }
        },
        viewAs: {
            '&:hover': {
                borderRadius: "10px",
                backgroundColor: "unset"
            },
            padding: "0px",
        },
        searchTextField: {
            width: 200,
            marginLeft: 24,
            marginRight: 24,
            '& .MuiInputBase-input': {
                padding: '12.7px 0px !important'
            },
            '& .MuiInputAdornment-positionStart': {
                marginLeft: '17px'
            }
        },
        autoText: {
            width: "90%",
            '& .MuiInputBase-input': {
                padding: '17px 14px !important'
            },
        },
    }),
);


let index = 0;
export default function LicensePage() {
    const { licenseStore, appStore, companyStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const classes = useStyles();
    const { companyId, orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    const [searchStr, setSearchStr] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        licenseStore.models = [];
        appStore.isLoading = true;
        Promise.all([
            licenseStore.queryLicenses(companyId),
            licenseStore.queryCompanyLicenseInfor(companyId),
            companyStore.getCompanyUnuseLicense(orgCompanyId),
            companyStore.getCompanyStructure(orgCompanyId)
        ]).then((values: any) => {
            setState({
                ...state,
                licenseInfo: values[1],
                companyTree: values[3].topCompany,
                selectedCompanyId: orgCompanyId,
                companyName: appStore.userAuth?.companyName
            });
            index = 0;
            appStore.isLoading = false;
            companyStore.setWordPressLicenses(values[2]);
            licenseStore.setParams(orgCompanyId, "", "");
        });
    }

    const getState = () => {
        return new Promise<void>((resolve, reject) => {
            setState({
                ...state,
                refresh: state.refresh++
            })
            resolve();
        })
    }

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t("license_name_is_required")
                });
            }
            if (state.editRow.name.trim().length > 255) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('license_name_max_length_is_255')
                });
            }

            if (state.editRow.address.trim().length > 255) {
                valdiationErrors.push({
                    field: 'address',
                    message: t("address_max_length_is_255")
                });
            }

            if (state.editRow.contactFirstName.trim().length === 0 || state.editRow.contactLastName.trim().length === 0) {
                valdiationErrors.push({
                    field: 'contactFirstName',
                    message: t("contact_person_is_required")
                });
                valdiationErrors.push({
                    field: 'contactLastName',
                    message: t("contact_person_is_required")
                });
            } else if (state.editRow.contactFirstName.trim().length > 50 || state.editRow.contactLastName.trim().length > 50) {
                valdiationErrors.push({
                    field: 'contactFirstName',
                    message: t("contact_person_max_length_is_50")
                });
                valdiationErrors.push({
                    field: 'contactLastName',
                    message: t("contact_person_max_length_is_50")
                });
            }
            if (state.editRow.emailAddress.trim().length === 0) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t("email_address_required")
                });
            } else if (!isValidEmail(state.editRow.emailAddress)) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t("email_format_error")
                });
            } else if (state.editRow.emailAddress.trim().length > 255) {
                valdiationErrors.push({
                    field: 'emailAddress',
                    message: t("email_address_max_length")
                });
            }


            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: t('name'), flex: 1, renderCell: ((row: GridCellParams) => {
                const license: License = row.row as License;
                return <LightTooltip title={license.name} placement="bottom-start" arrow>
                    <span>{license.name}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'contactFirstName', headerName: t('contact'), flex: 1,
            renderCell: ((row: GridCellParams) => {
                const license: License = row.row as License;
                return <LightTooltip title={`${license.contactFirstName} ${license.contactLastName}`}
                    placement="bottom-start" arrow>
                    <span>{`${license.contactFirstName} ${license.contactLastName}`}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'phone', headerName: t('phone'), flex: 1, renderCell: ((row: GridCellParams) => {
                const license: License = row.row as License;
                return <LightTooltip title={license.phone} placement="bottom-start" arrow>
                    <span>{license.phone}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'productName', headerName: t("product_name"), flex: 2,
            renderCell: ((row: GridCellParams) => {
                const license: License = row.row as License;
                return <LightTooltip title={license.productName ?? ''} placement="bottom-start" arrow>
                    <span>{license.productName ?? ''}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'expireDate', headerName: t("expire_date"), flex: 1.2,
            renderCell: ((row: GridCellParams) => {
                const license: License = row.row as License;
                return <LightTooltip title={license.expireDate ? moment(license.expireDate).format("YYYY-MM-DD") : ''}
                    style={license.isExpire ? { color: "red" } : {}}
                    placement="bottom-start" arrow>
                    <span>{license.expireDate ? moment(license.expireDate).format("YYYY-MM-DD") : ''}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'newExpire', headerName: t("new_expire_date"),
            sortable: false,
            flex: 1.2, renderCell: ((row: GridCellParams) => {
                const license: License = row.row as License;
                return <LightTooltip title={license.newExpire ? moment(license.newExpire).format("YYYY-MM-DD") : ''} placement="bottom-start" arrow>
                    <span>{license.newExpire ? moment(license.newExpire).format("YYYY-MM-DD") : ""}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'action', headerName: t("apply_license"), flex: 1.5, editable: false, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const model: License = row.row as License;
                let defaultLicense = '';
                if (model.isExpire && companyStore.wordPressLicenses && companyStore.wordPressLicenses.length > index && !model.defaultLicense) {
                    defaultLicense = companyStore.wordPressLicenses[index];
                    var newDate = new Date();
                    if (model.expireDate && new Date(model.expireDate) > newDate) {
                        model.newExpire = moment(model.expireDate).add('day', companyStore.wordPressLicenses[index]?.days).toDate();
                    } else {
                        model.newExpire = moment(newDate).add('day', companyStore.wordPressLicenses[index]?.days).toDate();
                    }
                    model.licenseKey = companyStore.wordPressLicenses[index]?.license_key ?? "";
                    index++;
                }

                return <Box style={{ display: 'flex', justifyContent: '', width: '100%' }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id={"free-solo-demo" + model.id}
                            freeSolo={true}
                            style={{
                                alignItems: "center",
                                display: "flex",
                                zIndex: 99999,
                            }}
                            noOptionsText={t("no_license_found")}
                            onInputChange={(event: any, newValue: any, aa: any) => {
                                if (newValue) {
                                    var finding = _.find(companyStore.wordPressLicenses, { displayName: newValue });
                                    if (finding && finding.days) {
                                        var newDate = new Date();
                                        if (model.expireDate && new Date(model.expireDate) > newDate) {
                                            model.newExpire = moment(model.expireDate).add('day', finding.days).toDate();
                                        } else {
                                            model.newExpire = moment(newDate).add('day', finding.days).toDate();
                                        }

                                        model.licenseKey = finding.license_key || "";
                                        licenseStore.setLicenseKey(model.id, finding.license_key);
                                    } else {
                                        model.licenseKey = newValue || "";
                                        licenseStore.setLicenseKey(model.id, newValue);
                                    }
                                } else {
                                    model.newExpire = undefined;
                                    model.licenseKey = "";
                                    licenseStore.setLicenseKey(model.id, "");
                                }
                                setState({
                                    ...state,
                                    refresh: state.refresh++
                                })
                            }}
                            getOptionLabel={(option: any) => {
                                return option?.displayName ?? "";
                            }}
                            defaultValue={defaultLicense}
                            className={classes.autoText}
                            options={companyStore.wordPressLicenses && companyStore.wordPressLicenses.length > 0 ? companyStore.wordPressLicenses : [{}]}
                            renderInput={(params) =>
                                <TextField {...params}
                                    style={{ padding: "17px 14px !important" }}
                                    placeholder={t("apply_license")}
                                    value={model.licenseKey}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event) => {
                                        model.licenseKey = event.target.value as string;
                                        licenseStore.setLicenseKey(model.id, model.licenseKey);
                                    }}
                                />}
                        />
                    </FormControl>
                </Box>;
            })
        }
    ];


    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
            licenseStore.setSort("createdTime", SortDirection.Descending);
            licenseStore.changePage(0);
        }
    }

    const handExtendAll = () => {
        getState().then(data => {
            if (licenseStore.models && licenseStore.models.length > 0) {
                let params = licenseStore.models.filter(x => x.licenseKey).map(x => {
                    return {
                        Id: x.id,
                        LicenseKey: x.licenseKey
                    }
                });
                if (params && params.length <= 0) {
                    appStore.showInfo = true;
                    appStore.infoMsg = t("please_enter_license_code_to_extend_the_company_expire_date");
                    return;
                }
                licenseStore.assignCompanyLicense(params).then(x => {
                    licenseStore.queryLicenses(companyId, searchStr, state.isExpired.toString());
                    companyStore.getCompanyUnuseLicense(orgCompanyId).then(x => {
                        companyStore.setWordPressLicenses(x);
                    });
                    index = 0;
                });
            }
        })

    }

    const refreshLicenses = (params: any) => {
        setState({
            ...state,
            selectedCompanyId: params.id
        });

        licenseStore.setParams(params.id, searchStr, state.isExpired.toString());
        licenseStore.queryLicenses(params.id, searchStr, state.isExpired.toString());
        index = 0;
    }

    const handleSearch = (value: string) => {
        setSearchStr(value);
        debounceSearch(value);
    }

    const debounceSearch = debounce((value: string) => {
        licenseStore.setParams(state.selectedCompanyId, value, state.isExpired.toString());
        licenseStore.queryLicenses(state.selectedCompanyId, value, state.isExpired.toString());
        index = 0;
    }, 500);

    const companiesView = () => {
        return (<Paper style={{ boxShadow: "unset" }}>
            <Box style={{ display: "flex", marginBottom: 16, justifyContent: "space-between", alignItems: "center" }}>
                <Box >
                    <StructureTree callback={refreshLicenses}></StructureTree>
                    <TextField
                        placeholder={t("search")}
                        style={{ width: 300 }}
                        value={searchStr}
                        onChange={(event) => handleSearch(event.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {<SearchIcon width="24" height="24" viewBox="0 0 24 24" />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    {searchStr.length > 0 &&
                                        <ClearIcon width="24" height="24" viewBox="0 0 24 24"
                                            onClick={() => handleSearch('')} style={{ cursor: 'pointer' }} />}
                                </InputAdornment>
                            )
                        }}

                        InputLabelProps={{ shrink: true }}
                        className={classes.searchTextField}

                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.isExpired}
                                onChange={() => {
                                    licenseStore.setParams(state.selectedCompanyId, searchStr, (!state.isExpired).toString());
                                    licenseStore.queryLicenses(state.selectedCompanyId, searchStr, (!state.isExpired).toString());
                                    index = 0;
                                    setState({ ...state, isExpired: !state.isExpired });
                                }}
                                name="expired"
                                color="primary"
                            />
                        }
                        label={t("show_expired")}
                    />
                </Box>
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={handExtendAll}
                >
                    {t("apply_license")}
                </Button>
            </Box>
            <CommonGridPage<License>
                columns={columns}
                store={licenseStore}
                cancelSearch={true}
                onEdit={(row: License) => setState({ ...state, dialogOpen: true, editRow: row, editMode: FormMode.Edit })}
            />
        </Paper>)
    }

    return (<>

        <Typography
            variant="h5"
            style={{ fontWeight: 600, color: "#374359", marginTop: 12, marginBottom: 16 }}
        >
            {t("my_license")}
        </Typography>
        {
            companiesView()
        }

        <CommonFormDialog<License>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={licenseStore}
            title={`${t("license")}`}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="lblRole" shrink> {t("name")}:</InputLabel>
                        <Select
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, licenseKey: event.target.value as string },
                                errors: [...state.errors].filter(x => x.field !== "departmentId")
                            })}
                            value={state.editRow.licenseKey}
                            displayEmpty
                            disableUnderline>
                            {state.licenses && state.licenses.length > 0 && state.licenses.map((item: any) =>
                                <MenuItem value={item.id} key={item.id}>{item.ownerCompanyId}</MenuItem>
                            )}

                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        label={t("license_name")}
                        value={state.editRow.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "name")
                        })}
                        placeholder={t("license_name")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        value={state.editRow.contactFirstName}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, contactFirstName: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "contactFirstName")
                        })}
                        placeholder={t("contact_first_name")}
                        label={t("contact_first_name")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "contactFirstName") > -1}
                        helperText={state.errors.find(x => x.field === "contactFirstName")?.message || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        value={state.editRow.contactLastName}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, contactLastName: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "contactLastName")
                        })}
                        placeholder={t("contact_last_name")}
                        label={t("contact_last_name")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "contactLastName") > -1}
                        helperText={state.errors.find(x => x.field === "contactLastName")?.message || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPhoneNumber
                        fullWidth
                        defaultCountry={'us'}
                        value={state.editRow.phone}
                        onChange={(value) => setState({ ...state, editRow: { ...state.editRow, phone: value } })}
                        placeholder={t("phone")}
                        label={t("phone")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        value={state.editRow.emailAddress}
                        disabled={state.editMode === FormMode.Edit}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, emailAddress: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "emailAddress")
                        })}
                        placeholder={t("email_address")}
                        label={t("email_address")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "emailAddress") > -1}
                        helperText={state.errors.find(x => x.field === "emailAddress")?.message || ''}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={state.editRow.address}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, address: event.target.value },
                            errors: [...state.errors].filter(x => x.field !== "address")
                        })}
                        placeholder={t("address")}
                        label={t("address")}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                        error={state.errors.findIndex(x => x.field === "address") > -1}
                        helperText={state.errors.find(x => x.field === "address")?.message || ''}
                    />
                </Grid>

                <Grid item xs={12}>
                    <KeyboardDatePicker
                        disableToolbar
                        InputLabelProps={{ shrink: true }}
                        autoOk
                        format="MM/dd/yyyy"
                        margin="normal"
                        label={t("expire_date")}
                        InputAdornmentProps={{ position: "start" }}
                        value={state.editRow.expireDate}
                        onChange={(value: any) => setState({
                            ...state,
                            editRow: { ...state.editRow, expireDate: value },
                            errors: [...state.errors].filter(x => x.field !== "expireDate")
                        })}
                    />
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
}