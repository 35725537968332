import './App.css';
import Login from './Features/Login';
import AboutKK from "./Features/AboutKK";
import ForgetPassword from './Features/ResetPassword';
import ResetPassword from './Features/ResetPassword/inputNewPassword';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import useStores from './Stores/useStores';
import axios, { AxiosRequestConfig } from 'axios';
import { observer } from 'mobx-react';
import { Alert } from "@material-ui/lab";
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './Components/ProtectedRoute';
import Layout from './Features/Layout';
import { useTranslation } from 'react-i18next';

// app
const App = observer(() => {
    const { t } = useTranslation();
    const { appStore } = useStores();
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
        appStore.setLoading(true);
        //console.log(config.url,(config.url || '').indexOf('users/sendResetEmail'));
        if ((config.url || '').indexOf('users/sendResetEmail') > -1) {
            return config;
        }

        if ((config.url || '').indexOf('users/resetUserPassword') > -1) {
            return config;
        }

        if ((config.url || '').indexOf('users/authenticate') === -1) {
            config.headers.Authorization = appStore.userAuth?.token;
        }

        if (appStore.userAuth?.orgCompanyId !== appStore.userAuth?.companyId) {
            config.headers["CompanyId"] = appStore.userAuth?.companyId;
        }

        // console.log(config)
        return config;
    }, error => {
        appStore.setLoading(true);
        return Promise.reject(error);
    });
    //request complete
    axios.interceptors.response.use(response => {
        if (response && response.data && !response.data.ok && response.data.message) {
            appStore.setLoading(false, response?.data?.message);
            return Promise.reject(response?.data);
        }

        // console.log(response)
        appStore.setLoading(false);
        return response;
    }, error => {
        if (!error.ok && error.message) {
            if (error.message_key) {
                if (error.message_key === "one_license_cannot_apply_different_companies" ||
                    error.message_key === "only_support_max_deep" ||
                    error.message_key === "combination_duplicated") {
                    appStore.setLoading(false, t(error.message_key, JSON.parse(error.message)) || t("request_failed"));
                } else {
                    appStore.setLoading(false, t(error.message_key) || t("request_failed"));
                }
            } else {
                appStore.setLoading(false, error.message || t("request_failed"));
            }

        } else {
            appStore.setLoading(false, error?.response?.data?.message || t("request_failed"));
        }
        return Promise.reject(error);
    });

    return (<>
        <Router>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/signup">
                    <Login />
                </Route>
                <Route path="/about">
                    <AboutKK />
                </Route>
                <Route path="/forgetPassword">
                    <ForgetPassword />
                </Route>
                <Route path="/resetPassword/:verifycode">
                    <ResetPassword />
                </Route>
                <ProtectedRoute path="/app" isAuthenticated={appStore.loggedIn} authenticationPath="/login"
                    component={Layout} />
                <Route exact path="/">
                    <Redirect to="/app" />
                </Route>
            </Switch>
        </Router>
        <Backdrop open={appStore.isLoading} style={{ color: '#fff', zIndex: 9999 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={appStore.showError}
            onClose={() => appStore.hideError()}
            autoHideDuration={6000}
            key={'bottom right error'}
        >
            <Alert onClose={() => appStore.hideError()} variant="filled" severity="error">
                <div dangerouslySetInnerHTML={{ __html: appStore.error }}></div>
            </Alert>
        </Snackbar>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={appStore.showInfo}
            onClose={() => appStore.hideInfo()}
            autoHideDuration={6000}
            key={'bottom right info'}
        >
            <Alert onClose={() => appStore.hideInfo()} variant="filled" severity="info">
                {appStore.infoMsg}
            </Alert>
        </Snackbar>
    </>);
});

export default App;
