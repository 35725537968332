import { Box, Link, List, ListItem, ListItemText, Paper, Typography } from "@material-ui/core";
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useStores from "../../Stores/useStores";
import { ChecklistInstance, ChecklistInstanceComplete } from "../../Models/ChecklistInstance";
import { Guid } from "guid-typescript";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import Filter7Icon from '@material-ui/icons/Filter7';
import Replay30Icon from '@material-ui/icons/Replay30';
import LaunchIcon from '@material-ui/icons/Launch';
import CommonGridPage from "../../Components/CommonGridPage";
import { Link as RouteLink } from "react-router-dom";
import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { InstanceFilters } from "../../Components/InstanceFilters"
import moment from "moment";
import { LightTooltip } from "../../Components/ToolTips";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        featureList: {
            display: 'flex',
            padding: "0px",
            '& .MuiListItemText-primary': {
                fontWeight: 700,
                fontSize: 18,
                color: '#374359 !important'
            },
            '& .MuiListItemIcon-root': {
                fontWeight: 700,
                fontSize: 18,
            },
        },
        inline: {
            display: 'inline',
        },
    }),
);

export interface PageState {
    gridId: string,
    deptId: string,
    categoryId: string,
    filterDate: Date,
    recurringType: string,
}

interface CompleteProgressState {
    dailyComplete: number,
    weeklyComplete: number,
    monthlyComplete: number,
}

const initState: PageState = {
    gridId: Guid.create().toString(),
    filterDate: new Date(),
    deptId: '',
    categoryId: '',
    recurringType: 'Daily',
};

const initCompleteState: CompleteProgressState = {
    dailyComplete: 0,
    weeklyComplete: 0,
    monthlyComplete: 0,
}

const Checklist = observer(() => {
    const { appStore } = useStores();
    initState.filterDate = appStore.getFilterDate();
    const [state, setState] = useState<PageState>(initState);
    const [completeState, setCompleteState] = useState<CompleteProgressState>(initCompleteState);
    const classes = useStyles();
    const { checklistInstanceStore } = useStores();
    const { t } = useTranslation();
    //load select department and role data
    useEffect(() => {
        checklistInstanceStore.setPreFilter([{
            field: 'selectedDate',
            value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss")
        }, {
            field: 'recurringType',
            value: 'Daily'
        }]);
        checklistInstanceStore.query();
        getCompleteProgress();
    }, []);


    function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
            <Box display="flex" alignItems="center">
                <Box mr={1}>
                    <Typography variant="body2" color="textSecondary" style={props.style}>{t("completed")}:</Typography>
                </Box>
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box minWidth={35}>
                    <Typography style={props.style} variant="body2" color="textSecondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }


    const handleRecurringTypeChange = (type: string) => {
        handleFilterChange({ recurringType: type });
    }

    const handleFilterChange = (change: any) => {
        setState({ ...state, ...change });
        const changedState = Object.assign({}, { ...state }, { ...change });

        checklistInstanceStore.setPreFilter([{
            field: 'recurringType',
            value: changedState.recurringType
        }, {
            field: 'categoryId',
            value: changedState.categoryId
        }, {
            field: 'selectedDate',
            value: moment(changedState.filterDate).format("YYYY-MM-DD HH:mm:ss")
        }, {
            field: 'departmentId',
            value: changedState.deptId
        }]);

        checklistInstanceStore.changePage(0);
        getCompleteProgress();
    }

    const getCompleteProgress = () => {
        checklistInstanceStore.getCompleteProgress()
            .then((data: ChecklistInstanceComplete) => {
                setCompleteState({
                    ...completeState,
                    weeklyComplete: data.weeklyTasks > 0 ? Math.round(data.weeklyCompleted / data.weeklyTasks * 100) : 0,
                    dailyComplete: data.dailyTasks > 0 ? Math.round(data.dailyCompleted / data.dailyTasks * 100) : 0,
                    monthlyComplete: data.monthlyTasks > 0 ? Math.round(data.monthlyCompleted / data.monthlyTasks * 100) : 0,
                });
            })
            .catch(error => {
                console.error(error);
            })
    }

    const columns: GridColDef[] = [
        {
            field: "checklistInstanceName", headerName: t('checklist'), flex: 1, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const instance: ChecklistInstance = row.row as ChecklistInstance;
                return <LightTooltip title={instance.checklistInstanceName} placement="bottom-start" arrow>
                    <span>{instance.checklistInstanceName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "categoryName", headerName: t("category"), flex: 1, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const instance: ChecklistInstance = row.row as ChecklistInstance;
                return <LightTooltip title={instance.categoryName} placement="bottom-start" arrow>
                    <span>{instance.categoryName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "departmentName", headerName: t("department"), flex: 1, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const instance: ChecklistInstance = row.row as ChecklistInstance;
                return <LightTooltip title={instance.departmentName} placement="bottom-start" arrow>
                    <span>{instance.departmentName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "failedCriticalCount", headerName: t("failed_critical"), flex: 1, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const checklistInstance: ChecklistInstance = row.row as ChecklistInstance;
                return `${checklistInstance.failedCriticalCount}/${checklistInstance.totalCriticalCount}`;
            })
        },
        {
            field: "taskDelayCount", headerName: t("delayed_completion"), flex: 1, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const checklistInstance: ChecklistInstance = row.row as ChecklistInstance;
                return `${checklistInstance.taskDelayCompletedCount}/${checklistInstance.taskDelayCount}`;
            })
        },

        {
            field: "taskCompletedRate", headerName: t("completed"), flex: 1, sortable: true,
            renderCell: ((row: GridCellParams) => {
                const checklistInstance: ChecklistInstance = row.row as ChecklistInstance;
                const progressValue = checklistInstance.totalTask ?
                    Math.round(checklistInstance.taskCompletedRate) : 0;
                return <div style={{ width: "100%" }}>
                    <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={progressValue} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${progressValue}%`}</Typography>
                        </Box>
                    </Box>
                </div>;
            })

        },
        {
            field: "action", headerName: t("actions"), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const checklistInstance: ChecklistInstance = row.row as ChecklistInstance;
                return <Link style={{ textAlign: "center", cursor: 'pointer' }}
                    component={RouteLink} to={`/app/checklist/${checklistInstance.id}`}>
                    <LaunchIcon />
                </Link>
            })

        },
    ]

    return <>
        <CommonPageTitle title={t('checklist')} subTitle={t('check_out_latest_checklist')}></CommonPageTitle>
        <InstanceFilters handleFilterChange={handleFilterChange} />
        <Paper elevation={3} style={{ marginTop: 12 }}>
            <List className={classes.featureList}>
                <ListItem button
                    onClick={() => {
                        handleRecurringTypeChange('Daily');
                    }}
                    style={{
                        backgroundColor: state.recurringType === 'Daily' ? "#1CAD5E" : "#FFFFFF"
                    }}>
                    <ListItemIcon style={{
                        color: state.recurringType === 'Daily' ? "#FFFFFF" : "#9094A9"
                    }}>
                        <Brightness6Icon style={{ fontSize: "38" }} />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment >
                        <Typography style={{ color: state.recurringType === 'Daily' ? "#fff" : "#374359", fontSize: "18px", fontWeight: 700, }}>{t("today")}</Typography>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            <LinearProgressWithLabel style={{ color: state.recurringType === 'Daily' ? "#fff" : "#9094A9" }} value={completeState.dailyComplete} />
                        </Typography>

                    </React.Fragment>
                    } />
                </ListItem>
                <ListItem button
                    onClick={() => {
                        handleRecurringTypeChange('Weekly');
                    }}
                    style={{
                        backgroundColor: state.recurringType === 'Weekly' ? "#1CAD5E" : "#FFFFFF"
                    }}>
                    <ListItemIcon style={{
                        color: state.recurringType === 'Weekly' ? "#FFFFFF" : "#9094A9"
                    }}>
                        <Filter7Icon style={{ fontSize: "38" }} />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment>
                        <Typography style={{ color: state.recurringType === 'Weekly' ? "#fff" : "#374359", fontSize: "18px", fontWeight: 700, }}>{t("weekly")}</Typography>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            <LinearProgressWithLabel style={{ color: state.recurringType === 'Weekly' ? "#fff" : "#9094A9" }} value={completeState.weeklyComplete} />
                        </Typography>
                    </React.Fragment>
                    } />

                </ListItem>
                <ListItem button
                    onClick={() => {
                        handleRecurringTypeChange('Monthly');
                    }}
                    style={{
                        backgroundColor: state.recurringType === 'Monthly' ? "#1CAD5E" : "#FFFFFF"
                    }}>
                    <ListItemIcon style={{
                        color: state.recurringType === 'Monthly' ? "#FFFFFF" : "#9094A9"
                    }}>
                        <Replay30Icon style={{ fontSize: "38" }} />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment>
                        <Typography style={{ color: state.recurringType === 'Monthly' ? "#fff" : "#374359", fontSize: "18px", fontWeight: 700, }}>{t("monthly")}</Typography>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            <LinearProgressWithLabel style={{ color: state.recurringType === 'Monthly' ? "#fff" : "#9094A9" }} value={completeState.monthlyComplete} />
                        </Typography>

                    </React.Fragment>
                    } />
                </ListItem>
            </List>
        </Paper>
        <CommonGridPage<ChecklistInstance>
            key={state.gridId}
            columns={columns}
            cancelSearch={true}
            store={checklistInstanceStore}
            rowHeight={80}
        />
    </>
});

export { Checklist };