import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { CoolingReportModel } from "../../Models/Reports";
import useStores from "../../Stores/useStores";
import ReportHeader, { ReportHeaderState, ReportHeaderProps, } from "../Reports/Comments/ReportHeader";
import moment from "moment";
import { Paper, Grid, Box } from "@material-ui/core";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from "react-i18next";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { excelDownload, makeUrlParams } from "../../Utils/utils";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    }
});

const convertTemperature = (temperature:number, lang: string)=>{
    if(lang == 'spanish'){
        return ((temperature-32)*5/9).toFixed(2);
    }
    return temperature;
}

const coolingReport = observer(() => {
    const { appStore, coolingReportsStore } = useStores();
    const componentRef = useRef<HTMLDivElement>(null);
    const classes = useRowStyles();
    const [queryData, setQueryData] = useState<Array<CoolingReportModel>>();
    const { t, i18n  } = useTranslation();
    const { companyId } = appStore.userAuth as AuthenticateResponseModel;

    const [ sensor, setSensor ] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const _sensor = params.get('sensor') === '1';
        setSensor(_sensor);

        const preFilters = [
            {
                field: "filterDate",
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss"),
            },
            {
                field: "sensor",
                value: `${_sensor ? 1 : 0}`,
            },
        ];

        coolingReportsStore.setPreFilter([...preFilters]);
        coolingReportsStore.queryCSV().then((resp: any) => {
            const { data } = resp;
            setQueryData(data);
        })
    }, []);

    const handleDownload = (filters: ReportHeaderState) => {
        let params = {
            selectedDate: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
            companyId: companyId,
            sensor: `${sensor ? 1 : 0}`,
            lang: i18n.language
        };
        coolingReportsStore.getExcel(makeUrlParams(params)).then((resp: any) => {
            const { data, count } = resp;
            excelDownload(true, data, `Temperature Report All Critical Areas.xlsx`);
        });
        // excelDownload(false, url, `Temperature Report All Critical Areas.xlsx`);
    };

    const handlePrint = (filters: ReportHeaderState) => { };
    const handleFilterChange = (filters: ReportHeaderState) => {
        coolingReportsStore.setPreFilter([
            {
                field: "filterDate",
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
            },
            {
                field: "sensor",
                value: `${sensor ? 1 : 0}`,
            }
        ]);
        coolingReportsStore.queryCSV().then((resp: any) => {
            const { data } = resp;
            setQueryData(data);
        })
    };

    const reportHeaderProps: ReportHeaderProps = {
        title: sensor ? t("cooling_sensor_report") : t("cooling_temperature_report"),
        description: sensor ? t("generate_cooling_sensor_reports") : t("generate_cooling_temperature_reports"),
        showFromDate: true,
        showToDate: false,
        showDepartment: false,
        showEmployee: false,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    };

    function GenerateColumns(column: number) {
        var columns = [];
        for (let index = 0; index < column; index++) {
            columns.push(index);
        }
        return columns;
    }

    function ChildRow(props: { row: CoolingReportModel }) {
        const { row } = props;
        const [openRow, setOpenRow] = useState(false);

        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
                            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{row.checklistName}</TableCell>
                    <TableCell align="center">{row.totalCount}</TableCell>
                    <TableCell align="center">{row.includeCount}</TableCell>
                    <TableCell align="center">{row.completedCount}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openRow} timeout={"auto"} unmountOnExit>
                            <Box margin={1}>
                                <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                                    <Table size="small" aria-label="purchases"  >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("task_name")}</TableCell>
                                                {row.taskColumnCount && row.taskColumnCount > 0 ?
                                                    GenerateColumns(row.taskColumnCount).map(x => (
                                                        <>
                                                            <TableCell align="center">{`${t("temperature")} \r\n #${x + 1}`}</TableCell>
                                                            <TableCell align="center">{"F/P"}</TableCell>
                                                        </>
                                                    ))
                                                    : ""
                                                }
                                                <TableCell align="center">{t("employee")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.tasks && row.tasks.length > 0 ? row.tasks.map((row) => (
                                                <TableRow key={row.taskName}>
                                                    <TableCell component="th" scope="row">{row.taskName}</TableCell>
                                                    {row.childrens && row.childrens.length > 0 ?
                                                        GenerateColumns(row.taskColumnCount).map(x => {
                                                            if ((x + 1) <= row.childrens.length) {
                                                                let item = row.childrens[x];
                                                                return <>
                                                                    <TableCell align="center">{convertTemperature(item.temperature, i18n.language)}</TableCell>
                                                                    <TableCell align="center">{item.isSuccess ? "P" : "F"}</TableCell>
                                                                </>
                                                            } else {
                                                                return <>
                                                                    <TableCell align="center">{""}</TableCell>
                                                                    <TableCell align="center">{""}</TableCell>
                                                                </>
                                                            }
                                                        })
                                                        : ""
                                                    }
                                                    <TableCell align="center">{row.employee}</TableCell>
                                                </TableRow>
                                            )) : <></>}
                                        </TableBody>
                                    </Table>
                                    {!row.tasks || row.tasks.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}>{t("no_data")}</div> : ''}
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    return (
        <><ReportHeader {...reportHeaderProps} />
            <div
                ref={componentRef}
                className="section-to-print"
                style={{ width: '100%' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper} style={{ minHeight: '650px' }}>
                            <Table aria-label={t("checklist_details")} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>{t("checklist_name")}</TableCell>
                                        <TableCell align="center">{t("total_task")}</TableCell>
                                        <TableCell align="center">{t("include_count")}</TableCell>
                                        <TableCell align="center">{t("completed_count")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ width: '100%' }}>
                                    {queryData && queryData.length > 0 ? queryData.map((row) => (
                                        <ChildRow key={row.checklistName} row={row} />
                                    )) : ''}
                                </TableBody>
                            </Table>
                            {!queryData || queryData.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px' }}>{t("no_data")}</div> : ''}
                        </TableContainer>

                    </Grid>
                </Grid>
            </div>
        </>
    );
});

export default coolingReport;
