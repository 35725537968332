
import { Typography } from "@material-ui/core";
 
export default function ExpirePage() {
    return (<>
        <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16 }}
        >
            Your account has been expired, please contract your admin to extend the date.
        </Typography>
    </>
    );
}