import {extendObservable} from "mobx";
import {BaseGridDataStore} from "./BaseGridDataStore";
import { Checklist } from "../Models/Checklist";
import { ChecklistService } from "../Services/ChecklistService";

export class ChecklistStore extends BaseGridDataStore<Checklist> {
    private _checklistService: ChecklistService;

    constructor() {
        let checklistService = new ChecklistService();
        super(checklistService);
        this._checklistService = checklistService;
        extendObservable(this, {
        });
    }

    refresh(categoryId: string) {
        return this._checklistService.refresh(categoryId);
    }
}
