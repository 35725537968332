export interface QueryCriteria {
    skip: number,
    take: number,
    sortBy?: string,
    sortDirection?: SortDirection,
    filters?: QueryFilter[],
    titles?: string[]
}

export interface QueryFilter {
    field: string,
    value: string,
    dataType?: number,
    operator?: number
}


export enum SortDirection {
    Ascending,
    Descending
}