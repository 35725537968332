import axios from "axios";
import { CompanySlot } from "../Models/CompanySlot";
import { BaseService } from "./BaseService";
import { SensorRecord } from "../Models/SensorRecord";
import { Sensor } from "../Models/Sensor";

export class CompanySlotService extends BaseService<CompanySlot> {
  constructor() {
    super("companySlots");
  }

  querySensorRecordsAsync = async (data: any): Promise<SensorRecord[]> => {
    const url = `${process.env.REACT_APP_API_URL}/CompanySlots/SensorRecords`;
    const response = await axios.post<SensorRecord[]>(url, data);
    return response.data;
  };

  querySensors = async (): Promise<Sensor[]> => {
    const url = `${process.env.REACT_APP_API_URL}/CompanySlots/Sensors`;
    const response = await axios.get<Sensor[]>(url);
    return response.data;
  };
}
