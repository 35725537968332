import { ChecklistInstance } from "../Models/ChecklistInstance";
import { RecurringType } from "../Models/RecurringType";
import { TimeType } from "../Models/TimeType";
import { DateTime } from "luxon";
import { DayOfWeek } from "../Models/DayOfWeek";
import { GetOrdinal } from "./NumberUtils";

import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';
import { Checklist } from "../Models/Checklist";
import { CheckListDetailsReport } from "../Models/Reports";

/**
 * TODO DRY
 * @param checklist
 */
export function getRecurringTimeText(checklist: ChecklistInstance | Checklist | CheckListDetailsReport) {
  if (checklist.recurringType === RecurringType.Daily && checklist.timeType === TimeType.OneTime && checklist.startTimeOfDay) {
    return DateTime.fromISO(checklist.startTimeOfDay.toString()).toLocaleString(DateTime.TIME_SIMPLE);
  } else if (checklist.recurringType === RecurringType.Daily && checklist.timeType === TimeType.OnDemand) {
    return "On Demand";
  } else if (checklist.recurringType === RecurringType.Weekly) {
    return DayOfWeek[checklist.startDayOfWeek || 0];
  } else {
    return GetOrdinal(checklist.startDayOfMonth || 1)
  }
};

export function getCompletedTimeText(recurringType: RecurringType, timeType: TimeType, time: any) {
  if (recurringType === RecurringType.Daily && timeType === TimeType.OneTime && time) {
    return DateTime.fromISO(time.toString()).toLocaleString(DateTime.TIME_SIMPLE);
  } else if (recurringType === RecurringType.Daily && timeType === TimeType.OnDemand) {
    return "On Demand";
  } else if (recurringType === RecurringType.Weekly) {
    return moment(time).format("ddd");
  } else {
    return moment(time).format("Do").toUpperCase();
  }
};


export function stringToTestId(string: string) {
  return string.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '');
}

export function makeJSDateObject(date: Date | Moment | DateTime | Dayjs) {
  if (date instanceof dayjs) {
    return (date as Dayjs).clone().toDate();
  }

  if (moment.isMoment(date)) {
    return (date as Moment).clone().toDate();
  }

  if (date instanceof DateTime) {
    return date.toJSDate();
  }

  if (date instanceof Date) {
    return new Date(date.getTime());
  }

  return date as any; // handle case with invalid input
}

export function copy(text: string) {
  return navigator.clipboard.writeText(text);
}

export function loadScript(src: string, position: Element) {
  const script = document.createElement('script');
  script.setAttribute('async', '');
  // eslint-disable-next-line no-console
  script.onerror = console.log;
  script.src = src;
  position.appendChild(script);

  return script;
}

export function getRandomItem<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}
// use to download a scv
export function funCSVDownload(content: string, filename: string) {
  const eleLink = document.createElement('a');
  eleLink.download = filename;
  eleLink.style.display = 'none';
  const contentData = "\ufeff" + content;
  const blob = new Blob([contentData], { type: "text/csv,charset=UTF-8" });
  eleLink.href = URL.createObjectURL(blob);
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
};

export function excelDownload(isBase64: boolean, url: string, filename: string) {
  if(isBase64){
      url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${url}`;
  }
  if (!filename.includes(".xlsx")) {
    filename += ".xlsx";
  }
  let dom = document.createElement('a') as any;
  dom.href = url;
  dom.download = decodeURI(filename);
  dom.style.display = 'none';
  document.body.appendChild(dom);
  dom.click();
  dom.parentNode.removeChild(dom);
}

export function makeUrlParams(params: any) {
  let allParams = new Array();
  Object.keys(params).forEach(name => {
    allParams.push(`${name}=${params[name]}`);
  });
  return allParams.join("&");
}