import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Scorecard } from "../../Models/Reports";
import useStores from "../../Stores/useStores";
import ReportHeader, {
  ReportHeaderState,
  ReportHeaderProps,
} from "../Reports/Comments/ReportHeader";
import moment from "moment";
import ReactEcharts from "echarts-for-react";
import { Paper, Grid, Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Done from "@material-ui/icons/Done";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { QueryFilter } from "../../Models/QueryCriteria";
import { Company } from "../../Models/Company";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

type CompanyData = {
  id: string,
  name: string,
  data: number[],
}

const scorecard = observer(() => {
  const { bizScorecardStore, appStore, companyStore } = useStores();
  const scorecardStore = bizScorecardStore;
  const componentRef = useRef<HTMLDivElement>(null);
  const [queryData, setQueryData] = useState<Array<Scorecard>>();
  const [chartOption, setChartOption] = useState<any>({});
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [chartKey, setChartKey] = useState(0);
  const { t } = useTranslation();
  const today = moment();
  const start = today.clone().isoWeekday(1);
  const end = start.clone().isoWeekday(7);
  useEffect(() => {
    const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    companyStore.getCompanyStructure(orgCompanyId).then((result: any) => {
      setCompanies(result.companyList || []);
      const companyIds = (result.companyList || []).map((x: any) => x.id);

      const preFilters = [
        {
          field: "fromDate",
          value: start.format("YYYY-MM-DD"),
        },
        {
          field: "toDate",
          value: end.format("YYYY-MM-DD"),
        },
        {
          field: "companyIds",
          value: companyIds.join(","),
        },
      ];
      scorecardStore.setPreFilter([...preFilters]);
      scorecardStore.queryCSV().then((resp: any) => {
        const { data } = resp;
        setQueryData(data);
        loadChart({ data });
      });
    });
  }, []);

  const getDates = () => {
    let fromDate = undefined;
    let toDate = undefined;
    for (const { field, value } of scorecardStore.preFilters) {
      field === "fromDate" && (fromDate = moment(value));
      field === "toDate" && (toDate = moment(value));
    }
    const dates = [];
    while (fromDate!.diff(toDate) <= 0) {
      dates.push(fromDate!.format("YYYY-MM-DD"));
      fromDate!.add({ days: 1 });
    }
    return dates;
  };

  const loadChart = ({ data, selectedCompanies }: any) => {
    const dates = getDates();

    const _companies = (selectedCompanies || companies).map(
      ({ id, name }: any) => ({ id, name })
    );
    const series: any[] = [];
    const companyData = new Map<string, CompanyData>();
    (selectedCompanies || companies).forEach(({ id, name }: { id: string, name: string }) => {
      const dataList = dates.map(d => 0);
      companyData.set(id, { id, name, data: dataList });
    });
    const dateMap = new Map<string, number>();
    dates.forEach((date, i) => {
      dateMap.set(date, i);
    });
    for (const item of (data || queryData)) {
      const date = moment(item.date).format('YYYY-MM-DD')
      const index = dateMap.get(date);
      const company = companyData.get(item.companyId);
      if(company && typeof index == 'number'){
        company.data[index] += item.totalScore;
      }
    }
    // for (const { id, name } of _companies) {
    //   const companyData = (data || queryData).filter(
    //     (x: any) => x.companyId === id
    //   );
    //   console.info('company data - ', companyData, dates);
    //   const scores: Array<Number> = [];
    //   for (const d of dates) {
    //     const dateData = companyData.filter((x: any) => {
    //       // console.info(`${x.date} - ${d} = ${moment(x.date).diff(moment(d))}`);
    //       const dateStr = moment(x.date).format('YYYY-MM-DD');
    //       return moment(x.date).diff(moment(d)) === 0;
    //     });
    //     console.info('data - ', dateData);
    //     let score = _.sumBy(
    //       dateData,
    //       "totalScore"
    //     );
    //     let mock = false;
    //     if (window.location.search.length > 0) {
    //       const params = window.location.search.substring(1).split("&");
    //       params.forEach((p: any) => {
    //         const kv = p.split("=");
    //         if (kv[0] === "mock") {
    //           mock = kv[1] === "true";
    //           return;
    //         }
    //       });
    //     }
    //     mock && (score += Math.random() * 100);
    //     console.info('score - ', score);
    //     scores.push(score);
    //   }
    //   series.push({
    //     name,
    //     type: "line",
    //     data: scores,
    //   });
    // }
    companyData.forEach(c=>{
      series.push({
        name: c.name,
        type: "line",
        data: c.data
      });
    });
    const option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: _companies.map((c: any) => c.name),
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: dates,
      },
      yAxis: {
        type: "value",
      },
      series,
    };

    setChartKey(chartKey + 1);
    setChartOption(option);
  };

  const handleDownload = (filters: ReportHeaderState) => {
    const fromDate = moment(filters.fromDate).format("YYYY-MM-DD");
    const toDate = moment(filters.toDate).format("YYYY-MM-DD");
    const companyIds = filters.companyIds;
    scorecardStore.download("xlsx", fromDate, toDate, companyIds);
  };

  const handlePrint = (filters: ReportHeaderState) => {
    const fromDate = moment(filters.fromDate).format("YYYY-MM-DD");
    const toDate = moment(filters.toDate).format("YYYY-MM-DD");
    const companyIds = filters.companyIds;
    scorecardStore.download("pdf", fromDate, toDate, companyIds);
  };

  const handleFilterChange = (filters: ReportHeaderState) => {
    scorecardStore.setPreFilter([
      {
        field: "companyIds",
        value: companies.map(({ id }: any) => id).join(","),
      },
      {
        field: "fromDate",
        value: moment(filters.fromDate).format("YYYY-MM-DD"),
      },
      {
        field: "toDate",
        value: moment(filters.toDate).format("YYYY-MM-DD"),
      },
    ]);

    setTimeout(() => {
      !appStore.isLoading &&
        scorecardStore.queryCSV().then((resp: any) => {
          const { data } = resp;
          setQueryData(data);
          loadChart({ data });
        });
    }, 3000);
  };

  const handleCompanyChange = (selectedCompanies: any[]) => {
    loadChart({ selectedCompanies });
  };

  const reportHeaderProps: ReportHeaderProps = {
    title: t("scorecard"),
    description: t("generate_scorecard"),
    showFromDate: true,
    showToDate: true,
    showCompanies: true,
    multipleCompanies: true,
    showDepartment: false,
    showEmployee: false,
    showWeekPicker: false,
    showDownload: true,
    showPrint: true,
    showBack: false,
    handleChange: handleFilterChange,
    handleCompanyChange: handleCompanyChange,
    handleDownload: handleDownload,
    handlePrint: handlePrint,
    downloadText: "Excel",
    printText: "PDF",
    initValues: { fromDate: start.toDate(), toDate: end.toDate() },
  };



  return (
    <>
      <ReportHeader {...reportHeaderProps} />
      <div
        ref={componentRef}
        className="section-to-print"
        style={{ width: "100%" }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Paper>
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{ padding: "30px" }}
                >
                  {`${t("scorecard_chart")}`}
                </Typography>
              </Box>
              <ReactEcharts key={chartKey} option={chartOption} />
            </Paper>
          </Grid>
          {/*           
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ minHeight: "500px" }}>
              <Table aria-label={t("checklist_details")}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left">{t("category")}</TableCell>
                    <TableCell align="left">{t("checklist")}</TableCell>
                    <TableCell align="left">
                      {`${t("total_tasks")}`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
                      {_.sumBy(queryData || [], "totalTasks")})
                    </TableCell>
                    <TableCell align="left">
                      {t("total_points")}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
                      {_.sumBy(queryData || [], "totalPoints")})
                    </TableCell>
                    <TableCell align="left">
                      {t("total_score")}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
                      {_.sumBy(queryData || [], "totalScore")})
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: "100%" }}>
                  {queryData && queryData.length > 0
                    ? queryData.map((row) => (
                        <ChildRow key={row.id} row={row} />
                      ))
                    : ""}
                </TableBody>
              </Table>
              {!queryData || queryData.length <= 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  {t("no_data")}
                </div>
              ) : (
                ""
              )}
            </TableContainer>
          </Grid>
          */}
        </Grid>
      </div>
    </>
  );
});

export default scorecard;
