import { IHasId } from "./IHasId";

export interface Task extends IHasId {
    id: string,
    name: string,
    checklistGroupId: string,
    checklistId: string
    taskType: TaskType,
    isMandatory: boolean,
    isCritical: boolean,
    isCentigrade: boolean,
    temperatureStart?: string | number | undefined,
    temperatureEnd?: string | number | undefined,
    isScorecard?: boolean,
    score?: string | number | undefined,
    explanation: string,
    sensorId: string,
    photos: string[],
    correctiveActionIds: string[],
    notificationRoleIds: string[],
    isIncludeTemperatureReport: boolean
}

export enum TaskType {
    YesOrNo,
    Temperature,
    Thermometer,
    Sensor,
}