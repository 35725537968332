import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Scorecard } from "../../Models/Reports";
import useStores from "../../Stores/useStores";
import ReportHeader, {
  ReportHeaderState,
  ReportHeaderProps,
} from "../Reports/Comments/ReportHeader";
import moment from "moment";
import {
  Paper,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Done from "@material-ui/icons/Done";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const scorecard = observer(() => {
  const { scorecardStore, appStore } = useStores();
  const componentRef = useRef<HTMLDivElement>(null);
  const classes = useRowStyles();
  const [queryData, setQueryData] = useState<Array<Scorecard>>();
  const { t } = useTranslation();

  useEffect(() => {
    const preFilters = [
      {
        field: "date",
        value: moment().format("YYYY-MM-DD"),
      },
    ];
    scorecardStore.setPreFilter([...preFilters]);
    scorecardStore.queryCSV().then((resp: any) => {
      const { data } = resp;
      setQueryData(data);
    });
  }, []);

  const handleDownload = (filters: ReportHeaderState) => {
    scorecardStore.download(moment(filters.fromDate).format("YYYY-MM-DD"), 'xlsx');
  };

  const handlePrint = (filters: ReportHeaderState) => {
    scorecardStore.download(moment(filters.fromDate).format("YYYY-MM-DD"), 'pdf');
  };

  const handleFilterChange = (filters: ReportHeaderState) => {
    scorecardStore.setPreFilter([
      {
        field: "date",
        value: moment(filters.fromDate).format("YYYY-MM-DD"),
      },
    ]);
    scorecardStore.queryCSV().then((resp: any) => {
      const { data } = resp;
      setQueryData(data);
    });
  };

  const reportHeaderProps: ReportHeaderProps = {
    title: t("scorecard"),
    description: t("generate_scorecard"),
    showFromDate: true,
    showToDate: false,
    showDepartment: false,
    showEmployee: false,
    showWeekPicker: false,
    showDownload: true,
    showPrint: true,
    showBack: false,
    handleChange: handleFilterChange,
    handleDownload: handleDownload,
    handlePrint: handlePrint,
    downloadText: "Excel",
    printText: "PDF",
  };

  function ChildRow(props: { row: Scorecard }) {
    const { row } = props;
    const [openRow, setOpenRow] = useState(false);
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenRow(!openRow)}
            >
              {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">{row.categoryName}</TableCell>
          <TableCell align="left">{row.checklistName}</TableCell>
          <TableCell align="center">{row.totalTasks}</TableCell>
          <TableCell align="center">{row.totalPoints}</TableCell>
          <TableCell align="center">{row.totalScore}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={openRow} timeout={"auto"} unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  {`${row.checklistName}`}
                </Typography>
                <TableContainer
                  component={Paper}
                  style={{ minHeight: "100px", marginTop: "10px" }}
                >
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("task")}</TableCell>
                        <TableCell>{t("completed")}</TableCell>
                        <TableCell>{t("success")}</TableCell>
                        <TableCell>{t("points")}</TableCell>
                        <TableCell>{t("score")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.tasks && row.tasks.length > 0 ? (
                        row.tasks.map((taskRow) => (
                          <TableRow key={taskRow.id}>
                            <TableCell component="th" scope="row">
                              {taskRow.taskName}
                            </TableCell>
                            <TableCell>
                              {!!taskRow.isCompleted && <Done />}
                            </TableCell>
                            <TableCell>
                              {!!taskRow.isSuccess && <Done />}
                            </TableCell>
                            <TableCell>
                              {taskRow.points}
                            </TableCell>
                            <TableCell>
                              {taskRow.score}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                  {!row.tasks || row.tasks.length <= 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100px",
                      }}
                    >
                      No CheckList
                    </div>
                  ) : (
                    ""
                  )}
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <ReportHeader {...reportHeaderProps} />
      <div
        ref={componentRef}
        className="section-to-print"
        style={{ width: "100%" }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ minHeight: "500px" }}>
              <Table aria-label={t("checklist_details")}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left">{t("category")}</TableCell>
                    <TableCell align="left">{t("checklist")}</TableCell>
                    <TableCell align="left">{`${t("total_tasks")}`}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({_.sumBy(queryData || [], "totalTasks")})</TableCell>
                    <TableCell align="left">{t("total_points")}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({_.sumBy(queryData || [], "totalPoints")})</TableCell>
                    <TableCell align="left">{t("total_score")}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({_.sumBy(queryData || [], "totalScore")})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: "100%" }}>
                  {queryData && queryData.length > 0
                    ? queryData.map((row) => (
                      <ChildRow key={row.id} row={row} />
                    ))
                    : ""}
                </TableBody>
              </Table>
              {!queryData || queryData.length <= 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  {t("no_data")}
                </div>
              ) : (
                ""
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default scorecard;
