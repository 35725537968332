import axios from "axios";
import {ChecklistInstance, ChecklistInstanceComplete} from "../Models/ChecklistInstance";
import { BaseService } from "./BaseService";
import {QueryCriteria} from "../Models/QueryCriteria";
import {TaskInstanceComment, TaskInstanceDetail, TaskInstanceFollowUp} from "../Models/TaskInstance";
import {PagedResult} from "../Models/PagedResult";

export class ChecklistInstanceService extends BaseService<ChecklistInstance> {
    constructor() {
        super('checklistInstances');
    }
    async getCompleteProgressAsync (criteria: QueryCriteria): Promise<ChecklistInstanceComplete>  {
        const url = `${process.env.REACT_APP_API_URL}/checklistInstances/completeProgress`;
        const response = await axios.post<ChecklistInstanceComplete>(url, criteria);
        return response.data;
    }

    async GetChichatCheklistDetailsAsync (criteria: QueryCriteria): Promise<ChecklistInstance[]>  {
        const url = `${process.env.REACT_APP_API_URL}/checklistInstances/getChichatCheklistDetails`;
        const response = await axios.post<ChecklistInstance[]>(url, criteria);
        return response.data;
    }

    async getChecklistDetailAsync (checklistId:string):Promise<ChecklistInstance> {
        const url = `${process.env.REACT_APP_API_URL}/checklistInstances/${checklistId}`;
        const response = await axios.get<ChecklistInstance>(url);
        return response.data;
    }

    async getTaskDetailAsync (taskInstanceId?:string):Promise<TaskInstanceDetail> {
        const url = `${process.env.REACT_APP_API_URL}/taskInstances/getTaskInstanceDetail/${taskInstanceId}`;
        const response = await axios.get<TaskInstanceDetail>(url);
        return response.data;
    }

    async getTaskFollowUpsAsync (taskInstanceId?:string):Promise<TaskInstanceFollowUp[]> {
        const url = `${process.env.REACT_APP_API_URL}/taskInstances/getTaskInstanceFollowups/${taskInstanceId}`;
        const response = await axios.get<TaskInstanceFollowUp[]>(url);
        return response.data;
    }


    async getTaskCommentsAsync (criteria: QueryCriteria): Promise<PagedResult<TaskInstanceComment>>  {
        const url = `${process.env.REACT_APP_API_URL}/taskInstances/getTaskInstanceComments`;
        const response = await axios.post<PagedResult<TaskInstanceComment>>(url, criteria);
        return response.data;
    }

    async replayCommentAsync(taskInstanceId:string,groupId:string,replayTo:string,content:string): Promise<boolean>  {
        const url = `${process.env.REACT_APP_API_URL}/taskInstances/AddTaskInstanceComment`;
        const response = await axios.post<boolean>(url, {
            TaskInstanceId:taskInstanceId,
            CommentToEmployeeId:replayTo,
            CommentsDetail:content,
            GroupId:groupId
        });
        return response.data;
    }

    async refresh(checklistId:string) {
        const url = `${process.env.REACT_APP_API_URL}/checklistInstances/refresh/${checklistId}`;
        const response = await axios.post<boolean>(url);
        return response.data;
    }
}