import { observer } from "mobx-react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Box, Button, Divider, InputAdornment, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Guid } from "guid-typescript";
import { CloudLibraryFormDialog } from "./CloudLibraryFormDialog";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Cloudlibrary } from "../../Models/Cloudlibrary";
import { FormMode } from "../Category/ChecklistFormDialog";
import { Category } from "../../Models/Category";
import { ValidationError } from "../../Models/ValidationError";
import useStores from "../../Stores/useStores";
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from 'react-i18next';
import LinkIcon from "../../Components/Icons/LinkIcon";
import ViewIcon from "../../Components/Icons/ViewIcon";
import { useConfirm } from "material-ui-confirm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            maxWidth: 345,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        fileDescription: {
            wordBreak: "break-all",
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: "40px",
        },
        filename: {
            wordBreak: "break-all",
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: "30px",
        },
        searchTextField: {
            width: 200,
            marginLeft: 24,
            '& .MuiInputBase-input': {
                padding: '9px 0px !important',
                height: "32px",
            },
            '& .MuiInputAdornment-positionStart': {
                marginLeft: '17px'
            }
        },
    }),
);

interface CloudLibraryState {
    dialogId: string,
    categoryId: string,
    search: string,
    dialogOpen: boolean,
    editRow: Cloudlibrary,
    editMode: FormMode,
    categories: Category[],
    errors: ValidationError[],
}

const initEditRow: Cloudlibrary = {
    id: '',
    name: '',
    categoryId: '',
    categoryName: '',
    url: '',
    description: '',
    videoUrl: ''
};

const initState: CloudLibraryState = {
    dialogId: Guid.create().toString(),
    search: '',
    categoryId: '',
    dialogOpen: false,
    editMode: FormMode.Add,
    editRow: initEditRow,
    categories: [],
    errors: []
};
const GetCartHead = (item: Cloudlibrary) => {
    if (item.fileType === 3) { // doc pdf
        return <DescriptionIcon style={{ fontSize: 40, marginTop: 15, color: "#9094A9" }}></DescriptionIcon>
    } else if (item.fileType === 1) { // mp4
        return <VideoLibraryIcon style={{ fontSize: 40, marginTop: 15, color: "#9094A9" }}></VideoLibraryIcon>
    } else if (item.fileType === null && item.videoUrl !== "") {
        return <LinkIcon style={{ fontSize: 40, marginTop: 15, color: "#9094A9" }}></LinkIcon>
    }
    // image
    return <div style={{ height: "80%", width: "100%", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <img src={item.url} alt={"Roma"} style={{ maxHeight: "100%", maxWidth: "100%", verticalAlign: "middle" }}></img>
    </div>
}
const CloudLibrary = observer(() => {
    const classes = useStyles();
    const [state, setState] = useState<CloudLibraryState>(initState);
    const { categoryStore, cloudLibraryStore, appStore } = useStores();
    const { hasActionPermission } = appStore.userAuth as AuthenticateResponseModel;
    const { t } = useTranslation();
    const confirm = useConfirm();
    
    useEffect(() => {
        getSelectList();
        cloudLibraryStore.changePageSize(50);
    }, []);

    const getSelectList = () => {
        Promise.all([categoryStore.queryAll()]).then(values => {
            setState({ ...state, categories: values[0] });
        });
    };

    const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const categoryId = event.target.value as string;
        setState({ ...state, categoryId: categoryId });
        if (categoryId && categoryId !== "") {
            cloudLibraryStore.setPreFilter([
                {
                    field: 'categoryId',
                    value: categoryId
                },])
        } else {
            cloudLibraryStore.setPreFilter([]);
        }

        cloudLibraryStore.query();
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initEditRow, id: Guid.create().toString() },
            dialogId: Guid.create().toString()
        })
    }

    const handleEdit = (entiy: Cloudlibrary) => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Edit,
            editRow: { ...entiy },
            dialogId: Guid.create().toString()
        })
    }

    const changeSearch = (search: string) => {
        setState({ ...state, search: search });
        cloudLibraryStore.changeSearch(search);
    }

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }

    const handleDelete = (id: string) => {
        confirm({
            title: `${t("warning")}`,
            description: `${t("delete_default_file")}`,
        }).then(() => {
            cloudLibraryStore.delete(id);
        })
    }

    const download = (url: string) => {
        const eleLink = document.createElement('a');
        eleLink.style.display = 'none';
        eleLink.target = "_blank"
        eleLink.href = url;
        // eleLink.href = record;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
    };

    return <>
        <CommonPageTitle title={t('cloud_library')}
            subTitle={t('upload_files_for_employees_to_browse')}
        />
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <Box>
                    <FormControl className={classes.formControl} style={{ marginTop: 25 }}>
                        <InputLabel></InputLabel>
                        <TextField
                            placeholder={t("search")}
                            style={{ width: 300 }}
                            value={state.search}
                            onChange={(event) => changeSearch(event.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {<SearchIcon width="24" height="24" viewBox="0 0 24 24" />}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {state.search.length > 0 &&
                                            <ClearIcon width="24" height="24" viewBox="0 0 24 24"
                                                onClick={() => changeSearch('')} style={{ cursor: 'pointer' }} />}
                                    </InputAdornment>
                                )
                            }}

                            InputLabelProps={{ shrink: true }}
                            className={classes.searchTextField}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel></InputLabel>
                        <Select fullWidth
                            displayEmpty
                            disableUnderline
                            style={{ width: 300 }}
                            value={state.categoryId}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">{t('all_categories')}</MenuItem>
                            {state.categories.map(x =>
                                <MenuItem value={x.id}>{x.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: 24 }}>
                    <FormControl className={classes.formControl}>
                        {hasActionPermission &&
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={handleCreate}
                            >
                                {t('upload_file')}
                            </Button>
                        }

                    </FormControl>
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
            {cloudLibraryStore.models.map(cloudLibrary => <>
                <Grid item xs={3} key={cloudLibrary.id}>
                    <Card className={classes.cardRoot}>
                        <CardActionArea>
                            <Typography
                                component="div"
                                style={{ height: 160, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                            >

                                <GetCartHead {...cloudLibrary}></GetCartHead>
                            </Typography>
                            <Divider style={{ width: '100%', marginTop: 10 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h6" className={classes.filename}>
                                    {cloudLibrary.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p"
                                    className={classes.fileDescription}>
                                    {cloudLibrary.description}
                                </Typography>
                            </CardContent>
                            <Divider style={{ width: '100%', marginTop: 0 }} />
                        </CardActionArea>
                        <CardActions>
                            <Grid container spacing={1}>
                                {typeof cloudLibrary.videoUrl !== 'undefined' && cloudLibrary.videoUrl !== null && cloudLibrary.videoUrl !== "" &&
                                    <Grid item xs={4} style={{ textAlign: "center", borderRight: "1px solid rgb(144 148 169 / 40%)" }}>
                                        <IconButton aria-label="download" color="primary"
                                            onClick={() => download(cloudLibrary.videoUrl)}>
                                            <ViewIcon style={{ color: "#1CAD5E" }} />
                                        </IconButton>
                                    </Grid>
                                }
                                {typeof cloudLibrary.url !== 'undefined' && cloudLibrary.url !== null && cloudLibrary.url !== "" &&
                                    <Grid item xs={4} style={{ textAlign: "center", borderRight: "1px solid rgb(144 148 169 / 40%)" }}>
                                        <IconButton aria-label="download" color="primary"
                                            onClick={() => download(cloudLibrary.url)}>
                                            <GetAppIcon />
                                        </IconButton>
                                    </Grid>
                                }
                                {hasActionPermission &&
                                    <Grid item xs={4} style={{ textAlign: "center", borderRight: "1px solid rgb(144 148 169 / 40%)" }}>
                                        <IconButton aria-label="edit" color="primary">
                                            <EditIcon onClick={() => handleEdit(cloudLibrary)} />
                                        </IconButton>
                                    </Grid>
                                }
                                {hasActionPermission &&
                                    <Grid item xs={4} style={{ textAlign: "center" }}>
                                        <IconButton aria-label="delete" color="primary">
                                            <DeleteIcon onClick={() => handleDelete(cloudLibrary.id)} />
                                        </IconButton>
                                    </Grid>
                                }

                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>

            </>)
            }
            {cloudLibraryStore.models.length ? null : <Grid item xs={12}>
                <Typography gutterBottom variant="h2" component="h2"
                    style={{ color: "#9094A9", textAlign: "center", paddingTop: 20 }}>
                    No Data
                </Typography>
            </Grid>
            }
        </Grid>

        <CloudLibraryFormDialog key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={cloudLibraryStore}
            categories={state.categories}
            title={t('cloud_library')}
            onClose={() => setState({ ...state, dialogOpen: false, errors: [] })}
            onSubmit={handleSubmit} />
    </>;
}
)

export default CloudLibrary;