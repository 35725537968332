import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Languages from "../../Components/Languages";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    h6title: {
        fontWeight: 700, color: "#1CAD5E", textAlign: "left", marginTop: 60
    },
    body1: {
        width: 960,
        marginTop: "20px",
        fontSize: 14,
        lineHeight: "22px",
    }
}));

const AboutKK = observer(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    return <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}>
            <img alt="" src="/kk-logo.png" style={{ width: "280px", height: "72px" }} />
            <Languages></Languages>
        </div>
        <Paper style={{ paddingTop: 80, paddingLeft: 240, paddingBottom: 80, marginTop: 20 }} elevation={3}>
            <Typography
                variant="h2"
                style={{ fontWeight: 700, color: "#374359", textAlign: "left" }}
            >
                {t('my_kitchen_inspector')}
            </Typography>
            <Typography
                variant="h6"
                className={classes.h6title}
            >
                {t('disclaimer')}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.body1}>
                {t('welcome_to_My_Kitchen_Inspector')}
            </Typography>
            <Typography
                variant="h6"
                className={classes.h6title}
            >
                {t('my_klean_kitchen_overview')}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.body1}>
                {t('the_foodservice_industry')}
            </Typography>
            <Typography
                variant="h6"
                className={classes.h6title}
            >
                {t('food_safety_hazards')}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.body1}>
                {t('according_to_fda')}
                <ul>
                    <li>
                        {t('improper_cooking_temperatures')}
                    </li>
                    <li>
                        {t('failure_properly_cool_foods')}
                    </li>
                    <li>
                        {t('improper_hot_cold_holding')}
                    </li>
                    <li>
                        {t('poor_personal_hygiene')}
                    </li>
                    <li>
                        {t('cross_contamination')}
                    </li>
                    <li>
                        {t('buying_food')}
                    </li>
                </ul>
                {t('potential_hazards')}
                <ul>
                    <li>
                        {t('biological_hazards')}
                    </li>
                    <li>
                        {t('chemical_hazards')}
                    </li>
                    <li>
                        {t('physical_hazards_broken')}
                    </li>
                </ul>
                {t('microorganisms')}<br />
                <span style={{ color: "#1CAD5E" }}>{t('bacteria')}</span>
                <ul>
                    <li>{t('salmonella')}</li>
                    <li>{t('shigella')}</li>
                    <li>{t('listeria_monocytogenes')}</li>
                    <li>{t('staphylococcus_aureus')}</li>
                    <li>{t('bacillus_cereus')}</li>
                    <li>{t('clostridium_botulinum')}</li>
                    <li>{t('escherichia_coli')}</li>
                </ul>

                <span style={{ color: "#1CAD5E" }}>{t('viruses')}</span>
                <ul>
                    <li>{t('hepatovirus')}</li>
                    <li>{t('norwalk_virus_gastroenteritis')}</li>
                    <li>{t('rotavirus_gastroenteritis')}</li>
                </ul>
                <span style={{ color: "#1CAD5E" }}>{t('parasites')}</span>
                <ul>
                    <li>{t('trichinel_spiralis')}</li>
                    <li>{t('anisakis_simplex')}</li>
                    <li>{t('toxoplasma_gondii')}</li>

                </ul>
                <span style={{ color: "#1CAD5E" }}>{t('meat')}</span>
                <ul>
                    <li>
                        {t('cyclospora_cayetanensis')}
                    </li>
                </ul>
            </Typography>

        </Paper>
    </div>
})
export default AboutKK;