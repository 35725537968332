import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import { Link } from "react-router-dom";
import EmployeeIcon from '../../Components/Icons/EmployeeIcon';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles({
    featureList: {
        display: 'flex',
        '& a:not(:last-child)': {
            borderRight: 'solid 1px rgba(0, 0, 0, 0.12)'
        },
        '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: 20,
            color: '#374359 !important'
        }
    },
});
export function Organization() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (<>
        <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#374359", fontSize: 24, marginTop: 12, marginBottom: 10 }}
        >
            {t('organization')}
        </Typography>
        <Typography
            variant="h5"
            style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
        >
            {t('organization_part_desc')}
        </Typography>
        <Paper elevation={3} style={{ marginTop: 32 }}>
            <List className={classes.featureList}>
                <ListItem component={Link} to="/app/companyroles">
                    <ListItemAvatar>
                        <AccountBoxIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('roles')} secondary={t('roles_part_desc')} />
                </ListItem>
                <ListItem component={Link} to="/app/departments">
                    <ListItemAvatar>
                        <GroupIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('departments')} secondary={t('departments_part_desc')} />
                </ListItem>
                <ListItem component={Link} to="/app/employees">
                    <ListItemAvatar>
                        <EmployeeIcon style={{ color: '#1CAD5E', fontSize: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={t('employees')} secondary={t('employees_part_desc')} />
                </ListItem>
            </List>
        </Paper>
    </>)
}