
import { extendObservable } from "mobx";
import { CheckListDetailsReport } from "../Models/Reports";
import { ChecklistDetailReportsService } from "../Services/ChecklistDetailReportsService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class CheckklistDetailReportsStore extends BaseGridDataStore<CheckListDetailsReport>{
    private _checklistInstanceService: ChecklistDetailReportsService;

    constructor() {
        let checklistInstanceService = new ChecklistDetailReportsService();
        super(checklistInstanceService);
        this._checklistInstanceService = checklistInstanceService;
        extendObservable(this, {
        });
    }

}