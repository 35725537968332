import { observer } from "mobx-react";
import React, { useState } from "react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { InstanceFilters } from "../../Components/InstanceFilters"
import { Paper, Typography } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { Guid } from "guid-typescript";
import { CorrectiveActionInstance } from "../../Models/CorrectiveActionInstance";
import CommonGridPage from "../../Components/CommonGridPage";
import useStores from "../../Stores/useStores";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from 'react-i18next';
interface CorrectiveActionInstancePageState {
    gridId: string,
    deptId: string,
    categoryId: string,
    filterDate: Date,
    isRead: boolean,
}

const initState: CorrectiveActionInstancePageState = {
    gridId: Guid.create().toString(),
    filterDate: new Date(),
    deptId: '',
    categoryId: '',
    isRead: false,
};


export function TaskRowCellInfo(props: { taskInstanceName: string, checklistInstanceName: string, executeTime: Date }) {
    const { taskInstanceName, checklistInstanceName, executeTime } = props;
    return <div style={{ display: "block;" }}>
        <LightTooltip title={taskInstanceName} placement="top-start" arrow>
            <Typography
                style={{
                    fontWeight: 700,
                    color: "#374359",
                    marginTop: 12,
                    marginBottom: 2,
                    wordWrap: "break-word"
                }}
            >
                {taskInstanceName}
            </Typography>
        </LightTooltip>
        <Typography
            style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
        >
            {checklistInstanceName} - {moment(executeTime).format("LT,MMM D")}
        </Typography>
    </div>;
}

export function CorrectActionCellInfo(props: CorrectiveActionInstance) {
    const { correctActionName, createTime, createBy } = props;
    const listStyle = {
        width: '100%',
    }
    return <>
        <List style={listStyle}>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={""} />
                </ListItemAvatar>
                <ListItemText primary=
                    {
                        <React.Fragment>
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    color: "#374359",
                                    marginTop: 12,
                                    marginBottom: 2,
                                    marginRight: 5,
                                    display: "inline"
                                }}

                                color="textPrimary"
                            >
                                {createBy}
                            </Typography>
                            {`- ${correctActionName}`}
                        </React.Fragment>
                    }
                    secondary={`${moment(createTime).format("LT,MMM D")} added a corrective action`}
                />
            </ListItem>
        </List>
    </>
}

export function AcknowledgeCell(props: CorrectiveActionInstance & { handleAcknowledge: (id: string) => void }) {
    const theme = createTheme({
        palette: {
            primary: green,
            secondary: red,
        },
    });
    const { id, handleAcknowledge } = props
    return <ThemeProvider theme={theme}>
        <Button variant="outlined"
            size="small"
            color="primary"
            onClick={() => handleAcknowledge(id)}
            disableRipple
            style={{ height: 28, marginLeft: 5 }}>
            Acknowledge
        </Button>
    </ThemeProvider>
}

const CorrectiveActionInstancePage = observer(() => {
    const { appStore } = useStores();
    initState.filterDate = appStore.getFilterDate();
    const { correctiveActionInstanceStore } = useStores();
    const [state, setState] = useState<CorrectiveActionInstancePageState>(initState);
    const { hasActionPermission } = appStore.userAuth as AuthenticateResponseModel;
    const { t } = useTranslation();

    if (correctiveActionInstanceStore.preFilters.length < 1) {
        correctiveActionInstanceStore.setPreFilter([{
            field: 'selectedDate',
            value: moment(initState.filterDate).format("YYYY-MM-DD HH:mm:ss")
        }]);
    }

    const handleFilterChange = (filters: any) => {
        setState({ ...state, ...filters });
        const changedState = Object.assign({}, { ...state }, { ...filters });
        correctiveActionInstanceStore.setPreFilter([{
            field: 'isRead',
            value: changedState.isRead
        }, {
            field: 'categoryId',
            value: changedState.categoryId
        }, {
            field: 'selectedDate',
            value: moment(changedState.filterDate).format("YYYY-MM-DD HH:mm:ss")
        }, {
            field: 'departmentId',
            value: changedState.deptId
        }]);
        correctiveActionInstanceStore.query();
    }

    const handleDoesAcknowledged = (isAcknowledged: boolean) => {
        handleFilterChange({ isRead: isAcknowledged });
    }

    const handleAcknowledge = (id: string) => {
        correctiveActionInstanceStore.acknowledge(id)
            .then(() => {
                correctiveActionInstanceStore.query();
            })
            .catch(error => {
                console.log(error);
            })
    }

    const columns: GridColDef[] = [
        {
            field: "taskInstanceName", headerName: t('task'), sortable: true, flex: 1,
            renderCell: ((row: GridCellParams) => {
                const actionInstance: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <TaskRowCellInfo taskInstanceName={actionInstance.taskInstanceName}
                    checklistInstanceName={actionInstance.checklistInstanceName}
                    executeTime={actionInstance.executeTime}
                />;
            })
        },
        {
            field: "correctActionName", headerName: t("corrective_action"), sortable: false, flex: 1,
            renderCell: ((row: GridCellParams) => {
                const actionInstance: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <CorrectActionCellInfo {...actionInstance} />;
            })
        },
    ];

    if (hasActionPermission) {
        columns.push({
            field: "action", headerName: t("actions"), sortable: false, flex: 1, hide: state.isRead,
            renderCell: ((row: GridCellParams) => {
                const actionInstance: CorrectiveActionInstance = row.row as CorrectiveActionInstance;
                return <>
                    {!actionInstance.isRead &&
                        <AcknowledgeCell {...actionInstance} handleAcknowledge={handleAcknowledge}></AcknowledgeCell>
                    }
                </>;
            })
        });
    }

    return <>
        <CommonPageTitle title={t('corrective_action')} subTitle={t('checkout_latest_corrective')} />
        <InstanceFilters handleFilterChange={handleFilterChange} />
        <Paper>
            <ButtonGroup variant="contained" aria-label="contained primary button group">
                <Button color={state.isRead === false ? 'primary' : 'inherit'}
                    style={{ width: 210, height: 60, fontSize: 16 }}
                    onClick={() => {
                        setState({ ...state, isRead: false });
                        handleDoesAcknowledged(false);
                    }}>{t('corrective_action')}
                </Button>
                <Button color={state.isRead === true ? 'primary' : 'inherit'}
                    style={{ width: 210, height: 60, fontSize: 16 }}
                    onClick={() => {
                        setState({ ...state, isRead: true });
                        handleDoesAcknowledged(true);
                    }}
                >{t('acknoledge')}
                </Button>
            </ButtonGroup>
        </Paper>
        <CommonGridPage<CorrectiveActionInstance>
            key={state.gridId}
            columns={columns}
            cancelSearch={false}
            store={correctiveActionInstanceStore}
            rowHeight={80}
            useAction={hasActionPermission}
        />
    </>
})
export { CorrectiveActionInstancePage }