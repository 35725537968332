import { Box, InputAdornment, Link, makeStyles, Paper, TablePagination, TextField } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridSortDirection,
    GridSortModel,
    GridOverlay,
    GridSortModelParams
} from "@material-ui/data-grid";
import SearchIcon from '@material-ui/icons/Search';
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { IHasId } from "../Models/IHasId";
import { observer } from "mobx-react";
import { IGridDataStore } from "../Stores/IGridDataStore";
import debounce from "lodash/debounce";
import useStores from "../Stores/useStores";
import { SortDirection } from "../Models/QueryCriteria";
import { createTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

const defaultTheme = createTheme();
const useStyles = makeStyles((theme) => ({
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '74px'
    },
    gridContainer: {
        display: 'flex',
        height: 'calc(100vh - 350px)',
        '& .MuiTooltip-tooltip': {
            backgroundColor: '#6C6C6C',
            color: '#FDFFFF',
            boxShadow: theme.shadows[1],
            fontSize: 20,
        },
        overflowX: 'auto'
    },
    gridRoot: {
        fontSize: 16,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        '& .MuiDataGrid-root .MuiDataGrid-cell': {
            textOverflow: 'initial',
            overflowX: 'auto'
        },
        '& .MuiDataGrid-columnHeaderWrapper': {
            background: '#F8F9FB'
        },
        overflowX: 'auto'
    },
    searchTextField: {
        width: 200,
        marginLeft: 24,
        '& .MuiInputBase-input': {
            padding: '9px 0px !important'
        },
        '& .MuiInputAdornment-positionStart': {
            marginLeft: '17px'
        }
    },
    pagnation: {
        border: 'none',
        '& .MuiSelect-select': {
            border: 'none',
        },
        '& .MuiTablePagination-selectRoot': {
            '& .MuiSelect-icon': {
                marginRight: '0px'
            }
        }
    },

    overlayRoot: {
        flexDirection: 'column',
        '& .ant-empty-img-1': {
            fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
            fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
        },
    },

    overlayLabel: {
        marginTop: theme.spacing(1),
    },


}), { defaultTheme });

export interface GridPageProps<T extends IHasId> {
    cancelSearch?: boolean,
    customRequest?: boolean,
    columns: GridColDef[],
    store: IGridDataStore<T>,
    onEdit?: (row: T) => void,
    cbDelete?: (row: T) => void,
    rowHeight?: number,
    minWidth?: number,
    useAction?: boolean,
    delConfirmMsg?: string,
    defaultSortBy?: string,
    defaultSortDirection?: GridSortDirection,
}

const debounceSearch = debounce((store: any, value: string) => {
    store.changeSearch(value);
}, 500);


function CustomNoRowsOverlay() {
    const classes = useStyles();

    return (
        <GridOverlay className={classes.overlayRoot}>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <div className={classes.overlayLabel}>{t("no_data")}</div>
        </GridOverlay>
    );
}

const CommonGridPage: <T extends IHasId>(props: GridPageProps<T>) => React.ReactElement | null = observer(<T extends IHasId>(props: GridPageProps<T>) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        columns,
        store,
        cancelSearch,
        useAction = true,
        customRequest = false,
        delConfirmMsg = t('delete_default_tip'),
        defaultSortBy = '',
        defaultSortDirection = 'desc'
    } = props;
    const { appStore } = useStores();
    const [searchStr, setSearchStr] = useState("");
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        { field: defaultSortBy, sort: defaultSortDirection },
    ]);
    const handleSortModelChange = (newModelParams: GridSortModelParams) => {
        if (customRequest) { return; }
        setSortModel(newModelParams.sortModel);
        if (newModelParams.sortModel.length) {
            const { field, sort } = newModelParams.sortModel[0];
            store.setSort(field, sort === 'desc' ? SortDirection.Descending : SortDirection.Ascending);
            store.query();
        } else {
            store.setSort("createdTime", SortDirection.Descending);
            store.query();
        }
    };

    useEffect(() => {
        store.clearSearch();
    }, []);

    if (useAction && columns.findIndex(x => x.field === 'action') === -1) {
        columns.push({
            field: 'action', headerName: t('actions'), width: 200, editable: false, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const model: T = row.row as T;
                return <Box style={{ display: 'flex', justifyContent: '', width: '100%' }}>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            if (props.onEdit) {
                                props.onEdit(model);
                            }
                            event.stopPropagation();
                        }}
                    >{t('edit')}</Link>
                    <Link style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleDelete(model.id, () => {
                                appStore.infoMsg = t('delete_success');
                                appStore.showInfo = true;
                                if (props.cbDelete && typeof props.cbDelete === "function") {
                                    props.cbDelete(model);
                                }
                            });
                            event.stopPropagation();
                        }}>{t('delete')}</Link>
                </Box>;
            })
        });
    }

    const confirm = useConfirm();
    const handleDelete = (id: string, cb: () => void) => {
        confirm({
            title: 'Warning',
            description: delConfirmMsg,
        }).then(() => {
            store.delete(id).then(cb);
        })
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        store.changePage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        store.changePageSize(parseInt(event.target.value ?? "25"));
    };
    const handleSearch = (value: string) => {
        setSearchStr(value);
        debounceSearch(store, value);
    }

    const pagination = <TablePagination
        className={classes.pagnation}
        count={store.count}
        page={store.pageIndex}
        onPageChange={handleChangePage}
        rowsPerPage={store.pageSize}
        labelRowsPerPage={t("rows_per_page")}
        onRowsPerPageChange={(handleChangeRowsPerPage)}
    />;


    return (

        <Paper elevation={3} style={{ minWidth: props.minWidth || 1060 }}>
            {cancelSearch ?
                null : <Box className={classes.pageHeader}>
                    <TextField
                        placeholder={t('search')}
                        style={{ width: 300 }}
                        value={searchStr}
                        onChange={(event) => handleSearch(event.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {<SearchIcon width="24" height="24" viewBox="0 0 24 24" />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    {store.search.length > 0 &&
                                        <ClearIcon width="24" height="24" viewBox="0 0 24 24"
                                            onClick={() => handleSearch('')} style={{ cursor: 'pointer' }} />}
                                </InputAdornment>
                            )
                        }}

                        InputLabelProps={{ shrink: true }}
                        className={classes.searchTextField}
                    />
                    {pagination}
                </Box>
            }
            <div className={classes.gridContainer}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        disableColumnMenu
                        disableSelectionOnClick
                        isCellEditable={(params: GridCellParams) => false}
                        //autoHeight
                        scrollbarSize={10}
                        nonce={t('no_data')}
                        rowHeight={props.rowHeight || 52}
                        className={classes.gridRoot}
                        rows={store.models.map(x => x)}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        columns={columns}
                        components={{
                            Pagination: function () {
                                return pagination;
                            },
                            NoRowsOverlay: CustomNoRowsOverlay,

                        }}
                    />
                </div>
            </div>
        </Paper>

    );

});

export default CommonGridPage;