import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import React, { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import {
    Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField,
} from "@material-ui/core";
import { Department } from "../../Models/Department";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { observer } from "mobx-react";
import BackButton from "../../Components/BackToButton";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { LightTooltip } from "../../Components/ToolTips";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { useTranslation } from 'react-i18next';
interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: Department,
    editMode: FormMode,
    departments: Department[],
    errors: ValidationError[]
}

const initDepartment: Department = {
    id: '',
    name: '',
    parentId: '',
    parentName: '',
    isLocation: false
};

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initDepartment,
    errors: [],
    departments: [],
};

const DepartmentPage = observer(() => {
    const { departmentStore, appStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { hasActionPermission } = appStore.userAuth as AuthenticateResponseModel;
    const { t } = useTranslation();

    useEffect(() => {
        departmentStore.queryAll().then(x => {
            setState({ ...state, departments: x });
        })
    }, []);

    const columns: GridColDef[] = [
        {
            field: "name", headerName: t('department_name'), flex: 2, renderCell: ((row: GridCellParams) => {
                const department: Department = row.row as Department;
                return <LightTooltip title={department.name} placement="bottom-start" arrow>
                    <span>{department.name}</span>
                </LightTooltip>;
            })
        },
        {
            field: "parentName", headerName: t('parent_department_name'), flex: 2, renderCell: ((row: GridCellParams) => {
                const department: Department = row.row as Department;
                return <LightTooltip title={department.parentName} placement="bottom-start" arrow>
                    <span>{department.parentName}</span>
                </LightTooltip>;
            })
        },
        {
            field: "isLocation", headerName: t('is_kitchen'), flex: 2,
            renderCell: ((row: GridCellParams) => {
                const department: Department = row.row as Department;
                return <Checkbox
                    checked={department.isLocation}
                    disabled
                    color="primary"
                />
            })
        }
    ];

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.trim().length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('department_name_required')
                });
            }
            if (state.editRow.name.trim().length > 200) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('department_name_max_length')
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const handleSubmit = (result: boolean) => {
        if (result) {
            departmentStore.queryAll().then(x => {
                setState({ ...state, departments: x, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
            })
        }
    }
    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initDepartment, id: Guid.create().toString() }
        })
    }

    return (<>
        <CommonPageTitle title={t('departments')} subTitle={t('manage_departments')}></CommonPageTitle>
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <BackButton isOrganization={true} />
            {hasActionPermission &&
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleCreate}
                >
                    {t('create_department')}
                </Button>
            }
        </Box>
        <CommonGridPage<Department>
            key={state.gridId}
            columns={columns}
            delConfirmMsg={t('delete_department_tip')}
            store={departmentStore}
            onEdit={(row: Department) => setState({
                ...state,
                dialogOpen: true,
                editRow: row,
                editMode: FormMode.Edit
            })}
            defaultSortBy={"name"}
            defaultSortDirection={"asc"}
            useAction={hasActionPermission}
        />

        <CommonFormDialog<Department>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={departmentStore}
            title={t('departments')}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        value={state.editRow?.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value }
                        })}
                        placeholder={t('department_name')}
                        label={t('department_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="lblRole" shrink>{t('parent_department')}</InputLabel>
                        <Select fullWidth
                            onChange={(event) => setState({
                                ...state,
                                editRow: { ...state.editRow, parentId: event.target.value as string }
                            })}
                            value={state.editRow.parentId}
                            displayEmpty
                            disableUnderline>
                            <MenuItem value="">{t("none")}</MenuItem>
                            {state.departments.filter(x => x.id !== state.editRow.id).map(department =>
                                <MenuItem value={department.id}>{department.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={state.editRow.isLocation}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setState({
                                ...state,
                                editRow: { ...state.editRow, isLocation: event.target.checked }
                            })}
                            color="primary" />}
                        label={t('is_kitchen')} />
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
});

export default DepartmentPage
