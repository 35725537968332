import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import React, { useState } from "react";
import { Guid } from "guid-typescript";
import { FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, createStyles, Grid, Link, makeStyles, MenuItem, Select, Switch, Typography } from "@material-ui/core";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { RecurringType } from "../../Models/RecurringType";
import { TimeType } from "../../Models/TimeType";
import { useConfirm } from "material-ui-confirm";
import { observer } from "mobx-react";
import { ChecklistFormDialog } from "./ChecklistFormDialog";
import { Department } from "../../Models/Department";
import { Checklist } from "../../Models/Checklist";
import { DateTime } from "luxon";
import { Link as RouteLink, useHistory, useParams, useLocation } from "react-router-dom";
import { ChecklistGroup } from "../../Models/ChecklistGroup";
import { getRecurringTimeText } from "../../Utils/utils";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { LightTooltip } from "../../Components/ToolTips";
import qs from "query-string";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import LanguagesModels from "../../Utils/languages";
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Refresh';

interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: Checklist,
    editGroup: number,
    editMode: FormMode,
    errors: ValidationError[],
    departments: Department[],
    checklistGroups: ChecklistGroup[]
}

const initChecklist: Checklist = {
    id: '',
    name: '',
    categoryId: "",
    departmentName: '',
    departmentId: undefined,
    recurringType: RecurringType.Daily,
    timeType: TimeType.OneTime,
    checklistCount: 0,
    taskCount: 0,
    hasMultipleGroup: false,
    startTimeOfDay: DateTime.fromISO('2020-01-01T00:00:00').toJSDate(),
    isCooling: false
}

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initChecklist,
    editGroup: 1,
    errors: [],
    departments: [],
    checklistGroups: []
};

const useStyles = makeStyles(() =>
    createStyles({
        selected: {
            '& .MuiSelect-select': {
                padding: "10px",
            },
            '&::before': {
                border: 0,
            }
        },

    }),
);

const ChecklistPage = observer(() => {
    const { checklistStore, departmentStore, appStore, categoryStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { search } = useLocation();
    const qObj = qs.parse(search);
    const { language } = qObj;
    const categoryName = qObj.checklistName;
    const { hasActionPermission, isSuperAdmin } = appStore.userAuth as AuthenticateResponseModel;
    const classes = useStyles();
    const { categoryId } = useParams<{ categoryId: string }>();
    const { t } = useTranslation();
    const confirm = useConfirm();
    const history = useHistory();

    initChecklist.categoryId = categoryId;
    checklistStore.setPreFilter([{
        field: 'categoryId',
        value: categoryId
    }]);

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: t('checklist_name'), flex: 1, renderCell: ((row: GridCellParams) => {
                const checklist: Checklist = row.row as Checklist;
                return <LightTooltip title={checklist.name} placement="bottom-start" arrow >
                    <span>{checklist.name}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'departmentName', headerName: t('department'), flex: 1, align: "center", renderCell: ((row: GridCellParams) => {
                const checklist: Checklist = row.row as Checklist;
                return <LightTooltip title={checklist.departmentName} placement="bottom-start" arrow>
                    <span>{checklist.departmentName}</span>
                </LightTooltip>;
            })
        },
        {
            field: 'recurringType', headerName: t('recurring_time'), flex: 1,
            renderCell: ((row: GridCellParams) => {
                const checklist: Checklist = row.row as Checklist;
                return `${RecurringType[checklist.recurringType]} - ${getRecurringTimeText(checklist)}`;
            })
        },

        { field: 'checklistGroupCount', headerName: t('groups'), width: 120, align: "left" },
        { field: 'taskCount', headerName: t('tasks'), width: 100, align: "left" },
        {
            field: 'isCooling', headerName: t('is_cooling'), width: 150,
            renderCell: ((row: GridCellParams) => {
                const checklist: Checklist = row.row as Checklist;
                return <Switch
                    checked={checklist.isCooling === true}
                    onChange={() => handleChangeActive(checklist)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            })
        },
    ];

    if (hasActionPermission) {
        columns.push({
            field: 'action', headerName: t('actions'), width: 450, editable: false, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const checklist: Checklist = row.row as Checklist;
                return <Box style={{ display: 'flex', justifyContent: '', width: '100%' }}>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            handleCreateOrUpdate(FormMode.Edit, checklist)
                        }}
                    >{t('edit_checklist')}</Link>
                    {checklist.hasMultipleGroup &&
                        <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                            component={RouteLink} to={`/app/checklists/${checklist.id}/checklistGroups?checklistName=${categoryName}&language=${language}&categoryName=${checklist.name}`}>{t('manage_groups')}</Link>}
                    {!checklist.hasMultipleGroup &&
                        <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                            component={RouteLink} to={`/app/checklists/${checklist.id}/tasks?checklistName=${categoryName}&language=${language}&categoryName=${checklist.name}&checklistGroup=NoGroup`}>{t('manage_tasks')}</Link>}
                    <Link style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleDelete(checklist.id);
                            event.stopPropagation();
                        }}>{t('delete_checklist')}</Link>
                </Box>;
            })
        });
    }

    if (appStore.userAuth?.isSuperAdmin) {
        const departmentColumnIndex = columns.findIndex(x => x.field === "departmentName");
        columns.splice(departmentColumnIndex, 1);
    }

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }

    const handleChangeActive = (checklist: Checklist) => {
        if (checklist.isCooling === true) {
            checklist.isCooling = false;
        } else {
            checklist.isCooling = true;
        }
        checklistStore.update(checklist);
    }

    const handleDelete = (id: string) => {
        confirm({
            title: t('warning'),
            description: t('delete_checklist_tip'),
        }).then(() => {
            checklistStore.delete(id);
        })
    }

    const handleCreateOrUpdate = (editMode: FormMode, checklist: Checklist) => {
        const entityId = editMode === FormMode.Edit ? checklist.id : Guid.create().toString();
        if (appStore.userAuth?.isSuperAdmin) {
            setState({
                ...state, dialogOpen: true,
                editMode: editMode,
                editRow: { ...checklist, id: entityId },
                dialogId: Guid.create().toString()
            })
        } else {
            Promise.all([departmentStore.queryKitchens()]).then(x => {
                setState({
                    ...state, dialogOpen: true,
                    editMode: editMode,
                    editRow: { ...checklist, id: entityId },
                    departments: x[0],
                    dialogId: Guid.create().toString()
                })
            });
        }
    }

    const handleRefreshTasks = () => {
      checklistStore.refresh(categoryId).then((x: any) => {});
    };

    return (<>
        <CommonPageTitle title={`${t('checklist')} - ${categoryName}`}
            subTitle={t('manage_checklists')}></CommonPageTitle>
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                    {isSuperAdmin &&
                        <>
                            <Typography style={{ marginRight: 10, color: "#999" }}>
                                {t('view_as')}
                            </Typography>
                            <Select fullWidth
                                displayEmpty
                                disableUnderline
                                disabled={true}
                                style={{ width: 180 }}
                                className={classes.selected}
                                value={categoryStore.language}
                            >
                                {LanguagesModels.map((x:any) =>
                                    <MenuItem value={x.key} key={x.key}>{x.value}</MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.goBack()}
                        style={{ marginRight: 16, background: '#374359', color: '#fff' }}
                    >
                        {t('back_to_category')}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<RefreshIcon />}
                        style={{ marginRight: 16 }}
                        onClick={handleRefreshTasks}
                    >
                        {t('refresh_tasks')}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleCreateOrUpdate(FormMode.Add, initChecklist)}
                    >
                        {t('create_checklist')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
        <CommonGridPage<Checklist>
            key={state.gridId}
            columns={columns}
            store={checklistStore}
            defaultSortBy={"createdTime"}
            defaultSortDirection={"desc"}
        />
        <ChecklistFormDialog
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={checklistStore}
            departments={state.departments}
            title={t("checklist")}
            onClose={() => setState({ ...state, dialogOpen: false, errors: [] })}
            onSubmit={handleSubmit}
        />
    </>
    );
});

export default ChecklistPage;