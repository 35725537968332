import { Box, Button, FormHelperText, Typography, Checkbox, ListItemText } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Department } from "../../../Models/Department";
import { Employee } from "../../../Models/Employee";
import { Guid } from "guid-typescript";
import useStores from "../../../Stores/useStores";
import WeekPicker from "../../../Components/WeekPicker";
import BackButton from "../../../Components/BackToButton";
import { RowStatus } from "../../../Models/RowStatus";
import { AuthenticateResponseModel } from "../../../Models/AuthenticateModel";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export interface ReportHeaderState {
    gridId: string,
    depts: Department[],
    employees: Employee[],
    deptId: string,
    companyId: string,
    companyIds: string[],
    employId: string,
    fromDate: Date,
    toDate?: Date | undefined,
    invalidFromDateError: string,
    invalidToDateError: string,
    startOfWeek: Date,
    orgCompanyId: string,
    companies: any,
}

const initState: ReportHeaderState = {
    gridId: Guid.create().toString(),
    depts: [],
    employees: [],
    fromDate: new Date(),
    toDate: undefined,
    invalidFromDateError: "",
    invalidToDateError: "",
    startOfWeek: new Date(),
    deptId: '',
    companyId: '',
    companyIds: [],
    employId: '',
    orgCompanyId: '',
    companies: {},
};

export interface ReportHeaderProps {
    title: string,
    description: string,
    showFromDate: boolean,
    showToDate: boolean,
    showDepartment: boolean,
    showEmployee: boolean,
    showWeekPicker: boolean,
    showPrint: boolean,
    showDownload: boolean,
    showCompanies?: boolean,
    multipleCompanies?: boolean,
    showBack?: boolean,
    headerWidth?: number,
    handleChange: (entity: ReportHeaderState) => void,
    handlePrint: (entity: ReportHeaderState) => void,
    handleDownload: (entity: ReportHeaderState) => void,
    initValues?: any,
    handleCompanyChange?: (val: any) => void,
    downloadText?: string,
    printText?: string,
}

export default function ReportHeader(props: ReportHeaderProps) {
    const { title, description, headerWidth, handleChange, handlePrint, handleDownload } = props;
    const { showDepartment, showFromDate, showToDate, showEmployee, showWeekPicker, showBack, showDownload, showPrint, initValues, showCompanies, downloadText, printText, multipleCompanies } = props;
    const classes = useStyles();
    const { appStore } = useStores();
    initState.fromDate = appStore.getFilterDate();
    const toDate = showToDate ? appStore.getFilterDate() : undefined;
    const [state, setState] = useState<ReportHeaderState>({
        ...{ ...initState, toDate },
        ...initValues,
    });
    // console.info('state - ', state);
    const { employeeStore, departmentStore, companyStore } = useStores();
    const { t } = useTranslation();
    //load select department and role data
    useEffect(() => {
        getSelectList();
    }, []);

    const getSelectList = () => {
        const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
        const stores: any[] = [departmentStore.queryAll(), employeeStore.queryAll()];
        if (showCompanies) {
            stores.push(companyStore.getCompanyStructure(orgCompanyId));
        }
        Promise.all(stores).then(values => {
            setState({
                ...state,
                depts: values[0],
                employees: values[1],
                ...(showCompanies ? { companies: values[2] } : {}),
                ...(multipleCompanies? { companyIds: (values[2].companyList || []).map((x: any) => x.id) } : {})
            });
        });
    };
    const handleFromDateChange = (date: any) => {
        if (!date || date.invalid) {
            setState({ ...state, invalidFromDateError: t('invalid_date') });
            return;
        }
        const mkDate = date.toJSDate();
        const maxDate = new Date('2100-12-31 23:59:59').valueOf();
        const minDate = new Date('1900-01-01 00:00:00').valueOf();
        if (mkDate.valueOf() > maxDate || mkDate.valueOf() < minDate) {
            setState({ ...state, invalidFromDateError: t('valid_range') });
            return;
        }
        const { toDate } = state;
        if (toDate && mkDate.getTime() > toDate.getTime()) {
            setState({ ...state, invalidFromDateError: t("must_not_be_later_than_the_end_time") });
            return;
        }
        setState({ ...state, fromDate: mkDate, invalidFromDateError: "", invalidToDateError: "" });
        handleChange({ ...state, fromDate: mkDate })
    }

    const handleWeekPickerChange = (data: { selectedDate: any }) => {
        setState({ ...state, startOfWeek: data.selectedDate });
        handleChange({ ...state, startOfWeek: data.selectedDate })
    }

    const handleToDateChange = (date: any) => {
        if (!date || date.invalid) {
            setState({ ...state, invalidToDateError: t('invalid_date') });
            return;
        }
        const mkDate = date.toJSDate();
        const maxDate = new Date('2100-12-31 23:59:59').valueOf();
        const minDate = new Date('1900-01-01 00:00:00').valueOf();
        if (mkDate.valueOf() > maxDate || mkDate.valueOf() < minDate) {
            setState({
                ...state, invalidToDateError: t('valid_range')
            });
            return;
        }
        const { fromDate } = state;
        if (fromDate && mkDate.getTime() < fromDate.getTime()) {
            setState({ ...state, invalidToDateError: t("must_not_be_later_than_the_end_time") });
            return;
        }
        setState({ ...state, toDate: mkDate, invalidToDateError: "", invalidFromDateError: "" });
        handleChange({ ...state, toDate: mkDate })
    }
    const handleDeptChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const deptId = event.target.value as string;
        setState({ ...state, deptId: deptId });
        handleChange({ ...state, deptId: deptId });
    };

    const handleCompanyChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const companyId = event.target.value as string;
        setState({ ...state, companyId });

        const { companies } = state;
        const { companyList } = companies;
        const { id, name } = _.head(
            companyList.filter((c: any) => c.id === companyId)
        ) as any || { id: "00000000-0000-0000-0000-000000000000", name: "All Companies" };
        props.handleCompanyChange && props.handleCompanyChange({ id, name });
    };

    const handleCompaniesChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const companyIds = event.target.value as string[];
        setState({ ...state, companyIds });

        const { companies } = state;
        const { companyList } = companies;
        const companiesVal = companyList
          .filter((c: any) => companyIds.includes(c.id))
          .map((c: any) => ({ id: c.id, name: c.name }));
        props.handleCompanyChange && props.handleCompanyChange(companiesVal);
    };

    const handleEmployChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const employId = event.target.value as string;
        setState({ ...state, employId: employId });
        handleChange({ ...state, employId: employId });
    };

    const handleDownloadBtn = () => {
        handleDownload({ ...state })
    }

    const handlePrintBtn = () => {
        handlePrint({ ...state })
    }

    const genCompanyItems = () => {
        const { companies } = state;
        const { topCompany } = companies;
        if (!topCompany) return [];

        const menuItems: any[] = [];
        const loopCompanies = (company: any) => {
            const { id, name, childCompanies } = company;
            props.multipleCompanies ?
            menuItems.push(
                <MenuItem value={id} key={id}>
                    <Checkbox checked={state.companyIds.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                </MenuItem>
            ):
            menuItems.push(
                <MenuItem value={id} key={id}>
                    {name}
                </MenuItem>
            );
            if (!_.isEmpty(childCompanies)) {
                childCompanies.forEach((child: any) => loopCompanies(child));
            }
        };
        loopCompanies(topCompany);

        return menuItems;
    };

    return (
        <div style={{ width: headerWidth }}>
            <Typography variant="h5" style={{ fontWeight: 700, color: "#374359", marginTop: 12, marginBottom: 16 }}>
                {title}
            </Typography>
            <Typography
                variant="h5"
                style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
            >
                {description}
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Box>
                        {showWeekPicker &&
                            <FormControl className={classes.formControl}>
                                <WeekPicker handleChange={handleWeekPickerChange} />
                            </FormControl>
                        }
                        {showFromDate &&
                            <FormControl className={classes.formControl} error={state.invalidFromDateError !== ""}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    style={{ width: 180 }}
                                    InputAdornmentProps={{ position: "start" }}
                                    value={state.fromDate}
                                    onChange={handleFromDateChange}
                                />
                                <FormHelperText>{state.invalidFromDateError}</FormHelperText>
                            </FormControl>
                        }
                        {showToDate &&
                            <span></span>
                        }
                        {showToDate &&
                            <FormControl className={classes.formControl} error={state.invalidToDateError !== ""}>
                                <InputLabel></InputLabel>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    style={{ width: 180 }}
                                    InputAdornmentProps={{ position: "start" }}
                                    value={state.toDate}
                                    onChange={handleToDateChange}
                                />
                                <FormHelperText>{state.invalidToDateError}</FormHelperText>
                            </FormControl>
                        }
                        {showEmployee &&
                            <FormControl className={classes.formControl}>
                                <InputLabel></InputLabel>
                                <Select fullWidth
                                    displayEmpty
                                    disableUnderline
                                    style={{ width: 180 }}
                                    value={state.employId}
                                    onChange={handleEmployChange}
                                >
                                    <MenuItem value="">{t("all_employees")}</MenuItem>
                                    {state.employees.filter(x => x.status === RowStatus.Active || x.status === RowStatus.InActive).map(x =>
                                        <MenuItem value={x.id} key={x.id}>{`${x.firstName} ${x.lastName}`}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        }

                        {showDepartment &&
                            <FormControl className={classes.formControl}>
                                <InputLabel></InputLabel>
                                <Select fullWidth
                                    displayEmpty
                                    disableUnderline
                                    style={{ width: 180 }}
                                    value={state.deptId}
                                    onChange={handleDeptChange}
                                >
                                    <MenuItem value="">{t('department')}</MenuItem>
                                    {state.depts.map(x =>
                                        <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        }

                        {showCompanies && !multipleCompanies &&
                            <FormControl className={classes.formControl}>
                                <InputLabel></InputLabel>
                                <Select fullWidth
                                    displayEmpty
                                    disableUnderline
                                    multiple={props.multipleCompanies || false}
                                    style={{ width: 180 }}
                                    value={state.companyId}
                                    onChange={handleCompanyChange}
                                >
                                    <MenuItem value="">{t("all_companies")}</MenuItem>
                                    {genCompanyItems()}
                                </Select>
                            </FormControl>
                        }

                        {showCompanies && multipleCompanies &&
                            <FormControl className={classes.formControl}>
                                <InputLabel></InputLabel>
                                <Select fullWidth
                                    displayEmpty
                                    disableUnderline
                                    multiple={multipleCompanies || false}
                                    renderValue={(selected: any) => {
                                        const { companyList } = state.companies;
                                        if (!companyList) return '';
                                        if (companyList.length === selected.length) {
                                            return t("all_companies");
                                        }
                                        return companyList
                                            .filter((x: any) => selected.includes(x.id))
                                            .map((x: any) => x.name)
                                            .join(', ');
                                    }}
                                    style={{ width: 180 }}
                                    value={state.companyIds}
                                    onChange={handleCompaniesChange}
                                >
                                    {genCompanyItems()}
                                </Select>
                            </FormControl>
                        }
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: 24 }}>
                        {_.isUndefined(showBack) && !showBack &&
                            <FormControl className={classes.formControl}>
                                <BackButton isReport={true} />
                            </FormControl>
                        }
                        {showDownload &&
                            <FormControl className={classes.formControl}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    size="small"
                                    startIcon={<GetAppIcon />}
                                    onClick={handleDownloadBtn}
                                >
                                    {t(downloadText || "download")}
                                </Button>
                            </FormControl>
                        }
                        {showPrint &&
                            <FormControl className={classes.formControl}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    size="small"
                                    startIcon={<PrintIcon />}
                                    onClick={handlePrintBtn}
                                >
                                    {t(printText || "print")}
                                </Button>
                            </FormControl>
                        }
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}