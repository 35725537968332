import {BaseService} from "./BaseService";
import {FollowUpInstance} from "../Models/FollowUpInstance";
import axios from "axios";
import {QueryCriteria} from "../Models/QueryCriteria";
import {PagedResult} from "../Models/PagedResult";

interface FollowUpQueryCriteria extends QueryCriteria {
    selectedDate:string,
    categoryId:string,
    departmentId:string,
    employeeId:string,
    toDate:string,
    isReportQuery:boolean,
}

export class FollowUpInstanceService  extends BaseService<FollowUpInstance> {
    constructor() {
        super('followUpInstance');
    }
    queryAsync = async (criteria: QueryCriteria): Promise<PagedResult<FollowUpInstance>> =>  {
        const url = `${process.env.REACT_APP_API_URL}/employees/getEmployeeFollowUpList`;
        const followUpCriteria = criteria as FollowUpQueryCriteria;
        criteria.filters?.map(x=>{
            if (x.field === "selectedDate") {
                followUpCriteria.selectedDate = x.value;
            } else if (x.field === "departmentId") {
                followUpCriteria.departmentId = x.value;
            }else if (x.field === "categoryId") {
                followUpCriteria.categoryId = x.value;
            }else if (x.field === "employeeId") {
                followUpCriteria.employeeId = x.value;
            }
            else if (x.field === "toDate") {
                followUpCriteria.toDate = x.value;
            }
            else if (x.field === "isReportQuery") {
                followUpCriteria.isReportQuery = x.value === "true";
            }
            return true;
        })
        const response = await axios.post<PagedResult<FollowUpInstance>>(url, followUpCriteria);
        return response.data;
    }

    downloadExcelAsync = async(criteria: QueryCriteria) => {
        const followUpCriteria = criteria as FollowUpQueryCriteria;
        criteria.filters?.map(x=>{
            if (x.field === "selectedDate") {
                followUpCriteria.selectedDate = x.value;
            } else if (x.field === "departmentId") {
                followUpCriteria.departmentId = x.value;
            }else if (x.field === "categoryId") {
                followUpCriteria.categoryId = x.value;
            }else if (x.field === "employeeId") {
                followUpCriteria.employeeId = x.value;
            }
            else if (x.field === "toDate") {
                followUpCriteria.toDate = x.value;
            }
            else if (x.field === "isReportQuery") {
                followUpCriteria.isReportQuery = x.value === "true";
            }
            return true;
        });
        const url = `${process.env.REACT_APP_API_URL}/employees/follow-export`;
        const response = await axios.post(url, criteria);
       return response.data;
    }
}
