import { SaReportsCustomerAnalytics } from "../Models/Reports";
import { SaReportsCustomerAnalyticsService } from "../Services/SaReportsCustomerAnalyticsService";
import { BaseGridDataStore } from "./BaseGridDataStore";

export class SaReportsCustomerAnalyticsStore extends BaseGridDataStore<SaReportsCustomerAnalytics>{
    private _service: SaReportsCustomerAnalyticsService;

    constructor() {
        let service = new SaReportsCustomerAnalyticsService();
        super(service);
        this._service = service;
    }
}