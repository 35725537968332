import axios, {AxiosRequestConfig} from "axios";
import {Notification} from "../Models/Notification";
import {BaseService} from "./BaseService";
import {QueryCriteria} from "../Models/QueryCriteria";
import {PagedResult} from "../Models/PagedResult";

export class NotificationService extends BaseService<Notification> {
    constructor() {
        super('notification');
    }

    getLastUnreadAsync = async (criteria: QueryCriteria,token?: string): Promise<PagedResult<Notification>> => {
        const instance = axios.create();
        instance.interceptors.request.use((config: AxiosRequestConfig) => {
            if ((config.url || '').indexOf('users/authenticate') === -1) {
                config.headers.Authorization = token;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
        const url = `${process.env.REACT_APP_API_URL}/notification/getLastUnread`;
        const response = await instance.post<PagedResult<Notification>>(url, criteria,{
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }});
        return response.data;
    }
}