import { ClickAwayListener, Grow, IconButton, makeStyles, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import LanguagesModels from "../Utils/languages";
import useStores from "../Stores/useStores";
import LanguageIcon from "./Icons/LanguageIcon";

interface PageState {
    aboutUsMenuOpen: boolean,
    langName: string,
    langImage: string,
}

const initState: PageState = {
    aboutUsMenuOpen: false,
    langName: "",
    langImage: ""
};

const useStyles = makeStyles((theme) => ({
    viewAs: {
        fontSize: "14px",
        '&:hover': {
            borderRadius: "10px",
            backgroundColor: "unset"
        }
    },
    langImage: {
        width: "20px",
        height: "20px",
        marginRight: "10px"
    }
}));

export default function Languages(props: {}) {
    const [state, setState] = useState<PageState>(initState);
    const aboutUsRef = React.useRef<HTMLButtonElement>(null);
    const classes = useStyles();
    const { appStore } = useStores();

    useEffect(() => {
        var defaultKey = localStorage.getItem('lang');
        var defaultLang = LanguagesModels.find(x => x.key === defaultKey);
        setState({
            ...state,
            langName: defaultLang?.value ?? LanguagesModels[0].value,
            langImage: (defaultLang?.key ?? 'english') + '.png'
        });
        appStore.setLang(defaultLang?.value ?? LanguagesModels[0].value);
    }, [])

    const handleAboutUsMenuToggle = () => {
        setState({ ...state, aboutUsMenuOpen: !state.aboutUsMenuOpen });
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setState({ ...state, aboutUsMenuOpen: false });
        }
    };

    const handleCloseAboutUsMenu = (event: React.MouseEvent<EventTarget>) => {
        if (aboutUsRef.current && aboutUsRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setState({ ...state, aboutUsMenuOpen: false });
    };

    const setLanguage = (lang: string) => {
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
        var defaultLang = LanguagesModels.find(x => x.key === lang);
        setState({
            ...state,
            langName: defaultLang?.value ?? LanguagesModels[0].value,
            langImage: (defaultLang?.key ?? 'english') + '.png',
            aboutUsMenuOpen: false
        });
        appStore.setLang(defaultLang?.value ?? LanguagesModels[0].value);
        window.location.reload();
    }

    return (
        <>
            <IconButton ref={aboutUsRef} className={classes.viewAs} onClick={() => handleAboutUsMenuToggle()}>
                <LanguageIcon style={{ color: '#374359' }}></LanguageIcon>&nbsp; {state.langName}
            </IconButton>
            <Popper open={state.aboutUsMenuOpen} anchorEl={aboutUsRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseAboutUsMenu}>
                                <MenuList autoFocusItem={state.aboutUsMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {LanguagesModels && LanguagesModels.map(x =>
                                        <MenuItem onClick={() => setLanguage(x.key)} style={{ fontSize: "14px" }} >{x.value}</MenuItem>
                                        // <img className={classes.langImage} src={"/" + x.key + ".png"}></img>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}