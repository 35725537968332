import {BaseGridDataStore} from "./BaseGridDataStore";
import {CorrectiveActionInstance} from "../Models/CorrectiveActionInstance";
import {CorrectiveActionInstanceService} from "../Services/CorrectiveActionInstanceService";
import {extendObservable} from "mobx";

export class CorrectiveActionInstanceStore extends BaseGridDataStore<CorrectiveActionInstance> {
    private _correctiveActionInstanceService: CorrectiveActionInstanceService;

    constructor() {
        let correctiveActionInstanceService = new CorrectiveActionInstanceService();
        super(correctiveActionInstanceService);
        this._correctiveActionInstanceService = correctiveActionInstanceService;
        extendObservable(this, {
            acknowledge: this.acknowledge,
        });
    }

    acknowledge(id: string) {
        return this._correctiveActionInstanceService.acknowledgeAsync(id);
    }
}