import axios from "axios";
import { Department } from "../Models/Department";
import { BaseService } from "./BaseService";

export class DepartmentService extends BaseService<Department> {
    constructor() {
        super('departments');
    }

    quertyKitchenAsync =  async(): Promise<Department[]> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/queryKitchens`;
        const response = await axios.get<Department[]>(url);
        return response.data;
    }
}