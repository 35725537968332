import {action, makeObservable, observable} from "mobx";
import {IHasId} from "../Models/IHasId";
import {QueryCriteria, QueryFilter, SortDirection} from "../Models/QueryCriteria";
import {BaseService} from "../Services/BaseService";
import {IGridDataStore} from "./IGridDataStore";

export class BaseGridDataStore<T extends IHasId> implements IGridDataStore<T> {
    _serivce: BaseService<T>;
    models: T[] = [];
    count: number = 0;
    pageIndex: number = 0;
    pageSize: number = 25;
    pages: number = 0;
    search: string = '';
    sortBy: string = '';
    sortDirection: SortDirection = SortDirection.Descending;
    preFilters: QueryFilter[] = [];

    constructor(service: BaseService<T>) {
        this._serivce = service;
        makeObservable(this, {
            preFilters: observable,
            models: observable,
            count: observable,
            pages: observable,
            pageIndex: observable,
            pageSize: observable,
            search: observable,
            sortBy: observable,
            sortDirection: observable,
            changePage: action,
            changePageSize: action,
            changeSearch: action,
            query: action,
            delete: action,
            add: action,
            update: action,
            setSort: action,
        })
    }

    changePageSize(pageSize: number) {
        this.pageIndex = 0;
        this.pageSize = pageSize;
        this.query();
    }

    changePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.query();
    }

    setPreFilter(filters: QueryFilter[]) {
        this.preFilters = filters;
    }

    setSort(sortBy: string, sortDirection: SortDirection) {
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
    }

    changeSearch(search: string) {
        this.search = search;
        this.changePage(0);
    }

    clearSearch() {
        this.search = "";
    }

    query() {
        const criteria: QueryCriteria = {
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize
        };

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }

        this._serivce.queryAsync(criteria).then(x => {
            this.models = x.data;
            this.count = x.count;
            this.pages = ((x.count + this.pageSize - 1) / this.pageSize);
        })
    }

    queryCSV() {
        const criteria: QueryCriteria = {skip: 0, take: 1000};

        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }
        return this._serivce.queryAsync(criteria)
    }

    queryAll(): Promise<T[]> {
        return this._serivce.queryAllAsync();
    }

    delete(id: string): Promise<void> {
        return this._serivce.deleteAsync(id).then(x => {
                if (x) {
                    //console.log(this.pages, this.pageIndex, this.pageIndex * this.pageSize, (this.pages - 1) * this.pageSize);
                    if (this.pageIndex * this.pageSize === (this.pages - 1) * this.pageSize) {
                        const toPage = this.pageIndex - 1;
                        this.changePage(toPage < 0 ? 0 : toPage);
                    } else {
                        this.query();
                    }
                }
                return Promise.resolve();
            }
        );
    }


    add(model: T) {
        return new Promise<boolean>((resolve, reject) => {
            this._serivce.addAsync(model).then(x => {
                if (x) {
                    this.query();
                }
                resolve(x);
            })
        });
    }

    update(model: T) {
        return new Promise<boolean>((resolve, reject) => {
            this._serivce.updateAsync(model).then(x => {
                if (x) {
                    this.query();
                }
                resolve(x);
            })
        });
    }

    excel(titles: string[]) {
        const criteria: QueryCriteria = {
            skip: 0,
            take: 1000,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
        };
        criteria.filters = [];
        if (this.preFilters.length > 0) {
            criteria.filters = this.preFilters.map(x => x);
        }

        if (this.search.length > 0) {
            criteria.filters.push({
                field: 'search',
                value: this.search
            });
        }
        criteria.titles = titles;
        return this._serivce.excelAsync(criteria)
    }
}