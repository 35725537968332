import axios from "axios";
import { IHasId } from "../Models/IHasId";
import { PagedResult } from "../Models/PagedResult";
import { QueryCriteria } from "../Models/QueryCriteria";

export class BaseService<T extends IHasId> {
    _controllerName: string = '';
    constructor(controllerName: string) {
        this._controllerName = controllerName;
    }
    
    queryAsync = async(criteria: QueryCriteria): Promise<PagedResult<T>> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/query`

        const response = await axios.post<PagedResult<T>>(url, criteria);
        return response.data;
    }

    queryAllAsync = async(): Promise<T[]> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/all`
        const response = await axios.get<T[]>(url);
        return response.data;
    }

    deleteAsync = async(id: string): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/${id}`
        const response = await axios.delete<boolean>(url);
        return response.data;
    }

    addAsync = async(entity: T): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}`
        const response = await axios.post<boolean>(url, entity);
        return response.data;
    }

    updateAsync =  async(entity: T): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/${entity.id}`
        const response = await axios.put<boolean>(url, entity);
        return response.data;
    }

    excelAsync = async(criteria: QueryCriteria) => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/excel`;
        const response = await axios.post(url, criteria);
       return response.data;
    }

    getExcelAsync =async (param:string) => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/excel?${param}`;
        const response = await axios.get(url);
       return response.data;
        
    }
}