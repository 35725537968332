import axios from "axios";
import { CorrectiveAction } from "../Models/CorrectiveAction";
import { BaseService } from "./BaseService";

export class CorrectiveActionService extends BaseService<CorrectiveAction> {
    constructor() {
        super('CorrectiveActions');
    }

    getAllByLanguageAsync =  async(language?: string): Promise<CorrectiveAction[]> => {
        const url = `${process.env.REACT_APP_API_URL}/CorrectiveActions/getAllByLanguage/${language}`
        const response = await axios.get<CorrectiveAction[]>(url);
        return response.data;
    }
}