import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import useStores from "../../Stores/useStores";
import ReportHeader, { ReportHeaderState, ReportHeaderProps } from '../Reports/Comments/ReportHeader';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Paper, Typography, Box, Switch, Grid } from "@material-ui/core";
import moment from 'moment';
import Avatar from "@material-ui/core/Avatar";
import { Parser } from "json2csv";
import { excelDownload, funCSVDownload } from "../../Utils/utils";
import { CorrectiveActionBzReport, FollowUpBzReport } from "../../Models/Reports";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import ReactEcharts from "echarts-for-react";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { LightTooltip } from "../../Components/ToolTips";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    cell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        maxWidth: '120px'
    },
    commentCell: {
        maxWidth: "150px",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
});

const correctiveAndFollowBizReport = observer(() => {
    const { correctiveAndFollowBZReportStore, appStore } = useStores();
    const [queryData, setQueryData] = useState<Array<any>>();
    const [activeTab, setActiveTab] = useState<string>('corrective');
    const [chartOption, setChartOption] = useState<any>({});
    const { t } = useTranslation();

    const classes = useRowStyles();
    useEffect(() => {
        const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
        const preFilters = [
            {
                field: "filterOrgCompanyId",
                value: orgCompanyId,
            },
            {
                field: "filterDate",
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss"),
            }
        ]
        correctiveAndFollowBZReportStore.setPreFilter([...preFilters]);
    }, []);

    useEffect(() => {
        loadChart();
    }, [activeTab])

    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0,0.3)",
        },
    };
    const genChartOption = (
        companies: Array<string>,
        completes: Array<number>
    ) => {
        return {
            legend: {
                data: [
                    "Total"
                ],
                left: "10%",
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ["stack"],
                    },
                    dataView: {},
                },
            },
            tooltip: {},

            xAxis: {
                data: companies,
                name: "Companies",
                axisLine: { onZero: true },
                splitLine: { show: false },
                splitArea: { show: false },
                axisLabel: {
                    interval: 0,
                    rotate: companies.length > 5 ? 30 : 0
                },
            },
            yAxis: {
                splitNumber: 1,
            },
            grid: {
                bottom: 100,
            },
            series: [
                {
                    name: "Total",
                    type: "bar",
                    stack: "one",
                    emphasis: emphasisStyle,
                    data: completes,
                },

            ],
        };
    };

    const loadChart = async () => {
        setQueryData([]);
        setChartOption({});
        let resp = null;
        if (activeTab === "corrective") {
            resp = await correctiveAndFollowBZReportStore.queryCSV();
            const { data } = resp;
            console.log(data)
            setQueryData(data)
            const option = genChartOption(
                data.map((r: any) => r.companyName),
                data.map((r: any) => r.correctiveActionCount)
            );
            setChartOption(option);
        } else {
            resp = await correctiveAndFollowBZReportStore.queryCSVFollowUp();
            const { data } = resp;
            console.log(data)
            setQueryData(data)
            const option = genChartOption(
                data.map((r: any) => r.companyName),
                data.map((r: any) => r.companyFollowUpCount)
            );
            setChartOption(option);

        }


    };


    const handleDownload = async (filters: ReportHeaderState) => {
        let resp = null;
        const fields = [t("company"), t("total")];
        const csvData: any = [];
        if (activeTab === "corrective") {
            correctiveAndFollowBZReportStore.excel(fields)
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('corrective_action_reports'));
                }
            });
            // resp = await correctiveAndFollowBZReportStore.queryCSV();
            // const { data, count } = resp;
            // if (count > 0 && data && data.length) {

            //     data.forEach((item: any) => {
            //         const rowData: any = {};

            //         rowData[t("company")] = item.companyName;
            //         rowData[t("total")] = item.correctiveActionCount + '';

            //         csvData.push(rowData);
            //     });
            //     const json2csvParser = new Parser({ fields });
            //     const csv = json2csvParser.parse(csvData);
            //     funCSVDownload(csv, (activeTab === "corrective" ? `${t("companies_corrective_actions")}` : `${t("companies_follow_up")}`) + ".csv");
            // } else {
            //     appStore.error = t("there_is_no_data_to_download");
            //     appStore.showError = true;
            // }
        } else {
            correctiveAndFollowBZReportStore.download(fields)
            .then(res => {
                if (res.count === 0) {
                    appStore.error = t("there_is_no_data_to_download");
                    appStore.showError = true;
                } else {
                    excelDownload(true, res.data, t('corrective_action_reports'));
                }
            });
            // resp = await correctiveAndFollowBZReportStore.queryCSVFollowUp();
            // const { data, count } = resp;
            // if (count > 0 && data && data.length) {

            //     data.forEach((item: any) => {
            //         const rowData: any = {};

            //         rowData[t("company")] = item.companyName;
            //         rowData[t("total")] = item.companyFollowUpCount + '';

            //         csvData.push(rowData);
            //     });
            //     const json2csvParser = new Parser({ fields });
            //     const csv = json2csvParser.parse(csvData);
            //     funCSVDownload(csv, (activeTab === "corrective" ? `${t("companies_corrective_actions")}` : `${t("companies_follow_up")}`) + ".csv");
            // } else {
            //     appStore.error = t("there_is_no_data_to_download");
            //     appStore.showError = true;
            // }

        }

    };
    const handlePrint = (filters: ReportHeaderState) => {
    }

    const handleFilterChange = (filters: ReportHeaderState) => {
        const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
        const preFilters = [
            {
                field: "filterOrgCompanyId",
                value: orgCompanyId,
            },
            {
                field: "filterDate",
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
            }

        ]
        correctiveAndFollowBZReportStore.setPreFilter([...preFilters]);
        loadChart()
    }



    const reportHeaderProps: ReportHeaderProps = {
        title: t("corrective_actions_follow_up_reports"),
        description: t("generate_corrective_actions_follow_up_reports"),
        showFromDate: true,
        showToDate: false,
        showDepartment: false,
        showEmployee: false,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    }


    function CorrectiveChildRow(props: { row: CorrectiveActionBzReport }) {
        const { row } = props;
        const [openRow, setOpenRow] = useState(false);
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell >
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
                            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left" component="th" scope="row" className={classes.cell} >
                        {row.companyName}
                    </TableCell>
                    <TableCell align="left">{row.correctiveActionCount}</TableCell>

                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openRow} timeout={"auto"} unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {`${row.companyName}-${t("corrective_actions_follow_up_reports")}`}
                                </Typography>


                                <TableContainer component={Paper} style={{ minHeight: '100px', marginTop: '10px' }}>

                                    <Table size="small" aria-label="purchases"  >
                                        <TableHead>
                                            <TableRow>

                                                <TableCell align="left">{t("company_name")}</TableCell>
                                                <TableCell align="left">{t("corrective_action")}</TableCell>
                                                <TableCell align="left">{t("checklist_name")}</TableCell>

                                                <TableCell align="left">{t("employee")}</TableCell>
                                                <TableCell align="left">{t("task_name")}</TableCell>
                                                <TableCell align="left">{t("is_read")}</TableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.childrens && row.childrens.length > 0 ? row.childrens.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell component="th" scope="row">{item.companyName}</TableCell>
                                                    <TableCell >{item.correctActionName}</TableCell>
                                                    <TableCell>{item.checklistInstanceName}</TableCell>

                                                    <TableCell align="left">
                                                        <Avatar alt={item.employeeName} src={item.employeePhoto} />
                                                        {item.employeeName}
                                                    </TableCell>
                                                    <TableCell align="left">{item.taskInstanceName}</TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            < Switch
                                                                checked={item.isRead}
                                                                onChange={() => { }}
                                                                color="primary"
                                                                name="checkedB"
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />

                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )) : <></>}
                                        </TableBody>
                                    </Table>
                                    {!row.childrens || row.childrens.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}>{t("no_checkList")}</div> : ''}
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }


    function FollowUpRow(props: { row: FollowUpBzReport }) {
        const { row } = props;
        const [openRow, setOpenRow] = useState(false);
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell >
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
                            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left" component="th" scope="row" className={classes.cell} >
                        {row.companyName}
                    </TableCell>
                    <TableCell align="left">{row.companyFollowUpCount}</TableCell>

                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openRow} timeout={"auto"} unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {`${row.companyName}-${t("corrective_actions_follow_up_reports")}`}
                                </Typography>
                                <TableContainer component={Paper} style={{ minHeight: '100px', marginTop: '10px' }}>
                                    <Table size="small" aria-label="purchases"  >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">{t("company_name")}</TableCell>
                                                <TableCell align="left">{t("follow_by")}</TableCell>
                                                <TableCell align="left">{t("checklist_name")}</TableCell>

                                                <TableCell align="left">{t("employee")}</TableCell>
                                                <TableCell align="left">{t("task_name")}</TableCell>
                                                <TableCell align="left">{t("latest_comment")}</TableCell>
                                                <TableCell align="left">{t("comment_time")}</TableCell>
                                                <TableCell align="left">{t("execution_time")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.childrens && row.childrens.length > 0 ? row.childrens.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell component="th" scope="row">{item.companyName}</TableCell>
                                                    <TableCell align="left">{item.followByName}</TableCell>
                                                    <TableCell align="left">{item.checklistInstanceName}</TableCell>

                                                    <TableCell align="left">
                                                        <Avatar alt={item.commentEmployeeName} src={item.commentEmployeePhoto} />
                                                        {item.followByName}
                                                    </TableCell>


                                                    <TableCell align="left">{item.taskInstanceName}</TableCell>
                                                    <TableCell align="left" className={classes.commentCell} >
                                                        <LightTooltip title={item.commentMessage} placement="left-end" arrow>
                                                            <span>{item.commentMessage}</span>
                                                        </LightTooltip>
                                                    </TableCell>
                                                    <TableCell align="left">{moment(item.commentTime).format("yyyy-MM-DD hh:mm:ss")}</TableCell>
                                                    <TableCell align="left">{moment(item.executeTime).format("yyyy-MM-DD hh:mm:ss")}</TableCell>

                                                </TableRow>
                                            )) : <></>}
                                        </TableBody>
                                    </Table>
                                    {!row.childrens || row.childrens.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}>No CheckList</div> : ''}
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }
    return (
        <>
            <div
                className="section-to-print"
                style={{ width: '100%' }}>
                <ReportHeader {...reportHeaderProps} />
                <Paper>
                    <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Button color={activeTab === 'corrective' ? 'primary' : 'inherit'}
                            style={{ width: 210, height: 60, fontSize: 16 }}
                            onClick={() => {
                                setActiveTab('corrective');
                            }}>{t("corrective_actions")}
                        </Button>
                        <Button color={activeTab === 'follow' ? 'primary' : 'inherit'}
                            style={{ width: 210, height: 60, fontSize: 16 }}
                            onClick={() => {
                                setActiveTab('follow');
                            }}
                        >{t("follow_up")}
                        </Button>
                    </ButtonGroup>
                </Paper>

                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Paper>
                            <Box sx={{ width: "100%" }}>
                                <Typography variant="h6" gutterBottom component="div" style={{ padding: '30px' }}>
                                    {activeTab === "corrective" ? `${t("corrective_actions")}` : `${t("follow_up")}`}
                                </Typography>
                            </Box>
                            <ReactEcharts option={chartOption} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} >
                        <TableContainer component={Paper} style={{ minHeight: '500px' }}>
                            {activeTab === "corrective" ? <Table  >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="left">{t("company_name")}</TableCell>
                                        <TableCell align="left">{t("corrective_action_count")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ width: '100%' }}>
                                    {queryData && queryData.length > 0 ? queryData.map((row) => (
                                        <CorrectiveChildRow key={row.id} row={row} />
                                    )) : ''}
                                </TableBody>
                            </Table> :

                                <Table  >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="left">{t("company_name")}</TableCell>
                                            <TableCell align="left">{t("follow_up_count")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ width: '100%' }}>
                                        {queryData && queryData.length > 0 ? queryData.map((row) => (
                                            <FollowUpRow key={row.id} row={row} />
                                        )) : ''}
                                    </TableBody>
                                </Table>
                            }
                            {!queryData || queryData.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px' }}>{t("no_data")}</div> : ''}
                        </TableContainer>:
                    </Grid>
                </Grid>
            </div>

        </>
    )
});

export default correctiveAndFollowBizReport;