import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { ChecklistDetailBzReport } from "../../Models/Reports";
import useStores from "../../Stores/useStores";
import ReportHeader, { ReportHeaderState, ReportHeaderProps, } from "../Reports/Comments/ReportHeader";
import { Parser } from "json2csv";
import { excelDownload, funCSVDownload } from "../../Utils/utils";
import moment from "moment";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import ReactEcharts from "echarts-for-react";
import { Paper, Grid, Box, Typography, Switch, LinearProgress } from "@material-ui/core";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from "react-i18next";
import _ from "lodash";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    }
});

const checklistDetailsBizReport = observer(() => {
    const { checklistDetailBizReportStore, appStore } = useStores();
    const [chartOption, setChartOption] = useState<any>({});
    const componentRef = useRef<HTMLDivElement>(null);
    const classes = useRowStyles();
    const [queryData, setQueryData] = useState<Array<ChecklistDetailBzReport>>();
    const { t } = useTranslation();

    useEffect(() => {
        const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
        const preFilters = [
            {
                field: "filterOrgCompanyId",
                value: orgCompanyId,
            },
            {
                field: "filterDate",
                value: moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss"),
            },
        ];
        checklistDetailBizReportStore.setPreFilter([...preFilters]);
        loadChart();
    }, []);

    const handleDownload = (filters: ReportHeaderState) => {
        checklistDetailBizReportStore.excel(['name', 'totalTask', 'totalCompleted']).then((resp: any) => {
            const { data, count } = resp;
            excelDownload(true, data, t('checklist_details_report'));
            // const fields = [`${t('company')}`, `${t('total_task')}`, `${t('completed')}`, `${t('incompleted')}`, `${t('mandatory_completed')}`, `${t('mandatory_incompleted')}`, `${t('corrective_action')}`, `${t('is_checklist_completed')}`];
            // const csvData: any = [];
            // if (count > 0 && data && data.length) {
            //     data.forEach((item: any) => {
            //         const rowData: any = {};
            //         rowData[`${t('company')}`] = item.name;
            //         rowData[`${t('total_task')}`] = item.totalTask + '';
            //         rowData[`${t('completed')}`] = item.totalCompleted + '';
            //         rowData[`${t('incompleted')}`] = item.totalInCompleted + '';
            //         rowData[`${t('mandatory_completed')}`] = item.totalMandatoryCompleted + '';
            //         rowData[`${t('mandatory_incompleted')}`] = item.totalMandatoryInCompleted + '';
            //         rowData[`${t('corrective_action')}`] = item.totalTaskWithCorrectiveAction + '';
            //         rowData[`${t('is_checklist_completed')}`] = item.isChecklistCompleted ? 'YES' : 'NO';

            //         csvData.push(rowData);
            //     });
                
            //     const json2csvParser = new Parser({ fields });
            //     const csv = json2csvParser.parse(csvData);
            //     funCSVDownload(csv, t("checklist_details_report") + ".csv");
            // } else {
            //     appStore.error = t("there_is_no_data_to_download");
            //     appStore.showError = true;
            // }
        });
    };

    const handlePrint = (filters: ReportHeaderState) => { };
    const handleFilterChange = (filters: ReportHeaderState) => {
        const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
        checklistDetailBizReportStore.setPreFilter([
            {
                field: "filterOrgCompanyId",
                value: orgCompanyId,
            },
            {
                field: "filterDate",
                value: moment(filters.fromDate).format("YYYY-MM-DD HH:mm:ss"),
            },
        ]);
        checklistDetailBizReportStore.query();
        loadChart();
    };

    const reportHeaderProps: ReportHeaderProps = {
        title: t("checklist_details_report"),
        description: t("generate_checklist_details_report"),
        showFromDate: true,
        showToDate: false,
        showDepartment: false,
        showEmployee: false,
        showWeekPicker: false,
        showDownload: true,
        showPrint: false,
        handleChange: handleFilterChange,
        handleDownload: handleDownload,
        handlePrint: handlePrint,
    };

    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0,0.3)",
        },
    };

    const genChartOption = (
        companies: Array<string>,
        completes: Array<number>,
        incompletes: Array<number>,
        mCompletes: Array<number>,
        mIncompletes: Array<number>,
    ) => {
        return {
            legend: {
                data: [
                    "Completed",
                    "Incompleted",
                    "Mandatory Completed",
                    "Mandatory InCompleted"
                ],
                left: "10%",
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ["stack"],
                    },
                    dataView: {},
                },
            },
            tooltip: {},

            xAxis: {
                data: companies,
                name: "Companies",
                axisLine: { onZero: true },
                splitLine: { show: false },
                splitArea: { show: false },
                axisLabel: {
                    interval: 0,
                    rotate: companies.length > 5 ? 30 : 0
                },
            },
            yAxis: {},
            grid: {
                bottom: 100,
            },
            series: [
                {
                    name: "Completed",
                    type: "bar",
                    stack: "one",
                    emphasis: emphasisStyle,
                    data: completes,
                },
                {
                    name: "Incompleted",
                    type: "bar",
                    stack: "one",
                    emphasis: emphasisStyle,
                    data: incompletes,
                },
                {
                    name: "Mandatory Completed",
                    type: "bar",
                    stack: "two",
                    emphasis: emphasisStyle,
                    data: mCompletes,
                },
                {
                    name: "Mandatory InCompleted",
                    type: "bar",
                    stack: "two",
                    emphasis: emphasisStyle,
                    data: mIncompletes,
                },
            ],
        };
    };

    const loadChart = () => {
        checklistDetailBizReportStore.queryCSV().then((resp: any) => {
            const { data } = resp;
            setQueryData(data)
            const option = genChartOption(
                data.map((r: any) => r.name),
                data.map((r: any) => r.totalCompleted),
                data.map((r: any) => r.totalInCompleted),
                data.map((r: any) => r.totalMandatoryCompleted),
                data.map((r: any) => r.totalMandatoryInCompleted)
            );
            setChartOption(option);
        });
    };
    function ChildRow(props: { row: ChecklistDetailBzReport }) {
        const { row } = props;
        const [openRow, setOpenRow] = useState(false);

        let completeRate = row.totalTask ? _.round((row.totalCompleted / row.totalTask) * 100, 2) : 0;
        completeRate = Math.round(completeRate)
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
                            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="left">{row.totalTask}</TableCell>
                    <TableCell align="left">
                        {
                            <div style={{ width: "100%" }}>
                                <Box display="flex" alignItems="center">
                                    <Box width="100%" mr={1}>
                                        <LinearProgress variant="determinate" value={completeRate} />
                                    </Box>
                                    <Box minWidth={35}>
                                        <Typography variant="body2" color="textSecondary">{`${completeRate}%`}</Typography>
                                    </Box>
                                </Box>
                            </div>
                        }

                    </TableCell>
                    <TableCell align="left">{row.totalInCompleted}/{row.totalTask}</TableCell>
                    <TableCell align="left">{row.totalMandatoryCompleted}/{row.totalMandatory}</TableCell>
                    <TableCell align="left">{row.totalMandatoryInCompleted}/{row.totalMandatory}</TableCell>
                    <TableCell align="left">{row.totalTaskWithCorrectiveAction}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openRow} timeout={"auto"} unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {`${row.name}-${t("checklist_details_report")}`}
                                </Typography>


                                <TableContainer component={Paper} style={{ minHeight: '100px', marginTop: '10px' }}>

                                    <Table size="small" aria-label="purchases"  >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("checklist")}</TableCell>
                                                <TableCell>{t("total_task")}</TableCell>
                                                <TableCell align="left">{t("completed")}</TableCell>
                                                <TableCell align="left">{t("incompleted")}</TableCell>
                                                <TableCell align="left">{t("mandatory_completed")}</TableCell>
                                                <TableCell align="left">{t("mandatory_incompleted")}</TableCell>
                                                <TableCell align="left">{t("corrective_action")}</TableCell>
                                                <TableCell align="left">{t("is_checklist_completed")}</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.checklists && row.checklists.length > 0 ? row.checklists.map((checkListRow) => (
                                                <TableRow key={checkListRow.id}>
                                                    <TableCell component="th" scope="row">{checkListRow.name}</TableCell>
                                                    <TableCell>{checkListRow.totalTask}</TableCell>
                                                    <TableCell align="left">{checkListRow.totalCompleted}</TableCell>
                                                    <TableCell align="left">{checkListRow.totalInCompleted}</TableCell>
                                                    <TableCell align="left">{checkListRow.totalMandatoryCompleted}</TableCell>
                                                    <TableCell align="left">{checkListRow.totalMandatoryInCompleted}</TableCell>
                                                    <TableCell align="left">{checkListRow.totalTaskWithCorrectiveAction}</TableCell>
                                                    <TableCell align="left">{
                                                        < Switch
                                                            checked={checkListRow.isChecklistCompleted}
                                                            onChange={() => { }}
                                                            color="primary"
                                                            name="checkedB"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />

                                                    }</TableCell>
                                                </TableRow>
                                            )) : <></>}
                                        </TableBody>
                                    </Table>
                                    {!row.checklists || row.checklists.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}>No CheckList</div> : ''}
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    return (
        <><ReportHeader {...reportHeaderProps} />
            <div
                ref={componentRef}
                className="section-to-print"
                style={{ width: '100%' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Paper>
                            <Box sx={{ width: "100%" }}>
                                <Typography variant="h6" gutterBottom component="div" style={{ padding: '30px' }}>
                                    {`${t("checklist_details_chart")}`}
                                </Typography>
                            </Box>
                            <ReactEcharts option={chartOption} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} >
                        <TableContainer component={Paper} style={{ minHeight: '500px' }}>
                            <Table aria-label={t("checklist_details")} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>{t("company")}</TableCell>
                                        <TableCell align="left">{t("total_task")}</TableCell>
                                        <TableCell align="left">{t("completed")}</TableCell>
                                        <TableCell align="left">{t("incompleted")}</TableCell>
                                        <TableCell align="left">{t("mandatory_completed")}</TableCell>
                                        <TableCell align="left">{t("mandatory_incompleted")}</TableCell>
                                        <TableCell align="left">{t("corrective_action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ width: '100%' }}>
                                    {queryData && queryData.length > 0 ? queryData.map((row) => (
                                        <ChildRow key={row.name} row={row} />
                                    )) : ''}
                                </TableBody>
                            </Table>
                            {!queryData || queryData.length <= 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px' }}>{t("no_data")}</div> : ''}
                        </TableContainer>

                    </Grid>
                </Grid>
            </div>
        </>
    );
});

export default checklistDetailsBizReport;
