import axios from "axios";
import { Category } from "../Models/Category";
import { BaseService } from "./BaseService";

export class CategoryService extends BaseService<Category> {
    constructor() {
        super('categories');
    }

    queryTemplatesAsync = async (language: any): Promise<Category[]> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/queryTemplate/${language}`;
        const response = await axios.get<Category[]>(url);
        return response.data;
    }

    importTemplateAsync = async (templateIds: string[], importOption: boolean, language: String): Promise<boolean> => {
        const url = `${process.env.REACT_APP_API_URL}/${this._controllerName}/importTemplate`;
        const response = await axios.post<boolean>(url, {
            templateIds: templateIds,
            importOption: importOption,
            language: language
        });
        return response.data;
    }

    async refresh() {
        const url = `${process.env.REACT_APP_API_URL}/categories/refresh`;
        const response = await axios.post<boolean>(url);
        return response.data;
    }
}