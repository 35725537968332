import { List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import DvrIcon from '@material-ui/icons/Dvr';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DnsIcon from '@material-ui/icons/Dns';
import { useTranslation } from "react-i18next";
import useStores from "../../Stores/useStores";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";

const useStyles = makeStyles({
    featureList: {
        display: 'flex',
        '& a:not(:last-child)': {
            borderRight: 'solid 1px rgba(0, 0, 0, 0.12)'
        },
        '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: 20,
            color: '#374359 !important'
        }
    },
});

const listItemIconStyle = {
    color: '#1CAD5E',
    fontSize: 40
}


export function BusinessReports() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appStore } = useStores();
    const { isAdvanceAccount } = appStore.userAuth as AuthenticateResponseModel;

    return (
        <>
            <Typography
                variant="h5"
                style={{ fontWeight: 700, color: "#374359", fontSize: 24, marginTop: 12, marginBottom: 10 }}
            >
                {t('business_reports')}
            </Typography>
            <Typography
                variant="h5"
                style={{ fontWeight: 400, color: "#9094A9", fontSize: 14 }}
            >
                {t("check_out_latest_business_reports")}
            </Typography>
            <Paper elevation={3} style={{ marginTop: 32 }}>
                <List className={classes.featureList}>
                    <ListItem component={Link} to="/app/companiestaskstatusreports">
                        <ListItemAvatar>
                            <DvrIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("companies_task_status_report")} secondary={t("generate_companies_task_status_report")} />
                    </ListItem>
                    <ListItem component={Link} to="/app/companiestaskassignmentreports">
                        <ListItemAvatar>
                            <AssignmentIndIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("companies_task_assignment_report")} secondary={t("generate_task_assignment_report_to_see_how_many_tasks_are_assigned")} />
                    </ListItem>
                    <ListItem component={Link} to="/app/companiestrendreports">
                        <ListItemAvatar>
                            <DnsIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("companies_trend_reports")} secondary={t("generate_companies_trend_report_from_here")} />
                    </ListItem>
                </List>
                <List className={classes.featureList}>
                    <ListItem component={Link} to="/app/checklistDetailsBizReport">
                        <ListItemAvatar>
                            <DnsIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("companies_checklist_detail_reports")} secondary={t("generate_companies_checklist_detail_report_from_here")} />
                    </ListItem>
                    <ListItem component={Link} to="/app/correctiveAndFollowBizReport">
                        <ListItemAvatar>
                            <DnsIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("companies_corrective_action_follow_up_reports")} secondary={t("generate_companies_corrective_action_follow_up_report_from_here")} />
                    </ListItem>
                    {isAdvanceAccount ?
                    <ListItem component={Link} to="/app/scorecardBizReport">
                        <ListItemAvatar>
                            <DnsIcon style={listItemIconStyle} />
                        </ListItemAvatar>
                        <ListItemText primary={t("scorecard")} secondary={""} />
                    </ListItem>
                    :
                    <ListItem/>
                    }
                </List>
            </Paper>
        </>
    )
}