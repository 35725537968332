import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import useStores from "../../Stores/useStores";
import CommonGridPage from "../../Components/CommonGridPage";
import React, { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { CommonFormDialog, FormMode } from "../../Components/CommonFormDialog";
import { Box, Button, Grid, Link, TextField } from "@material-ui/core";
import { ValidationError } from "../../Models/ValidationError";
import AddIcon from '@material-ui/icons/Add';
import { observer } from "mobx-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Link as RouteLink } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ChecklistGroup } from "../../Models/ChecklistGroup";
import { useConfirm } from "material-ui-confirm";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { LightTooltip } from "../../Components/ToolTips";
import qs from "query-string";
import { useTranslation } from 'react-i18next';

interface PageState {
    dialogOpen: boolean,
    gridId: string,
    dialogId: string,
    editRow: ChecklistGroup,
    editMode: FormMode,
    errors: ValidationError[],
    deleteOption: string,
    deleteDialogOpen: boolean,
    expandRow: string
}

const initChecklistGroup: ChecklistGroup = {
    id: '',
    checklistId: '',
    name: '',
    tasks: []
};

const initState: PageState = {
    dialogOpen: false,
    gridId: Guid.create().toString(),
    dialogId: Guid.create().toString(),
    editMode: FormMode.Add,
    editRow: initChecklistGroup,
    errors: [],
    deleteOption: 'category',
    deleteDialogOpen: false,
    expandRow: ''
};

const ChecklistGroupPage = observer(() => {
    const { checklistGroupStore } = useStores();
    const [state, setState] = useState<PageState>(initState);
    const { checklistId } = useParams<{ checklistId: string }>();
    const { t } = useTranslation();
    initChecklistGroup.checklistId = checklistId;
    checklistGroupStore.setPreFilter([{
        field: 'ChecklistId',
        value: checklistId
    }]);

    useEffect(() => {
        checklistGroupStore.query();
    }, [])
    const { search } = useLocation();
    const { categoryName, checklistName } = qs.parse(search);
    const columns: GridColDef[] = [
        {
            field: "name", headerName: t("group_name"), flex: 6, renderCell: ((row: GridCellParams) => {
                const group: ChecklistGroup = row.row as ChecklistGroup;
                return <LightTooltip title={group.name} placement="bottom-start" arrow>
                    <span>{group.name}</span>
                </LightTooltip>;
            })
        },
        { field: "taskCount", headerName: t("task"), flex: 0.7 },
        { field: "mandatoryCount", headerName: t('mandatory') + ' ' + t('task'), flex: 1 },
        {
            field: 'action', headerName: t('actions'), flex: 2, editable: false, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const checklistGroup: ChecklistGroup = row.row as ChecklistGroup;
                return <Box style={{ display: 'flex', justifyContent: '', width: '100%' }}>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            handleEdit(checklistGroup);
                        }}
                    >{t('edit')} {t('Group')}</Link>
                    <Link style={{ marginRight: 14, textDecoration: 'underline', cursor: 'pointer' }}
                        component={RouteLink}
                        to={`/app/categories/${checklistId}/${checklistGroup.id}/tasks?categoryName=${categoryName}&checklistName=${checklistName}&checklistGroup=${checklistGroup.name}`}>
                    {t('manage_tasks')}
                    </Link>
                    <Link style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleDelete(checklistGroup.id);
                            event.stopPropagation();
                        }}>{t('delete')} {t('Group')}</Link>
                </Box>;
            })
        }
    ];

    const handleValidate = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const valdiationErrors: ValidationError[] = [];
            if (state.editRow.name.length === 0) {
                valdiationErrors.push({
                    field: 'name',
                    message: t('checklist_group_name_is_required')
                });
            }
            if (state.editRow.name.trim().length > 200) {
                valdiationErrors.push({
                    field: 'name',
                    message: t("checklist_group_name_max_length_is")
                });
            }

            if (valdiationErrors.length === 0) {
                resolve(true);
            } else {
                reject();
                setState({ ...state, errors: valdiationErrors })
            }
        })
    };

    const handleSubmit = (result: boolean) => {
        if (result) {
            setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] });
        }
    }

    const handleEdit = (checklistGroup: ChecklistGroup) => {
        setState({
            ...state,
            dialogOpen: true,
            editRow: { ...checklistGroup },
            editMode: FormMode.Edit,
            dialogId: Guid.create().toString()
        });
    }

    const confirm = useConfirm();
    const handleDelete = (id: string) => {
        confirm({
            title: t('warning'),
            description: t("sure_you_want_to_delete_checklist_group"),
        }).then(() => {
            checklistGroupStore.delete(id);
        })
    }

    const handleCreate = () => {
        setState({
            ...state, dialogOpen: true,
            editMode: FormMode.Add,
            editRow: { ...initChecklistGroup, id: Guid.create().toString() }
        })
    }

    const history = useHistory();

    return (<>

        <CommonPageTitle title={`${t("checklist_groups")} - ${categoryName} -> ${checklistName}`}
            subTitle={t("manage_checklist_groups")}></CommonPageTitle>
        <Box style={{ display: "flex", marginBottom: 16, justifyContent: "flex-end" }}>
            <Box>
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => history.goBack()}
                    style={{ marginRight: 16, background: '#374359', color: '#fff' }}
                >
                    {t('back_to_checklist')}
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleCreate}
                >
                    {t('create_checklist_group')}
                </Button>
            </Box>
        </Box>
        <CommonGridPage<ChecklistGroup>
            key={state.gridId}
            columns={columns}
            store={checklistGroupStore}
        />


        <CommonFormDialog<ChecklistGroup>
            key={state.dialogId}
            open={state.dialogOpen}
            row={state.editRow}
            mode={state.editMode}
            store={checklistGroupStore}
            title={t("checklist_groups")}
            onClose={() => setState({ ...state, dialogOpen: false, dialogId: Guid.create().toString(), errors: [] })}
            onSubmit={handleSubmit}
            onValidation={handleValidate}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required={true}
                        error={state.errors.findIndex(x => x.field === "name") > -1}
                        helperText={state.errors.find(x => x.field === "name")?.message || ''}
                        value={state.editRow?.name}
                        onChange={(event) => setState({
                            ...state,
                            editRow: { ...state.editRow, name: event.target.value }
                        })}
                        placeholder={t('checklist_group_name')}
                        label={t('checklist_group_name')}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </CommonFormDialog>
    </>
    );
});

export default ChecklistGroupPage
