import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    IconButton,
    Paper, TextField,
    Typography
} from "@material-ui/core";
import { getRecurringTimeText } from "../Utils/utils";
import { ChecklistInstance } from "../Models/ChecklistInstance";
import { createStyles, createTheme, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { RecurringType } from "../Models/RecurringType";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import Avatar from "@material-ui/core/Avatar";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { useEffect, useState } from "react";
import { TaskInstanceComment, TaskInstanceDetail, TaskInstanceFollowUp } from "../Models/TaskInstance";
import { TaskType } from "../Models/Task";
import { blue, green, orange, pink, red } from "@material-ui/core/colors";
import { observer } from "mobx-react";
import moment from "moment";
import ReplyIcon from '@material-ui/icons/Reply';
import useStores from "../Stores/useStores";
import { AuthenticateResponseModel } from "../Models/AuthenticateModel";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pink: {
            color: '#FFFFFF',
            backgroundColor: pink[500],
        },
        orange: {
            color: '#FFFFFF',
            backgroundColor: green[500],
        },
        purple: {
            color: '#FFFFFF',
            backgroundColor: red[500],
        },
        green: {
            color: '#FFFFFF',
            backgroundColor: green[500],
        },
        imageRoot: {
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        imageList: {
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
        },
    }));

export interface TaskDetailState {
    taskInfo: TaskInstanceDetail,
    comments: TaskInstanceComment[],
    totalComments: number,
    followups: TaskInstanceFollowUp[],
    groupName?: string,
    checklist?: ChecklistInstance
}

interface TaskDetailProps {
    data: TaskDetailState,
    onlyChichat: boolean,
    afterReply: (taskId: string) => void
}

export const initTaskDetailState: TaskDetailState = {
    taskInfo: {
        id: "",
        checklistInstanceName: "",
        categoryInstanceName: "",
        taskInstanceId: "",
        taskInstanceName: "",
        checklistGroupId: "",
        checklistId: "",
        taskType: TaskType.YesOrNo,
        isMandatory: false,
        isCritical: false,
        isCompleted: false,
        isDelay: false,
        isSuccess: false,
        isFollowUp: false,
        explanation: "",
        attachPhotos: [],
        correctiveActions: [],
        assignedModel: {
            photo: '',
            name: ''
        },
        selectedAction: {
            id: "",
            name: ""
        },
    },
    comments: [],
    followups: [],
    totalComments: 0,
    groupName: "",
}


interface PageState {
    chichat: boolean
}

const theme = createTheme({
    palette: {
        primary: green,
        secondary: red,
    },
});

const useReplayStyles = makeStyles({
    dialogHeader: {
        '& .MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    cancelButton: {
        '& .MuiButton-label': {
            color: '#9094A9'
        }
    }
});

function TaskCompleteStatus(taskInfo: TaskInstanceDetail) {
    let statusTheme = createTheme({
        palette: {
            primary: green,
        },
    });
    let statusTxt = "Pending";
    let startIcon = <HourglassEmptyIcon />;

    if (taskInfo.isCompleted && !taskInfo.isSuccess) {
        statusTxt = "Failed";
        startIcon = <ClearIcon />;
        statusTheme = createTheme({
            palette: {
                primary: red,
            },
        });

    } else if (taskInfo.isCompleted && taskInfo.isSuccess) {
        statusTxt = "Completed";
        startIcon = <CheckIcon />;
        statusTheme = createTheme({
            palette: {
                primary: blue,
            },
        });

    } else if (taskInfo.isDelay) {
        statusTxt = "Delayed";
        startIcon = <AvTimerIcon />;
        statusTheme = createTheme({
            palette: {
                primary: orange,
            },
        });
    }

    return <ThemeProvider theme={statusTheme}>
        <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={startIcon}
        >
            {statusTxt}
        </Button>
    </ThemeProvider>
}

const TaskDetailInfo = observer((props: TaskDetailProps) => {
    const { t } = useTranslation();
    const { afterReply, onlyChichat = false } = props;
    const { appStore } = useStores();
    const { taskInfo, comments, checklist, followups, groupName } = props.data;
    const [state, setState] = useState<PageState>({ chichat: onlyChichat });
    const { hasActionPermission } = appStore.userAuth as AuthenticateResponseModel;

    const classes = useStyles();
    useEffect(() => {
        console.log(`Just Call:`, taskInfo.taskInstanceId);
    }, [taskInfo])

    const toggleChichat = () => {
        setState({ ...state, chichat: !state.chichat });
    }

    function TaskComment(props: {
        comment: TaskInstanceComment,
        avatarClass: string,
        afterReply: (taskId: string) => void,
        canReplay?: boolean,
        taskInstanceId?: string
    }) {
        const { comment, avatarClass, afterReply = () => { }, taskInstanceId = '' } = props;
        const [open, setOpen] = useState<boolean>(false);
        const [state, setState] = useState<{ content: string, error: string }>({ content: "", error: "" });
        const classes = useReplayStyles();
        const { checklistInstanceStore, appStore } = useStores();
        const canReplay = appStore.userAuth?.employeeId !== comment.commentsPersonId;

        const handleClose = () => {
            setOpen(false);
        }
        const handleReplay = () => {
            const { commentsPersonId, groupId } = comment;
            const { content } = state;
            if (content.trim().length < 0) {
                setState({ ...state, error: t("must_have_some_words_for_reply") })
            } else {
                checklistInstanceStore.replayComment(taskInstanceId, groupId, commentsPersonId, state.content)
                    .then(x => {
                        setState({ ...state, content: "", error: "" });
                        setOpen(false);
                        appStore.infoMsg = t("reply_successfully")
                        appStore.showInfo = true;
                        if (afterReply && typeof afterReply === "function") {
                            afterReply(taskInstanceId);
                        } else {
                            window.location.reload();
                        }

                    });
            }
        }

        return (
            <>
                <Box display="flex">
                    <Box minWidth={30}>
                        <Avatar className={avatarClass}>{comment.commentsPersonName[0]}</Avatar>
                    </Box>
                    <Box width="70%" ml={2} mt={1}>
                        <Typography variant="h5" component="h2">
                            {comment.commentsPersonName} {canReplay &&
                                <ReplyIcon color="primary" style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />}
                        </Typography>
                    </Box>
                    <Box width="30%" mt={1}>
                        <Typography variant="body2" color="textSecondary"
                            style={{ textAlign: "right" }}>
                            {moment(comment.commentTime).format("LT,MMM D")}
                        </Typography>
                    </Box>

                </Box>
                <Box width="100%" style={{ paddingLeft: "55px" }}>
                    <Typography variant="body2" color="textSecondary">
                        {comment.commentsDetail}
                    </Typography>
                </Box>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth
                    maxWidth={"sm"}>
                    <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
                        <span>{t("reply_comment")}</span>
                        <IconButton onClick={handleClose}>
                            <ClearIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={true}
                                    error={state.error.trim().length > 0}
                                    helperText={state.error || ''}
                                    value={state.content}
                                    onChange={(event) => setState({
                                        ...state,
                                        content: event.target.value,
                                        error: ""
                                    })}
                                    multiline={true}
                                    rows={3}

                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ paddingRight: 24, marginTop: 24 }}>
                        <Button variant="outlined" disableElevation className={classes.cancelButton} onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        {hasActionPermission &&
                            <Button color="primary" variant="contained" disableElevation
                                onClick={() => handleReplay()}>
                                {t("reply")}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return <>
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" style={{
                    fontWeight: 700,
                    color: "#374359",
                    marginTop: 12,
                    marginBottom: 16
                }}>
                    {taskInfo.taskInstanceName}
                </Typography>
                <Typography component="h2">
                    {groupName}
                </Typography>
                <Typography color="textSecondary">
                    {checklist ? getRecurringTimeText(checklist) : ""}
                    <ThemeProvider theme={theme}>

                        <Button variant="outlined" size="small" color="primary" disableRipple
                            style={{ height: 30, marginLeft: 5 }}>
                            {checklist ? RecurringType[checklist.recurringType] : ""}
                        </Button>
                        {taskInfo.isMandatory ?
                            <Button variant="outlined" size="small" color="secondary" disableRipple
                                style={{ height: 30, marginLeft: 5 }}>{t("mandatory")}</Button> : null
                        }
                        <Button style={{ marginRight: "10px", float: "right" }}
                            onClick={toggleChichat}
                            color="primary">{state.chichat ? t("view_task_details") : t("display_comments_only")}</Button>

                    </ThemeProvider>

                </Typography>

            </CardContent>
        </Card>
        {!state.chichat && <Card variant="outlined">
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {t("task_status")}
                </Typography>
                <Typography variant="h5" component="h2">
                    <TaskCompleteStatus {...taskInfo} />
                </Typography>
            </CardContent>
        </Card>}
        {!state.chichat && <Card variant="outlined">
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {t("corrective_action")}
                </Typography>
                <Typography variant="h5" component="h2">
                    <ThemeProvider theme={theme}>
                        <Button color="primary">{taskInfo.selectedAction.name}</Button>
                    </ThemeProvider>
                </Typography>
            </CardContent>
        </Card>}
        {!state.chichat && <Card variant="outlined">
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {t("assigned_employee")}
                </Typography>
                <Box display="flex">
                    <Box minWidth={30}>
                        <Avatar alt="Remy Sharp" src={taskInfo.assignedModel.photo} />
                    </Box>
                    <Box ml={2} mt={1}>
                        <Typography variant="h5" component="h2">
                            {taskInfo.assignedModel.name ? taskInfo.assignedModel.name : t("no_employee_assigned")}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>}
        {!state.chichat && <Card variant="outlined">
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {t("photos_attached")}
                </Typography>
                {taskInfo.attachPhotos.length > 0 ?
                    taskInfo.attachPhotos.map(attach => (
                        <>
                            <Box display="flex">
                                <Box minWidth={300}>
                                    <Typography variant="h5" component="h2">
                                        {t("attached_by")} {attach.attachedPersonName}
                                    </Typography>
                                </Box>
                                <Box ml={2} width="100%">
                                    <Typography variant="body2" color="textSecondary"
                                        style={{ textAlign: "right" }}>
                                        <span>{moment(attach.attachedTime).format("LT,MMM D")}</span>
                                    </Typography>
                                </Box>
                            </Box>

                            <div className={classes.imageRoot}>
                                <ImageList className={classes.imageList} rowHeight={180}>
                                    {attach.files.map((item, index) => (
                                        <ImageListItem key={index}
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                border: "1px red"
                                            }}>
                                            <img alt={"R"} src={item.filePath} />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </div>
                        </>
                    )) : <Typography variant="h5" component="h2">
                        {t("no_attached_photos")}
                    </Typography>
                }
            </CardContent>
        </Card>}
        <Card variant="outlined">
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {t("comments")}
                </Typography>
                {comments.length > 0 ?
                    comments.map((comment, commentIndex) => (
                        <div key={commentIndex}>
                            <TaskComment comment={comment}
                                avatarClass={classes.pink}
                                afterReply={afterReply}
                                canReplay={true}
                                taskInstanceId={taskInfo.taskInstanceId} />
                            {comment.childComments.map((childComment) => (
                                <div style={{
                                    paddingLeft: "50px",
                                    paddingRight: "30px",
                                    marginTop: "20px"
                                }}>
                                    <Paper style={{
                                        backgroundColor: "#F8F9FB",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "10px",
                                        paddingTop: "10px"
                                    }}>
                                        <TaskComment comment={childComment}
                                            avatarClass={classes.orange}
                                            afterReply={afterReply}
                                            canReplay={false}
                                            taskInstanceId={taskInfo.taskInstanceId} />
                                    </Paper>
                                </div>
                            ))}
                        </div>
                    )) : <Typography variant="h5" component="h2">
                        {t("no_comments")}
                    </Typography>
                }

            </CardContent>
        </Card>
        {!state.chichat && <Card variant="outlined">
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {t("follow_up")}
                </Typography>
                {followups.length > 0 ?
                    followups.map((followUp, index) => (
                        <Box display="flex" mb={1} mt={1}>
                            <Box minWidth={30}>
                                <Avatar className={classes.pink}>{followUp.followUpEmployeeName[0]}</Avatar>
                            </Box>
                            <Box width="70%" ml={2} mt={1}>
                                <Typography variant="h5" component="h2">
                                    {followUp.followUpEmployeeName}
                                    {/*<span style={{fontSize: "12px"}}>(manager)</span>*/}
                                </Typography>
                            </Box>
                            <Box width="30%">
                                <Typography variant="body2" color="textSecondary"
                                    style={{ textAlign: "right" }}>
                                    {moment(followUp.followUpTime).format("LT,MMM D")}
                                </Typography>
                            </Box>
                        </Box>
                    )) : <Typography variant="h5" component="h2">
                        {t("no_follow_up")}
                    </Typography>
                }
            </CardContent>
        </Card>}
    </>
});

export default TaskDetailInfo;