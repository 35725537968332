import React, { useEffect, useState } from 'react';
import { Badge, ClickAwayListener, Divider, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useStores from '../../Stores/useStores';
import { LightTooltip } from '../../Components/ToolTips';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { AuthenticateResponseModel } from '../../Models/AuthenticateModel';
import { NotificationCellInfo } from '../Notifications';
import { Notification } from '../../Models/Notification';

interface PageState {
    notifications: Notification[];
    notificationCount: number;
    notificationMenuOpen: boolean;

}

const initState: PageState = {
    notifications: [],
    notificationCount: 0,
    notificationMenuOpen: false,
};

const NotificationPopper = observer(() => {
    const [state, setState] = useState<PageState>(initState);
    const { t } = useTranslation();
    const { appStore, notificationStore } = useStores();
    const { orgCompanyId, haveExpireCompany, hasLicense, isHQ } = appStore.userAuth as AuthenticateResponseModel;
    const history = useHistory();
    const notificationRef = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const timer = setInterval(() => {
            getUnreadNotifications();
        }, 1800 * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const getUnreadNotifications = () => {
        if (appStore.userAuth && appStore.userAuth.isSuperAdmin) {
            return;
        }
        const token = appStore.userAuth?.token;
        const companyId = appStore.userAuth?.orgCompanyId;
        notificationStore.getLastUnread(token, companyId).then((data: any) => {
            setState({
                ...state,
                notifications: data.data.data.slice(0, 5),
                notificationCount: data.data.count
            });

            if ((haveExpireCompany !== data.haveExpireCompany) || (hasLicense !== data.hasLicense)) {
                let auth = appStore.userAuth as AuthenticateResponseModel;
                if (haveExpireCompany !== data.haveExpireCompany) {
                    auth.haveExpireCompany = data.haveExpireCompany;
                }
                if (hasLicense !== data.hasLicense) {
                    auth.hasLicense = data.hasLicense;
                    if (auth.hasLicense && orgCompanyId === companyId) {
                        auth.hasActionPermission = data.hasLicense;
                    }
                }
                appStore.setAuthenticated(auth).then(x => {
                    if (hasLicense !== data.hasLicense) {
                        if (isHQ) {
                            history.replace('/app/companyStructure');
                        } else {
                            history.replace('/app/expire');
                        }
                        window.location.reload();
                    }
                });
            }
        });
    };

    const handleShowNotifications = () => {
        setState({ ...state, notificationMenuOpen: false });
        history.replace('/app/notification');
    };

    const handleNotificationMenuToggle = () => {
        setState({ ...state, notificationMenuOpen: !state.notificationMenuOpen });
    };

    const handleNotificationCloseMenu = (event: React.MouseEvent<EventTarget>) => {
        if (notificationRef.current && notificationRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setState({ ...state, notificationMenuOpen: false });
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setState({ ...state, notificationMenuOpen: false });
        }
    };

    return (
        <>
            <LightTooltip title={`${t("notifications")}`} placement="bottom-start" arrow>
                <IconButton ref={notificationRef} onClick={() => handleNotificationMenuToggle()}>
                    <Badge
                        color="secondary"
                        badgeContent={state.notificationCount > 99 ? '99+' : state.notificationCount}
                    >
                        <NotificationsIcon style={{ color: '#374359' }} />
                    </Badge>
                </IconButton>
            </LightTooltip>
            <Popper
                open={state.notificationMenuOpen}
                anchorEl={notificationRef.current}
                placement="bottom-end"
                role={undefined}
                transition
                disablePortal
                style={{ width: "500px" }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleNotificationCloseMenu}>
                                <MenuList
                                    autoFocusItem={state.notificationMenuOpen}
                                    id="menu-list-grow2"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {state.notifications.map((item) => (
                                        <>
                                            <MenuItem style={{ padding: 0 }} onClick={handleShowNotifications} key={item.id}>
                                                <NotificationCellInfo {...item} />
                                            </MenuItem>
                                            <Divider style={{ width: '100%', color: '#9094A9' }} />
                                        </>
                                    ))}

                                    <MenuItem onClick={handleShowNotifications}>
                                        <span
                                            style={{
                                                color: '#1CAD5E',
                                                width: '100%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {t("view_all_notifications")}
                                        </span>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
});

export default NotificationPopper;