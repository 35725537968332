import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import useStores from "../../Stores/useStores";
import ReportHeader, {
  ReportHeaderState,
  ReportHeaderProps,
} from "../Reports/Comments/ReportHeader";
import { Paper, Typography, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Bar, Line } from "react-chartjs-2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { AuthenticateResponseModel } from "../../Models/AuthenticateModel";
import { t } from "i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paperHead: {
      padding: theme.spacing(2),
      textAlign: "left",
      height: 30,
      fontWeight: 700,
      color: "#374359",
      marginTop: 12,
      marginBottom: 1,
    },
    table: {
      width: "100%",
      marginTop: 0,
    },
    tableHead: {
      height: 60,
      background: "#F8F9FB",
    },
    tableRow: {
      height: 20,
    },
  })
);

const printPageStyle = `
  @page {
    size: 210mm 297mm;
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: always;
    }
    body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    width:100%;
  }
  }
`;

interface Datasets {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

interface ChartData {
  labels: string[];
  datasets: Datasets[];
}

interface taskCompletionRow {
  id: string;
  date: string;
  completed: number;
  due: number;
  delayedCompleted: number;
  total: number;
  companyId: string;
  companyName: string;
}

interface healthCheckRow {
  id: string;
  date: string;
  total: number;
  failed: number;
  failedRate: number;
  companyId: string;
  companyName: string;
}

interface correctiveAndFollow {
  id: string;
  date: string;
  corrective: number;
  total: number;
  correctiveRate: number;
  companyId: string;
  companyName: string;
}

interface PageState {
  allTaskCompletionRows: taskCompletionRow[];
  allHealthCheckRows: healthCheckRow[];
  allCorrectiveAndFollowRows: correctiveAndFollow[];
  taskCompletionRows: taskCompletionRow[];
  healthCheckRows: healthCheckRow[];
  correctiveAndFollowRows: correctiveAndFollow[];
  taskCompletionData: ChartData;
  healthCheckData: ChartData;
  correctiveAndFollowData: ChartData;
  company: any;
}



const trendBizReport = observer(() => {
  const defaultTaskCompletionData: ChartData = {
    labels: [],
    datasets: [
      {
        label: t("completed"),
        data: [],
        backgroundColor: "#1CAD5E",
        borderColor: "#1CAD5E",
        borderWidth: 1,
      },
      {
        label: t("delayed_completion"),
        data: [],
        backgroundColor: "#FF6E00",
        borderColor: "#FF6E00",
        borderWidth: 1,
      },
      {
        label: t("incompleted"),
        data: [],
        backgroundColor: "#58A3FF",
        borderColor: "#58A3FF",
        borderWidth: 1,
      },
    ],
  };
  const defaultHealthCheckData: ChartData = {
    labels: [],
    datasets: [
      {
        label: t("total_task"),
        data: [],
        backgroundColor: "#1CAD5E",
        borderColor: "#1CAD5E",
        borderWidth: 1,
      },
      {
        label: t("failed"),
        data: [],
        backgroundColor: "#FF6E00",
        borderColor: "#FF6E00",
        borderWidth: 1,
      },
    ],
  };
  const defaultCorrectiveAndFollowData = {
    labels: [],
    datasets: [
      {
        label: t("corrective_actions"),
        data: [],
        backgroundColor: "#1CAD5E",
        borderColor: "#1CAD5E",
        borderWidth: 1,
      },
      {
        label: t("total"),
        data: [],
        backgroundColor: "#FF6E00",
        borderColor: "#FF6E00",
        borderWidth: 1,
      },
    ],
  };
  
  const initState: PageState = {
    allTaskCompletionRows: [],
    allHealthCheckRows: [],
    allCorrectiveAndFollowRows: [],
    taskCompletionRows: [],
    healthCheckRows: [],
    correctiveAndFollowRows: [],
    taskCompletionData: defaultTaskCompletionData,
    healthCheckData: defaultHealthCheckData,
    correctiveAndFollowData: defaultCorrectiveAndFollowData,
    company: { id: "00000000-0000-0000-0000-000000000000", name: "All Companies" },
  };
  
  const classes = useStyles();
  const { trendBizReportsStore, appStore } = useStores();
  const [state, setState] = useState<PageState>(initState);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedDate = moment(appStore.getFilterDate()).format("YYYY-MM-DD HH:mm:ss");
    const { orgCompanyId } = appStore.userAuth as AuthenticateResponseModel;
    trendBizReportsStore.setPreFilter([
      {
        field: "filterOrgCompanyId",
        value: orgCompanyId,
      },
      {
        field: "selectedDate",
        value: selectedDate,
      },
    ]);
    getReportData(selectedDate);
  }, []);
  const handleDownload = (filters: ReportHeaderState) => { };
  const handlePrint = (filters: ReportHeaderState) => {
    if (typeof handlePrintSelf == "function") {
      handlePrintSelf();
    }
  };
  const handlePrintSelf = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: printPageStyle,
  });

  const handleFilterChange = (filters: ReportHeaderState) => {
    const selectedDate = moment(filters.startOfWeek).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    getReportData(selectedDate);
  };

  const handleCompanyChange = (company: any) => {
    rowsToData(company);
  };

  const rowsToData = (
    company: any,
    allTaskCompletionRows?: taskCompletionRow[],
    allHealthCheckRows?: healthCheckRow[],
    allCorrectiveAndFollowRows?: correctiveAndFollow[]
  ) => {
    allTaskCompletionRows =
      allTaskCompletionRows || state.allTaskCompletionRows;
    allHealthCheckRows = allHealthCheckRows || state.allHealthCheckRows;
    allCorrectiveAndFollowRows =
      allCorrectiveAndFollowRows || state.allCorrectiveAndFollowRows;

    const taskCompletionRows = allTaskCompletionRows.filter(
      (d) => d.companyId === company.id
    );
    const healthCheckRows = allHealthCheckRows.filter(
      (d) => d.companyId === company.id
    );
    const correctiveAndFollowRows = allCorrectiveAndFollowRows.filter(
      (d) => d.companyId === company.id
    );


    const correctiveLabels: string[] = [];
    const correctiveTotalData: number[] = [];
    const correctiveData: number[] = [];
    correctiveAndFollowRows.forEach((item: any) => {
      correctiveLabels.push(item.date);
      correctiveData.push(item.corrective);
      correctiveTotalData.push(item.total);
    });
    const correctiveAndFollowData: ChartData = JSON.parse(
      JSON.stringify(defaultCorrectiveAndFollowData)
    );
    correctiveAndFollowData.labels = correctiveLabels;
    correctiveAndFollowData.datasets[0].data = correctiveData;
    correctiveAndFollowData.datasets[1].data = correctiveTotalData;

    const healthCheckLabels: string[] = [];
    const healthCheckFailedData: number[] = [];
    const healthCheckTotalData: number[] = [];
    healthCheckRows.forEach((item: any) => {
      healthCheckLabels.push(item.date);
      healthCheckFailedData.push(item.failed);
      healthCheckTotalData.push(item.total);
    });
    const healthCheckData: ChartData = JSON.parse(
      JSON.stringify(defaultHealthCheckData)
    );
    healthCheckData.labels = healthCheckLabels;
    healthCheckData.datasets[0].data = healthCheckTotalData;
    healthCheckData.datasets[1].data = healthCheckFailedData;

    const taskCompletionLabels: string[] = [];
    const taskCompletionCompleteData: number[] = [];
    const taskCompletionDueData: number[] = [];
    const taskCompletionDelayedData: number[] = [];
    taskCompletionRows.forEach((item: any) => {
      taskCompletionLabels.push(item.date);
      taskCompletionCompleteData.push(item.completed);
      taskCompletionDueData.push(item.due);
      taskCompletionDelayedData.push(item.delayedCompleted);
    });
    const taskCompletionData: ChartData = JSON.parse(
      JSON.stringify(defaultTaskCompletionData)
    );
    taskCompletionData.labels = taskCompletionLabels;
    taskCompletionData.datasets[0].data = taskCompletionCompleteData;
    taskCompletionData.datasets[1].data = taskCompletionDelayedData;
    taskCompletionData.datasets[2].data = taskCompletionDueData;

    setState({
      ...state,
      company,
      allTaskCompletionRows,
      allHealthCheckRows,
      allCorrectiveAndFollowRows,
      taskCompletionRows,
      taskCompletionData: taskCompletionData,
      healthCheckData: healthCheckData,
      healthCheckRows,
      correctiveAndFollowData: correctiveAndFollowData,
      correctiveAndFollowRows,
    });
  };

  const getReportData = (selectedDate: string) => {
    Promise.all([
      trendBizReportsStore.getCorrectiveReports(selectedDate),
      trendBizReportsStore.getHealthCheckReports(selectedDate),
      trendBizReportsStore.getCompletionReports(selectedDate),
    ]).then((values) => {
      const taskCompletionRows = values[2];
      const healthCheckRows = values[1];
      const correctiveAndFollowRows = values[0];
      rowsToData(
        state.company,
        taskCompletionRows,
        healthCheckRows,
        correctiveAndFollowRows
      );
    });
  };

  const reportHeaderProps: ReportHeaderProps = {
    title: t("companies_trend_reports"),
    description: t("generate_corrective_actions_follow_up_reports"),
    showFromDate: false,
    showToDate: false,
    showDepartment: false,
    showEmployee: false,
    showWeekPicker: true,
    showCompanies: true,
    showDownload: false,
    showPrint: true,
    handleChange: handleFilterChange,
    handleDownload: handleDownload,
    handlePrint: handlePrint,
    handleCompanyChange: handleCompanyChange,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <>
      <ReportHeader {...reportHeaderProps} />
      <div
        ref={componentRef}
        className="section-to-print"
      >
        <div className="page-break" />
        <Paper style={{ height: 40 }} className={classes.paperHead}>
          <Typography variant="h6">{`${t("task_completion_of")} ${state.company.name}`}</Typography>
        </Paper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Bar
                data={state.taskCompletionData}
                options={options}
                height={85}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>{t("date")}</TableCell>
                    <TableCell align="center">{t("completed")}</TableCell>
                    <TableCell align="center">{t("incompleted")}</TableCell>
                    <TableCell align="center">{t("delayed_completion")}</TableCell>
                    <TableCell align="center">{t("total_task")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.taskCompletionRows.map((row) => (
                    <TableRow key={row.id} className={classes.tableRow}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="center" scope="row">
                        {row.completed}
                      </TableCell>
                      <TableCell align="center">{row.due}</TableCell>
                      <TableCell align="center">
                        {row.delayedCompleted}
                      </TableCell>
                      <TableCell align="center">{row.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <div className="page-break" />
        <Paper style={{ height: 40 }} className={classes.paperHead}>
          <Typography variant="h6">{`${t("health_check_report_of")} ${state.company.name}`}</Typography>
        </Paper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Bar data={state.healthCheckData} options={options} height={85} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>{t("date")}</TableCell>
                    <TableCell align="center">{t("total_task")}</TableCell>
                    <TableCell align="center">{t("failed")}</TableCell>
                    <TableCell align="center">{t("failed_rate")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.healthCheckRows.map((row) => (
                    <TableRow key={row.id} className={classes.tableRow}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="center">{row.total}</TableCell>
                      <TableCell align="center">{row.failed}</TableCell>
                      <TableCell align="center">
                        {(row.failedRate * 100).toFixed(2)} %
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <div className="page-break" />
        <Paper style={{ height: 40 }} className={classes.paperHead}>
          <Typography variant="h6">{`${t("corrective_actions_of")} ${state.company.name}`}</Typography>
        </Paper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Line
                data={state.correctiveAndFollowData}
                options={options}
                height={85}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>{t("date")}</TableCell>
                    <TableCell align="center">{t("corrective_actions")}</TableCell>
                    <TableCell align="center">{t("corrective_rate")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.correctiveAndFollowRows.map((row) => (
                    <TableRow key={row.id} className={classes.tableRow}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="center">{row.corrective}</TableCell>
                      <TableCell align="center">
                        {(row.correctiveRate * 100).toFixed(2)} %
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default trendBizReport;
