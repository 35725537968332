import { observer } from "mobx-react";
import React, { useState } from "react";
import { CommonPageTitle } from "../../Components/CommonPageTitle";
import { InstanceFilters } from "../../Components/InstanceFilters"
import { Guid } from "guid-typescript";
import CommonGridPage from "../../Components/CommonGridPage";
import useStores from "../../Stores/useStores";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { FollowUpInstance } from "../../Models/FollowUpInstance";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { TaskRowCellInfo } from "../CorrectiveActionInstance"
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { LightTooltip } from "../../Components/ToolTips";

interface CorrectiveActionInstancePageState {
    gridId: string,
    deptId: string,
    categoryId: string,
    filterDate: Date,
}

const initState: CorrectiveActionInstancePageState = {
    gridId: Guid.create().toString(),
    filterDate: new Date(),
    deptId: '',
    categoryId: '',
};

export function FollowupCellInfo(props: FollowUpInstance) {
    const { commentEmployeePhoto, commentEmployeeName, commentTime, commentMessage } = props;
    const listStyle = {
        width: '100%',
    }
    return <>
        <List style={listStyle}>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={commentEmployeePhoto} />
                </ListItemAvatar>
                <ListItemText
                    primary=
                    {
                        <React.Fragment>
                            <Typography
                                style={{
                                    fontWeight: 700,
                                    color: "#374359",
                                    marginTop: 12,
                                    marginBottom: 2,
                                    marginRight: 10,
                                    display: 'inline',
                                }}
                                color="textPrimary"
                            >
                                {commentEmployeeName}
                            </Typography>
                            {moment(commentTime).format("LT,MMM D")}
                        </React.Fragment>
                    }
                    secondary={<LightTooltip title={commentMessage} placement="bottom-start" arrow>
                    <span>{commentMessage}</span>
                </LightTooltip>}
                />
            </ListItem>
        </List>
    </>
}

const FollowUpInstancePage = observer(() => {
    const { appStore } = useStores();
    initState.filterDate = appStore.getFilterDate();
    const [state, setState] = useState<CorrectiveActionInstancePageState>(initState);
    const { followUpInstanceStore } = useStores();
    const { t } = useTranslation();

    followUpInstanceStore.setPreFilter([{
        field: 'selectedDate',
        value: moment(initState.filterDate).format("YYYY-MM-DD HH:mm:ss")
    }]);

    const handleFilterChange = (filters: any) => {
        setState({ ...state, ...filters });
        const changedState = Object.assign({}, { ...state }, { ...filters });
        followUpInstanceStore.setPreFilter([{
            field: 'categoryId',
            value: changedState.categoryId
        }, {
            field: 'selectedDate',
            value: moment(changedState.filterDate).format("YYYY-MM-DD HH:mm:ss")
        }, {
            field: 'departmentId',
            value: changedState.deptId
        }]);
        followUpInstanceStore.query();
    }
    const columns: GridColDef[] = [
        {
            field: "taskInstanceName", headerName: t('task'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const instance: FollowUpInstance = row.row as FollowUpInstance;
                return <TaskRowCellInfo taskInstanceName={instance.taskInstanceName}
                    checklistInstanceName={instance.checklistInstanceName}
                    executeTime={instance.executeTime}
                />;
            })
        },
        {
            field: "commentMessage", headerName: t('latest_comment'), flex: 1, sortable: false,
            renderCell: ((row: GridCellParams) => {
                const instance: FollowUpInstance = row.row as FollowUpInstance;
                if (!instance.commentEmployeeName) {
                    return <></>
                }
                return <FollowupCellInfo {...instance}></FollowupCellInfo>;
            })
        },
    ];
    return <>
        <CommonPageTitle title={t('follow_up')} subTitle={t('checkout_latest_follow_up')} />
        <InstanceFilters handleFilterChange={handleFilterChange} />
        <CommonGridPage<FollowUpInstance>
            key={state.gridId}
            columns={columns}
            cancelSearch={true}
            useAction={false}
            store={followUpInstanceStore}
            rowHeight={80}
        />

    </>
})
export { FollowUpInstancePage }