import { TrendBizReport,TrendQueryCriteria } from "../Models/Reports";
import { BaseService } from "./BaseService";
import axios from "axios";

export class TrendBizReportsService extends BaseService<TrendBizReport> {
    constructor() {
        super('TrendBizReports');
    }

    async getTrendReportsSummaryAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendBizReports/GetTrendReportsSummary`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

    async getCompletionReportsAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendBizReports/getCompletionReports`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

    async getHealthCheckReportsAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendBizReports/GetHealthCheckReports`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

    async getCorrectiveReportsAsync(criteria:TrendQueryCriteria): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/TrendBizReports/GetCorrectiveReports`;
        const response = await axios.post<any>(url, criteria);
        return response.data;
    }

}